
//React
import React from 'react';
import { useTranslation } from 'react-i18next';

//component
import { useAppSelector } from "../../stores"
import { FamlaServices } from '../../services'
import { InputForm, SelectForm, Spiner } from '../../components'

//interface

import { iAlert } from '../../interfaces'
import { Routes } from '../../routes'




interface ChildProps {
    processId: string
    processName: string
}

interface folder {
    _id: string
    title: string
}


const AssignProcessToFolderModal = (props: ChildProps) => {
    const { processId, processName } = props
    const { t } = useTranslation();
    const users = useAppSelector(state => state.auth)
    const [state, setState] = React.useState({
        email: "",
        isSubmit: false,
        role: "",
        myFolder: [],
        folderId: ""
    })

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: '',

    })



    const selectOption = state.myFolder.map((folder: folder) => ({
        label: folder.title, value: folder._id

    }))

    React.useEffect(() => {
        const loadFolder = async () => {
            let data = await FamlaServices("api_host", `/project/my`, "GET", "", users.access_token);
            if (data.status === 200) {
                setState((prev) => ({ ...prev, myFolder: data.body.data }))
            }
        }

        loadFolder()

    }, [users.access_token])

    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'email') {
            setState((prev) => ({ ...prev, email: fieldValue }))
        } else if (name === 'role') {
            setState({ ...state, role: fieldValue })
        } else if (name === 'selectOwner') {
            setState({ ...state, folderId: fieldValue })
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.folderId.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: 'Select a folder' });
            return;
        }


        setState({ ...state, isSubmit: true });

        let newFormData = {
            project_id: state.folderId,
            updated_at: Date.now(),
        }

        let data = await FamlaServices("api_host", `/process/update/${processId}`, "PUT", newFormData, users.access_token);

        if (data.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t("navigation.processClassifiedWithSuccess") });
            window.location.replace(Routes.HOMEVIEW);
        }

        else if (data.status === 400 && data.body.description === "process cannot be update by its user") {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.processCannot") });

        }
        else if (data.status === 404) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcuredProcessNotFund") });

        } else if (data.status === 401) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.youAreNotLoggedIn") });
        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        }
        setState({ ...state, isSubmit: false });
    };

    return (
        <div className="modal" id={`asignProcessToFolder${processId}`} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark ">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white fs-5">{t('navigation.assigningProcessFolder')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {alert.isActive &&
                            <div className={`alert alert-${alert.type}`} role="alert">
                                {alert.message}
                            </div>
                        }
                        <InputForm
                            inputType="text"
                            inputLabel=""
                            inputName="email"
                            inputValue={processName}
                            isFloatingLabel={false}
                            labelClass="text-theme"
                            onChange={(e) => handleChange(e)}
                            inputContainerClass={'mb-3 p-0 disabled'}
                            inputClass="bg-dark text-light text-bg-dark px-3 disabled"
                            inputStyle={{ height: 0, padding: 0 }}
                        />

                        <SelectForm
                            selectClass="bg-dark text-light text-bg-dark"
                            placeholder={t('navigation.selectFolder')}
                            selectName='selectOwner'
                            selectOptions={selectOption}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">{state.isSubmit ? <Spiner className='spinner-border-sm' /> : <>{t('words.organize')}</>}</button>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default AssignProcessToFolderModal;