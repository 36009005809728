import { Block, Line } from '../types';
import { STYLES } from '../styles/constants';

export function getBlockDimensions(block: Block): { width: number; height: number } {
  // Si le bloc a des dimensions personnalisées, les utiliser
  if (block.width && block.height) {
    return {
      width: block.width,
      height: block.height
    };
  }

  // Sinon, utiliser les dimensions par défaut selon le type
  switch (block.type) {
    case 'start':
    case 'end':
      return {
        width: STYLES.startEnd.width,
        height: STYLES.startEnd.height
      };
    case 'process':
      return {
        width: STYLES.process.width,
        height: STYLES.process.height
      };
    case 'decision':
      return {
        width: STYLES.decision.width,
        height: STYLES.decision.height
      };
    default:
      return {
        width: STYLES.process.width,
        height: STYLES.process.height
      };
  }
}

export function getBlockFill(block: Block): string {
  return block.fill || STYLES.colors.getBlockColors(block.type).bgColor;
}

export function removeBlockAndConnections(
  blocks: Block[],
  blockId: string,
  lines?: Line[]
): { updatedBlocks: Block[]; updatedLines: Line[] | undefined } {
  const updatedBlocks = blocks.filter(b => b.id !== blockId);
  const updatedLines = lines?.filter(
    line => line.from !== blockId && line.to !== blockId
  );

  return { updatedBlocks, updatedLines };
}

export function ensureBlockDimensions(block: Block): Block {
  const dimensions = getBlockDimensions(block);
  return {
    ...block,
    width: block.width || dimensions.width,
    height: block.height || dimensions.height
  };
}