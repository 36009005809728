// Reac Component
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

// App Component
import { Routes } from "../../routes";
import { BreadCrumb, SearchInput } from "../../components";
import { FamlaServices } from "../../services";
import { AddAdmin } from "../../views/modal";

// Features
import ListProcess from "./ListProcess"
import { logout, useAppDispatch, useAppSelector } from "../../stores";
import { ShareProcessModal } from './ShareProcessModal'

//interface
import { iAlert } from "../../interfaces";
import { iProcess, iUser } from "../../components/type";

//utils
import useSearch from "../../utils/useSearch";
import { useTranslation } from "react-i18next";

const DashProcessPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const [users, setUsers] = React.useState<iUser[]>([]);
    const [process, setProcess] = React.useState<iProcess[]>([]);
    const companyId = auth.session.company_id;
    const [loading, setLoading] = React.useState(false);
    const [isSubmit, setIsSubmit] = React.useState(false);
    // const [filter, setFilter] = React.useState<string | undefined>('USER');
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    });
    const [alert1, setAlert1] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    });

    const [pagination, setPagination] = React.useState({
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
    });

    const enrichData = true;

    const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch(`/process/search/by/company`, companyId, enrichData);

    const logOut = () => {
        dispatch(logout());

        navigate(`${Routes.AUTH_LOGIN}?status=10`);
    };

    async function loadAllUsers(currentPage: number, per_page: number) {
        setLoading(true);

        try {
            setAlert({ isActive: false, type: '', message: '' });

            let data = await FamlaServices("api_host", `/users/company/${companyId}`, "GET", "", auth.access_token);

            if (data.status === 200) {
                const paginage = data.body.paginage;
                const users = [
                    ...data.body.data.admin,
                    ...data.body.data.worker,
                    ...data.body.data.owner
                ]
                setUsers(users);

                setPagination({
                    ...pagination,
                    current_page: paginage.current_page,
                    total: paginage.total,
                    last_page: paginage.last_page
                });
            } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                setAlert({ isActive: true, type: 'warning', message: 'You do not have authorisation to continue' });
            } else if (data.status === 401) {
                logOut();
            } else {
                setAlert({ isActive: true, type: 'danger', message: 'An error has occurred, please try again later' });
            }
        } catch (e) {
            if (e instanceof Error) {

            }
        } finally {
            setLoading(false);
        }
    }

    async function getProcessStats(processId: string): Promise<{ timeSaved: number; mappingCreationDate: string }> {
        try {
            let response = await FamlaServices("api_host", `/stats/process/${processId}`, "GET", "", auth.access_token);

            if (response.status === 200) {
                return {
                    timeSaved: response.body.data || 0,
                    mappingCreationDate: response.body.time || "",
                };
            }
        } catch (e) {
            // En cas d'erreur, on retourne des valeurs par défaut
        }
        return { timeSaved: 0, mappingCreationDate: "" };
    }



    async function loadProcess(currentPage: number, per_page: number) {
        setLoading(true);

        try {
            setAlert({ isActive: false, type: '', message: '' });

            let data = await FamlaServices("api_host", `/process/company/end/process`, "GET", "", auth.access_token);

            if (data.status === 200) {
                const paginage = data.body.paginage;
                let processList = data.body.data;

                // Ajout de la propriété timeSaved pour chaque process
                const updatedProcesses = await Promise.all(
                    processList.map(async (process: iProcess) => {
                        const { timeSaved, mappingCreationDate } = await getProcessStats(process._id);
                        return { ...process, timeSaved, mappingCreationDate };
                    })
                );

                setProcess(updatedProcesses);

                setPagination({
                    ...pagination,
                    current_page: paginage.current_page,
                    total: paginage.total,
                    last_page: paginage.last_page
                });
            } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                setAlert({ isActive: true, type: 'warning', message: 'You do not have authorisation to continue' });
            } else if (data.status === 401) {
                logOut();
            } else {
                setAlert({ isActive: true, type: 'danger', message: 'An error has occurred, please try again later' });
            }
        } catch (e) {
            if (e instanceof Error) {

            }
        } finally {
            setLoading(false);
        }
    }



    React.useEffect(() => {
        loadAllUsers(pagination.current_page, pagination.per_page);
        loadProcess(pagination.current_page, pagination.per_page)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.access_token]);

    const handleNextPage = () => {
        if (pagination.current_page < pagination.last_page) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page + 1 }));
            loadAllUsers(pagination.current_page + 1, pagination.per_page);
        }
    };

    const handlePrevPage = () => {
        if (pagination.current_page > 1) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page - 1 }));
            loadAllUsers(pagination.current_page - 1, pagination.per_page);
        }
    };


    const [selectedProcesses, setSelectedProcesses] = useState<string[]>([]);


    const handleShareToAll = async () => {
        const form = {
            allprocess: false,
            process_list: selectedProcesses,
            alluser: true,
            user_list: []
        }
        let data = await FamlaServices("api_host", `/admin/add_viewer/`, "PUT", form, auth.access_token);
        if (data.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t('dashboard.theSelectedProcessWill') });
            setTimeout(() => {
                setSelectedProcesses([]);
                setAlert({ isActive: false, type: '', message: "" });
            }, 3000);
        } else {
            setAlert({ isActive: true, type: 'warning', message: t('navigation.youAreNotLoggedIn') });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 3000);
        }
    };

    const handleShareToUsers = async (userIds: string[]) => {
        setIsSubmit(true)
        const form = {
            allprocess: false,
            process_list: selectedProcesses,
            alluser: false,
            user_list: userIds
        }
        let data = await FamlaServices("api_host", `/admin/add_viewer/`, "PUT", form, auth.access_token);
        if (data.status === 200) {
            setIsSubmit(false)
            setSelectedProcesses([])
            setAlert1({ isActive: true, type: 'success', message: t('dashboard.theProcessSuccessfullyShared') });
            setTimeout(() => {
                setAlert1({ isActive: false, type: '', message: "" });
            }, 3000);
        } else {
            setAlert({ isActive: true, type: 'warning', message: t('navigation.youAreNotLoggedIn') });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 3000);
        }
    };

    // const filterOption = [
    //     { label: "USER", value: "USER" },
    // ]

    const downloadTranscript = async () => {
        try {
            const dataExport = {
                search: searchQuery || "",
                filter: "TITLE"
            }

            const response = await FamlaServices("api_host", `/process/search/by/company/export`, "POST", dataExport, auth.access_token);

            if (response.status !== 200) {
                throw new Error(`Erreur: ${response.status}`);
            }

            if (response.contentType && response.contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                const blob = new Blob([response.body], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `process.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.warn("Le fichier reçu n'est pas un fichier Excel.");
            }

        } catch (error) {
        }
    };

    return (
        <>
            <div className="container-fluid p-4">
                <BreadCrumb title={t("views.processView.library")} links={Routes.HOMEVIEW} actionName={t("dashboard.exitDashboard")} />
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card rounded-3 bg-dark border-secondary">
                            {alert.isActive &&
                                <div className={`alert alert-${alert.type} mb-0 col-12 col-md-6 col-lg-6 offset-lg-6 my-2`} role="alert">
                                    {alert.message}
                                </div>
                            }
                            <div className="d-flex justify-content-between my-2 me-2 gap-2">
                                <div className="ms-2">
                                    <SearchInput
                                        inputType="text"
                                        inputValue={searchQuery}
                                        placeholderValue={`${t('words.name')}`}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                                        inputContainerClass="w-100"
                                        inputStyle={{ borderRadius: 20 }}
                                        inputClass="bg-dark text-light border-0"
                                        iconClass="fa-solid fa-magnifying-glass "
                                        iconName=""
                                        formClass='rounded-2  text-light text-bg-dark bg-dark border border-secondary'
                                        iconContainer='bg-dark text-secondary border-0'
                                    />
                                </div>
                                <div className="d-flex gap-2">
                                    <button className="btn btn-primary btn-sm" onClick={downloadTranscript}>{t('dashboard.exportXls')}</button>
                                    <button data-bs-target="#shareProcessModal" data-bs-toggle="modal" className="btn btn-primary btn-sm small"
                                        disabled={selectedProcesses.length === 0}
                                    >
                                        <i className='fa fa-users me-1'></i> {t('dashboard.shareToSelectedUser')}
                                    </button>
                                    <button
                                        className="btn btn-primary btn-sm small"
                                        onClick={handleShareToAll}
                                        disabled={selectedProcesses.length === 0}
                                    >
                                        <i className='fa fa-share me-1'></i>{t('dashboard.shareToAll')}
                                    </button>
                                </div>

                            </div>
                            <div className="table-responsive border-0">
                                {searchQuery === "" ?
                                    <ListProcess
                                        loading={loading}
                                        featuresDatas={process}
                                        context="user"
                                        key={1}
                                        listType="ADMIN"
                                        setUsers={setUsers}
                                        selectedProcesses={selectedProcesses}
                                        onSelectionChange={setSelectedProcesses}
                                    />
                                    :
                                    <ListProcess
                                        loading={searchLoad}
                                        featuresDatas={searchResults}
                                        context="user"
                                        key={2}
                                        setUsers={setUsers}
                                        listType="ADMIN"
                                        selectedProcesses={selectedProcesses}
                                        onSelectionChange={setSelectedProcesses}
                                    />
                                }
                            </div>
                            {/* <div className="card-footer border-top-0 bg-dark">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination mb-0 justify-content-end">
                                        <li className={`page-item ${pagination.current_page === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link mx-1 rounded" onClick={handlePrevPage}>
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                        </li>
                                        <li className={`page-item cursor-pointer  ${pagination.current_page === 1 ? 'active' : ''}`} onClick={() => { loadAllUsers(1, pagination.per_page) }}>
                                            <button className="page-link mx-1 rounded " >1</button>
                                        </li>
                                        {pagination.last_page >= 2 &&
                                            <li className={`page-item cursor-pointer  ${pagination.current_page === 2 ? 'active' : ''}`} onClick={() => { loadAllUsers(2, pagination.per_page) }}>
                                                <button className="page-link mx-1 rounded " >2</button>
                                            </li>}
                                        {pagination.last_page >= 3 &&
                                            <li className={`page-item cursor-pointer  ${pagination.current_page === 3 ? 'active' : ''}`} onClick={() => { loadAllUsers(3, pagination.per_page) }}>
                                                <button className="page-link mx-1 rounded " >3</button>
                                            </li>
                                        }
                                        <li className={`page-item ${pagination.current_page >= pagination.last_page ? 'disabled' : ''}`}>
                                            <button className="page-link mx-1 rounded bg-transparent" onClick={handleNextPage} disabled={pagination.current_page >= pagination.last_page}>
                                                <i className="fa-solid fa-chevron-right text-secondary"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <AddAdmin setUsers={setUsers} companyId={companyId} />
            <ShareProcessModal
                onShare={handleShareToUsers}
                users={users}
                isSubmit={isSubmit}
                alert={alert1}
            />

        </>
    )
};

export default DashProcessPage;