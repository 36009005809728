import React from 'react';
import { Users, UserCheck } from 'lucide-react';
import { iUser } from '../../../components/type';
import { useTranslation } from 'react-i18next';

interface Collaborator {
    user: iUser;
    edit: boolean;
}

interface CollaboratorModalProps {
    collaborators: Collaborator[];
    onToggleAccess: (userId: string) => void;
    onGrantAllAccess: () => void;
}

export function CollaboratorModal({
    collaborators,
    onToggleAccess,
    onGrantAllAccess,
}: CollaboratorModalProps) {

    const { t } = useTranslation()
    return (
        <div className="modal fade" id="collaboratorModalEdit" tabIndex={-1} aria-labelledby="collaboratorModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title d-flex align-items-center gap-2" id="collaboratorModalLabel">
                            <Users size={24} />
                            {t('features.swimlane.enableMapEdit')}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        {/* <button
                            onClick={onGrantAllAccess}
                            className="btn btn-primary d-flex align-items-center gap-2 mb-3"
                        >
                            <UserCheck size={18} />
                            Donner accès à tous les collaborateurs
                        </button> */}

                        <div className="overflow-auto" style={{ maxHeight: "400px" }}>
                            {collaborators.map((collaborator) => (
                                <div key={collaborator.user._id} className="d-flex justify-content-between align-items-center p-3 bg-light rounded mb-2">
                                    <div>
                                        <h6 className="mb-0">{collaborator.user.fullname}</h6>
                                        <small className="text-muted">{collaborator.user.email}</small>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <span className={`badge ${collaborator.edit ? 'bg-success' : 'bg-secondary'}`}>
                                            {collaborator.edit ? t('features.swimlane.accessGranted') : t('features.swimlane.noAccess')}
                                        </span>
                                        <button
                                            onClick={() => onToggleAccess(collaborator.user._id ?? "")}
                                            className={`btn ${collaborator.edit ? 'btn-outline-danger' : 'btn-outline-success'}`}
                                        >
                                            {collaborator.edit ? t('features.swimlane.removeAccess') : t('features.swimlane.giveAccess')}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            {t('words.close')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
