import * as d3 from 'd3';
import { STYLES } from '../styles/constants';
import { editHeader } from '../utils/headerEditor';
import { SwimlaneData, EditMode } from '../types';
import { createRowContextMenu } from './menus/RowContextMenu';

export function renderSubHeaders(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  data: SwimlaneData,
  onSave: (data: SwimlaneData) => void,
  editMode: EditMode,
  onRowOperation: (index: number, operation: 'up' | 'down' | 'delete') => void,
  t: (key: string) => string
) {
  const subHeaderContainer = container
    .append('div')
    .attr('class', 'sub-headers')
    .style('display', 'flex')
    .style('flex-direction', 'column');

  data.subHeaderRows.headers.forEach((header, index) => {
    const lane = subHeaderContainer
      .append('div')
      .attr('class', 'lane')
      .style('width', '100%')
      .style('height', `${STYLES.header.laneHeight}px`)
      .style('border-left', `1px solid ${STYLES.colors.border}`)
      .style('border-right', `1px solid ${STYLES.colors.border}`)
      .style('border-bottom', `1px solid ${STYLES.colors.border}`)
      .style('border-top', index === 0 ? `1px solid ${STYLES.colors.border}` : 'none')
      .style('background-color', STYLES.colors.background)
      .style('position', 'relative');

    const sideHeader = lane
      .append('div')
      .attr('class', 'side-header')
      .style('width', `${STYLES.header.sideHeaderWidth}px`)
      .style('height', '100%')
      .style('position', 'absolute')
      .style('left', '0')
      .style('top', '0')
      .style('background-color', STYLES.colors.background)
      .style('border-right', `1px solid ${STYLES.colors.border}`)
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'center');

    const headerContent = sideHeader
      .append('div')
      .style('position', 'relative')
      .style('width', '100%')
      .style('height', '100%')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'center');

    if (editMode.enabled) {
      const contextMenuTrigger = headerContent
        .append('div')
        .attr('class', 'context-menu-trigger')
        .style('position', 'absolute')
        .style('top', '4px')
        .style('right', '4px')
        .style('cursor', 'pointer')
        .style('padding', '4px')
        .style('border-radius', '4px')
        .style('display', 'flex')
        .style('gap', '2px')
        .style('opacity', '0')
        .style('transition', 'opacity 0.2s')
        .on('click', (event) => {
          event.stopPropagation();
          createRowContextMenu(
            headerContent,
            { x: event.pageX, y: event.pageY },
            {
              onAddRowUp: () => onRowOperation(index, 'up'),
              onAddRowDown: () => onRowOperation(index, 'down'),
              onDeleteRow: () => onRowOperation(index, 'delete')
            },
            t,
          );
        });

      // Create the three dots
      for (let i = 0; i < 3; i++) {
        contextMenuTrigger.append('div')
          .style('width', '4px')
          .style('height', '4px')
          .style('background-color', '#666')
          .style('border-radius', '50%');
      }

      // Show/hide menu trigger on hover
      headerContent
        .on('mouseenter', function () {
          contextMenuTrigger.style('opacity', '1');
        })
        .on('mouseleave', function () {
          contextMenuTrigger.style('opacity', '0');
        });
    }

    // Modified text rendering approach
    const textContainer = headerContent
      .append('div')
      .attr('class', 'header-text')
      .style('position', 'relative')
      .style('height', '100%')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'center');

    const displayText = header.length > 15 ? header.slice(0, 15) + '...' : header;

    textContainer
      .append('div')
      .style('transform', 'rotate(-90deg)')
      .style('transform-origin', 'center')
      .style('text-overflow', 'ellipsis')
      .style('white-space', 'nowrap')
      .style('color', STYLES.colors.text)
      .style('cursor', 'pointer')
      .style('padding', '5px')
      .text(displayText)
      .on('click', (event) => {
        editHeader(event.target as HTMLElement, index, data, onSave);
      });

    // Main lane area
    lane
      .append('div')
      .attr('class', 'lane-content')
      .style('margin-left', `${STYLES.header.sideHeaderWidth}px`)
      .style('height', '100%')
      .style('position', 'relative')
      .style('background-color', STYLES.colors.background);
  });
}