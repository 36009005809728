import { SwimlaneData, Block, ProcessGroup, Line } from '../types';
import { STYLES } from '../styles/constants';

interface RowOperationResult {
  updatedData: SwimlaneData;
  updatedBlocks: Block[];
  updatedGroups: ProcessGroup[];
}

export function deleteRow(
  data: SwimlaneData,
  blocks: Block[],
  groups: ProcessGroup[],
  index: number
): RowOperationResult {
  // Remove header
  const newHeaders = [...data.subHeaderRows.headers];
  newHeaders.splice(index, 1);

  // Get blocks in the deleted lane
  const laneHeight = STYLES.header.laneHeight;
  const headerHeight = STYLES.header.height + STYLES.header.gap;
  const deletedLaneY = headerHeight + (index * laneHeight);
  const nextLaneY = deletedLaneY + laneHeight;

  // Filter out blocks in the deleted lane and update positions for blocks below
  const updatedBlocks = blocks.filter(block => {
    const blockY = block.y || 0;
    return blockY < deletedLaneY || blockY >= nextLaneY;
  }).map(block => {
    const blockY = block.y || 0;
    if (blockY >= nextLaneY) {
      return { ...block, y: blockY - laneHeight };
    }
    return block;
  });

  // Remove the process group for the deleted lane
  const newGroups = [...groups];
  newGroups.splice(index, 1);

  // Update the swimlane data
  const updatedData = {
    ...data,
    subHeaderRows: {
      ...data.subHeaderRows,
      headers: newHeaders
    }
  };

  return {
    updatedData,
    updatedBlocks,
    updatedGroups: newGroups
  };
}

export function addRow(
  data: SwimlaneData,
  blocks: Block[],
  groups: ProcessGroup[],
  index: number,
  position: 'up' | 'down'
): RowOperationResult {
  // Create new header array with empty string at the specified position
  const newHeaders = [...data.subHeaderRows.headers];
  const insertIndex = position === 'up' ? index : index + 1;
  newHeaders.splice(insertIndex, 0, 'New Lane');

  // Create new process group for the new lane
  const newGroup: ProcessGroup = {
    id: `group-${Date.now()}`,
    type: '$sgroup',
    groupChildren: []
  };

  // Insert new group at the correct position
  const newGroups = [...groups];
  newGroups.splice(insertIndex, 0, newGroup);

  // Calculate the lane height
  const laneHeight = STYLES.header.laneHeight;

  // Update block positions based on their current lane
  const updatedBlocks = blocks.map(block => {
    const blockCopy = { ...block };
    const blockY = block.y || 0;
    const headerHeight = STYLES.header.height + STYLES.header.gap;
    const relativeY = blockY - headerHeight;
    const currentLane = Math.floor(relativeY / laneHeight);

    if (currentLane >= insertIndex) {
      // Block is in or below the insertion point, move it down
      blockCopy.y = blockY + laneHeight;
    }

    return blockCopy;
  });

  // Update the swimlane data
  const updatedData = {
    ...data,
    subHeaderRows: {
      ...data.subHeaderRows,
      headers: newHeaders
    }
  };

  return {
    updatedData,
    updatedBlocks,
    updatedGroups: newGroups
  };
}