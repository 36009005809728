import React from "react";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

// Views
import PrivateRoutes from './PrivateRoutes';
import { ProcessDiagram } from "../features/process/process";
import {
    AllMAp,
    AuthView,
    CreateProcessView,
    DashboardView,
    Error404View,
    GroupOfProcessViewAll,
    LoginView,
    MailVerification,
    ProcessInterviewView,
    ProcessView,
    ProcessViewAll,
    Privacy,
    ResetPasswordView,
    ResetLastPasswordView,
    SiginView,
    NewGroupOfProcessView,
    Faq,
    ContactUs,
    CompanyCreateView,
    ProcessByFolderView,
    TermsAndCondition,
    OautView,
    DashboardViews,
    UsersView,
    OwnerView,
    AdminView,
    CollaboratorsView,
    DashProcessView,
    CheckMailBox
} from "../views";
import { AuthGuard } from "../utils";


const NavigationApp = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<Error404View />}>
            <Route path="/" element={<AuthGuard component={AuthView} />} />
            <Route path="/google" element={<OautView />} />
            <Route path="/auth">
                <Route path="login" element={<LoginView />} />
                <Route path="signup" element={<SiginView />} />
                <Route path="mail" element={<MailVerification />} />
                <Route path="reset-password" element={<ResetPasswordView />} />
                <Route path="reset" element={<ResetLastPasswordView />} />
                <Route path="email-verification" element={<CheckMailBox />} />
            </Route>
            <Route path="/home" element={<AuthGuard component={DashboardView} />} />

            <Route path="/Privacy_Policy" element={<Privacy />} />
            <Route path="/terms_and_conditions" element={<TermsAndCondition />} />
            <Route path="/faq" element={<AuthGuard component={Faq} />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route element={<PrivateRoutes />}>
                <Route path="/process">
                    <Route path="create" element={<CreateProcessView />} />
                    <Route path="createGroup" element={<NewGroupOfProcessView />} />
                    <Route path="interviews/:id" element={<AuthGuard component={ProcessInterviewView} />} />
                    <Route path="overview" element={<AuthGuard component={ProcessView} />} />
                    <Route path="my/processes" element={<AuthGuard component={ProcessViewAll} />} />
                    <Route path="group" element={<AuthGuard component={GroupOfProcessViewAll} />} />
                    <Route path="all" element={<AuthGuard component={AllMAp} />} />
                    <Route path="mapping/:id/:sId" element={<AuthGuard component={ProcessDiagram} />} />
                    <Route path="folder/:id" element={<AuthGuard component={ProcessByFolderView} />} />
                </Route>
            </Route>
            <Route element={<PrivateRoutes />}>
                <Route path="/dashboard">
                    <Route path="home" element={<AuthGuard component={DashboardViews} />} />
                    <Route path="collaborators" element={<CollaboratorsView />} />
                    <Route path="users" element={<UsersView />} />
                    <Route path="owner" element={<OwnerView />} />
                    <Route path="admin" element={<AdminView />} />
                    <Route path="process" element={<DashProcessView />} />
                </Route>
            </Route>
            <Route path="/company/create" element={<AuthGuard component={CompanyCreateView} />} />
            <Route path="*" element={<Error404View />} />
        </Route>
    )
);

export default NavigationApp;
