import * as d3 from 'd3';
import { createArrowMarker } from './connectionUtils';

export function setupSvgLayer(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>
): d3.Selection<SVGSVGElement, unknown, null, undefined> {
  const svg = container
    .append('svg')
    .style('position', 'absolute')
    .style('top', '0')
    .style('left', '0')
    .style('width', '100%')
    .style('height', '100%')
    .style('pointer-events', 'none')
    .style('z-index', '1');

  createArrowMarker(svg);
  
  return svg;
}