import * as d3 from 'd3';
import { Line } from '../../types';
import { STYLES } from '../../styles/constants';

const SIDE_OPTIONS = ['top', 'right', 'bottom', 'left'] as const;

export function createLineContextMenu(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  position: { x: number; y: number },
  line: Line,
  onUpdate: (line: Line) => void,
  onDelete: () => void,
  t: (key: string) => string,
  onToggleLayers?: () => void
) {
  // Remove any existing context menus
  d3.selectAll('.line-context-menu').remove();

  // Get container's scroll position and dimensions
  const containerNode = container.node();
  const containerRect = containerNode?.getBoundingClientRect();
  if (!containerRect) return;

  // Create menu container
  const menu = container
    .append('div')
    .attr('class', 'line-context-menu')
    .style('position', 'absolute')
    .style('left', `${position.x}px`)
    .style('top', `${position.y}px`)
    .style('background-color', 'white')
    .style('border', `1px solid ${STYLES.colors.border}`)
    .style('border-radius', '4px')
    .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)')
    .style('min-width', '140px') // Reduced from 180px
    .style('z-index', '1000')
    .style('padding', '6px'); // Reduced from 8px

  // Add dropdowns section
  const dropdownsSection = menu
    .append('div')
    .style('margin-bottom', '6px'); // Reduced from 8px

  // Fonction pour traduire les options
  const getTranslatedSideOptions = () =>
    SIDE_OPTIONS.map(side => ({ key: side, label: t(`words.${side}`) }));

  const translatedSideOptions = getTranslatedSideOptions();

  // Fonction pour récupérer la clé originale à partir d'un label traduit
  const findOriginalKey = (translatedValue: string) => {
    const match = translatedSideOptions.find(option => option.label === translatedValue);
    return match ? match.key : null;
  };

  // From side dropdown
  addDropdown(dropdownsSection, t('words.from'), t(`words.${line.fromSide}`), (newTranslatedSide) => {
    const originalKey = findOriginalKey(newTranslatedSide);
    if (originalKey) {
      onUpdate({ ...line, fromSide: originalKey });
    }
  }, translatedSideOptions.map(option => option.label));

  // To side dropdown
  addDropdown(dropdownsSection, t('words.to'), t(`words.${line.toSide}`), (newTranslatedSide) => {
    const originalKey = findOriginalKey(newTranslatedSide);
    if (originalKey) {
      onUpdate({ ...line, toSide: originalKey });
    }
  }, translatedSideOptions.map(option => option.label));

  // Add divider
  menu.append('div')
    .style('height', '1px')
    .style('background-color', STYLES.colors.border)
    .style('margin', '6px 0'); // Reduced from 8px

  // Action buttons container
  const actionContainer = menu
    .append('div')
    .style('display', 'flex')
    .style('gap', '4px') // Reduced from 8px
    .style('justify-content', 'flex-start')
    .style('align-items', 'center')
    .style('padding', '0 2px'); // Reduced from 4px

  // Delete button with icon
  const deleteButton = actionContainer
    .append('button')
    .style('padding', '4px') // Reduced from 6px
    .style('background', '#fee2e2')
    .style('border', 'none')
    .style('border-radius', '3px') // Reduced from 4px
    .style('cursor', 'pointer')
    .style('color', '#dc2626')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('justify-content', 'center')
    .style('transition', 'all 0.2s')
    .attr('title', t('words.delete'))
    .on('mouseenter', function () {
      d3.select(this).style('background', '#fecaca');
    })
    .on('mouseleave', function () {
      d3.select(this).style('background', '#fee2e2');
    })
    .on('click', () => {
      onDelete();
      menu.remove();
    });

  // Trash icon
  deleteButton
    .append('svg')
    .attr('width', '14') // Reduced from 16
    .attr('height', '14') // Reduced from 16
    .attr('viewBox', '0 0 24 24')
    .attr('fill', 'none')
    .attr('stroke', 'currentColor')
    .attr('stroke-width', '2')
    .attr('stroke-linecap', 'round')
    .attr('stroke-linejoin', 'round')
    .html('<path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>');

  // Layers button with icon
  const layersButton = actionContainer
    .append('button')
    .style('padding', '4px') // Reduced from 6px
    .style('background', '#f0fdf4')
    .style('border', 'none')
    .style('border-radius', '3px') // Reduced from 4px
    .style('cursor', 'pointer')
    .style('color', '#16a34a')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('justify-content', 'center')
    .style('transition', 'all 0.2s')
    .attr('title', t('features.swimlane.showLayers'))
    .on('mouseenter', function () {
      d3.select(this).style('background', '#dcfce7');
    })
    .on('mouseleave', function () {
      d3.select(this).style('background', '#f0fdf4');
    })
    .on('click', () => {
      if (onToggleLayers) {
        onToggleLayers();
      }
      menu.remove();
    });

  // Layers icon
  layersButton
    .append('svg')
    .attr('width', '14') // Reduced from 16
    .attr('height', '14') // Reduced from 16
    .attr('viewBox', '0 0 24 24')
    .attr('fill', 'none')
    .attr('stroke', 'currentColor')
    .attr('stroke-width', '2')
    .attr('stroke-linecap', 'round')
    .attr('stroke-linejoin', 'round')
    .html('<path d="M12 2L2 7l10 5 10-5-10-5z"/><path d="m2 17 10 5 10-5"/><path d="m2 12 10 5 10-5"/>');

  // Edit text button with icon
  const editButton = actionContainer
    .append('button')
    .style('padding', '4px') // Reduced from 6px
    .style('background', '#e0f2fe')
    .style('border', 'none')
    .style('border-radius', '3px') // Reduced from 4px
    .style('cursor', 'pointer')
    .style('color', '#0284c7')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('justify-content', 'center')
    .style('transition', 'all 0.2s')
    .attr('title', t('words.edit'))
    .on('mouseenter', function () {
      d3.select(this).style('background', '#bae6fd');
    })
    .on('mouseleave', function () {
      d3.select(this).style('background', '#e0f2fe');
    })
    .on('click', () => {
      const textElement = container.select(`.line-text-${line.id}`);
      if (!textElement.empty()) {
        const node = textElement.node();
        if (node instanceof HTMLElement) {
          node.dispatchEvent(new Event('click', { bubbles: true }));
        }
      }

      menu.remove();
    });

  // Edit icon
  editButton
    .append('svg')
    .attr('width', '14') // Reduced from 16
    .attr('height', '14') // Reduced from 16
    .attr('viewBox', '0 0 24 24')
    .attr('fill', 'none')
    .attr('stroke', 'currentColor')
    .attr('stroke-width', '2')
    .attr('stroke-linecap', 'round')
    .attr('stroke-linejoin', 'round')
    .html('<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>');

  // Close menu when clicking outside
  d3.select('body').on('click.line-context-menu', () => {
    menu.remove();
  });

  // Prevent menu from closing when clicking inside
  menu.on('click', (event) => {
    event.stopPropagation();
  });

  // Adjust menu position if it goes off screen
  const menuNode = menu.node();
  if (menuNode) {
    const menuRect = menuNode.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    if (menuRect.right > viewportWidth) {
      menu.style('left', `${position.x - menuRect.width}px`);
    }

    if (menuRect.bottom > viewportHeight) {
      menu.style('top', `${position.y - menuRect.height}px`);
    }
  }
}

function addDropdown(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  label: string,
  currentValue: string,
  onChange: (newValue: typeof SIDE_OPTIONS[number]) => void,
  translatedOptions: string[]
) {
  const dropdownContainer = container.append('div').style('margin-bottom', '4px');

  dropdownContainer
    .append('label')
    .style('display', 'block')
    .style('font-size', '11px')
    .style('font-weight', '500')
    .style('color', '#666')
    .style('margin-bottom', '2px')
    .text(label);

  const select = dropdownContainer
    .append('select')
    .style('width', '100%')
    .style('padding', '4px 6px')
    .style('border', `1px solid ${STYLES.colors.border}`)
    .style('border-radius', '3px')
    .style('background-color', 'white')
    .style('cursor', 'pointer')
    .style('outline', 'none')
    .style('font-size', '11px')
    .on('change', function () {
      const newValue = this.value as typeof SIDE_OPTIONS[number];
      onChange(newValue);
    });


  translatedOptions.forEach((side, index) => {
    select.append('option')
      .attr('value', side)
      .attr('selected', side === currentValue ? '' : null)
      .text(side);
  });

  select
    .on('mouseenter', function () {
      d3.select(this).style('border-color', '#999');
    })
    .on('mouseleave', function () {
      d3.select(this).style('border-color', STYLES.colors.border);
    });
}
