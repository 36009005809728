import React from 'react';

interface BadgeProps {
    label: string;
    className?: string;
    classNamePrent?: string
}

const Badge: React.FC<BadgeProps> = ({ label, className = '', classNamePrent }) => {
    return (
        <div className={`${classNamePrent}`}>
            <span className={`badge ${className}`}>
                {label}
            </span>
        </div>
    );
};

export default Badge;
