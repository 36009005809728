import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../stores";
import { FamlaServices } from '../../services';
import { iAlert } from '../../interfaces';
import { InputForm, Spiner } from '../../components';
import { Plus, X } from 'lucide-react';

interface ChildProps {
    processId: string;
    closeRef: any;
    interviewwId: string | undefined;
    invited: string | undefined;
}

const InviteUser = ({ processId, closeRef, interviewwId, invited }: ChildProps) => {
    const { t } = useTranslation();
    const users = useAppSelector(state => state.auth);
    const [emails, setEmails] = useState<string[]>(['']);
    const [isSubmit, setIsSubmit] = useState(false);
    const [alert, setAlert] = useState<iAlert>({ isActive: false, type: '', message: '' });
    const [role, setRole] = useState(invited || "");

    const addEmailField = () => {
        setEmails([...emails, '']);
    };

    const removeEmailField = (index: number) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const handleEmailChange = (index: number, event: React.ChangeEvent<HTMLInputElement> | any) => {
        const newEmails = [...emails];
        newEmails[index] = event.target.value;  // ✅ TypeScript reconnaît maintenant `value`
        setEmails(newEmails);
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(event.target.value);  // ✅ Correction du problème sur `role`
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsSubmit(true);

        const validEmails = emails.filter(email => email.trim() !== '');
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const invalidEmails = validEmails.filter(email => !emailRegex.test(email));

        if (invalidEmails.length > 0) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.enterValidEmails') });
            setIsSubmit(false);
            return;
        }

        if (validEmails.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.enterAtLeastOneEmail') });
            setIsSubmit(false);
            return;
        }

        let newFormData = {
            process_id: processId,
            mail: validEmails,
            role: role || invited
        };

        let data = await FamlaServices("api_host", `/process/add_member/`, "PUT", newFormData, users.access_token);

        if (data.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t('navigation.invitationSentSuccessfully') });
            setEmails(['']);
            setRole("");
            window.location.assign(`/process/interviews/${interviewwId}`);
        } else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOccurred") });
        }
        setIsSubmit(false);
    };

    return (
        <div className="modal" data-bs-backdrop="static" id='inviteUser' tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white">{t('navigation.inviteCollaborator')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {alert.isActive && <div className={`alert alert-${alert.type}`} role="alert">{alert.message}</div>}

                        {emails.map((email, index) => (
                            <div key={index} className="d-flex align-items-center mb-2">
                                <InputForm
                                    inputType="email"
                                    inputLabel={t('words.email')}
                                    inputName={`email-${index}`}
                                    inputValue={email}
                                    isFloatingLabel={true}
                                    labelClass="text-theme"
                                    onChange={(e) => handleEmailChange(index, e)}
                                    inputClass="bg-dark text-light"
                                />
                                {index > 0 && (
                                    <button type="button" className="btn btn-theme btn-sm ms-2" onClick={() => removeEmailField(index)}>
                                        <X size={16} />
                                    </button>
                                )}
                            </div>
                        ))}

                        {emails.length < 3 &&
                            <button type="button" className="btn btn-none mb-3 d-flex text-primary gap-1" onClick={addEmailField}>
                                <Plus className='mt-1' size={20} /> {t('navigation.addAnotherEmail')}
                            </button>
                        }
                        <InputForm
                            inputType="text"
                            inputLabel={t('words.role')}
                            inputName="role"
                            inputValue={role || invited}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={handleRoleChange}
                            inputClass="bg-dark text-light"
                        />
                    </div>
                    <div className="modal-footer">
                        <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">
                            {isSubmit ? <Spiner className='spinner-border-sm' /> : <>{t('words.invite')}</>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteUser;
