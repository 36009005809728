import { SwimlaneData, Block, ProcessGroup } from '../types';

interface HistoryState {
  data: SwimlaneData;
  blocks: Block[];
  groups: ProcessGroup[];
}

export class HistoryManager {
  private history: HistoryState[] = [];
  private currentIndex: number = -1;
  private maxHistory: number = 50;

  push(state: HistoryState) {
    // Remove any future states if we're not at the end
    this.history = this.history.slice(0, this.currentIndex + 1);
    
    // Add new state
    this.history.push({
      data: JSON.parse(JSON.stringify(state.data)),
      blocks: JSON.parse(JSON.stringify(state.blocks)),
      groups: JSON.parse(JSON.stringify(state.groups))
    });
    
    // Remove oldest state if we exceed maxHistory
    if (this.history.length > this.maxHistory) {
      this.history.shift();
    } else {
      this.currentIndex++;
    }
  }

  canUndo(): boolean {
    return this.currentIndex > 0;
  }

  canRedo(): boolean {
    return this.currentIndex < this.history.length - 1;
  }

  undo(): HistoryState | null {
    if (!this.canUndo()) return null;
    this.currentIndex--;
    return JSON.parse(JSON.stringify(this.history[this.currentIndex]));
  }

  redo(): HistoryState | null {
    if (!this.canRedo()) return null;
    this.currentIndex++;
    return JSON.parse(JSON.stringify(this.history[this.currentIndex]));
  }
}