import React, { useState, useEffect, useRef, LegacyRef, RefObject } from "react";
import { Check, Square } from "lucide-react";
import { Layer, LayerGroup, LayerSelections } from "../../lib/types";
import { FamlaServices } from "../../../services";

interface LayerSettingsProps {
    block: Layer[];
    startEnd: Layer[];
    line: Layer[];
    initialSelections: LayerSelections;
    onSelectionsChange: (selections: LayerSelections) => void;
    t: (key: string) => string
}


export function LayerSettings({
    block,
    line,
    startEnd,
    initialSelections,
    onSelectionsChange,
    t
}: LayerSettingsProps) {
    const defauldSelectionProcess = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
    const defauldSelectionLine = ['1', '2', '3', '4']
    const defauldSelectionstartEnd = ['1']
    const [selections, setSelections] = useState<LayerSelections>({
        block: initialSelections.block !== null ? initialSelections.block : defauldSelectionProcess,
        line: initialSelections.line !== null ? initialSelections.line : defauldSelectionLine,
        startEnd: initialSelections.startEnd !== null ? initialSelections.startEnd : defauldSelectionstartEnd
    });

    const closeRef = useRef<HTMLButtonElement | null>(null);

    const selectionChange = () => {
        onSelectionsChange(selections)
        if (closeRef.current) {
            closeRef.current.click()
        }
    }

    const layerGroups = [
        { name: t('words.proces'), layers: block, key: 'block' as const },
        { name: t('words.line'), layers: line, key: 'line' as const },
        { name: t('words.start') + t('words.end'), layers: startEnd, key: 'startEnd' as const }
    ];

    const handleGroupToggle = (groupKey: keyof LayerSelections) => {
        setSelections(prev => {
            const newSelections = { ...prev };
            const group = layerGroups.find(g => g.key === groupKey);
            if (!group) return prev;

            if (prev[groupKey]?.length === 0) {
                // If none selected, select all
                newSelections[groupKey] = group.layers.map(layer => layer.id);
            } else {
                // If any selected, clear selection
                newSelections[groupKey] = [];
            }
            return newSelections;
        });
    };

    const handleLayerToggle = (groupKey: keyof LayerSelections, layerId: string) => {
        setSelections(prev => {
            const newSelections = { ...prev };
            const currentSelection = prev[groupKey] ?? [];

            if (currentSelection.includes(layerId)) {
                // Remove if already selected
                newSelections[groupKey] = currentSelection.filter(id => id !== layerId);
            } else {
                // Add if not selected
                newSelections[groupKey] = [...currentSelection, layerId];
            }

            return newSelections;
        });
    };

    const isLayerSelected = (groupKey: keyof LayerSelections, layerId: string): boolean => {
        return selections[groupKey]?.includes(layerId) ?? false;
    };

    const isGroupSelected = (groupKey: keyof LayerSelections): boolean => {
        const group = layerGroups.find(g => g.key === groupKey);
        if (!group) return false;
        return selections[groupKey]?.length === group.layers.length;
    };



    return (
        <div className="modal fade" id="layerSettingsModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('layer.layerSettings')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {layerGroups?.map((group) => (
                            <div key={group.key} className="mb-3">
                                <div
                                    className="d-flex align-items-center gap-2 mb-2 cursor-pointer"
                                    onClick={() => handleGroupToggle(group.key)}
                                >
                                    <span className="border rounded p-1">
                                        {isGroupSelected(group.key) ? <Check size={16} /> : <Square size={16} />}
                                    </span>
                                    <strong>{group.name}</strong>
                                </div>
                                <div className="ms-4">
                                    {(group.layers || []).map((layer) => (
                                        <div
                                            key={layer.id}
                                            className="d-flex align-items-center gap-2 cursor-pointer"
                                            onClick={() => handleLayerToggle(group.key, layer.id)}
                                        >
                                            <span className="border rounded p-1">
                                                {isLayerSelected(group.key, layer.id) ? <Check size={14} /> : <Square size={14} />}
                                            </span>
                                            <span>{layer.text}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>
                            {t('words.close')}
                        </button>
                        <button type="button" className="btn btn-primary" onClick={selectionChange}>
                            {t('words.save')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
