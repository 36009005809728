import React from "react"
import { useState } from "react";
import { iCompany } from "../../components/type";

interface ChangeCompanyModalProps {
    companies: iCompany[];
    currentCompanyId: string;
    onSave: (companyId: string) => void;
    t: (key: string) => string;
}

const ChangeCompanyModal = ({ companies, currentCompanyId, onSave, t }: ChangeCompanyModalProps) => {
    const [selectedCompany, setSelectedCompany] = useState<string | undefined>(currentCompanyId);

    const handleSave = () => {
        if (selectedCompany && selectedCompany !== currentCompanyId) {
            onSave(selectedCompany);
        }
    };

    return (
        <div className="modal fade" id="changeCompany" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header border-secondary text-white">
                        <h5 className="modal-title fs-5">
                            <i className="bi bi-building"></i> {t('layout.header.changeCompany')}
                        </h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    {/* CONTENU QUI SCROLLE */}
                    <div className="modal-body overflow-auto" style={{ maxHeight: "300px" }}>
                        <p className="text-theme">{t('layout.header.selectCompany')}</p>
                        <ul className="list-group bg-dark mt-2">
                            {companies.map((c:iCompany) => (
                                <li
                                    key={c?._id}
                                    className={`list-group-item bg-dark d-flex justify-content-between align-items-center border-secondary 
                                    ${selectedCompany === c?._id ? " text-primary border border-primary" : "text-white"}`}
                                    onClick={() => setSelectedCompany(c?._id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {c?.name}
                                    {selectedCompany === c?._id && <i className="bi bi-check-lg"></i>}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-outline-light" data-bs-dismiss="modal">
                            {t('words.close')}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                            disabled={!selectedCompany || selectedCompany === currentCompanyId}
                        >
                            {t('words.save')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeCompanyModal;
