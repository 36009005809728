import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from "react-i18next";
import { iProcess, iUser } from "../../components/type";
import { formatDateTime } from "../../utils";
import { useAppSelector } from "../../stores";

interface FeaturesProps {
    loading: boolean
    featuresDatas: iProcess[]
    context?: string
    deleteCompany?: (id?: string, comapnyId?: string) => void
    listType?: 'USERS' | 'COLLABORATORS' | 'ADMIN' | 'OWNER'
    setUsers?: Dispatch<SetStateAction<iUser[]>> | undefined;
    selectedProcesses: string[];
    onSelectionChange: (processIds: string[]) => void;
}

function UsersList(props: FeaturesProps) {
    const { loading, featuresDatas, selectedProcesses, onSelectionChange } = props;
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation();

    const toggleProcess = (processId: string) => {
        const newSelection = selectedProcesses.includes(processId)
            ? selectedProcesses.filter(_id => _id !== processId)
            : [...selectedProcesses, processId];
        onSelectionChange(newSelection);
    };

    const toggleAll = () => {
        if (selectedProcesses.length === featuresDatas.length) {
            onSelectionChange([]);
        } else {
            onSelectionChange(featuresDatas.map(p => p._id));
        }
    };

    const renderTableContent = () => {
        if (loading) {
            return (
                <tr>
                    <td colSpan={7} className="text-center p-4">
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            );
        }

        if (!featuresDatas?.length) {
            return (
                <tr>
                    <td colSpan={7} className="text-center p-4">
                        {t('navigation.noData')}
                    </td>
                </tr>
            );
        }

        return featuresDatas.map((data) => (
            <tr key={data._id}>
                <td className="align-middle border-top-0 text-center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedProcesses.includes(data._id)}
                        onChange={() => toggleProcess(data._id)}
                    />
                </td>
                <td className="align-middle border-top-0 small">
                    {data.title}
                </td>
                <td className="align-middle border-top-0 text-center small">
                    {data.owner.fullname}
                </td>
                <td className="align-middle border-top-0 text-center small">
                    {formatDateTime(data.mappingCreationDate ?? '')}
                </td>
                <td className="align-middle border-top-0 text-center small">
                    {data.timeSaved} {"min"}
                </td>
            </tr>
        ));
    };

    return (
        <table className="table mb-0 text-nowrap table-sm table-dark text-secondary">
            <thead className="table-theme border-0">
                <tr>
                    <th className='border-0 text-center bg-teal text-white'>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={featuresDatas.length > 0 && selectedProcesses.length === featuresDatas.length}
                            onChange={toggleAll}
                            disabled={loading || featuresDatas.length === 0}
                        />
                    </th>
                    <th className="border-0 bg-teal text-white">{t('navigation.processName')}</th>
                    <th className="border-0 text-center bg-teal text-white">{t('words.owner')}</th>
                    <th className="border-0 text-center bg-teal text-white">{t('dashboard.mappingCreationDate')}</th>
                    <th className="border-0 text-center bg-teal text-white">{t('navigation.timeSaved')}</th>
                </tr>
            </thead>
            <tbody>
                {renderTableContent()}
            </tbody>
        </table>
    );
}

export default UsersList;