import * as d3 from 'd3';
import { Line, Layer } from '../../types';
import { LayerUIManager } from './layer/LayerUIManager';
import { LayerStateManager } from './layer/LayerStateManager';
import { LayerPositionManager } from './layer/LayerPositionManager';
import { LayerEventManager } from './layer/LayerEventManager';

export class LayerManager {
  private uiManager: LayerUIManager;
  private stateManager: LayerStateManager;
  private positionManager: LayerPositionManager;
  private eventManager: LayerEventManager;
  private lines: Line[] = [];
  private t: (key: string) => string;
  private attributesDataEditable: any

  constructor(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    editMode: { enabled: boolean },
    onUpdate: (line: Line) => void,
    t: (key: string) => string,
    attributesDataEditable: any
  ) {
    this.t = t; // Store the translation function
    this.attributesDataEditable = attributesDataEditable
    this.stateManager = new LayerStateManager();
    this.positionManager = new LayerPositionManager();
    this.eventManager = new LayerEventManager(onUpdate);
    this.uiManager = new LayerUIManager(container, editMode.enabled, this.eventManager, this.t, this.attributesDataEditable);
  }

  public renderLayers(line: Line, points: { x: number; y: number }[], showLayers: boolean) {
    // Store the line for reference
    if (!this.lines.find(l => l.id === line.id)) {
      this.lines.push(line);
    }

    // Store points for future use
    this.positionManager.updatePoints(line.id, points);

    // Calculate position
    const position = this.positionManager.calculatePosition(line.id);

    // Get current state
    const hiddenLayers = this.stateManager.getHiddenLayers(line);
    const showHiddenMenu = this.stateManager.isHiddenMenuVisible() && this.stateManager.isActiveLine(line.id);

    // Filter visible layers based on global hidden state
    const visibleLayers = (line.layers || []).filter(layer =>
      !this.stateManager.isLayerHidden(layer)
    );

    // Create a new line object with filtered layers
    const filteredLine = {
      ...line,
      layers: visibleLayers
    };

    // Render UI
    this.uiManager.render({
      line: filteredLine,
      position,
      showLayers,
      hiddenLayers,
      showHiddenMenu,
      onHideLayer: (layer) => this.hideLayer(layer),
      onShowLayer: (layer) => this.showLayer(layer),
      onToggleMenu: () => this.toggleHiddenMenu(line)
    });
  }

  public toggleLayersVisibility(lineId: string, visible: boolean) {
    this.uiManager.toggleVisibility(lineId, visible);
  }

  private hideLayer(layer: Layer) {
    // Add layer to hidden layers globally
    this.stateManager.hideLayer(layer);

    // Reset hidden menu visibility
    this.stateManager.setHiddenMenuVisible(false);

    // Update all lines that have this layer
    this.lines.forEach(line => {
      if (line.layers?.some(l => this.isSameLayer(l, layer))) {
        // Create new line object with updated layers
        const updatedLayers = (line.layers || []).filter(l => !this.isSameLayer(l, layer));
        const updatedLine = { ...line, layers: updatedLayers };

        // Update line through event manager
        this.eventManager.updateLine(updatedLine);

        // Re-render with current points
        const points = this.positionManager.getPoints(line.id);
        this.renderLayers(updatedLine, points, true);
      }
    });
  }

  private showLayer(layer: Layer) {
    // Remove layer from hidden layers globally
    this.stateManager.showLayer(layer);

    // Reset hidden menu visibility
    this.stateManager.setHiddenMenuVisible(false);

    // Update all lines that originally had this layer
    this.lines.forEach(line => {
      if (line.layers?.some(l => this.isSameLayer(l, layer))) {
        // Add layer back to line
        const updatedLayers = [...(line.layers || [])];
        if (!updatedLayers.some(l => this.isSameLayer(l, layer))) {
          updatedLayers.push(layer);
        }
        const updatedLine = { ...line, layers: updatedLayers };

        // Update line through event manager
        this.eventManager.updateLine(updatedLine);

        // Re-render with current points
        const points = this.positionManager.getPoints(line.id);
        this.renderLayers(updatedLine, points, true);
      }
    });
  }

  private toggleHiddenMenu(line: Line) {
    // Toggle menu visibility in state for this specific line
    this.stateManager.toggleHiddenMenu(line.id);

    // Re-render all lines
    this.lines.forEach(currentLine => {
      const points = this.positionManager.getPoints(currentLine.id);
      this.renderLayers(currentLine, points, true);
    });
  }

  public cleanup(lineId: string) {
    // Remove line from stored lines
    this.lines = this.lines.filter(l => l.id !== lineId);

    // Clear stored points
    this.positionManager.clearPoints(lineId);

    // Clean up UI
    this.uiManager.cleanup(lineId);
  }

  // Helper method to compare layers
  private isSameLayer(layer1: Layer, layer2: Layer): boolean {
    return layer1.text === layer2.text && layer1.type === layer2.type;
  }
}