//React
import React from 'react'


//App
import { DashNavigation, Routes } from '../../routes'
import { Images } from '../../constants'
import { NavLink } from 'react-router-dom'


const Offcanvas = () => {
    return (
        <div className="offcanvas offcanvas-start second_bg border-0 web-none" id="offcanvas" aria-labelledby="offcanvasLabel">
            <div className="offcanvas-header pb-0 mb-0">
                <NavLink className="navbar-vertical_navlink" to={Routes.HOMEVIEW} >
                    <img src={Images.logov1} alt="" style={{ width: "100px" }} />
                </NavLink>
                <button type="button" className="btn-close bg-white fw-bold fs-6" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body boder-0 pt-0 mt-0">
                <div className="w-100 pt-0 mt-0" >
                    <DashNavigation className='vh-100' />
                </div>
            </div>
        </div>
    )
}

export default Offcanvas