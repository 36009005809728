// React Component
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

// App Component
import { Buttons, InputForm } from '../../components'
import { Routes } from '../../routes'
import { FamlaServices } from '../../services'
import { login, useAppDispatch, useAppSelector } from '../../stores'
import { Metrics } from '../../constants'
import { useTranslation } from 'react-i18next'

//interface
import { iAlert } from '../../interfaces'
import { iSessions } from '../auth'

// interface city {
//     _id: string
//     name: string
//     adress: string
// };


const CompanyCreate = () => {
    const users = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [company, setCompany] = React.useState({
        name: '',
        adress: '',
        city: '',
        country: '',
        postalCode: "",
        website: "",
        phoneNumber: "",
        isCollaborator: true,
        isSubmit: false,
        myRole: ''
    });
    console.log("user", users);

    // const [city, setcity] = React.useState<city[]>([])
    // const sortedcity = city.sort((a, b) => a.name.localeCompare(b.name));
    // const selectcityOptions = sortedcity.map((city) => ({
    //     label: city.name,
    //     value: city.name,
    // }));


    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    React.useEffect(() => {
        // const getAllcity = async () => {
        //     let data = await FamlaServices("api_host", `/city/`, "GET", "", users.access_token);
        //     if (data.status === 200) {
        //         setcity(data.body.data)
        //     }
        // }
        // getAllcity()

        const getMyRole = async () => {
            let data = await FamlaServices("api_host", `/users/my/role`, "GET", "", users.access_token);
            if (data.status === 200) {
                setCompany((prev) => ({ ...prev, myRole: data.body.data }))
            }
        }

        getMyRole()

    }, [users.access_token])

    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'name') {
            setCompany({ ...company, name: fieldValue })
        } else if (name === 'adress') {
            setCompany({ ...company, adress: fieldValue })
        } else if (name === 'city') {
            setCompany({ ...company, city: fieldValue })
        } else if (name === 'country') {
            setCompany({ ...company, country: fieldValue })
        } else if (name === 'postalcode') {
            setCompany({ ...company, postalCode: fieldValue })
        } else if (name === 'website') {
            setCompany({ ...company, website: fieldValue })
        } else if (name === 'number') {
            setCompany({ ...company, phoneNumber: fieldValue })
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (company.name.trim().length === 0) {
            setAlert({ isActive: true, type: 'danger', message: 'Company name is required' });
            return;
        } else if (company.city.trim().length === 0) {
            setAlert({ isActive: true, type: 'danger', message: 'City is required' });
            return;
        } else if (company.adress.trim().length === 0) {
            setAlert({ isActive: true, type: 'danger', message: 'Address is required' });
            return;
        } else if (!Metrics.phoneRegex.test(company.phoneNumber)) {
            setAlert({ isActive: true, type: 'danger', message: 'Phone number is required ' });
            return;
        } else if (company.country.trim().length === 0) {
            setAlert({ isActive: true, type: 'danger', message: 'Country is required' });
            return;
        }


        let formData = {
            name: company.name,
            address: company.adress,
            city: company.city,
            postal_code: company.postalCode,
            country: company.country,
            website: company.website,
            phone_number: company.phoneNumber
        }

        setCompany({ ...company, isSubmit: true });

        let data = await FamlaServices("api_host", `/company/`, "POST", formData, users.access_token);

        if (data.status === 201) {
            setCompany((prevState) => ({ ...prevState, name: '', adress: '', city: "", postalCode: "", country: "", website: "", phoneNumber: "", isSubmit: false }));
            let sessions: iSessions = data.body.data;
            dispatch(login({ token: users.access_token, session: sessions, user: sessions.user }));
            navigate(Routes.HOMEVIEW);

        } else if (data.status === 401) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.youAreNotLoggedIn') });
        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.anErrorOcured') });
        }
        setCompany({ ...company, isSubmit: false });
    };


    return (
        <div className="w-50" style={{ margin: "auto" }}>
            <div className="mb-5" style={{ marginTop: "20%" }}>
                <Link to={Routes.HOMEVIEW} className="text-light fw-bold fs-3 text-decoration-none">
                    <i className="fa-solid fa-arrow-left "></i> {t('form.createCompany.createACompany')}
                </Link>
            </div>

            <div className="mt-4 pb-5 overflow-auto">
                <span className='text-theme'>{t('navigation.allFieldsRequired')}</span>
                <InputForm
                    inputType="text"
                    inputLabel={t('form.createCompany.companyName') + ' *'}
                    inputName="name"
                    inputValue={company.name}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />

                <InputForm
                    inputType="text"
                    inputLabel={t('words.address') + ' *'}
                    inputName="adress"
                    inputValue={company.adress}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                <InputForm
                    inputType="text"
                    inputLabel={t('words.city') + ' *'}
                    inputName="city"
                    inputValue={company.city}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                <InputForm
                    inputType="text"
                    inputLabel={t('form.createCompany.postalCode') + ' *'}
                    inputName="postalcode"
                    inputValue={company.postalCode}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                <InputForm
                    inputType="text"
                    inputLabel={t('words.country') + ' *'}
                    inputName="country"
                    inputValue={company.country}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                <InputForm
                    inputType="text"
                    inputLabel={t('form.createCompany.website')}
                    inputName="website"
                    inputValue={company.website}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                <InputForm
                    inputType="number"
                    inputLabel={t('form.createCompany.phoneNumber') + " *"}
                    inputName="number"
                    inputValue={company.phoneNumber}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />

                {/* <SelectForm
                    selectClass="mb-3"
                    placeholder="Select city"
                    selectName='selectName'
                    selectOptions={selectcityOptions}
                    onChange={(e) => handleChange(e)}
                /> */}


                {alert.isActive &&
                    <div className={`alert alert-${alert.type}`} role="alert">
                        {alert.message}
                    </div>
                }
                <Buttons
                    buttonType="submit"
                    name={t('words.create')}
                    onClick={(e) => { handleSubmit(e) }}
                    isLoading={company.isSubmit}
                    btnClass="btn-theme mt-5 w-100"
                    labelClass="text-white fw-bold fs-5"
                    btnStyle={{ height: 68 }}
                    arrowVisible={true}
                    arrowColor='text-white'
                />
            </div>
        </div>
    )
}

export default CompanyCreate