import { Line, Layer } from '../../../types';

export class LayerStateManager {
  // Store hidden layers by their text (as a unique identifier)
  private hiddenLayerTypes: Set<string> = new Set();
  private showHiddenMenu: boolean = false;
  private activeLineId: string | null = null;

  public getHiddenLayers(line: Line): Layer[] {
    // If we're showing the menu and this is the active line,
    // only return hidden layers that were originally on this line
    if (this.showHiddenMenu && this.activeLineId === line.id) {
      return (line.layers || []).filter(layer => 
        this.hiddenLayerTypes.has(this.getLayerIdentifier(layer))
      );
    }
    return [];
  }

  public isHiddenMenuVisible(): boolean {
    return this.showHiddenMenu;
  }

  public hideLayer(layer: Layer) {
    this.hiddenLayerTypes.add(this.getLayerIdentifier(layer));
  }

  public showLayer(layer: Layer) {
    this.hiddenLayerTypes.delete(this.getLayerIdentifier(layer));
  }

  public clearHiddenLayers() {
    this.hiddenLayerTypes.clear();
    this.activeLineId = null;
  }

  public toggleHiddenMenu(lineId: string) {
    if (this.showHiddenMenu && this.activeLineId === lineId) {
      this.showHiddenMenu = false;
      this.activeLineId = null;
    } else {
      this.showHiddenMenu = true;
      this.activeLineId = lineId;
    }
  }

  public setHiddenMenuVisible(visible: boolean) {
    this.showHiddenMenu = visible;
    if (!visible) {
      this.activeLineId = null;
    }
  }

  public isActiveLine(lineId: string): boolean {
    return this.activeLineId === lineId;
  }

  // Helper method to get a unique identifier for a layer
  private getLayerIdentifier(layer: Layer): string {
    return `${layer.text}:${layer.type || 'default'}`;
  }

  // Check if a layer is hidden
  public isLayerHidden(layer: Layer): boolean {
    return this.hiddenLayerTypes.has(this.getLayerIdentifier(layer));
  }

  // Get all hidden layer types
  public getHiddenLayerTypes(): string[] {
    return Array.from(this.hiddenLayerTypes);
  }
}