import * as d3 from 'd3';
import { Block, Position, EditMode } from '../../types';
import { createProcessBlock } from './ProcessBlock';
import { createDecisionBlock } from './DecisionBlock';
import { createStartEndBlock } from './StartEndBlock';
import { getBlockDimensions } from '../../utils/blockUtils';

export function createBlock(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  block: Block,
  editMode: EditMode,
  onDragEnd: (id: string, position: Position) => void,
  onTextUpdate: (id: string, newText: string) => void,
  allBlocks: Block[] = [],
  t: (key: string) => string,
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>,
  attributesDataEditable: any
) {
  // Ensure block has dimensions
  const dimensions = getBlockDimensions(block);
  const blockWithDimensions: any = {
    ...block,
    width: block.width || dimensions.width,
    height: block.height || dimensions.height
  };

  switch (block.type) {
    case 'process':
      return createProcessBlock(container, blockWithDimensions, editMode, onDragEnd, onTextUpdate, allBlocks, t, setBlocks, attributesDataEditable);
    case 'decision':
      return createDecisionBlock(container, blockWithDimensions, editMode, onDragEnd, onTextUpdate, allBlocks, t, setBlocks);
    case 'start':
    case 'end':
      return createStartEndBlock(container, blockWithDimensions, editMode, onDragEnd, onTextUpdate, allBlocks, t, setBlocks, attributesDataEditable);
    default:
      return null;
  }
}