// React Component
import React from 'react'
import { useTranslation } from 'react-i18next'

// App Component
import { CardProcess, NewProcessOrGroup, SearchInput } from '../../components'
import { AppLayout, HeaderLayout, ProcessLayout } from '../../layouts'
import { FamlaServices } from '../../services'
import { useAppSelector } from '../../stores'
import { Spiner } from '../../components'

// Features


//interface
import { iProject } from '../../interfaces';
import { useSearch } from '../../utils'


interface iState {
    isLoadingProject: boolean,

}


const GroupOfProcessViewAll = () => {
    const { t } = useTranslation()
    const users = useAppSelector((state) => state.auth);
    const [processNumbers, setProcessNumbers] = React.useState<{ [key: string]: number }>({});
    const [notifProject, setNotifProject] = React.useState<{ [key: string]: boolean }>({});
    const [project, setProject] = React.useState<iProject[]>([]);

    const [state, setState] = React.useState<iState>({
        isLoadingProject: true,
    })

    const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch("/project/search/my/project");

    const getProcessNumber = async (projectId: string) => {
        try {
            let data = await FamlaServices("api_host", `/project/number/${projectId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                return data.body.data;
            } else {
                return 0;
            }
        } catch (e) {
            return 0;
        }
    };
    const loadNotificationByProject = async (projectId: string) => {
        try {
            let data = await FamlaServices("api_host", `/notification/project/by/id/${projectId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                const datas = data.body.data
                if (datas || datas !== null) {
                    return true
                } else {
                    return false
                }
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    React.useEffect(() => {
        const getGroupOfProcessByUser = async () => {
            let data = await FamlaServices("api_host", `/project/my`, "GET", "", users.access_token);
            if (data.status === 200) {
                let projects = data.body.data

                const numbers: { [key: string]: number } = {};
                const notifs: { [key: string]: boolean } = {};
                for (const project of projects) {
                    numbers[project._id] = await getProcessNumber(project._id);
                    notifs[project._id] = await loadNotificationByProject(project._id);
                }
                setProcessNumbers(numbers);
                setNotifProject(notifs)
                setProject(projects)
                setState((prev) => ({ ...prev, isLoadingProject: false }))

            }
        }

        getGroupOfProcessByUser()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users.access_token])

    const deleFolder = async (folder_id: string, name: string) => {
        if (name !== "Default") {
            setProject(project.filter((project: { _id: string }) => project._id !== folder_id));
            await FamlaServices("api_host", `/project/${folder_id}`, "DELETE", "", users.access_token);
        }
    }

    return (
        <AppLayout>

            <>
                <HeaderLayout
                    loadcompany={false}
                    search={true}
                    setSearchQuery={setSearchQuery}
                />
                <div className="container-fluid mt-3 pb-3 px-3 h-100">
                    {/* <div className="mt-4 m-auto d-flex align-items-center justify-content-center w-75">
                        <SearchInput
                            inputType="text"
                            inputValue={searchQuery}
                            placeholderValue={`${t('words.search')}...`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                            inputContainerClass="w-75"
                            inputStyle={{ borderRadius: 20 }}
                            inputClass="bg-black text-light border-0"
                            iconClass="fa-solid fa-magnifying-glass  "
                            iconName=""
                            formClass='rounded-5 bg-black text-light text-bg-dark'
                            iconContainer='bg-black text-light border-0'
                        />
                    </div> */}
                    {(searchQuery !== "") ?
                        <ProcessLayout title={`${t('words.searching')}...${searchQuery}`} arrowBack={true} style={{}} addClassName={` containerCardProcess overflow-auto${project.length <= 5 ? 'vh-100' : ''}`}>
                            <>
                                {searchResults.map((project: iProject, index: number) => (
                                    <CardProcess
                                        processName={project.title}
                                        thisProcessHasABadge={processNumbers[project._id] > 0}
                                        theBadgeHasAValue={true}
                                        badgeValue={processNumbers[project._id] || 0}
                                        thisProcessHasAOwner={true}
                                        owner=''
                                        key={index}
                                        route=''
                                        deleteProcess={() => { deleFolder(project._id, project.title) }}
                                        description={project.description}
                                        processId={project._id}
                                        folder={true}
                                        setProject={setProject}
                                        setProcess={() => { }}
                                        notifFolder={notifProject[project._id] || false}
                                    />
                                ))}
                                {(searchResults.length === 0 && searchLoad) &&
                                    <NewProcessOrGroup newElement={t('views.processView.folderNotFound')} create='process' noResult={true} />
                                }
                            </>

                        </ProcessLayout>
                        :
                        <ProcessLayout title={t('views.processView.library')} arrowBack={true} style={{}} addClassName={` containerCardProcess overflow-auto${project.length <= 5 ? 'vh-100' : ''}`}>
                            {state.isLoadingProject ? (
                                <Spiner className='ms-5 mt-5 spinner-border-sm' />
                            ) : (
                                <>
                                    {project.map((project: iProject, index: number) => (
                                        <CardProcess
                                            processName={project.title}
                                            thisProcessHasABadge={processNumbers[project._id] > 0}
                                            theBadgeHasAValue={true}
                                            badgeValue={processNumbers[project._id] || 0}
                                            thisProcessHasAOwner={true}
                                            owner=''
                                            key={index}
                                            route=''
                                            deleteProcess={() => { deleFolder(project._id, project.title) }}
                                            description={project.description}
                                            processId={project._id}
                                            folder={true}
                                            setProject={setProject}
                                            setProcess={() => { }}
                                            elementType='folder'
                                        />
                                    ))}
                                </>


                            )}
                        </ProcessLayout>
                    }
                </div>
            </>
        </AppLayout>
    )
}

export default GroupOfProcessViewAll