import React, { RefObject, useState } from "react";
import { ZoomIn, ZoomOut, Layers, Square, Diamond, PlayCircle, StopCircle, Sliders, EyeOff, Undo2, Redo2, Save, Palette, Type, Bold, Paintbrush, AlignCenter, TextCursor, AlignLeft, AlignRight, icons, Check, Eye, DropletIcon, ArrowLeft, Droplet, ChevronLeft, CheckIcon, Pencil, PencilOff, BoldIcon, LightbulbIcon, ItalicIcon, Text, TypeIcon, CaseLowerIcon, CaseUpperIcon, CaseSensitiveIcon, Sparkle, SparkleIcon, Sparkles, } from 'lucide-react';
import { Dropdown, DropdownMultiMenu } from "../../../components"
import { Button } from "../../../features/swimLane";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Block } from '../../lib/types';
import { ColorPicker } from '../ColorPicker';
import { Routes } from "../../../routes";
import { iUser } from "../../../components/type";
import { FamlaServices } from "../../../services";
import { useAppSelector } from "../../../stores";
import { Images } from "../../../constants";
import { slice } from "../../../utils";
import { ApprovalModal } from "../../../views/modal";
import { StartLogo } from "../../components/StartLogo"


interface TopBarProps {
    isEditing: boolean;
    hasUnsavedChanges: boolean;
    scale: number;
    approvallist: iUser[];
    processId: string;
    activeFocusdisa: () => void;
    activeFocusa: () => void;
    handleZoom: (direction: "in" | "out") => void;
    handleExportPdf: () => void;
    handleExportPng: () => void;
    exportFile: (type: string) => void;
    swimlaneRef: RefObject<{ toggleEditMode: () => void }>;
    role: string;
    canEditMap: boolean;
    handleToggleLayers: () => void;
    handleToggleLayersLine: () => void;
    showLayers: boolean;
    showLayersLine: boolean;
    onUndo: () => void;
    onRedo: () => void;
    selectedBlock: Block | null;
    currentData: any;
    handleWidthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlockUpdate: (updates: Partial<Block>) => void;
    handleTextStyleUpdate: (property: string, value: any) => void;
    canUndo: boolean;
    canRedo: boolean;
    setMarginTop: React.Dispatch<React.SetStateAction<string>>;
    handleSelectAll: () => void;
    title: string;
    handleCopy: () => void;
    handleCut: () => void;
    handlePaste: () => void;
    allSwimLane: any[];
    setMap: React.Dispatch<React.SetStateAction<string>>;
    owner: iUser;
    collaborators: any[];
    setShowRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
    showRightPanel: boolean;
}

type StateType = "shape" | "properties" | "hide";

const TopBar: React.FC<TopBarProps> = ({
    isEditing,
    hasUnsavedChanges,
    scale,
    approvallist,
    processId,
    activeFocusdisa,
    activeFocusa,
    handleZoom,
    handleExportPdf,
    handleExportPng,
    exportFile,
    swimlaneRef,
    role,
    canEditMap,
    handleToggleLayers,
    handleToggleLayersLine,
    showLayers,
    showLayersLine,
    onUndo,
    onRedo,
    selectedBlock,
    currentData,
    handleWidthChange,
    handleBlockUpdate,
    handleTextStyleUpdate,
    canUndo,
    canRedo,
    setMarginTop,
    handleSelectAll,
    title,
    handleCopy,
    handleCut,
    handlePaste,
    allSwimLane,
    setMap,
    owner,
    collaborators,
    setShowRightPanel,
    showRightPanel
}) => {
    const activeSaveModal = React.useRef<HTMLDivElement>(null)
    const activeSaveModal1 = React.useRef<HTMLDivElement>(null)
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation()
    const navigate = useNavigate()
    const mapId = useAppSelector((state) => state.mapId.mapId);
    const handleGoBack = () => {
        if (hasUnsavedChanges) {
            activeSaveModal.current && activeSaveModal.current.click()
        } else {
            navigate(-1);
        }

    };
    const handleGoToHome = () => {
        if (hasUnsavedChanges) {
            activeSaveModal1.current && activeSaveModal1.current.click()
        } else {
            navigate(Routes.HOMEVIEW);
        }

    };
    const [isPropertiesPanelOpen, setIsPropertiesPanelOpen] = useState<boolean>(true);

    const togglePropertiesPanel = () => {
        setIsPropertiesPanelOpen(prev => {
            const newState = !prev;
            setMarginTop(newState ? '89px' : '0px'); // Mise à jour basée sur le nouvel état
            return newState;
        });
    };


    const getClassName = (panel: boolean, color: string) => `
        p-2 rounded flex items-center gap-2 transition-colors
        ${panel
            ? `bg-${color}-200 text-${color}-700`
            : `bg-${color}-50 text-${color}-600 hover:bg-${color}-100`
        }
    `;

    const downloadTranscript = async () => {
        try {
            const response = await FamlaServices("api_host", `/file/export-pdf/?process_id=${processId}`, "GET", "", users.access_token);

            if (response.status !== 200) {
                throw new Error(`Erreur: ${response.status}`);
            }

            // Vérifier si la réponse contient un fichier PDF
            if (response.contentType && response.contentType.includes("application/pdf")) {
                const blob = response.body;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `process_${processId}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
            }
        } catch (error) {
        }
    };

    const checkType = (type: Block['type']) => {
        if (type === (t('words.proces'))) {
            return 'process'
        } else if (type === (t('words.decision'))) {
            return 'decision'
        } else if (type === (t('words.start'))) {
            return 'start'
        } else {
            return 'end'
        }
    }

    const renderShapeButton = (type: Block['type'], Icon: any, color: string) => {
        const colorClasses: Record<string, string> = {
            blue: 'bg-blue-50 text-blue-600 hover:bg-blue-100',
            purple: 'bg-purple-50 text-purple-600 hover:bg-purple-100',
            green: 'bg-green-50 text-green-600 hover:bg-green-100',
            red: 'bg-red-50 text-red-600 hover:bg-red-100',
        };


        return (
            <button
                draggable
                onDragStart={(e) => {
                    e.dataTransfer.setData('shape-type', checkType(type));
                }}
                className={`flex flex-col items-center justify-center p-2 rounded-lg transition-colors ${colorClasses[color]}`}
            >
                <Icon className="w-5 h-5" />
                <span className="text-xs capitalize mt-1">{type}</span>
            </button>
        );
    };


    const menuItemsFile = [
        { label: t('features.swimlane.openInterview'), onClick: handleGoBack },
        {
            label: t('words.export'),
            children: [
                {
                    label: t('words.map'),
                    children: [
                        { label: "PDF", className: "dropdown-item", onClick: handleExportPdf },
                        { label: "PNG", onClick: handleExportPng },
                        { label: "CSV", onClick: () => exportFile?.('csv') },
                        { label: "XES", onClick: () => exportFile?.('xes') }
                    ],
                    icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
                },
                {
                    label: t('words.interview'),
                    children: [
                        { label: "Transcript", onClick: downloadTranscript }
                    ],
                    icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
                }
            ],
            icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
        },

        {
            label: isEditing ? t('words.save') : "",
            className: `${hasUnsavedChanges
                ? "text-black hover:text-green-500"
                : "text-black text-green-500 "
                }`
            ,
            // disabled: (!hasUnsavedChanges),
            dataBsTarget: "#renameSwimlane",
            dataBsToggle: "modal"

        },
        // {
        //     label: t("features.swimlane.enableEditMode"),
        //     className: `${hasUnsavedChanges
        //         ? "text-black hover:text-green-500"
        //         : "text-black text-gray-500 cursor-not-allowed"
        //         }`
        //     ,
        //     onClick: () => swimlaneRef.current?.toggleEditMode(),
        //     icon: isEditing ? <CheckIcon className="w-4 h-4" /> : <PencilOff className="w-4 h-4" />

        // },

        // {
        //     label: t("features.swimlane.versionHistory"),
        //     dataBsTarget: "#mapModal",
        //     dataBsToggle: "modal"
        // },
        {
            label: t("features.swimlane.versionHistory"),
            children: allSwimLane.map((swimLane) => ({
                label: slice(swimLane.name ? swimLane.name : swimLane._id, 25),
                className: "dropdown-item",
                onClick: () => setMap(swimLane._id)
            })),
            classParentChildren: "overflow-scroll",
            heightCh: 250,
            icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
        },

        {
            label: approvallist.length === 0 ? "" : t("words.approve"),
            dataBsTarget: `#generic${processId}`,
            dataBsToggle: "modal",
            onClick: activeFocusa
        },

        {
            label: approvallist.length === 0 ? "" : t("words.disApprove"),
            dataBsTarget: `#disapprove`,
            dataBsToggle: "modal",
            onClick: activeFocusdisa
        },

        // {
        //     label: t("words.improve"),
        //     dataBsTarget: `#approve`,
        //     dataBsToggle: "modal"
        // },
        {
            label: t('words.share'),
            dataBsTarget: `#shareModal${mapId}`,
            dataBsToggle: "modal"

        },

        { label: t('words.close'), onClick: handleGoToHome },

    ];

    const menuItemsEdit = [
        {
            label: t("features.swimlane.undo"),
            shortcut: 'Ctrl+Z',
            icon: <Undo2 className="w-4 h-4" />,
            onClick: onUndo,
            disabled: !canUndo,
            className: `p-2 rounded flex items-center gap-2 ${canUndo
                ? 'text-blue-50 text-blue-600 hover:bg-blue-100'
                : 'text-gray-100 text-gray-400 cursor-not-allowed disabled'
                }`

        },


        {
            label: t("features.swimlane.redo"),
            shortcut: 'Ctrl+Y',
            icon: <Redo2 className="w-4 h-4" />,
            onClick: onRedo,
            disabled: !canRedo,
            className: `p-2 rounded flex items-center gap-2 ${canRedo
                ? 'text-blue-50 text-blue-600 hover:bg-blue-100'
                : 'text-gray-100 text-gray-400 cursor-not-allowed disabled'
                }`
        },

        {
            label: t("words.copy"),
            shortcut: 'Ctrl+C',
            onClick: handleCopy,
        },

        {
            label: t("words.past"),
            shortcut: 'Ctrl+V',
            onClick: handlePaste,
        },

        {
            label: t("features.swimlane.selectAll"),
            shortcut: '(Ctrl+A)',
            onClick: handleSelectAll,
        },
    ];
    const menuItemsView = [
        {
            label: t('words.attributes'),
            children: [
                {
                    label: isEditing ? t("features.swimlane.tasksAttributes") : '',
                    onClick: handleToggleLayers,
                    className: `p-2 rounded flex items-center gap-2 transition-colors ${showLayers
                        ? 'bg-green-100 text-green-600 hover:bg-green-200'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                        }`,

                    icon: showLayers ? <Layers className="w-4 h-4 text-blue-500" /> : <></>

                },
                {
                    label: isEditing ? t("features.swimlane.arrowsAttributes") : '',
                    onClick: handleToggleLayersLine,
                    className: `p-2 rounded flex items-center gap-2 transition-colors ${showLayers
                        ? 'bg-green-100 text-green-600 hover:bg-green-200'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                        }`,

                    icon: showLayersLine ? <Layers className="w-4 h-4 text-blue-500" /> : <></>

                },
            ],
            icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
        },

        // {
        //     label: isEditing ? t("features.swimlane.shapes") : "",
        //     className: getClassName("shape", "blue"),
        //     onClick: () => togglePanel("shape"),
        //     icon: activePanel === "shape" && <Check className="w-4 h-4" />
        // },
        {
            label: isEditing ? t("features.swimlane.properties") : "",
            className: getClassName(isPropertiesPanelOpen, "purple"),
            onClick: () => togglePropertiesPanel(),
            icon: isPropertiesPanelOpen && <Check className="w-4 h-4" />
        },

        // {
        //     label: isEditing ? t("features.swimlane.hideElements") : '',
        //     className: "p-2 rounded flex items-center gap-2 transition-colors bg-gray-50 text-gray-600 hover:bg-gray-100",
        //     onClick: () => handlesHowProperties("hide"),
        //     icon: showProperties === "hide" ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />
        // },

        {
            label: " Zoom",
            onClick: () => handleZoom("out"),
            className: `rounded hover:bg-gray-100 ${scale <= 0.5 && "disabled"}`,
            disabled: scale <= 0.5,
            icon: <ZoomOut className="w-4 h-4 text-gray-600" />,
            shortcut: ""
        },
        {
            label: " Zoom",
            onClick: () => handleZoom("in"),
            className: `rounded hover:bg-gray-100 ${scale >= 2 && "disabled"}`,
            disabled: scale >= 2,
            icon: <ZoomIn className="w-4 h-4 text-gray-600" />,
            shortcut: ""
        },
    ];
    const menuItemsHelp = [
        // { label: t('navigation.userGuide'), disabled: true, className: '' },
        // { label: t('features.swimlane.onboardingTutorial'), disabled: true },
        { label: t('features.swimlane.reportAbug'), href: Routes.CONTACTUS },

    ];
    const menuItemsProcessOwner = [
        {
            label: t('words.attributes'),
            dataBsTarget: `#layerSettingsModal`,
            dataBsToggle: "modal"
        },
        {
            label: t('words.requestApproval'),
            dataBsTarget: `#approval${mapId}`,
            dataBsToggle: "modal"
        },
        {
            label: collaborators.length > 0 ? t('features.swimlane.enableMapEdit') : "",
            dataBsTarget: `#collaboratorModalEdit`,
            dataBsToggle: "modal"
        },
        {
            label: t('features.swimlane.enableDataEdit'),
            dataBsTarget: `#attributeModal`,
            dataBsToggle: "modal"
        },
        // { label: t('features.swimlane.enableDataEdit'), href: "#" },
        // { label: t('features.swimlane.moveToBin'), href: "#" },
    ];
    const menuItemsFormat = [
        {
            label: t('words.alignment'),
            icon: <AlignRight className="w-4 h-4" />,
            className: "dropdown-item",
            children: [
                {
                    label: t('words.left'),
                    icon: <AlignLeft className="w-4 h-4" />,
                    className: "dropdown-item",
                    onClick: () => handleTextStyleUpdate('textAlign', 'left')
                },
                {
                    label: t('words.center'),
                    icon: <AlignCenter className="w-4 h-4" />,
                    className: "dropdown-item",
                    onClick: () => handleTextStyleUpdate('textAlign', 'center')
                },
                {
                    label: t('words.right'),
                    icon: <AlignRight className="w-4 h-4" />,
                    className: "dropdown-item",
                    onClick: () => handleTextStyleUpdate('textAlign', 'right')
                },


            ],
            icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
        },

        {
            label: t('words.text'),
            icon: <BoldIcon className="w-4 h-4" />,
            className: "dropdown-item",
            children: [
                {
                    label: t('words.bold'),
                    icon: <BoldIcon className="w-4 h-4" />,
                    className: "dropdown-item",
                    onClick: () => handleTextStyleUpdate('fontWeight', 'bold')
                },
                {
                    label: t('words.italic'),
                    icon: <ItalicIcon className="w-4 h-4" />,
                    className: "dropdown-item",
                    onClick: () => handleTextStyleUpdate('fontWeight', 'light')
                },
                // {
                //     label: t('words.normal'),
                //     icon: <Text className="w-4 h-4" />,
                //     className: "dropdown-item",
                //     onClick: () => handleTextStyleUpdate('fontWeight', 'normal')
                // },


            ],
            icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
        },
        {
            label: t('features.swimlane.fontSize'),
            icon: <TypeIcon className="w-4 h-4" />,
            className: "",
            children: [
                {
                    label: t('words.normal'),
                    icon: <BoldIcon className="w-4 h-4" />,
                    className: "",
                    onClick: () => handleTextStyleUpdate('textTransform', 'none')
                },
                {
                    label: t('words.uppercase'),
                    icon: <CaseUpperIcon className="w-4 h-4" />,
                    className: "",
                    onClick: () => handleTextStyleUpdate('textTransform', 'uppercase')
                },
                {
                    label: t('words.lowercase'),
                    icon: <CaseLowerIcon className="w-4 h-4" />,
                    className: "",
                    onClick: () => handleTextStyleUpdate('textTransform', 'lowercase')
                },
                {
                    label: t('words.capitalize'),
                    icon: <CaseSensitiveIcon className="w-4 h-4" />,
                    className: "",
                    onClick: () => handleTextStyleUpdate('textTransform', 'capitalize')
                },


            ],
            icons: <i className="bi bi-caret-right-fill text-secondary small"></i>
        },

    ];
    const menuItems = [
        { label: "Action", href: "#" },
        { label: "Another action", href: "#" },
        {
            label: "Export",
            className: "dropdown-item",
            children: [
                {
                    label: "Map",
                    children: [
                        { label: "PDF", className: "dropdown-item", onClick: () => { } },
                        { label: "PNG", href: "#" },
                        { label: "CSV", href: "#" },
                        { label: "XES", href: "#" }
                    ]
                },
                {
                    label: "Interview",
                    children: [
                        { label: "Transcript", href: "#" }
                    ]
                }
            ]
        },
        { label: "Something else here", href: "#" }
    ];

    return (
        <>

            <div
                className={`fixed top-0 bg-white shadow-sm  transition-all duration-300 ${showRightPanel && "transition-all w-[calc(100%-200px)] md:w-[calc(100%-200px)] lg:w-[calc(100%-250px)] xl:w-[calc(100%-280px)] 2xl:w-[calc(100%-500px)]"} `}
                style={{
                    left: isEditing ? "0" : "0",
                    right: isEditing ? "0" : "0",
                    zIndex: "1050",

                }}

            >
                <div className={`flex items-center justify-between w-100 gap-3 px-4 ${showRightPanel && "transition-all w-[calc(100%-200px)] md:w-[calc(100%-200px)] lg:w-[calc(100%-250px)] xl:w-[calc(100%-280px)] 2xl:w-[calc(100%-500px)]"}`}>
                    <div className="flex items-center gap-3 ">
                        <div className="pb-2">
                            <img className="img-fluid rounded-1 " src={Images.logoUrl} alt="" width={30} height={30} />
                        </div>

                        <div>
                            <div className="p-0 m-0 fs-5">{title}</div>
                            <div className="max-w-full flex items-center justify-between pt-0">

                                <div className="flex items-center gap-3">
                                    <DropdownMultiMenu
                                        dropName={t("words.file")}
                                        buttonClassName="px-0 pb-2 rounded-lg transition-colors bg-white"
                                        menuItems={menuItemsFile}
                                        id="dropdownFile"
                                        key={"dropdownFile"}
                                    />
                                    {isEditing ?
                                        <DropdownMultiMenu
                                            dropName={t("words.edition")}
                                            buttonClassName="px-0 pb-2 rounded-lg transition-colors bg-white"
                                            menuItems={menuItemsEdit}
                                            id="dropdownEdit"
                                            key={'dropdownEdit'}
                                        />
                                        :
                                        <></>
                                    }
                                    <DropdownMultiMenu
                                        dropName={t("words.view")}
                                        buttonClassName="px-0 pb-2 rounded-lg transition-colors bg-white"
                                        menuItems={menuItemsView}
                                        id="dropdownView"
                                        key={'dropdownView'}
                                    />
                                    {isEditing ?
                                        <DropdownMultiMenu
                                            dropName={'Format'}
                                            buttonClassName="px-0 pb-2 rounded-lg transition-colors bg-white"
                                            menuItems={menuItemsFormat}
                                            id="menuItemsFormat"
                                            key={'menuItemsFormat'}
                                        />
                                        :
                                        <></>
                                    }
                                    {(users.user?._id === owner?._id) ?
                                        <DropdownMultiMenu
                                            dropName={'Process owner'}
                                            buttonClassName="px-0 pb-2 rounded-lg transition-colors bg-white"
                                            menuItems={menuItemsProcessOwner}
                                            id="menuItemsProcessOwner"
                                            key={'menuItemsProcessOwner'}
                                        />
                                        :
                                        <></>
                                    }
                                    <DropdownMultiMenu
                                        dropName={t("words.help")}
                                        buttonClassName="px-0 pb-2 rounded-lg transition-colors bg-white"
                                        menuItems={menuItemsHelp}
                                        id="menuItemsHelp"
                                        key={'menuItemsHelp'}
                                    />


                                    {/* {isEditing &&
                        <button
                            className={`py-2 rounded flex items-center gap-2  ${showProperties === "shape"
                                ? 'text-blue-700'
                                : `text-black`
                                }`}

                            onClick={() => handlesHowProperties("shape")}
                        >
                            {t("features.swimlane.shapes")}
                        </button>
                    } */}


                                    {/* History Controls */}


                                </div>

                                <div className="flex items-center gap-4">
                                </div>
                            </div>
                        </div>
                    </div>
                    {!showRightPanel &&
                        <div className="flex items-center">
                            <button
                                onClick={() => setShowRightPanel(!showRightPanel)}
                                className="d-flex py-1 px-2 rounded-full text-gradient  "
                                style={{ border: "1px solid #017d7c" }}
                                id="analyseButton"
                            >
                                <span > <Sparkles className="stroke-[#017d7c] p-0 pt-1 rotate-icon" size={22} /></span>

                                <span className="font-medium mb-1">{t('features.swimlane.analysis')}</span>
                            </button>

                        </div>
                    }
                </div>


                {/* Shapes Panel */}
                {/* {
                (activePanel === "shape" && isEditing) && (
                    <div className="border-t border-gray-200 px-4 py-2">
                        <div className="grid grid-cols-4 gap-4 max-w-md">
                            {renderShapeButton('process', Square, 'blue')}
                            {renderShapeButton('decision', Diamond, 'purple')}
                            {renderShapeButton('start', PlayCircle, 'green')}
                            {renderShapeButton('end', StopCircle, 'red')}
                        </div>
                    </div>
                )
            } */}

                {/* Properties Panel */}
                {
                    (isPropertiesPanelOpen && isEditing) && (
                        <div className="border-t border-gray-200 px-4 py-2 flex items-center gap-3 small">
                            {isEditing &&
                                <div className="flex items-center gap-3">
                                    <button
                                        onClick={onUndo}
                                        disabled={!canUndo}
                                        className={`py-2 rounded flex items-center gap-2 ${canUndo
                                            ? 'text-blue-600 hover:bg-blue-100'
                                            : ' text-gray-400 cursor-not-allowed'
                                            }`}
                                        title={t("features.swimlane.undo")}
                                    >
                                        <Undo2 className="w-5 h-5" />
                                    </button>
                                    <button
                                        onClick={onRedo}
                                        disabled={!canRedo}
                                        className={`py-2 rounded flex items-center gap-2 ${canRedo
                                            ? 'text-blue-600 hover:bg-blue-100'
                                            : ' text-gray-400 cursor-not-allowed'
                                            }`}
                                        title={t("features.swimlane.redo")}
                                    >
                                        <Redo2 className="w-5 h-5" />
                                    </button>

                                </div>
                            }
                            {/* Swimlane Width */}
                            {/* {!selectedBlock &&
                                <div className={` ${showRightPanel && "hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:block"}`}>
                                    <div className="flex items-center gap-2" >
                                        <span className="text-gray-600">
                                            W
                                        </span>
                                        <div className="flex items-center gap-1">
                                            <input
                                                type="number"
                                                className="px-1 py-1 border border-gray-300 rounded text-sm"
                                                value={currentData.width}
                                                onChange={handleWidthChange}
                                                min="800"
                                                step="50"
                                                disabled
                                                style={{ width: 55 }}

                                            />
                                            <span className="text-sm text-gray-500">px</span>
                                        </div>
                                    </div>
                                </div>
                            } */}

                            {/* {selectedBlock && } */}
                            {(
                                <>
                                    {/* Block Properties */}
                                    {
                                        <div className={` ${showRightPanel && "hidden md:hidden lg:hidden xl:block 2xl:block"}`}>
                                            <div className=" flex items-center gap-2 ">
                                                <span className="text-gray-600">
                                                    x
                                                </span>
                                                <div className="flex items-center gap-1">
                                                    <input
                                                        type="number"
                                                        className="px-1 py-1 border border-gray-300 rounded text-sm"
                                                        value={selectedBlock?.width || 120}
                                                        onChange={(e) => handleBlockUpdate({ width: parseInt(e.target.value, 10) })}
                                                        min="50"
                                                        step="10"
                                                        style={{ width: 50 }}
                                                    />
                                                    <span className="text-sm text-gray-500">px</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        <div className={`${showRightPanel && "hidden md:hidden lg:hidden xl:hidden 2xl:block"}`}>
                                            <div className="flex items-center gap-2  ">
                                                <span className="text-gray-600">
                                                    y
                                                </span>
                                                <div className="flex items-center gap-1">
                                                    <input
                                                        type="number"
                                                        className="px-1 py-1 border border-gray-300 rounded text-sm"
                                                        value={selectedBlock?.height || 60}
                                                        onChange={(e) => handleBlockUpdate({ height: parseInt(e.target.value, 10) })}
                                                        min="30"
                                                        step="10"
                                                        style={{ width: 50 }}
                                                    />
                                                    <span className="text-sm text-gray-500">px</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="flex items-center gap-2">
                                        <span className="text-gray-600">
                                            <Palette size={16} />
                                        </span>
                                        <ColorPicker
                                            color={selectedBlock?.fill || '#c7755b'}
                                            onChange={(color) => handleBlockUpdate({ fill: color })}
                                            label=""
                                        />
                                    </div>

                                    {/* Text Properties */}
                                    <div className="flex items-center gap-2">
                                        <span className="text-gray-600">
                                            <Type size={16} />
                                        </span>
                                        <div className="flex items-center gap-1">
                                            <input
                                                type="number"
                                                className="px-1 py-1 border border-gray-300 rounded text-sm"
                                                value={selectedBlock?.textStyle?.fontSize || 14}
                                                onChange={(e) => handleTextStyleUpdate('fontSize', parseInt(e.target.value, 10))}
                                                min="8"
                                                max="72"
                                                style={{ width: 50 }}
                                            />
                                            <span className="text-sm text-gray-500">px</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span className="text-gray-600">
                                            <Paintbrush size={16} />
                                        </span>
                                        <ColorPicker
                                            color={selectedBlock?.textStyle?.textColor || '#ffffff'}
                                            onChange={(color) => handleTextStyleUpdate('textColor', color)}
                                            label=""
                                        />
                                    </div>

                                    {/* <div className="flex items-center gap-2">
                                    <select
                                        className="px-2 py-1 border border-gray-300 rounded text-sm"
                                        value={selectedBlock?.textStyle?.textAlign || 'center'}
                                        onChange={(e) => handleTextStyleUpdate('textAlign', e.target.value)}
                                    >
                                        <option value="left" className="flex items-center gap-1">
                                            <div className="flex items-center">
                                                {t('words.left')}
                                            </div>
                                        </option>
                                        <option value="center" className="flex items-center gap-1">
                                            <div className="flex items-center">
                                                {t('words.center')}
                                            </div>
                                        </option>
                                        <option value="right" className="flex items-center gap-1">
                                            <div className="flex items-center">
                                                {t('words.right')}
                                            </div>
                                        </option>
                                    </select>
                                </div> */}

                                    {/* <div className="flex items-center gap-2">
                                    <select
                                        className="px-2 py-1 border border-gray-300 rounded text-sm"
                                        value={selectedBlock?.textStyle?.textTransform || 'none'}
                                        onChange={(e) => handleTextStyleUpdate('textTransform', e.target.value)}
                                        style={{ width: 60 }}
                                    >
                                        <option title={t('words.uppercase')} value="uppercase">AA</option>
                                        <option title={t('words.normal')} value="none">Aa</option>
                                        <option title={t('words.capitalize')} value="capitalize">Aa</option>
                                        <option title={t('words.lowercase')} value="lowercase">aa</option>
                                    </select>
                                </div> */}
                                    <div className="flex items-center gap-1">

                                        <button
                                            className={`p-1.5 rounded hover:bg-gray-100 transition-colors ${selectedBlock?.textStyle?.fontWeight === 'bold' ? 'bg-gray-100' : ''
                                                }`}
                                            onClick={() => handleTextStyleUpdate('fontWeight', 'bold')}
                                            title={t('words.bold')}
                                        >
                                            <span className="text-gray-600 font-bold text-base">B</span>
                                        </button>

                                        <button
                                            className={`p-1.5 rounded hover:bg-gray-100 transition-colors ${selectedBlock?.textStyle?.fontWeight === 'light' ? 'bg-gray-100' : ''
                                                }`}
                                            onClick={() => handleTextStyleUpdate('fontWeight', 'light')}
                                            title={t('words.italic')}
                                        >
                                            {/* <span className="text-gray-600 font-light text-base">I</span> */}
                                            <ItalicIcon className="w-4 h-4" />
                                        </button>
                                        {/* <button
                                        className={`p-1.5 rounded hover:bg-gray-100 transition-colors ${selectedBlock?.textStyle?.fontWeight === 'normal' ? 'bg-gray-100' : ''
                                            }`}
                                        onClick={() => handleTextStyleUpdate('fontWeight', 'normal')}
                                        title={t('words.normal')}
                                    >
                                        <span className="text-gray-600 font-normal text-base">B</span>
                                    </button> */}

                                    </div>

                                    {/* Separator before Shape section - full height */}
                                    <div className="self-stretch w-px bg-gray-300 mx-0"></div>

                                    {/* Shape Selection Section with Title */}
                                    <div className="flex flex-col items-center justify-center">
                                        <h3 className="text-xs font-medium text-gray-500 pb-1">{t("features.swimlane.shapes")}</h3>
                                        <div className="grid grid-cols-4 gap-1">
                                            {renderShapeButton(t('words.proces'), Square, 'blue')}
                                            {renderShapeButton(t('words.decision'), Diamond, 'purple')}
                                            {renderShapeButton(t('words.start'), PlayCircle, 'green')}
                                            {renderShapeButton(t('words.end'), StopCircle, 'red')}
                                        </div>
                                    </div>

                                    {/* Separator after Shape section - full height */}
                                    <div className="self-stretch w-px bg-gray-300 mx-0"></div>


                                    {/* Zoom Controls */}
                                    <div className="flex items-center gap-1">
                                        <button
                                            onClick={() => handleZoom("out")}
                                            className="p-1 rounded hover:bg-gray-100"
                                            disabled={scale <= 0.5}
                                        >
                                            <ZoomOut className="w-4 h-4 text-gray-600" />
                                        </button>
                                        <span className="text-sm text-gray-600 p-0">{Math.round(scale * 100)}%</span>
                                        <button
                                            onClick={() => handleZoom("in")}
                                            className="p-1 rounded hover:bg-gray-100"
                                            disabled={scale >= 2}
                                        >
                                            <ZoomIn className="w-4 h-4 text-gray-600" />
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                }
            </div >
            <span ref={activeSaveModal} data-bs-target={`#checkSave`} data-bs-toggle='modal'></span>
            <span ref={activeSaveModal1} data-bs-target={`#checkSaveifBacktoHome`} data-bs-toggle='modal'></span>

        </>

    );
};

export default TopBar;
