import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../stores";
import { FamlaServices } from '../../services';
import { Spiner } from '../../components';
import { AlertCircle } from 'lucide-react';

interface ReminderModalProps {
    interviewId: string;
    closeRef: any;
}

const ReminderModal = ({ interviewId, closeRef }: ReminderModalProps) => {
    const { t } = useTranslation();
    const users = useAppSelector(state => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alert, setAlert] = useState<{ isActive: boolean, type: string, message: string }>({ isActive: false, type: '', message: '' });

    const handleReminder = async () => {
        setIsSubmitting(true);

        const response = await FamlaServices("api_host", `/interview/reminder/${interviewId}`, "PUT", "", users.access_token);

        if (response.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t('views.processInterview.reminderSent') });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        } else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        }
        setIsSubmitting(false);
    };

    return (
        <div className="modal" id='reminderModal' tabIndex={-1} data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark text-light">
                    <div className="modal-header border-0">
                        <h4 className="modal-title">{t('navigation.sendReminder')}</h4>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {alert.isActive && (
                            <div className={`alert alert-${alert.type}`} role="alert">{alert.message}</div>
                        )}

                        <div className='d-flex gap-2'>
                            <AlertCircle size={24} className="text-theme mb-3" />
                            <p>{t('views.processInterview.reminderMessage')}</p>
                        </div>

                    </div>
                    <div className="modal-footer ">
                        <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            {t('words.close')}
                        </button>
                        <button type="button" onClick={handleReminder} className="btn btn-primary" disabled={isSubmitting}>
                            {isSubmitting ? <Spiner className='spinner-border-sm' /> : t('words.reminder')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReminderModal;
