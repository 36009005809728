
import React from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function FeedbackDialog({ onClose, onSubmit }: { onClose: () => void; onSubmit: (feedback: string, status: boolean) => void }) {
    const [feedback, setFeedback] = React.useState('');
    const { t } = useTranslation();


    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
                <div className="bg-white rounded-lg p-6 w-full max-w-md">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">{t("features.swimlane.shareYourFeedback")}</h3>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            <X size={20} />
                        </button>
                    </div>
                    <textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        className="w-full h-32 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder={t("features.swimlane.feedbackPlaceholder")}
                    />
                    <div className="flex justify-end gap-3 mt-4">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-gray-600 hover:text-gray-800"
                        >
                            {t('words.cancel')}
                        </button>
                        <button
                            onClick={() => {
                                onSubmit(feedback, false);
                                onClose();
                            }}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                        >
                            {t('words.send')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}