// React Component
import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"


//App Component
import { Routes } from "../routes"
import { Images } from "../constants"
import { FamlaServices } from "../services"
import { logout, useAppSelector } from "../stores"
import { Dropdown, InputForm, SearchInput, SelectForm, Spiner } from "../components"
import { i18n } from '../services'

//interface
import { iAlert } from "../interfaces"
import { ApprovalRequestModal, ChangeCompanyModal, InviteOwnerModal } from "../views/modal"
import { iCompany, iUser } from "../components/type"
import { useCountdown, downloadFileDirect } from "../utils"
import { Building2 } from "lucide-react"

interface ChildProps {
    loadcompany: boolean
    listApproval?: Array<any>
    loading?: boolean,
    companyById?: iCompany
    inputValue?: string
    setSearchQuery?: (value: string | undefined | ((prev: string | undefined) => string | undefined)) => void;
    setFilter?: (value: string | undefined | ((prev: string | undefined) => string | undefined)) => void;
    search?: boolean
    searchfilter?: boolean
    filterValue?: string

}

type iState = {
    email: string,
    isSubmit: boolean,
    spinner: boolean,
    admins: iUser[],
    myCompany: iCompany[]

}

const HeaderLayout = (props: ChildProps) => {
    const {
        loadcompany,
        listApproval,
        loading,
        companyById,
        inputValue,
        setSearchQuery,
        setFilter,
        search,
        searchfilter,
        filterValue
    } = props
    const users = useAppSelector((state) => state.auth);
    const companyId = users.session.company_id;
    const role = useAppSelector((state) => state.role.role);
    const company = useAppSelector((state) => state.company.company);
    const [value, setValue] = useState('')
    const closeRef = useRef<HTMLButtonElement>(null);
    const defaultProjectId: string = "b15abbcd-4b91-407a-acf4-58eb7fafbdea";
    const [currentLanguage, setCurrentLanguage] = React.useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language;
        } catch (error) {
            return navigator.language;
        }
    });
    const [state, setState] = React.useState<iState>({
        email: "",
        isSubmit: false,
        spinner: true,
        admins: [],
        myCompany: []

    })

    const { t } = useTranslation();
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    let daysRemaing = useCountdown(companyById?.expired_at)

    const [selectedCompany, setSelectedCompany] = useState(companyId || undefined);

    const handleSave = () => {
        if (selectedCompany && selectedCompany !== companyId) {
            switchToNewCompany(selectedCompany);
        }
    };

    const updateLang = async (lang: string) => {
        const language = lang === "fr" ? "french" : "english"
        try {
            const updateData = {
                fullname: users.user.fullname,
                phone_number: users.user.phone_number,
                lang: language,
                updated_at: users.user.created_at
            };

            let data = await FamlaServices("api_host", `/users/`, "PUT", updateData, users.access_token);
            if (data.status === 200) {
            }
        } catch (error) {

        }

    }

    React.useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language: string) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);
        // let action = {
        //     type: "LANGUAGE",
        //     value: {
        //         language: updateLanguage,
        //         languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
        //     }
        // };
        // dispatch(action);
        updateLang(updateLanguage);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };



    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'email') {
            setState((prev) => ({ ...prev, email: fieldValue }))
        }
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.email.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.enterValideEmail') });
            return;
        }

        const formData = {
            project_id: defaultProjectId,
            mail: [state.email],
        };

        setState((prevState) => ({ ...prevState, isSubmit: true }));

        try {
            const data = await FamlaServices("api_host", `/project/add_owner/`, "PUT", formData, users.access_token);

            if (data.status === 200) {
                setAlert({ isActive: true, type: 'success', message: t('navigation.invitationSentSuccessfully') });
                setState((prevState) => ({ ...prevState, email: "" }));
                setTimeout(() => {
                    setAlert({ isActive: false, type: '', message: "" });
                }, 3000);
            } else if (data.status === 404) {
                setAlert({ isActive: true, type: 'danger', message: t('navigation.proJectNotFound') });
            } else if (data.status === 401) {
                setAlert({ isActive: true, type: 'danger', message: t("navigation.youAreNotLoggedIn") });
            } else {
                setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        } finally {
            setState((prevState) => ({ ...prevState, isSubmit: false }));
        }
    };


    React.useEffect(() => {
        async function loadAdmin() {

            setState((prevState) => ({ ...prevState, spinner: true }))
            try {
                setAlert({ isActive: false, type: '', message: '' });
                let data = await FamlaServices("api_host", `/users/company/${companyId}?page=${1}&limit=${1000}`, "GET", "", users.access_token);

                if (data.status === 200) {
                    const admin = data.body.data.admin
                    setState((prevState) => ({ ...prevState, admins: admin, spinner: false }))

                } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                    setAlert({ isActive: true, type: 'warning', message: 'You do not have authorisation to continue' });
                } else if (data.status === 401) {
                    logout();
                } else {
                    setAlert({ isActive: true, type: 'danger', message: 'An error has occurred, please try again later' });
                }
            } catch (e) {
                if (e instanceof Error) {
                    console.error(e.message);
                }
            } finally {
                setState((prevState) => ({ ...prevState, spinner: false }))
            }
        }

        if (loadcompany) {
            loadAdmin();
        }

        async function loadMycompany() {

            setState((prevState) => ({ ...prevState, spinner: true }))
            try {
                setAlert({ isActive: false, type: '', message: '' });
                let data = await FamlaServices("api_host", `/company/my`, "GET", "", users.access_token);

                if (data.status === 200) {
                    const companies = data.body.data
                    setState((prevState) => ({ ...prevState, myCompany: companies, spinner: false }))

                } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                    setAlert({ isActive: true, type: 'warning', message: 'You do not have authorisation to continue' });
                } else if (data.status === 401) {
                    logout();
                } else {
                    setAlert({ isActive: true, type: 'danger', message: 'An error has occurred, please try again later' });
                }
            } catch (e) {
                if (e instanceof Error) {
                    console.error(e.message);
                }
            } finally {
                setState((prevState) => ({ ...prevState, spinner: false }))
            }
        }

        loadMycompany()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, loadcompany, users.access_token])

    const switchToNewCompany = async (company_id: string | undefined) => {
        let form = {
            company_id: company_id,
            interview_id: ""
        }
        let data = await FamlaServices("api_host", `/session/`, "PUT", form, users.access_token);
        setState({ ...state, isSubmit: true });
        if (data.status === 200) {
            setState({ ...state, isSubmit: false });
            window.location.replace(Routes.HOMEVIEW)
        }
    }

    function setFilters(value: string) {
        if (value) {
            setFilter?.(value)
            setValue(value)
        }

    }

    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light border-bottom border-secondary">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img src={Images.logov1} height={30} alt="" className="" style={{ maxWidth: "80px", maxHeight: "20px" }} />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto  mb-lg-0">
                                <li className="nav-item me-2">
                                    {search &&
                                        <SearchInput
                                            inputType="text"
                                            inputValue={inputValue}
                                            placeholderValue={`${t('words.search')}...`}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery?.(event.target.value)}
                                            inputContainerClass="w-100 "
                                            inputStyle={{ borderRadius: 10, padding: 1, paddingRight: "100px" }}
                                            inputClass="newbg-black new-text-secondary border-0 small"
                                            iconClass="fa-solid fa-magnifying-glass new-text-secondary "
                                            iconName=""
                                            formClass='rounded-3 bg-transparent new-text-secondary text-bg-dark small border-custom w-100'
                                            iconContainer='newbg-black new-text-secondary border-0'
                                            setFilter={setFilter}
                                            filter={true}
                                            fiterOption={[]}
                                        />}
                                </li>
                                {searchfilter &&
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle btn btn-transparent py-1 px-3 border-custom text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ fontSize: "13px", marginTop: "2px" }}>
                                            {value === "" ? t('words.filter') : value === 'TITLE' ? `${t('words.name')}/${t('words.description')}` : t('words.role')}
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-dark bg-dark border border-secondary" aria-labelledby="navbarDropdown">
                                            <li onClick={() => setFilters?.('ROLE')}><a className="dropdown-item text-white text-decoration-none">{t('words.role')}</a></li>
                                            <li onClick={() => setFilters?.('TITLE')}><a className="dropdown-item text-white text-decoration-none">{`${t('words.name')}/${t('words.description')}`}</a></li>
                                        </ul>
                                    </li>
                                }
                            </ul>
                            <form className="d-flex">
                                {(companyById?.status && companyById?.status !== "PREMIUM") &&
                                    <div className="me-2 ">
                                        <Link to={Routes.CONTACTUS} className="text-decoration-none pe-3 text-theme me-2 btn btn-transparent btn-sm border-0  ">
                                            {daysRemaing > 0 ?
                                                <>
                                                    {daysRemaing > 1 ?
                                                        <>
                                                            {t('layout.header.upgradeToPremiums', { daysRemaing })}
                                                        </>
                                                        :
                                                        <>
                                                            {t('layout.header.upgradeToPremium', { daysRemaing })}
                                                        </>
                                                    }

                                                </>
                                                :
                                                <>
                                                    {t('layout.header.upgradeToPrem')}
                                                </>
                                            }



                                        </Link>

                                    </div>
                                }

                                <Dropdown
                                    key={3}
                                    dropName={currentLanguage === "fr" ? t('words.french') : t('words.english')}
                                    dropdownType="dropdown"
                                    iconClassName=''
                                    buttonDropClassName='btn btn-secondary newbg-black border-0 m-none  btn-sm me-2'
                                    styleButton={{}}
                                    theRouteOfAction4={''}
                                    theRouteOfAction5={''}
                                    lang={true}
                                    changeLanguage={changeLanguage}
                                />

                                <Dropdown
                                    key={2}
                                    dropName={t('words.help')}
                                    dropdownType="dropstart"
                                    iconClassName=''
                                    buttonDropClassName='btn btn-secondary newbg-black border-0 m-none  btn-sm me-2'
                                    styleButton={{}}
                                    deleteProcess={() => { }}
                                    action4={t('navigation.contactUs')}
                                    action5={t('navigation.privacyHub')}
                                    theRouteOfAction4={Routes.CONTACTUS}
                                    theRouteOfAction5={Routes.PRIVACY}
                                    faq={t('words.faq')}
                                    routeFaq={Routes.FAQ}
                                    editdiadram={t('navigation.userGuide')}
                                    runEditor={downloadFileDirect}
                                />

                                <Dropdown
                                    key={1}
                                    dropName={users.user.fullname}
                                    dropdownType="dropstart"
                                    iconClassName=''
                                    buttonDropClassName='btn btn-secondary bg-teal rounded-3 btn-sm border-0 me-2'
                                    styleButton={{}}
                                    deleteProcess={() => { }}
                                    action2={t('navigation.logOut')}
                                    action3={t('navigation.inviteOwner')}
                                    theRouteOfAction4=""
                                    theRouteOfAction5=""
                                    dataBsTarget="#inviteOwner"
                                    routeFaq=""
                                    updateMyCompany={loadcompany ? t('layout.header.company') : ""}
                                    requestApproval={loadcompany ? t('layout.header.approvalRequests') : ""}
                                    action6={""}
                                    dataBsTargetAction6=""
                                    link={(role && role === "ADMIN") ? t('dashboard.dashboard') : ""}
                                    href={Routes.DASHBOARDS}
                                />
                            </form>
                        </div>
                    </div>
                </nav>
            </header>

            <InviteOwnerModal
                alert={alert}
                email={state.email}
                isSubmitting={state.isSubmit}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                t={t}
            />


            <ChangeCompanyModal
                companies={state.myCompany}
                currentCompanyId={companyId}
                onSave={switchToNewCompany}
                t={t}
            />

            <ApprovalRequestModal
                approvalList={listApproval}
                loading={loading}
            />

        </>
    )
}

export default HeaderLayout 