import { GroupLayer, Layer, LayerGroup, LayerSelections } from "../lib/types";

export const generateLayerSelections = (t: (key: string) => string): any => {
    const block = [
        { id: '1', text: t('layer.block.leadtime'), type: 'minute', value: '' },
        { id: '2', text: t('layer.block.defectrate'), type: 'percent', value: '' },
        { id: '3', text: t('layer.block.itsystem'), type: 'text', value: '' },
        { id: '4', text: t('layer.block.cost'), type: '$', value: '' },
        { id: '5', text: t('layer.block.carbonfootprint'), type: 'tCO2e', value: '' },
        { id: '6', text: t('layer.block.headcount'), type: 'integer', value: '' },
        { id: '7', text: t('layer.block.irritantsandimprovements'), type: 'text', value: '' },
        { id: '8', text: t('layer.block.risksandcontrols'), type: 'text', value: '' },
        { id: '9', text: t('layer.block.relateddocuments'), type: 'link', value: '' },
        { id: '10', text: t('layer.block.failuremode'), type: 'text', value: '' }
    ];

    const line = [
        { id: "1", text: t('layer.line.input'), type: "text", value: "" },
        { id: "2", text: t('layer.line.output'), type: "text", value: "" },
        { id: "3", text: t('layer.line.inventory'), type: "text", value: "" },
        { id: "4", text: t('layer.line.transfertime'), type: "text", value: "" }
    ];

    const startEnd = [
        { id: '1', text: t('layer.block.link'), type: 'link', value: '' }
    ];

    return {
        block: block,
        line: line,
        startEnd: startEnd
    };
};


