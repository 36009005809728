import React, { useState, useEffect } from 'react';
import { Settings } from 'lucide-react';
import { AttributeSection } from '../AttributeSection';
import { Layer } from '../../lib/types';
import { useTranslation } from 'react-i18next';
import { FamlaServices } from '../../../services';
import { useAppSelector } from '../../../stores';

export interface AttributeItem {
    id: string;
    edit: boolean;
}

export interface AttributesPayload {
    start_end_layers: AttributeItem[];
    line_layers: AttributeItem[];
    block_layers: AttributeItem[];
}

interface attributProps {
    blockLayerAttributes: Layer[]
    lineLayerAttributes: Layer[]
    startEndLayerAttributes: Layer[]
    mapId: string
    initialData: {
        block_layers: AttributeItem[] | null;
        line_layers: AttributeItem[] | null;
        start_end_layers: AttributeItem[] | null;
    }
}

export function AttributModal(props: attributProps) {
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation();
    const closeModal = React.useRef<HTMLButtonElement>(null);

    const { blockLayerAttributes, lineLayerAttributes, startEndLayerAttributes, mapId, initialData } = props;

    // Initialize attributes based on initialData
    const initializeAttributes = () => {
        return {
            block_layers: blockLayerAttributes.map(attr => {
                if (initialData.block_layers === null) {
                    return { id: attr.id, edit: true };
                }
                const initialItem = initialData.block_layers.find(item => item.id === attr.id);
                return { id: attr.id, edit: initialItem ? initialItem.edit : true };
            }),
            line_layers: lineLayerAttributes.map(attr => {
                if (initialData.line_layers === null) {
                    return { id: attr.id, edit: true };
                }
                const initialItem = initialData.line_layers.find(item => item.id === attr.id);
                return { id: attr.id, edit: initialItem ? initialItem.edit : true };
            }),
            start_end_layers: startEndLayerAttributes.map(attr => {
                if (initialData.start_end_layers === null) {
                    return { id: attr.id, edit: true };
                }
                const initialItem = initialData.start_end_layers.find(item => item.id === attr.id);
                return { id: attr.id, edit: initialItem ? initialItem.edit : true };
            }),
        };
    };

    const [attributes, setAttributes] = useState<AttributesPayload>(initializeAttributes());

    // Update attributes when initialData changes
    useEffect(() => {
        setAttributes(initializeAttributes());
    }, [initialData, blockLayerAttributes, lineLayerAttributes, startEndLayerAttributes]);

    const toggleAttribute = (section: keyof AttributesPayload, attributeId: string) => {
        setAttributes(prev => ({
            ...prev,
            [section]: prev[section].map(attr =>
                attr.id === attributeId ? { ...attr, edit: !attr.edit } : attr
            ),
        }));
    };

    const handleSubmit = async () => {
        try {
            let data = await FamlaServices("api_host", `/mapping/update/edit/${mapId}`, "PUT", attributes, users.access_token);
            if (data.status === 200) {
                closeModal.current && closeModal.current.click()
            }
        } catch (error) {

        }
    };

    return (
        <div className="container py-5">
            {/* Modal Bootstrap */}
            <div className="modal fade" id="attributeModal" tabIndex={-1} aria-labelledby="attributeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <Settings className='text-primary' size={20} /> <h5 className="modal-title ms-1" id="attributeModalLabel">{t('features.swimlane.editableAttrinbutesConfiguration')} </h5>
                            <button onClick={() => setAttributes(initializeAttributes())} ref={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <AttributeSection
                                title={t('words.proces')}
                                attributes={blockLayerAttributes}
                                selectedAttributes={attributes.block_layers}
                                onToggle={(id) => toggleAttribute('block_layers', id)}
                            />

                            <AttributeSection
                                title={t('words.line')}
                                attributes={lineLayerAttributes}
                                selectedAttributes={attributes.line_layers}
                                onToggle={(id) => toggleAttribute('line_layers', id)}
                            />

                            <AttributeSection
                                title={t('words.start') + t('words.end')}
                                attributes={startEndLayerAttributes}
                                selectedAttributes={attributes.start_end_layers}
                                onToggle={(id) => toggleAttribute('start_end_layers', id)}
                            />
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => setAttributes(initializeAttributes())} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                {t('words.cancel')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                {t('words.save')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AttributModal;
