import * as d3 from 'd3';
import { STYLES } from '../../styles/constants';
import { useTransition } from 'react';

interface Position {
  x: number;
  y: number;
}

interface RowContextMenuCallbacks {
  onAddRowUp: () => void;
  onAddRowDown: () => void;
  onDeleteRow: () => void;
}

export function createRowContextMenu(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  position: Position,
  callbacks: RowContextMenuCallbacks,
  t: (key: string) => string
) {
  // Remove any existing context menus
  d3.selectAll('.row-context-menu').remove();

  // Get container's position and scroll position
  const containerNode = container.node();
  const containerRect = containerNode?.getBoundingClientRect();
  const scrollContainer = containerNode?.closest('.overflow-auto') as HTMLElement;
  const scrollLeft = scrollContainer?.scrollLeft || 0;
  const scrollTop = scrollContainer?.scrollTop || 0;

  if (!containerRect) return;
  // Take into account the container's position, scroll position, and current scale
  const scale = parseFloat(container.style('transform')?.match(/scale\((.*?)\)/)?.[1] || '1');
  const menuX = (position.x - containerRect.left) / scale + scrollLeft;
  const menuY = (position.y - containerRect.top) / scale + scrollTop;

  const menu = container
    .append('div')
    .attr('class', 'row-context-menu')
    .style('position', 'absolute')
    .style('left', `${menuX}px`)
    .style('top', `${menuY}px`)
    .style('transform', `scale(${1 / scale})`) // Counter the container's scale
    .style('transform-origin', 'top left')
    .style('background-color', 'white')
    .style('border', `1px solid ${STYLES.colors.border}`)
    .style('border-radius', '4px')
    .style('box-shadow', '0 2px 8px rgba(0,0,0,0.1)')
    .style('min-width', '180px')
    .style('z-index', '1000');

  // Add menu items
  addMenuItem(menu, t('features.swimlane.addRowUp'), '⬆️', callbacks.onAddRowUp);
  addMenuItem(menu, t('features.swimlane.addRowDown'), '⬇️', callbacks.onAddRowDown);
  addDivider(menu);
  addMenuItem(menu, t('features.swimlane.deleteRow'), '🗑', callbacks.onDeleteRow, true);

  // Close menu when clicking outside
  d3.select('body').on('click.row-context-menu', () => {
    menu.remove();
  });

  // Prevent menu from closing when clicking inside
  menu.on('click', (event) => {
    event.stopPropagation();
  });

  // Adjust menu position if it goes off screen
  const menuNode = menu.node();
  if (menuNode) {
    const menuRect = menuNode.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    if (menuRect.right > viewportWidth) {
      menu.style('left', `${menuX - menuRect.width}px`);
    }

    if (menuRect.bottom > viewportHeight) {
      menu.style('top', `${menuY - menuRect.height}px`);
    }

  }
}

function addMenuItem(
  menu: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  label: string,
  icon: string,
  onClick: () => void,
  isDanger: boolean = false
) {
  const item = menu
    .append('div')
    .style('padding', '8px 12px')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('gap', '8px')
    .style('cursor', 'pointer')
    .style('color', isDanger ? '#dc2626' : 'inherit')
    .style('transition', 'background-color 0.2s')
    .on('mouseenter', function () {
      d3.select(this).style('background-color', isDanger ? '#fee2e2' : '#f0f0f0');
    })
    .on('mouseleave', function () {
      d3.select(this).style('background-color', 'white');
    })
    .on('click', () => {
      onClick();
      menu.remove();
    });

  item
    .append('span')
    .style('font-size', '14px')
    .text(icon);

  item
    .append('span')
    .text(label);
}

function addDivider(menu: d3.Selection<HTMLDivElement, unknown, null, undefined>) {
  menu
    .append('div')
    .style('height', '1px')
    .style('background-color', STYLES.colors.border)
    .style('margin', '4px 0');
}