import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Swimlane, Node, Line } from "../components/Swimlane/types";

/**
 * Exporte un conteneur HTML en image PNG avec une meilleure qualité.
 * @param containerRef - Référence au conteneur HTML.
 */
export const exportToPng = async (containerRef: React.RefObject<HTMLDivElement>): Promise<void> => {
  if (!containerRef.current) return;

  try {
    const canvas = await html2canvas(containerRef.current, {
      scale: 2, // Améliore la qualité
      useCORS: true,
      backgroundColor: "#ffffff",
    });

    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "swimlane-diagram.png";
    link.click();
  } catch (error) {
    console.error("Erreur lors de l'exportation en PNG:", error);
  }
};

/**
 * Exporte un conteneur HTML en PDF en haute qualité.
 * @param containerRef - Référence au conteneur HTML.
 */
export const exportToPdf = async (containerRef: React.RefObject<HTMLDivElement>): Promise<void> => {
  if (!containerRef.current) return;

  try {
    const canvas = await html2canvas(containerRef.current, {
      scale: 2, // Améliore la qualité
      useCORS: true,
      backgroundColor: "#ffffff",
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [canvas.width, canvas.height], // Ajuste la taille au contenu
    });

    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("swimlane-diagram.pdf");
  } catch (error) {
    console.error("Erreur lors de l'exportation en PDF:", error);
  }
};

/**
 * Exporte les données sous forme de fichier JSON.
 * @param data - Les données à exporter.
 */
export const exportToJson = (data: {
  swimlane: Swimlane;
  nodes: Node[];
  lines: Line[];
}): void => {
  const jsonString = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });
  downloadBlob(blob, "swimlane-diagram.json");
};

/**
 * Télécharge un fichier Blob avec un nom spécifique.
 * @param blob - Contenu du fichier.
 * @param fileName - Nom du fichier à télécharger.
 */
const downloadBlob = (blob: Blob, fileName: string): void => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
