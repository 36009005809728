import * as d3 from 'd3';
import { STYLES } from '../styles/constants';
import { editHeader } from '../utils/headerEditor';
import { SwimlaneData } from '../types';

export function renderMainHeader(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  data: SwimlaneData,
  onSave: (data: SwimlaneData) => void
) {
  const mainHeader = container
    .append('div')
    .attr('class', 'main-header')
    .style('width', '100%')
    .style('height', `${STYLES.header.height}px`)
    .style('background-color', STYLES.colors.background)
    .style('border', `1px solid ${STYLES.colors.border}`)
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('justify-content', 'center')
    .style('margin-bottom', `${STYLES.header.gap}px`);

  mainHeader
    .append('div')
    .attr('class', 'header-text')
    .text(data.header.text)
    .style('color', STYLES.colors.text)
    .on('click', (event) => {
      editHeader(event.target as HTMLElement, 'main', data, onSave);
    });
}