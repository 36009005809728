
import { ThumbsUp, ThumbsDown, X, ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useState, useRef } from 'react';
import { FeedbackDialog } from './../PopUp/FeedBackDialog';
import { FamlaServices } from '../../../services';
import { useAppSelector } from '../../../stores';
import { useTranslation } from 'react-i18next';
import { SaveModal } from "../PopUp/saveDiagrame"
import { iAlert } from '../../../interfaces';

interface SwimlaneTabsProps {
    swimlanes: { _id: string; name?: string }[];
    mapId: string;
    setMap: (id: string) => void;
    handleSetMapId: (id: string) => void;
    hasUnsavedChanges: boolean;
    swimlaneName: string;
    handleAction: () => void;
    submit: boolean;
    alert: { msg: string; type: string };
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;


}

const SwimlaneTabs = (props: SwimlaneTabsProps) => {
    const { swimlanes: originalSwimlanes, mapId, setMap, hasUnsavedChanges, swimlaneName, handleAction, submit, alert, handleChange } = props;
    const [feedbackStatus, setFeedbackStatus] = useState<string | null>(null);
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [pendingMapId, setPendingMapId] = useState<string | null>(null);
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation();

    const swimlanes = React.useMemo(() => [...originalSwimlanes], [originalSwimlanes]);

    const handleFeedback = async (feedback: string, status: boolean) => {
        try {
            const feedbackData = {
                map_id: mapId,
                thumbs: status,
                content: feedback,
                created_at: new Date().toISOString()
            };

            const data = await FamlaServices("api_host", "/comment/", "POST", feedbackData, users.access_token);

            if (data.status === 201) {
                setShowFeedbackDialog(false);
            }

            return data.body;
        } catch (error) {
            console.error("Erreur lors de l'envoi du commentaire :", error);
            return null;
        }
    };

    const handleTabClick = (newMapId: string) => {
        if (mapId === newMapId) return;

        if (hasUnsavedChanges) {
            setPendingMapId(newMapId);
            setShowSaveModal(true);
        } else {
            setMap(newMapId);
        }
    };

    const handleSave = async () => {
        // Implement your save logic here
        handleAction()
        // After successful save:
        if (pendingMapId) {
            setMap(pendingMapId);
            setPendingMapId(null);
        }
        setShowSaveModal(false);
    };

    const handleDiscard = () => {
        if (pendingMapId) {
            setMap(pendingMapId);
            setPendingMapId(null);
        }
        setShowSaveModal(false);
    };

    const selectedIndex = React.useMemo(() =>
        swimlanes.findIndex(swimlane => swimlane._id === mapId),
        [swimlanes, mapId]
    );

    // Initialize currentIndex to show the selected item
    const [currentIndex, setCurrentIndex] = useState(() => {
        // Calculate the appropriate starting index to show the selected item
        if (selectedIndex >= 0) {
            // Ensure the selected item is visible in a group of 4
            return Math.floor(selectedIndex / 4) * 4;
        }
        return 0;
    });

    // Update currentIndex when mapId changes
    React.useEffect(() => {
        if (selectedIndex >= 0) {
            const newIndex = Math.floor(selectedIndex / 4) * 4;
            setCurrentIndex(newIndex);
        }
    }, [selectedIndex]);


    const handlePrevious = () => {
        setCurrentIndex((prev) => Math.max(0, prev - 4));
    };

    const handleNext = () => {
        setCurrentIndex((prev) =>
            Math.min(swimlanes.length - 4, prev + 4)
        );
    };

    const visibleSwimlanes = swimlanes.slice(currentIndex, currentIndex + 4);
    const canScrollLeft = currentIndex > 0;
    const canScrollRight = currentIndex + 4 < swimlanes.length;
    const shouldShowCarousel = swimlanes.length > 4;

    return (
        <>
            <div className="d-flex justify-content-between bg-light" >
                {/* Tabs */}
                <div className="flex items-center justify-between gap-2  h-full">
                    {shouldShowCarousel &&
                        <button
                            onClick={handlePrevious}
                            disabled={!canScrollLeft}
                            className={`p-2 rounded-full ${canScrollLeft
                                ? 'text-gray-700 hover:bg-gray-100'
                                : 'text-gray-300 cursor-not-allowed'
                                }`}
                        >
                            <ChevronLeft size={24} />
                        </button>
                    }

                    <div className={`flex-1 flex justify-between h-full ${shouldShowCarousel ? "" : "ml-3"}`}>
                        {visibleSwimlanes.map((swimLane: any, index: number) => (
                            <button
                                key={`${swimLane._id}-${index}`}
                                className={`px-4 h-full flex items-center whitespace-nowrap border-r border-gray-300 ${mapId === swimLane._id
                                    ? "bg-white text-gray-800 border-t-2 border-t-blue-500 active-tab"
                                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                                    }`}
                                onClick={() => handleTabClick(swimLane._id)}
                            >
                                <span className="truncate block max-w-[150px]">
                                    {swimLane.name || swimLane._id}
                                </span>
                            </button>
                        ))}
                    </div>

                    {shouldShowCarousel &&
                        <button
                            onClick={handleNext}
                            disabled={!canScrollRight}
                            className={`p-2 rounded-full ${canScrollRight
                                ? 'text-gray-700 hover:bg-gray-100'
                                : 'text-gray-300 cursor-not-allowed'
                                }`}
                        >
                            <ChevronRight size={24} />
                        </button>}
                </div>

                {/* Feedback System */}
                <div className="flex items-center gap-2 px-4">
                    <p className="text-gray-600">{t('features.swimlane.helpful')}</p>
                    <button
                        onClick={() => { setFeedbackStatus('positive'); handleFeedback('', true); }}
                        className={`p-2 rounded-full transition-colors ${feedbackStatus === 'positive' ? 'bg-green-100 text-green-600' : 'hover:bg-gray-100 text-gray-500'
                            }`}
                        aria-label="Thumbs up"
                    >
                        <ThumbsUp size={20} />
                    </button>
                    <button
                        onClick={() => {
                            setFeedbackStatus('negative');
                            setShowFeedbackDialog(true);
                        }}
                        className={`p-2 rounded-full transition-colors ${feedbackStatus === 'negative' ? 'bg-red-100 text-red-600' : 'hover:bg-gray-100 text-gray-500'
                            }`}
                        aria-label="Thumbs down"
                    >
                        <ThumbsDown size={20} />
                    </button>
                </div>
            </div>

            {/* Save Confirmation Modal */}
            {showSaveModal && (
                <SaveModal
                    isOpen={showSaveModal}
                    onClose={() => setShowSaveModal(false)}
                    onSave={handleSave}
                    onDiscard={handleDiscard}
                    diagramName={swimlaneName}
                    onDiagramNameChange={handleChange}
                    submit={submit}
                    alert={alert}
                />
            )}

            {showFeedbackDialog && (
                <FeedbackDialog
                    onClose={() => setShowFeedbackDialog(false)}
                    onSubmit={handleFeedback}
                />
            )}
        </>
    );
};

export { SwimlaneTabs };