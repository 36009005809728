import * as d3 from 'd3';
import { Block, Line, Position } from '../../types';
import { STYLES } from '../../styles/constants';
import { createBlockSelectionMenu } from '../menus/BlockSelectionMenu';
import { setupSvgLayer } from '../../utils/svgUtils';
import { createInfoModal } from './InfoModal';
import { FamlaServices } from '../../../../services';


interface BlockSelectionManagerCallbacks {
  onDelete: (block: Block) => void;
  onAddLine: (block: Block) => void;
  onLineCreated: (line: Line) => void;
}

export class BlockSelectionManager {
  private container: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private selectedBlock: Block | null = null;
  private allBlocks: Block[] = [];
  private callbacks: BlockSelectionManagerCallbacks;
  private tempLine: d3.Selection<SVGPathElement, unknown, null, undefined> | null = null;
  private sourceBlock: Block | null = null;
  private sourceSide: string = '';
  private sourcePoint: Position | null = null;
  private connectionPoints: d3.Selection<HTMLDivElement, unknown, null, undefined>[] = [];
  private showLayers: boolean = false;
  private infoModal: d3.Selection<HTMLDivElement, unknown, null, undefined> | null = null;
  private mapId: string | "";
  private t: (key: string) => string;
  private lineLayer: string[];

  constructor(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    callbacks: BlockSelectionManagerCallbacks,
    mapId: string,
    t: (key: string) => string,
    lineLayer: string[],

  ) {
    this.container = container;
    this.callbacks = callbacks;
    this.svg = setupSvgLayer(container);
    this.mapId = mapId;
    this.t = t;
    this.lineLayer = lineLayer;

    d3.select('body').on('click.block-selection', (event) => {
      const target = event.target as HTMLElement;
      const isBlock = target.closest('.process-block, .decision-block, .start-block, .end-block');
      const isMenu = target.closest('[class^="block-selection-menu"]');
      const isConnectionPoint = target.closest('.connection-point');
      const isModal = target.closest('.info-modal');
      const isModalClose = target.closest('.modal-close-button');

      if (isModalClose) {
        this.closeInfoModal();
      } else if (!isBlock && !isMenu && !isConnectionPoint && !isModal) {
        this.clearSelection();
      }
    });
  }

  private getFilteredLayers() {
    const allLayers = [
      { id: "1", text: this.t(`input`), type: "text", value: "" },
      { id: "2", text: this.t(`output`), type: "text", value: "" },
      { id: "3", text: this.t(`inventory`), type: "text", value: "" },
      { id: "4", text: this.t(`transfertime`), type: "text", value: "" }
    ];

    if (this.lineLayer === null) {
      return allLayers;
    }

    return allLayers.filter(layer => this.lineLayer!.includes(layer.id));
  }

  public updateBlocks(blocks: Block[]): void {
    this.allBlocks = [...blocks];
  }

  public selectBlock(block: Block): void {
    // Vérifier si un menu existe déjà pour ce bloc
    const existingMenu = this.container.select(`[class^="block-selection-menu-${block.id}"]`);

    // Si c'est le même bloc ET que le menu existe déjà, ne rien faire
    if (this.selectedBlock?.id === block.id && !existingMenu.empty()) {
      return;
    }

    // Si c'est un bloc différent OU si le menu n'existe pas, créer un nouveau menu
    this.clearSelection();
    this.selectedBlock = block;

    createBlockSelectionMenu(this.container, block, {
      onDelete: this.callbacks.onDelete,
      onFetchInfo: (block) => this.fetchBlockInfo(block),
      onToggleLayers: () => this.toggleLayers()
    },
    this.t
  );
  }

  public clearSelection(): void {
    this.selectedBlock = null;
    this.container.selectAll('[class^="block-selection-menu"]').remove();
    this.hideConnectionPoints();
    this.cancelLinkMode();
  }

  private toggleLayers(): void {
    this.showLayers = !this.showLayers;
    this.container.selectAll('[class*="-layers-"] > div')
      .style('display', this.showLayers ? 'block' : 'none');
  }

  private async fetchBlockInfo(block: Block): Promise<void> {
    // Show loading state
    this.showLoadingModal();

    try {
      // Prepare data for API call
      const requestData = {
        map_id: this.mapId,
        text: block.text,
        element_id: block.id
      };

      // Make API call using FamlaServices
      const response = await FamlaServices("api_host", `/reference/`, "POST", requestData, "");

      // Close loading modal
      this.closeInfoModal();

      if (response.status === 201) {
        // Since response.data doesn't exist on the type, we need to use type assertion or access it differently
        const responseData = response.body.data; // Use body instead of data
        if (responseData.length > 0) {
          this.showInfoModal(block, responseData);
        } else {
          this.showErrorModal(this.t('features.swimlane.noInformation'));
        }

      } else {
        // Show error if status is not 200
        this.showErrorModal(this.t('navigation.anErrorOcured'));
      }
    } catch (error) {

      this.closeInfoModal();
      this.showErrorModal(this.t('navigation.anErrorOcured'));
    }
  }

  private showLoadingModal(): void {
    this.closeInfoModal();
    this.infoModal = createInfoModal(this.container, {
      title: this.t('words.loading') + '...',
      content: `<div class="flex justify-center items-center p-4"><div class="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div></div><p class="text-center text-gray-600">${this.t('words.loading')}` + '...</p>',
      onClose: () => this.closeInfoModal()
    },
      this.t);
  }

  private showInfoModal(block: Block, data: any): void {
    this.closeInfoModal();

    // Format the response data for display
    let formattedContent = '<div class="modal-content">';

    // Check if data contains the expected stakeholder array format
    if (data && Array.isArray(data)) {
      formattedContent += `
        <div class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr class="bg-gray-100">
                <th class="py-3 px-4 text-left font-semibold text-gray-700 border-b">${this.t('features.swimlane.stakeholder')}</th>
                <th class="py-3 px-4 text-left font-semibold text-gray-700 border-b">${this.t('words.question')}</th>
                <th class="py-3 px-4 text-left font-semibold text-gray-700 border-b">${this.t('words.answer')}</th>
              </tr>
            </thead>
            <tbody>
      `;

      // Loop through each stakeholder entry
      data.forEach((stakeholderData: any) => {
        const stakeholder = stakeholderData.stakeholder || 'Unknown';
        const answers = stakeholderData.answers || [];

        // For each stakeholder, loop through their answers
        answers.forEach((answer: any, index: number) => {
          formattedContent += `
            <tr class="${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-blue-50 transition-colors">
              ${index === 0 ? `<td class="py-3 px-4 border-b border-gray-200 align-top font-medium" rowspan="${answers.length}">${stakeholder}</td>` : ''}
              <td class="py-3 px-4 border-b border-gray-200">${answer.question || ''}</td>
              <td class="py-3 px-4 border-b border-gray-200">${answer.answer || ''}</td>
            </tr>
          `;
        });
      });

      formattedContent += `
            </tbody>
          </table>
        </div>
      `;
    } else if (typeof data === 'object' && data !== null) {
      // Fallback for other object data
      formattedContent += `<pre class="bg-gray-50 p-3 rounded text-sm overflow-auto max-h-60">${JSON.stringify(data, null, 2)}</pre>`;
    } else {
      // Fallback for non-object data (like text)
      formattedContent += `<p class="p-3 bg-gray-50 rounded">${data}</p>`;
    }

    formattedContent += '</div>';

    this.infoModal = createInfoModal(this.container, {
      title: this.t('words.information'),
      content: formattedContent,
      onClose: () => this.closeInfoModal()
    },
      this.t
    );
  }

  private showErrorModal(message: string): void {
    this.closeInfoModal();
    this.infoModal = createInfoModal(this.container, {
      title: this.t('words.error'),
      content: `
        <div class="p-4 bg-red-50 rounded border border-red-200">
          <div class="flex items-center mb-2">
            <svg class="w-5 h-5 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
             <span class="font-medium text-red-700">${this.t('words.error')}</span>
          </div>
          <p class="text-red-600">${message}</p>
        </div>
      `,
      onClose: () => this.closeInfoModal()
    },
      this.t);
  }

  private closeInfoModal(): void {
    if (this.infoModal) {
      this.infoModal.remove();
      this.infoModal = null;
    }
  }

  public startLinkMode(block: Block): void {
    this.sourceBlock = block;
    this.showConnectionPoints(block);
  }

  private cancelLinkMode(): void {
    if (this.tempLine) {
      this.tempLine.remove();
      this.tempLine = null;
    }
    this.container.on('mousemove', null);
    this.sourceBlock = null;
    this.sourceSide = '';
    this.sourcePoint = null;
    this.hideConnectionPoints();
  }

  private hideConnectionPoints(): void {
    this.connectionPoints.forEach(point => point.remove());
    this.connectionPoints = [];
  }

  private showConnectionPoints(block: Block, isTarget: boolean = false): void {
    let width, height;
    if (block.type === 'start' || block.type === 'end') {
      width = STYLES.startEnd.width;
      height = STYLES.startEnd.height;
    } else {
      width = block.type === 'process' ? STYLES.process.width : STYLES.decision.width;
      height = block.type === 'process' ? STYLES.process.height : STYLES.decision.height;
    }

    const x = block.x || 0;
    const y = block.y || 0;

    const points = [
      { x: x + width / 2, y, side: 'top' },
      { x: x + width, y: y + height / 2, side: 'right' },
      { x: x + width / 2, y: y + height, side: 'bottom' },
      { x, y: y + height / 2, side: 'left' }
    ];

    const newPoints = points.map(point => {
      const connectionPoint = this.container
        .append('div')
        .attr('class', `connection-point ${point.side}`)
        .style('position', 'absolute')
        .style('width', '10px')
        .style('height', '10px')
        .style('background-color', isTarget ? '#10b981' : STYLES.colors.connectionPoint)
        .style('border', '2px solid transparent')
        .style('border-radius', '50%')
        .style('transform', 'translate(-50%, -50%)')
        .style('cursor', 'pointer')
        .style('z-index', '100')
        .style('left', `${point.x}px`)
        .style('top', `${point.y}px`)
        .style('transition', 'all 0.2s ease')
        .style('box-shadow', '0 0 0 0 rgba(66, 153, 225, 0.5)');

      connectionPoint
        .on('mouseenter', function () {
          d3.select(this)
            .style('transform', 'translate(-50%, -50%) scale(1.5)')
            .style('background-color', isTarget ? '#059669' : '#2563eb')
            .style('border-color', '#ffffff')
            .style('box-shadow', '0 0 0 4px rgba(66, 153, 225, 0.3)');
        })
        .on('mouseleave', function () {
          d3.select(this)
            .style('transform', 'translate(-50%, -50%) scale(1)')
            .style('background-color', isTarget ? '#10b981' : STYLES.colors.connectionPoint)
            .style('border-color', 'transparent')
            .style('box-shadow', '0 0 0 0 rgba(66, 153, 225, 0.5)');
        });

      if (isTarget) {
        connectionPoint.on('click', (event) => {
          event.stopPropagation();
          if (this.sourceBlock && this.sourceSide) {
            const newLine: Line = {
              id: `line-${Date.now()}`,
              type: 'line',
              sourceId: "",
              targetId: "",
              from: this.sourceBlock.id,
              to: block.id,
              fromSide: this.sourceSide as any,
              toSide: point.side as any,
              text: "",
              layers: this.getFilteredLayers() as any,
            };

            this.callbacks.onLineCreated(newLine);
            this.cancelLinkMode();
          }
        });
      } else {
        connectionPoint.on('click', (event) => {
          event.stopPropagation();

          d3.select(event.currentTarget)
            .style('transform', 'translate(-50%, -50%) scale(1.2)')
            .style('background-color', '#1d4ed8')
            .style('box-shadow', '0 0 0 6px rgba(66, 153, 225, 0.4)');

          this.sourceSide = point.side;
          this.sourcePoint = { x: point.x, y: point.y };
          this.hideConnectionPoints();

          this.allBlocks
            .filter(b => b.id !== block.id)
            .forEach(b => this.showConnectionPoints(b, true));

          if (this.tempLine) {
            this.tempLine.remove();
          }

          this.tempLine = this.svg
            .append('path')
            .attr('stroke', STYLES.colors.arrow)
            .attr('stroke-width', '2')
            .attr('fill', 'none')
            .attr('marker-end', 'url(#arrowhead)');

          this.container.on('mousemove', (event) => {
            if (this.tempLine && this.sourcePoint) {
              const containerRect = this.container.node()?.getBoundingClientRect();
              if (!containerRect) return;

              const mouseX = event.clientX - containerRect.left;
              const mouseY = event.clientY - containerRect.top;

              const path = d3.path();
              path.moveTo(this.sourcePoint.x, this.sourcePoint.y);
              path.lineTo(mouseX, mouseY);
              this.tempLine.attr('d', path.toString());
            }
          });
        });
      }

      return connectionPoint;
    });

    if (!isTarget) {
      this.connectionPoints = newPoints;
    } else {
      this.connectionPoints = [...this.connectionPoints, ...newPoints];
    }
  }
}