import { NavLink } from "react-router-dom";

interface BreadCrumbProps {
    title: string
    links: string
    actionName: string
}

function BreadCrumb(props: BreadCrumbProps) {
    const { title, links, actionName } = props;

    return (
        <header className="d-flex justify-content-between align-items-center py-3 px-0 shadow">
            <h1 className="h4 mb-0 text-light">{title}</h1>
            <NavLink to={links} className="text-danger border-0">{actionName}</NavLink>
        </header>
    )
}

export default BreadCrumb;  