import * as d3 from 'd3';

interface InfoModalOptions {
    title: string;
    content: string;
    onClose: () => void;
    modalStyle?: {
        [key: string]: string;
    };
    headerStyle?: {
        [key: string]: string;
    };
    contentStyle?: {
        [key: string]: string;
    };
    footerStyle?: {
        [key: string]: string;
    };
}

export function createInfoModal(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    options: InfoModalOptions,
    t: (key: string) => string
): d3.Selection<HTMLDivElement, unknown, null, undefined> {
    // Create overlay
    const overlay = container
        .append('div')
        .attr('class', 'info-modal-overlay')
        .style('position', 'fixed')
        .style('top', '0')
        .style('left', '0')
        .style('width', '100%')
        .style('height', '100%')
        .style('background-color', 'rgba(0, 0, 0, 0.5)')
        .style('z-index', '1000')
        .style('display', 'flex')
        .style('justify-content', 'center')
        .style('z-index', '5000')
        .style('align-items', 'center');

    // Create modal container
    const modal = overlay
        .append('div')
        .attr('class', 'info-modal')
        .style('background-color', 'white')
        .style('border-radius', '0.5rem')
        .style('box-shadow', '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)')
        .style('width', '600px')
        .style('max-width', '90%')
        .style('max-height', '80vh')
        .style('overflow', 'hidden')
        .style('display', 'flex')
        .style('flex-direction', 'column');

    // Apply custom modal styles if provided
    if (options.modalStyle) {
        Object.entries(options.modalStyle).forEach(([key, value]) => {
            modal.style(key, value);
        });
    }

    // Create header
    const header = modal
        .append('div')
        .attr('class', 'info-modal-header')
        .style('padding', '1rem')
        .style('border-bottom', '1px solid #e5e7eb')
        .style('display', 'flex')
        .style('justify-content', 'space-between')
        .style('align-items', 'center')
        .style('position', 'sticky')
        .style('top', '0')
        .style('background-color', 'white')
        .style('z-index', '10');

    // Apply custom header styles if provided
    if (options.headerStyle) {
        Object.entries(options.headerStyle).forEach(([key, value]) => {
            header.style(key, value);
        });
    }

    // Add title
    header
        .append('h3')
        .attr('class', 'info-modal-title')
        .style('margin', '0')
        .style('font-size', '1.25rem')
        .style('font-weight', '600')
        .style('color', '#1f2937')
        .html(options.title);

    // Add close button
    header
        .append('button')
        .attr('class', 'modal-close-button')
        .style('background', 'none')
        .style('border', 'none')
        .style('cursor', 'pointer')
        .style('font-size', '1.5rem')
        .style('line-height', '1')
        .style('color', '#6b7280')
        .style('padding', '0.25rem')
        .style('border-radius', '0.25rem')
        .style('transition', 'color 0.2s ease, background-color 0.2s ease')
        .html('&times;')
        .on('mouseenter', function () {
            d3.select(this)
                .style('color', '#ef4444')
                .style('background-color', '#fee2e2');
        })
        .on('mouseleave', function () {
            d3.select(this)
                .style('color', '#6b7280')
                .style('background-color', 'transparent');
        })
        .on('click', options.onClose);

    // Create content
    const content = modal
        .append('div')
        .attr('class', 'info-modal-content')
        .style('padding', '1rem')
        .style('overflow-y', 'auto')
        .style('flex', '1 1 auto');

    // Apply custom content styles if provided
    if (options.contentStyle) {
        Object.entries(options.contentStyle).forEach(([key, value]) => {
            content.style(key, value);
        });
    }

    // Add content
    content.html(options.content);

    // Create footer
    const footer = modal
        .append('div')
        .attr('class', 'info-modal-footer')
        .style('padding', '1rem')
        .style('border-top', '1px solid #e5e7eb')
        .style('display', 'flex')
        .style('justify-content', 'flex-end')
        .style('position', 'sticky')
        .style('bottom', '0')
        .style('background-color', 'white')
        .style('z-index', '10');

    // Apply custom footer styles if provided
    if (options.footerStyle) {
        Object.entries(options.footerStyle).forEach(([key, value]) => {
            footer.style(key, value);
        });
    }

    // Add close button to footer
    footer
        .append('button')
        .attr('class', 'info-modal-close-btn')
        .style('background-color', '#f3f4f6')
        .style('color', '#1f2937')
        .style('border', 'none')
        .style('border-radius', '0.375rem')
        .style('padding', '0.5rem 1rem')
        .style('font-weight', '500')
        .style('cursor', 'pointer')
        .style('transition', 'background-color 0.2s ease')
        .text(t('words.close'))
        .on('mouseenter', function () {
            d3.select(this).style('background-color', '#e5e7eb');
        })
        .on('mouseleave', function () {
            d3.select(this).style('background-color', '#f3f4f6');
        })
        .on('click', options.onClose);

    // Add click handler to overlay for closing
    overlay.on('click', function (event) {
        if (event.target === this) {
            options.onClose();
        }
    });

    // Add keyboard handler for Escape key
    d3.select('body').on('keydown.modal', function (event) {
        if (event.key === 'Escape') {
            options.onClose();
            d3.select('body').on('keydown.modal', null); // Remove event listener
        }
    });

    return overlay;
}