//React component
import React from 'react'
import { useTranslation } from 'react-i18next'

//app component
import { AppLayout } from '../../layouts'
import { Images } from '../../constants'
import { useAppSelector } from '../../stores'


const CheckMailBox = () => {
    const users = useAppSelector((state) => state.auth.user);
    const { t } = useTranslation()

    return (
        <AppLayout>
            <div className={`container p-5 text-center text-start d-flex align-items-center justify-content-center `}>

                <div className="pb-5" >
                    <span> <i className="fa-regular fa-envelope text-white my-5" style={{ fontSize: 90 }}></i></span>
                    <div>
                        <img className='img-fluid' src={Images.logov1} alt="famla" style={{ width: 100 }} />
                        <div className='mt-3'>
                            <p className="mb-4 fs-6 text-white">{t('views.auth.mailVerification')} <br />{t('views.auth.pleaseFollowTheInstructions')} </p>
                        </div>

                    </div>
                </div>

            </div>
        </AppLayout>
    )
}

export default CheckMailBox 