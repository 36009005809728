import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { SwimlaneHeaders } from './lib/SwimlaneHeaders';
import { Block, Layer } from './lib/types';
import { useExportHandlers } from "./hooks/useExportHandlers";
import { useClipboardActions } from "./hooks/useClipboardActions";
import { FamlaServices } from '../services';
import { useAppSelector } from '../stores';
import { useTranslation } from 'react-i18next';
import { ApprovalModal, ConfirmationModal, GenericModal, ShareModal } from '../views/modal';
import { LayerSettings } from './components/PopUp/layerSetting'
import { CollaboratorModal } from './components/PopUp/CollaboratorModal'
import { AttributModal } from "./components/PopUp/AttributModal"
// import { LeftSidebar } from './components/Layout/LeftSidebar';
import TopBar from './components/Layout/TopBar';
import { iUser } from '../components/type';
import { generateLayerSelections } from "../../src/swimlane/data/layer"
// import { Sidebar } from './components/Layout/Sidebar';
// import { initialSwimlaneData, initialBlocks, initialProcessGroups } from "../swimlane/data/swimlaneData"
import { AnalysisPanel } from './components/AnalysisPanel/AnalysisPanel'
import { RightSidebar } from './components/Layout/RightSidebar';
import { SwimlaneTabs } from './components/Layout/SwimlaneTabs';


interface childprops {
  swimlaneData: any,
  processId: string,
  allSwimLane: any[],
  loadAllSwimlane: () => void,
  sId: string,
  canEditMap: boolean;
  approvallist: any
  setMap: React.Dispatch<React.SetStateAction<string>>
  layer: any,
  handleSelectionsChange: () => void
  collaborators: any[]
  setCollaborators: React.Dispatch<React.SetStateAction<any[]>>
  processesWithDiagrams: any[]
}


function App(props: childprops) {
  const mapId = useAppSelector((state) => state.mapId.mapId);

  const {
    swimlaneData,
    processId,
    allSwimLane,
    loadAllSwimlane,
    sId, canEditMap,
    approvallist,
    setMap, layer,
    handleSelectionsChange,
    collaborators,
    setCollaborators,
    processesWithDiagrams
  } = props;


  const owner: iUser = swimlaneData.process.owner;
  const allswimlaneReverse = React.useMemo(() => {
    return [...allSwimLane].reverse();
  }, [allSwimLane]);

  // const allswimlaneReverseSlice = React.useMemo(() => {
  //   return allswimlaneReverse.slice(0, 3);
  // }, [allswimlaneReverse]);

  const initialBlocks = JSON.parse(swimlaneData.swimlane);
  const initialProcessGroups = JSON.parse(swimlaneData.second);
  const initialSwimlaneData = JSON.parse(swimlaneData.line);
  const lines = initialSwimlaneData.lines
    ? initialSwimlaneData.lines.map((line: any) => ({
      ...line
    }))
    : [];

  const swimData = [
    {
      ...initialSwimlaneData,
      lines: undefined,
    },
    ...initialProcessGroups,
    ...initialBlocks,
    ...lines,
  ];

  const baseWidth = initialSwimlaneData.width;
  const baseHeight = initialSwimlaneData.lines && (initialSwimlaneData.lines.length * 200);


  // References and states
  const containerRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const swimlaneRef = useRef<SwimlaneHeaders | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [scale, setScale] = useState(1);
  const [currentData, setCurrentData] = useState(initialSwimlaneData);
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const [blocks, setBlocks] = useState<Block[]>(initialBlocks);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const users = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const closeModal = useRef<HTMLButtonElement>(null);
  const inputRefa = useRef<HTMLInputElement>(null);
  const inputRefdisa = useRef<HTMLInputElement>(null);
  const role = useAppSelector((state) => state.role.role);
  const [showLayers, setShowLayers] = useState(false);
  const [showLayersLine, setShowLayersLine] = useState(false);
  const [showRightPanel, setShowRightPanel] = useState(false);
  // const [showLayers, setShowLayers] = useState(() => {
  //   const saved = localStorage.getItem('showLayers');
  //   return saved !== null ? JSON.parse(saved) : true;
  // });
  const [marginTop, setMarginTop] = useState('89px');
  const [title, setTitle] = useState('');
  // const [mappId, setMappId] = useState(() => localStorage.getItem("mapId") || '');
  const layerSelection = generateLayerSelections(t)

  // Initialize clipboard actions
  const { handleCopy, handleCut, handlePaste } = useClipboardActions(blocks, setBlocks, swimlaneRef);

  let lastSwimlaneName = allSwimLane[allSwimLane.length - 1]?.name

  const [state, setState] = useState({
    submit: false,
    swimlaneName: lastSwimlaneName,
    comment: "",
    isDisabled: true,
  });

  const [alert, setAlert] = useState({
    type: '',
    msg: ""
  });

  const swimlaneRefTogleLayer = useRef<SwimlaneHeaders | null>(null);

  const handleToggleLineLayers = () => {
    if (swimlaneRefTogleLayer.current) {
      swimlaneRefTogleLayer.current.toggleLineLayers();
      setShowLayersLine((prev) => !prev);

    }
  };

  const attributesDataEditable = {
    block_layers: swimlaneData.block_layers,
    line_layers: swimlaneData.line_layers,
    start_end_layers: swimlaneData.start_end_layers,
    owner: users.user?._id === owner?._id,
    processesWithDiagrams: processesWithDiagrams
  }
  function getHeaderText(swimlaneData: { header: { text: string } }): void {
    setTitle(swimlaneData.header.text);
  }


  // Initialize swimlane
  useEffect(() => {
    if (!swimlaneRefTogleLayer.current && !swimlaneRef.current) {
      // Créer une seule instance de SwimlaneHeaders
      const swimlaneInstance = new SwimlaneHeaders(
        "swimlane-container",
        currentData,
        blocks,
        initialProcessGroups,
        t,
        mapId,
        setBlocks,
        layer.line,
        attributesDataEditable
      );

      // Assigner l'instance aux deux refs
      swimlaneRefTogleLayer.current = swimlaneInstance;
      swimlaneRef.current = swimlaneInstance;

      // Ajouter les callbacks une seule fois
      swimlaneInstance.onSaveCallback((updatedData, updatedBlocks, updatedGroups) => {
        setCurrentData(updatedData);
        setBlocks(updatedBlocks);
        setHasUnsavedChanges(true);
      });

      swimlaneInstance.onEditModeChange((editMode) => {
        setIsEditing(editMode);
        if (!editMode) {
          setSelectedBlock(null);
        }
      });

      swimlaneInstance.onHistoryChange((canUndoValue, canRedoValue) => {
        setCanUndo(canUndoValue);
        setCanRedo(canRedoValue);
      });

      swimlaneInstance.onBlockSelect((block) => {
        setSelectedBlock(block);
      });

      // Activer le mode édition si l'utilisateur a les permissions
      if (users.user?._id === owner?._id || canEditMap === true) {
        swimlaneInstance?.toggleEditMode();
      }

      swimlaneInstance.render();
    }

    getHeaderText(initialSwimlaneData);

  }, []);


  const handleZoom = (direction: "in" | "out") => {
    const step = 0.05;
    let newScale = scale;

    // Ajuster l'échelle en fonction de la direction
    if (direction === "in") {
      newScale = Math.min(Math.max(scale + step, 0.5), 2);
    } else {
      newScale = Math.min(Math.max(scale - step, 0.5), 2);
    }

    newScale = Math.round(newScale * 20) / 20; // Arrondir à 2 décimales
    setScale(newScale);

    // Ajuster les dimensions dynamiquement pour le parent
    if (parentRef.current) {
      parentRef.current.style.width = `${baseWidth * newScale}px`;
      parentRef.current.style.height = `${baseHeight ?? 0 * newScale}px`;
    }

    // Appliquer le zoom à l'élément containerRef
    if (containerRef.current) {
      containerRef.current.style.transform = `scale(${newScale})`;
      containerRef.current.style.transformOrigin = "top left";
    }
  };

  useEffect(() => {
    const handleWheelZoom = (event: WheelEvent) => {
      if (event.ctrlKey) { // Vérifie si "Ctrl" est maintenu
        event.preventDefault(); // Empêche le zoom du navigateur

        if (event.deltaY < 0) {
          handleZoom("in"); // Molette vers le haut → Zoom avant
        } else {
          handleZoom("out"); // Molette vers le bas → Zoom arrière
        }
      }
    };

    window.addEventListener("wheel", handleWheelZoom, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheelZoom);
    };
  }, [scale]);

  // Initialiser la taille du parent au premier rendu
  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.style.width = `${baseWidth}px`;
      parentRef.current.style.height = `${baseHeight}px`;
    }
  }, [baseWidth, baseHeight]);


  // Handle keyboard shortcuts for clipboard actions
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Only process if in edit mode
      if (!isEditing) return;

      // Check for Ctrl+A (or Cmd+A on Mac)
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        // Prevent the default browser "Select All" behavior
        event.preventDefault();
        handleSelectAll();
      }

      // Copy: Ctrl+C or Cmd+C
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        event.preventDefault();
        handleCopy();
      }

      // Cut: Ctrl+X or Cmd+X
      if ((event.ctrlKey || event.metaKey) && event.key === 'x') {
        event.preventDefault();
        handleCut();
      }

      // Paste: Ctrl+V or Cmd+V
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        event.preventDefault();
        handlePaste();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, handleCopy, handleCut, handlePaste]);

  // Handle swimlane width change
  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWidth = parseInt(e.target.value, 10);
    if (newWidth >= 800) {
      const updatedData = { ...currentData, width: newWidth };
      setCurrentData(updatedData);
      swimlaneRef.current?.updateData(updatedData);
      setHasUnsavedChanges(true);
    }
  };

  const handleChange = (event: any) => {
    const { type, name, value, checked } = event.target;
    let fieldValue = type === 'checkbox' ? checked : value;

    if (type === 'number') {
      fieldValue = Number(fieldValue);
    }

    if (name === 'swimlane') {
      const isSameName = lastSwimlaneName === fieldValue;
      setState({ ...state, swimlaneName: fieldValue, isDisabled: isSameName });
    }

    if (name === 'comment') {
      setState({ ...state, comment: fieldValue })
    }
  };

  function activeFocus() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }
  function activeFocusa() {
    if (inputRefa.current) {
      inputRefa.current.focus();
    }
  }
  function activeFocusdisa() {
    if (inputRefdisa.current) {
      inputRefdisa.current.focus();
    }
  }



  const handleSubmit = async (swimlaneId: string, status: string) => {
    setState({ ...state, submit: true });
    let newForm = {
      validation: status === "approve" ? true : false,
      comment: state.comment,
      updated_at: Date.now()
    }
    let data = await FamlaServices("api_host", `/approval/${swimlaneId}`, "PUT", newForm, users.access_token);

    if (data.status === 200) {
      let messageType = status === "approve" ? t('features.swimlane.sucessApprov') : t('features.swimlane.sucessDisapprov')
      setAlert({ type: 'success', msg: messageType });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: 'danger', msg: "" });
      }, 5000);

    }
    else if (data.status === 400) {
      setAlert({ type: 'danger', msg: data.body.description });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: '', msg: "" });
      }, 5000);


    } else if (data.status === 401) {
      setAlert({ type: 'danger', msg: data.body.description });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: '', msg: "" });
      }, 5000);

    }
    else {
      setAlert({ type: 'danger', msg: data.body.description });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: '', msg: "" });
      }, 5000);
    }
  };


  const exportFile = async (type: string) => {
    const mapId = sId;
    const link = `/mapping/convert/to-${type}?map_id=${mapId}`;

    try {
      const response = await FamlaServices("api_host", link, "POST", null, users.access_token);

      if (response.status === 200 && response.body instanceof Blob) {

        const url = window.URL.createObjectURL(response.body);

        const linkElement = document.createElement("a");
        linkElement.href = url;

        if (type === "xes") {
          linkElement.download = "exported-file.xml";
        } else {
          linkElement.download = `exported-file.${type}`;
        }

        document.body.appendChild(linkElement);

        linkElement.click();

        linkElement.remove();
        window.URL.revokeObjectURL(url);
      } else {

      }
    } catch (error) {

    }
  };



  // Handle drag and drop
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const blockLayer: Layer[] = [
    { id: '1', text: t('leadtime'), type: 'minute', value: '' },
    { id: '2', text: t('defectrate'), type: 'percent', value: '' },
    { id: '3', text: t('itsystem'), type: 'text', value: '' },
    { id: '4', text: t('cost'), type: '$', value: '' },
    { id: '5', text: t('carbonfootprint'), type: 'tCO2e', value: '' },
    { id: '6', text: t('headcount'), type: 'integer', value: '' },
    { id: '7', text: t('irritantsandimprovements'), type: 'text', value: '' },
    { id: '8', text: t('risksandcontrols'), type: 'text', value: '' },
    { id: '9', text: t('relateddocuments'), type: 'link', value: '' },
    { id: '10', text: t('failuremode'), type: 'text', value: '' },
  ]

  const startLayer: Layer[] = [
    { id: '1', text: t('link'), type: 'link', value: '' }
  ]

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const shapeType = e.dataTransfer.getData('shape-type') as Block['type'];
    if (!shapeType) return;

    const container = containerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const x = (e.clientX - rect.left) / scale;
    const y = (e.clientY - rect.top) / scale;


    const getAdditionalProperties = (ids: string[], layers: Layer[]): { layers: Layer[] } => {
      if (ids === null) return { layers: layers };
      const filteredLayers = layers.filter(layer => ids?.includes(layer.id));
      return { layers: filteredLayers };
    };

    const additionalProperties =
      shapeType === 'process' || shapeType === 'decision'
        ? getAdditionalProperties(layer.block, blockLayer)
        : shapeType === 'start' || shapeType === 'end'
          ? getAdditionalProperties(layer.startEnd, startLayer)
          : { layers: [] };


    const newBlock: Block = {
      id: `block-${Date.now()}`,
      type: shapeType,
      text: `New ${shapeType}`,
      x,
      y,
      ...additionalProperties
    };


    const updatedBlocks = [...blocks, newBlock];
    if (initialProcessGroups.length > 0) {
      initialProcessGroups[0].groupChildren.push(newBlock.id);
    }

    setBlocks(updatedBlocks);
    swimlaneRef.current?.updateData(currentData, updatedBlocks, initialProcessGroups);
  };


  const {
    isExporting,
    handleExportJson,
    handleExportPng,
    handleExportPdf
  } = useExportHandlers(containerRef, swimData);


  const handleSave = async () => {
    // Create the complete data object
    const completeData = {
      swimlaneData: currentData,
      blocks: blocks,
      groups: initialProcessGroups
    };

    setState({ ...state, submit: true })

    // Convert to JSON string
    const jsonData = JSON.stringify(completeData, null, 2);

    let data = await FamlaServices("api_host", `/mapping/new/${processId}?name=${state.swimlaneName}`, "PUT", jsonData, users.access_token);
    if (data.status === 200) {
      loadAllSwimlane()
      closeModal.current && closeModal.current.click();
      setState({ ...state, submit: false, swimlaneName: "", isDisabled: state.swimlaneName })
      setAlert({ ...alert, type: "success", msg: t('features.swimlane.mapSaveWithSucess') })
      setTimeout(() => {
        setAlert({ ...alert, type: "", msg: "" })
      }, 3000);
      setHasUnsavedChanges(false);
    }


  };

  // Initialize layer visibility on component mount
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const layerDivs = container.querySelectorAll('[class*="-layers-"] > div');

      // Set initial visibility to match the state
      layerDivs.forEach(div => {
        (div as HTMLElement).style.display = showLayers ? 'block' : 'none';
      });
    }
  }, [showLayers]);
  // Initialize layer visibility on component mount
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const layerDivs = container.querySelectorAll('line-layers');

      // Set initial visibility to match the state
      layerDivs.forEach(div => {
        (div as HTMLElement).style.display = showLayersLine ? 'block' : 'none';
      });
    }
  }, [showLayersLine]);

  const handleToggleLayers = () => {
    setShowLayers((prev: any) => {
      const newValue = !prev;
      // Toggle visibility of all layer divs
      const container = containerRef.current;
      if (container) {
        const layerDivs = container.querySelectorAll('[class*="-layers-"] > div');

        layerDivs.forEach(div => {
          (div as HTMLElement).style.display = newValue ? 'block' : 'none';
        });
      }
      return newValue;
    });
  };

  // const handleToggleLayersLine = () => {
  //   setShowLayersLine((prev: boolean) => {
  //     const newValue = !prev;

  //     const lineLayers = document.querySelectorAll('[class*="line-layers-"]');

  //     lineLayers.forEach((element) => {
  //       const el = element as HTMLElement;
  //       // Inverser l'affichage
  //       el.style.display = (el.style.display === 'none') ? 'block' : 'none';
  //     });

  //     return newValue;
  //   });
  // };

  const handleTextStyleUpdate = (property: string, value: any) => {
    if (!selectedBlock) return;

    const updatedBlock = {
      ...selectedBlock,
      textStyle: {
        ...selectedBlock.textStyle,
        [property]: value
      }
    };

    const updatedBlocks = blocks.map(block =>
      block.id === selectedBlock.id ? updatedBlock : block
    );

    setBlocks(updatedBlocks);
    setSelectedBlock(updatedBlock);
    swimlaneRef.current?.updateData(undefined, updatedBlocks, initialProcessGroups);
  };
  const handleBlockUpdate = (updates: Partial<Block>) => {
    if (!selectedBlock) return;

    const updatedBlock = {
      ...selectedBlock,
      ...updates
    };

    const updatedBlocks = blocks.map(block =>
      block.id === selectedBlock.id ? updatedBlock : block
    );

    setBlocks(updatedBlocks);
    setSelectedBlock(updatedBlock);
    swimlaneRef.current?.updateData(undefined, updatedBlocks, initialProcessGroups);
  };
  const handleUndo = () => {
    swimlaneRef.current?.undo();
  };

  const handleRedo = () => {
    swimlaneRef.current?.redo();
  };

  const handleSelectAll = () => {
    if (!containerRef.current) return;

    const container = d3.select(containerRef.current.parentNode as HTMLElement);

    // Select all blocks
    container.selectAll('.block')
      .classed('block-selected', true)
      .style('box-shadow', '0 0 0 2px rgba(59, 130, 246, 0.5)');

    const updatedBlocks = blocks.map(block => ({
      ...block,
      selected: true
    }));

    setBlocks(updatedBlocks);


  };

  const handleDeselectAll = () => {
    if (!containerRef.current) return;

    const container = d3.select(containerRef.current.parentNode as HTMLElement);

    // Deselect all blocks
    container.selectAll('.block-selected')
      .classed('block-selected', false)
      .style('box-shadow', 'none');

    // Update the blocks state to reflect deselection
    const updatedBlocks = blocks.map(block => ({
      ...block,
      selected: false
    }));

    setBlocks(updatedBlocks);
    setSelectedBlock(null);
    // swimlaneRef.current?.updateData(undefined, updatedBlocks, initialProcessGroups);
  };

  // Add click handler to the container for deselection
  useEffect(() => {
    const handleContainerClick = (event: MouseEvent) => {
      // Only process if in edit mode
      if (!isEditing) return;

      const target = event.target as HTMLElement;
      const container = containerRef.current?.parentNode as HTMLElement;

      // Check if the click is directly on the container (not on a block or its children)
      if (container && (target === container ||
        (target.closest('.diagram-container') &&
          !target.closest('.block') &&
          !target.closest('[class^="block-selection-menu-"]') &&
          !target.closest('.connection-point')))) {
        handleDeselectAll();
      }
    };

    // Add event listener to the parent container
    const containerParent = containerRef.current?.parentNode as HTMLElement;
    if (containerParent) {
      containerParent.addEventListener('click', handleContainerClick);
    }

    // Clean up event listener on component unmount or when editing mode changes
    return () => {
      if (containerParent) {
        containerParent.removeEventListener('click', handleContainerClick);
      }
    };
  }, [isEditing, blocks]);

  const handleSetMapId = (id: string) => {
    localStorage.setItem("mapId", id);
  }

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check for Ctrl+A (or Cmd+A on Mac)
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        // Prevent the default browser "Select All" behavior
        event.preventDefault();
        handleSelectAll();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

  }, [])

  const handleToggleAccess = async (userId: string) => {

    const dataEdit = {
      map_id: mapId,
      user_id: userId,
      edit: true
    }
    try {
      let data = await FamlaServices("api_host", `/approval/edit`, "POST", dataEdit, users.access_token);
      if (data.status === 201) {
        setCollaborators(prevCollaborators =>
          prevCollaborators.map(collab =>
            collab.user._id === userId
              ? { ...collab, edit: !collab.edit }
              : collab
          )
        );
      }

    } catch (error) {

    }

    // Here you would typically make an API call to update the access
  };

  const handleGrantAllAccess = () => {
    setCollaborators(prevCollaborators =>
      prevCollaborators.map(collab => ({ ...collab, edit: true }))
    );
    // Here you would typically make an API call to update all access
  };




  return (
    <>
      <div>

        <div className={`flex flex-col h-screen w-screen overflow-hidden fixed ${showRightPanel && "transition-all w-[calc(100%-200px)] md:w-[calc(100%-2O0px)] lg:w-[calc(100%-250px)] xl:w-[calc(100%-280px)] 2xl:w-[calc(100%-500px)]"}`} >

          {/* Main Content */}
          <div className="flex-1 flex flex-col h-full">

            {/* Top Bar */}
            <TopBar
              isEditing={isEditing}
              scale={scale}
              hasUnsavedChanges={hasUnsavedChanges}
              handleZoom={handleZoom}
              handleExportPdf={handleExportPdf}
              handleExportPng={handleExportPng}
              exportFile={exportFile}
              activeFocusdisa={activeFocusdisa}
              activeFocusa={activeFocusa}
              approvallist={approvallist}
              processId={processId}
              swimlaneRef={swimlaneRef}
              canEditMap={canEditMap}
              role={role}
              handleToggleLayers={handleToggleLayers}
              handleToggleLayersLine={handleToggleLineLayers}
              onUndo={handleUndo}
              onRedo={handleRedo}
              showLayers={showLayers}
              showLayersLine={showLayersLine}
              handleBlockUpdate={handleBlockUpdate}
              handleTextStyleUpdate={handleTextStyleUpdate}
              selectedBlock={selectedBlock}
              currentData={currentData}
              handleWidthChange={handleWidthChange}
              canUndo={canUndo}
              canRedo={canRedo}
              setMarginTop={setMarginTop}
              handleSelectAll={handleSelectAll}
              title={title}
              handleCopy={handleCopy}
              handleCut={handleCut}
              handlePaste={handlePaste}
              allSwimLane={allSwimLane}
              setMap={setMap}
              owner={owner}
              collaborators={collaborators}
              setShowRightPanel={setShowRightPanel}
              showRightPanel={showRightPanel}
            />

            {/* Conteneur principal du diagramme avec hauteur calculée pour éviter le chevauchement avec la barre fixe */}
            <div className="flex-1 mt-14 bg-white relative" style={{ height: 'calc(100vh - 14rem)' }}>
              {/* Conteneur de défilement avec barres de défilement visibles */}
              <div
                className="absolute inset-0 p-6"
                style={{
                  overflowX: 'scroll',
                  overflowY: 'scroll',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#c7755c #e2e8f0',
                  WebkitOverflowScrolling: 'touch',
                  msOverflowStyle: 'auto'
                }}
              >
                {/* Conteneur du diagramme avec dimensions minimales garanties */}
                <div
                  className="relative"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  style={{
                    minWidth: `${Math.max(baseWidth, 1200)}px`,
                    minHeight: `${Math.max(baseHeight || 0, 600)}px`,
                    width: 'fit-content',
                    height: 'fit-content'
                  }}
                  ref={parentRef}
                >
                  <div
                    id="swimlane-container"
                    ref={containerRef}
                    className="bg-white rounded-lg shadow-md inline-block"
                    style={{
                      marginTop: isEditing ? marginTop : "0px",
                      transformOrigin: "top left"
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Barre de navigation inférieure - Positionnée de manière fixe sans chevauchement */}
            <SwimlaneTabs
              swimlanes={allswimlaneReverse}
              mapId={mapId}
              setMap={setMap}
              handleSetMapId={handleSetMapId}
              hasUnsavedChanges={hasUnsavedChanges}
              swimlaneName={state.swimlaneName}
              handleAction={handleSave}
              submit={state.submit}
              alert={alert}
              handleChange={handleChange}
            />

          </div>
        </div>
        {showRightPanel &&
          <RightSidebar className="">
            <AnalysisPanel
              className=''
              processId={processId}
              mapId={mapId}
              setShoRightPanel={setShowRightPanel}
            />
          </RightSidebar>
        }
      </div>
      <ConfirmationModal
        key={3}
        id="renameSwimlane"
        title={t('features.swimlane.saveFileAs')}
        buttonLabel={t('words.save')}
        inputLabel={t('navigation.diagramName')}
        inputName="swimlane"
        inputValue={state.swimlaneName}
        inputRef={inputRef}
        handleChange={handleChange}
        handleAction={handleSave}
        submit={state.submit}
        alert={alert}
        closeModalRef={closeModal}
        showInput={true}
        isDisabled={state?.isDisabled}
      />

      <ConfirmationModal
        key={2}
        id="disapprove"
        title={t('words.disApprove')}
        message={t('features.swimlane.disApproveQuestion')}
        buttonLabel={t('words.disApprove')}
        inputLabel={t('words.comments')}
        inputName="comment"
        inputValue={state.comment}
        inputRef={inputRefdisa}
        handleChange={handleChange}
        handleAction={() => handleSubmit(sId, "disapprove")}
        submit={state.submit}
        alert={alert}
        closeModalRef={closeModal}
      />
      <ConfirmationModal
        key={1}
        id="approve"
        title={t('words.approve')}
        message={t('features.swimlane.approveQuestion')}
        buttonLabel={t('words.approve')}
        inputLabel={t('words.comments')}
        inputName="comment"
        inputValue={state.comment}
        inputRef={inputRefa}
        handleChange={handleChange}
        handleAction={() => handleSubmit(sId, "approve")}
        submit={state.submit}
        alert={alert}
        closeModalRef={closeModal}
      />

      <ConfirmationModal
        key={"checkSave"}
        id="checkSave"
        title={t('features.swimlane.saveFileAs')}
        message={t('features.swimlane.doYouwantSaveave')}
        buttonLabel={t('words.save')}
        inputLabel={t('navigation.diagramName')}
        inputName="swimlane"
        inputValue={state.swimlaneName}
        inputRef={inputRef}
        handleChange={handleChange}
        handleAction={handleSave}
        submit={state.submit}
        alert={alert}
        closeModalRef={closeModal}
        showInput={true}
        isDisabled={state?.isDisabled}
        route='chatBot'
      />

      <ConfirmationModal
        key={"checkSaveifBacktoHome"}
        id="checkSaveifBacktoHome"
        title={t('features.swimlane.saveFileAs')}
        message={t('features.swimlane.doYouwantSaveave')}
        buttonLabel={t('words.save')}
        inputLabel={t('navigation.diagramName')}
        inputName="swimlane"
        inputValue={state.swimlaneName}
        inputRef={inputRef}
        handleChange={handleChange}
        handleAction={handleSave}
        submit={state.submit}
        alert={alert}
        closeModalRef={closeModal}
        showInput={true}
        isDisabled={state?.isDisabled}
        route='home'
      />
      <ConfirmationModal
        key={"checkSaveifSwapSwimlane"}
        id="checkSaveifSwapSwimlane"
        title={t('features.swimlane.saveFileAs')}
        message={t('features.swimlane.doYouwantSaveave')}
        buttonLabel={t('words.save')}
        inputLabel={t('navigation.diagramName')}
        inputName="swimlane"
        inputValue={state.swimlaneName}
        inputRef={inputRef}
        handleChange={handleChange}
        handleAction={handleSave}
        submit={state.submit}
        alert={alert}
        closeModalRef={closeModal}
        showInput={true}
        isDisabled={state?.isDisabled}
        route='swilaneVersion'
        processId={processId}
        mapId={mapId}
      />

      <GenericModal
        sentence={t('features.swimlane.improvSentence')}
        processId={processId}
        title={t('words.improve')}
        action={() => { }}
        actionButton=''
        classNameSentence="text-white opacity-50"
      />

      <ApprovalModal
        swimlaneId={mapId}
        isDarkMode={false}
      />

      <LayerSettings
        block={layerSelection.block}
        line={layerSelection.line}
        startEnd={layerSelection.startEnd}
        onSelectionsChange={handleSelectionsChange}
        initialSelections={layer}
        t={t}
      />

      <CollaboratorModal
        collaborators={collaborators}
        onToggleAccess={handleToggleAccess}
        onGrantAllAccess={handleGrantAllAccess}

      />

      <ShareModal swimlaneId={mapId} processId={processId} theme='light' />

      < AttributModal
        blockLayerAttributes={layerSelection.block}
        lineLayerAttributes={layerSelection.line}
        startEndLayerAttributes={layerSelection.startEnd}
        mapId={mapId}
        initialData={attributesDataEditable}
      />

    </>
  );
}

export default App;