import { Block, Line, ConnectionPoint, Position } from '../types';
import { STYLES } from '../styles/constants';
import { getBlockDimensions } from './blockUtils';

export function calculateConnectionPoint(block: Block, side: 'top' | 'right' | 'bottom' | 'left'): ConnectionPoint {
  const x = block.x || 0;
  const y = block.y || 0;
  const dimensions = getBlockDimensions(block);

  // Calculer les points de connexion en fonction du type de bloc
  if (block.type === 'start' || block.type === 'end') {
    const borderRadius = STYLES.startEnd.borderRadius;

    // Pour les blocs start/end, placer les points de connexion à l'extérieur du bloc
    switch (side) {
      case 'top':
        return { x: x + dimensions.width / 2, y: y - 2, side };
      case 'right':
        return { x: x + dimensions.width + 2, y: y + dimensions.height / 2, side };
      case 'bottom':
        return { x: x + dimensions.width / 2, y: y + dimensions.height + 2, side };
      case 'left':
        return { x: x - 2, y: y + dimensions.height / 2, side };
    }
  } else if (block.type === 'decision') {
    // Points de connexion pour les blocs de décision (forme de losange)
    switch (side) {
      case 'top':
        return { x: x + dimensions.width / 2, y, side };
      case 'right':
        return { x: x + dimensions.width, y: y + dimensions.height / 2, side };
      case 'bottom':
        return { x: x + dimensions.width / 2, y: y + dimensions.height, side };
      case 'left':
        return { x, y: y + dimensions.height / 2, side };
    }
  } else {
    // Points de connexion pour les blocs de processus (rectangles)
    switch (side) {
      case 'top':
        return { x: x + dimensions.width / 2, y, side };
      case 'right':
        return { x: x + dimensions.width, y: y + dimensions.height / 2, side };
      case 'bottom':
        return { x: x + dimensions.width / 2, y: y + dimensions.height, side };
      case 'left':
        return { x, y: y + dimensions.height / 2, side };
    }
  }
}

export function calculateElbowPoints(from: ConnectionPoint, to: ConnectionPoint): Position[] {
  const points: Position[] = [from];

  // Calculer la direction de la ligne
  const dx = to.x - from.x;
  const dy = to.y - from.y;
  const distance = Math.sqrt(dx * dx + dy * dy);

  // Si les points sont très proches, tracer une ligne directe
  if (distance < 30) {
    points.push(to);
    return points;
  }

  // Cas spécial : connexion directe si les points sont alignés
  const isHorizontallyAligned = Math.abs(from.y - to.y) < 5;
  const isVerticallyAligned = Math.abs(from.x - to.x) < 5;

  if ((isHorizontallyAligned || isVerticallyAligned) && from.side !== to.side) {
    points.push(to);
    return points;
  }

  // Gestion spéciale pour les connexions du même côté
  if (from.side === to.side) {
    const EXTENSION = 5; // Extension de 5px
    const OFFSET = 10; // Décalage perpendiculaire

    switch (from.side) {
      case 'right':
        points.push({ x: Math.max(from.x, to.x) + OFFSET, y: from.y });
        points.push({ x: Math.max(from.x, to.x) + OFFSET, y: to.y });
        points.push({ x: to.x + EXTENSION, y: to.y });
        points.push(to);
        return points;
      case 'left':
        points.push({ x: Math.min(from.x, to.x) - OFFSET, y: from.y });
        points.push({ x: Math.min(from.x, to.x) - OFFSET, y: to.y });
        points.push({ x: to.x - EXTENSION, y: to.y });
        points.push(to);
        return points;
      case 'top':
        points.push({ x: from.x, y: Math.min(from.y, to.y) - OFFSET });
        points.push({ x: to.x, y: Math.min(from.y, to.y) - OFFSET });
        points.push({ x: to.x, y: to.y - EXTENSION });
        points.push(to);
        return points;
      case 'bottom':
        points.push({ x: from.x, y: Math.max(from.y, to.y) + OFFSET });
        points.push({ x: to.x, y: Math.max(from.y, to.y) + OFFSET });
        points.push({ x: to.x, y: to.y + EXTENSION });
        points.push(to);
        return points;
    }
  }

  // Déterminer si on peut utiliser un seul angle droit
  const canUseSingleBend = (
    // Vertical to horizontal
    (from.side === 'top' || from.side === 'bottom') &&
    (to.side === 'left' || to.side === 'right') ||
    // Horizontal to vertical
    (from.side === 'left' || from.side === 'right') &&
    (to.side === 'top' || to.side === 'bottom')
  );

  if (canUseSingleBend) {
    // Point intermédiaire pour un seul angle droit
    const bendPoint = {
      x: from.side === 'top' || from.side === 'bottom' ? from.x : to.x,
      y: from.side === 'left' || from.side === 'right' ? from.y : to.y
    };
    points.push(bendPoint);
  } else {
    // Utiliser deux angles droits avec un ratio adaptatif
    const ratio = Math.min(0.90, distance / 200); // Ajuster le ratio en fonction de la distance

    // Ajuster les points intermédiaires selon le côté de départ
    switch (from.side) {
      case 'right':
        points.push({ x: from.x + Math.abs(dx) * ratio, y: from.y });
        points.push({ x: from.x + Math.abs(dx) * ratio, y: to.y });
        break;
      case 'left':
        points.push({ x: from.x - Math.abs(dx) * ratio, y: from.y });
        points.push({ x: from.x - Math.abs(dx) * ratio, y: to.y });
        break;
      case 'bottom':
        points.push({ x: from.x, y: from.y + Math.abs(dy) * ratio });
        points.push({ x: to.x, y: from.y + Math.abs(dy) * ratio });
        break;
      case 'top':
        points.push({ x: from.x, y: from.y - Math.abs(dy) * ratio });
        points.push({ x: to.x, y: from.y - Math.abs(dy) * ratio });
        break;
    }
  }

  points.push(to);
  return points;
}

export function createArrowMarker(svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) {
  svg.select('defs').remove();

  const defs = svg.append('defs');

  defs.append('marker')
    .attr('id', 'arrowhead')
    .attr('markerWidth', '12')
    .attr('markerHeight', '6')
    .attr('refX', '3')
    .attr('refY', '3')
    .attr('orient', 'auto')
    .append('polygon')
    .attr('points', '0 0, 4.5 3, 0 6')
    .attr('fill', STYLES.colors.arrow);
}