import * as d3 from 'd3';

export class LayerButtonRenderer {
  public renderAddButton(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    onToggleMenu: () => void
  ) {
    const buttonContainer = container
      .append('div')
      .style('padding', '6px')
      .style('display', 'flex')
      .style('justify-content', 'center')
      .style('border-bottom', '1px solid rgba(0, 0, 0, 0.06)')
      .style('background', '#fafafa')
      .style('transition', 'background 0.2s ease');

    const button = buttonContainer
      .append('button')
      .style('background-color', '#eef2ff')
      .style('color', '#4f46e5')
      .style('padding', '2px 4px')
      .style('border-radius', '6px')
      .style('border', 'none')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('gap', '4px')
      .style('cursor', 'pointer')
      .style('font-size', '12px')
      .style('font-weight', '500')
      .style('transition', 'all 0.2s ease')
      .style('width', '45px')
      .style('justify-content', 'center')
      .on('click', (event) => {
        event.stopPropagation();
        onToggleMenu();
      });

    button
      .on('mouseenter', function () {
        d3.select(this)
          .style('background-color', '#e0e7ff')
          .style('transform', 'translateY(-1px)');
      })
      .on('mouseleave', function () {
        d3.select(this)
          .style('background-color', '#eef2ff')
          .style('transform', 'translateY(0)');
      });

    button
      .append('span')
      .html('&#43;')
      .style('font-size', '14px')
      .style('line-height', '1');

    button
      .append('span')
      // .text('Add');

    return button;
  }
}