import { Line } from '../../../types';

interface Point {
  x: number;
  y: number;
}

export class LayerPositionManager {
  private points: Map<string, Point[]> = new Map();

  public updatePoints(lineId: string, points: Point[]) {
    if (points.length > 0) {
      this.points.set(lineId, points);
    }
  }

  public getPoints(lineId: string): Point[] {
    return this.points.get(lineId) || [{ x: 100, y: 100 }];
  }

  public clearPoints(lineId: string) {
    this.points.delete(lineId);
  }
  public calculatePosition(lineId: string): Point {
    const points = this.getPoints(lineId);
    if (points.length < 2) return { x: 100, y: 100 };

    // Identifier le premier et le dernier segment
    const firstPointA = points[0];
    const firstPointB = points[1];
    const lastPointA = points[points.length - 2];
    const lastPointB = points[points.length - 1];

    // Vérification des types de lignes
    const isStrictlyHorizontal = points.every((p, i) => i === 0 || p.y === points[i - 1].y);
    const isStrictlyVertical = points.every((p, i) => i === 0 || p.x === points[i - 1].x);

    // Dimensions du layer
    const layerWidth = 90;
    const layerHeight = 120;
    const offset = 10; // Décalage pour éviter le chevauchement

    const applyLayerLogic = (pointA: Point, pointB: Point) => {
      const centerX = (pointA.x + pointB.x) / 2;
      const centerY = (pointA.y + pointB.y) / 2;
      const isSegmentVertical = Math.abs(pointA.y - pointB.y) > Math.abs(pointA.x - pointB.x);

      if (isSegmentVertical) {
        // Cas vertical : essayer de placer la `layer` à droite si possible
        const rightSpace = centerX + layerWidth + offset < window.innerWidth;
        return {
          x: rightSpace ? centerX + offset : centerX - layerWidth - offset, // À droite ou à gauche
          y: centerY - layerHeight / 2 // Centré verticalement sur la ligne
        };
      } else {
        // Cas horizontal : placer en dessous
        return {
          x: centerX - layerWidth / 2, // Centrage horizontal
          y: centerY + offset // Position sous la ligne
        };
      }
    };

    if (isStrictlyHorizontal || isStrictlyVertical) {
      return applyLayerLogic(lastPointA, lastPointB);
    } else {
      return applyLayerLogic(firstPointA, firstPointB);
    }
  }
}
