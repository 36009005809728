import { useState, useEffect } from 'react';
import { FamlaServices } from '../services';
import { useAppSelector } from './../stores/store';

const useSearch = (url: string, companyId?: string, enrichData: boolean = false) => {
    const token = useAppSelector((state) => state.auth.access_token);
    const [searchQuery, setSearchQuery] = useState<string | undefined>('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [searchLoad, setSearchLoad] = useState(false);
    const [filter, setFilter] = useState<string | undefined>('TITLE');

    // Fonction pour récupérer `timeSaved` et `mappingCreationDate`
    const getProcessStats = async (processId: string): Promise<{ timeSaved: number; mappingCreationDate: string }> => {
        try {
            let response = await FamlaServices("api_host", `/stats/process/${processId}`, "GET", "", token);
            if (response.status === 200) {
                return {
                    timeSaved: response.body.data || 0,
                    mappingCreationDate: response.body.time || "",
                };
            }
        } catch (e) { }
        return { timeSaved: 0, mappingCreationDate: "" };
    };

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (searchQuery && token) {
                setSearchLoad(true);
                const requestData = { search: searchQuery, filter, company_id: companyId };
                let res = await FamlaServices("api_host", url, 'POST', requestData, token);

                if (res.status === 201) {
                    let dataList = res.body.data;

                    // Si enrichData est activé, ajouter timeSaved et mappingCreationDate
                    if (enrichData) {
                        dataList = await Promise.all(
                            dataList.map(async (process: any) => {
                                const { timeSaved, mappingCreationDate } = await getProcessStats(process._id);
                                return { ...process, timeSaved, mappingCreationDate };
                            })
                        );
                    }

                    setSearchResults(dataList);
                    setSearchLoad(true);
                    console.log(dataList);

                } else {
                    setSearchResults([]);
                }
                setSearchLoad(false);
            } else {
                setSearchResults([]);
            }
        };

        fetchSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, searchQuery, url]);

    return { searchQuery, setSearchQuery, searchResults, searchLoad, setFilter, filter };
};

export default useSearch;
