//React
import React from "react"
import { NavLink } from "react-router-dom";

// App
import DashLayout from "../../layouts/dashboard/dashLayout";
import { Routes } from "../../routes";
import { Offcanvas } from "../modal";
import { logout, useAppSelector } from "../../stores";
import { FamlaServices } from "../../services";
import { Spiner } from "../../components";

//Chart
import { ChartDashboard } from "../../chart";

//interface
import { iAlert } from "../../interfaces";
import { useTranslation } from "react-i18next";
import { useCountdown } from "../../utils";
type BarChartDataset = {
    label: any;
    data: number[];
    backgroundColor: string;
};

type BarChartData = {
    labels: string[];
    datasets: BarChartDataset[];
};

const DashboardViews = () => {
    const auth = useAppSelector(state => state.auth);
    const companyId = auth.session.company_id;
    const company = useAppSelector((state) => state.company.company);
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)
    const [state, setState] = React.useState({
        totalUsers: 0,
        tokensConsumed: 0,
        remainingTokens: 0,
        mostActiveUsers: 0,
        tokensTenMonth: []
    });

    const totalToken = state.tokensConsumed + state.remainingTokens;

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    });
    let daysRemaing = useCountdown(company?.expired_at)

    const [barChartData, setBarChartData] = React.useState<BarChartData>({
        labels: [],
        datasets: [
            {
                label: "Token",
                data: [],
                backgroundColor: "#03A9F4",
            },
        ],
    });

    React.useEffect(() => {
        async function loadTokens() {
            setLoading(true);

            try {

                let data = await FamlaServices("api_host", `/request/total_tokens/company?company_id=${companyId}`, "GET", "", auth.access_token);
                if (data.status === 200) {
                    setState(prevState => ({ ...prevState, tokensConsumed: data.body.data.total_tokens, remainingTokens: data.body.data.left_token }));
                    setLoading(false)
                }
                else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                    setAlert({ isActive: true, type: "warning", message: 'You do not have authorisation to continue' })
                }
                else if (data.status === 401) {
                    logout();
                }
                else {

                }
            }
            catch (e) {
                if (e instanceof Error) {
                    console.error(e.message);
                }
            } finally {
                setLoading(false);
            }
        }
        loadTokens();
        async function loadAllUsers() {
            setLoading(true);

            try {

                let data = await FamlaServices("api_host", `/users/company/${companyId}?page=${1}&limit=${1000000}`, "GET", "", auth.access_token); console.log(data);

                if (data.status === 200) {
                    const totalUser = data.body.data
                    setState(prevState => ({ ...prevState, totalUsers: totalUser.count }));
                    setLoading(false)
                }
                else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                    setAlert({ isActive: true, type: "warning", message: 'You do not have authorisation to continue' })

                }
                else if (data.status === 401) {
                    logout();
                }
                else {

                }
            }
            catch (e) {
                if (e instanceof Error) {
                    console.error(e.message);
                }
            } finally {
                setLoading(false);
            }
        }
        loadAllUsers();
        async function loadTokensForTenMonths() {
            setLoading(true);

            try {
                const data = await FamlaServices("api_host", `/request/total_tokens/company/last_10_months?company_id=${companyId}`, "GET", "", auth.access_token);

                if (data.status === 200) {
                    const tokensData = data.body.data;

                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    const currentMonth = new Date().getMonth();

                    // Créer un tableau de 10 mois en commençant par le mois actuel en remontant
                    const labels: string[] = [];
                    for (let i = 10; i >= 0; i--) {
                        labels.push(monthNames[(currentMonth - i + 12) % 12]);
                    }
                    const datasetData = tokensData.reverse().map((item: any) => item.total);

                    setBarChartData({
                        labels: labels,
                        datasets: [
                            {
                                label: "Token",
                                data: datasetData,
                                backgroundColor: "#03A9F4",
                            },
                        ],
                    });
                } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                    setAlert({ isActive: true, type: "warning", message: 'You do not have authorisation to continue' })

                } else if (data.status === 401) {
                    logout();
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        loadTokensForTenMonths();

    }, [auth.access_token, companyId]);

    const options = {
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'white',
                    borderColor: '#00000',
                },
                ticks: {
                    color: 'white',
                },
            },
            y: {
                grid: {
                    display: true,
                    color: '#6c757d',
                    borderColor: '#00000',
                },
                ticks: {
                    color: 'white',
                },
            },
        },
    };
    return (
        <DashLayout>
            {loading ?
                <Spiner className="text-warning spinner-border-sm" classParent="d-flex justify-content-center align-items-center mt-5" />
                :
                <div className="container-fluid p-4">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2">
                            <h1 className="mb-0 fw-bold text-light h4">
                                <NavLink to={Routes.HOMEVIEW} className="fa-solid fa-arrow-left text-decoration-none m-none  text-light "></NavLink> {t('dashboard.dashboard')}
                            </h1>
                            <h1 className="web-none" data-bs-toggle='offcanvas' data-bs-target='#offcanvas'>
                                <i className="fa-solid fa-bars fs-5 text-light"></i>
                            </h1>
                        </div>
                        <div>
                            <NavLink to={Routes.HOMEVIEW} className={'text-danger text-end'}>{t("dashboard.exitDashboard")}</NavLink>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                            <div className="card mb-4 bg-info border-0">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                                        <div>
                                            <span className="fs-6 text-uppercase fw-semi-bold">{t('dashboard.totalUsers')}</span>
                                        </div>
                                        <div>
                                            <span className="fa-solid fa-users fs-5 text-uppercase"></span>
                                        </div>
                                    </div>
                                    <h2 className="fw-bold mb-1">
                                        {state.totalUsers}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                            <div className="card mb-4 bg-primary  border-0">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                                        <div>
                                            <span className="fs-6 text-uppercase fw-semi-bold">{t('dashboard.totalTokens')}</span>
                                        </div>
                                        <div>
                                            <i className="fa-solid fa-layer-group text-uppercase"></i>
                                        </div>
                                    </div>
                                    <h2 className="fw-bold mb-1">
                                        {totalToken}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                            <div className="card mb-4 bg-theme border-0">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                                        <div>
                                            <span className="fs-6 text-uppercase fw-semi-bold">{t('dashboard.tokensConsumed')}</span>
                                        </div>
                                        <div>
                                            <i className="fa-solid fa-chart-line text-uppercase"></i>
                                        </div>
                                    </div>
                                    <h2 className="fw-bold mb-1">
                                        {state.tokensConsumed}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                            <div className="card mb-4 bg-warning border-0">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                                        <div>
                                            <span className="fs-6 text-uppercase fw-semi-bold">{t('dashboard.remainingTokens')}</span>
                                        </div>
                                        <div>
                                            <i className="fa-solid fa-hourglass-half fs-5 text-uppercase"></i>
                                        </div>
                                    </div>
                                    <h2 className="fw-bold mb-1">
                                        {state.remainingTokens}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 col-md-12 col-12">
                            <div className="card mb-4 border-0">
                                <div className="card-header align-items-center card-header-height d-flex bg-dark text-light">
                                    <div>
                                        <h4 className="mb-0">{t('dashboard.monthlyTokenConsumption')}</h4>
                                    </div>
                                </div>
                                <div className="card-body bg-dark">
                                    <div id="earning" className="apex-charts">
                                        <ChartDashboard type="bar" data={barChartData} options={options} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="card mb-4 border-0 bg-dark">
                                <div className="card-body bg-dark rounded-2">
                                    <ul className="list-group">
                                        <li className="list-group-item border-secondary bg-dark text-light">{t('words.status')} : <span className={`text-uppercase fw-bold ${company.status === "PREMIUM" ? "text-success" : "text-warning"}`}>{company.status}</span> </li>
                                        {company.status === "FREE" &&
                                            <li className="list-group-item border-secondary bg-dark text-light">{t('dashboard.daysRemaining')}: <span className="text-warning fs-5">{daysRemaing}</span> </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Offcanvas />
                </div>
            }
        </DashLayout>
    )
}

export default DashboardViews;