const Routes = {
    HOME: '/',
    AUTH: '/',
    AUTH_LOGIN: '/auth/login',
    AUTH_SIGNIN: '/auth/signup',
    AUTH_MAIL: '/auth/mail',
    AUTH_RESET_PASSWORD: '/auth/reset-password',
    CHECKMAIL: '/auth/email-verification',
    HOMEVIEW: '/home',
    CREATE_GROUP_PROCESS: "/process/createGroup",
    CREATE_MY_COMPANY: "/company/create",
    CONTACTUS: '/contact',
    PROCESS_CREATE: '/process/create',
    PROCESS_BY_FOLDER: '/process/folder/',
    PROCESS_INTERVIEW: '/process/interviews/',
    PROCESS_VIEW: '/process/overview',
    MY_PROCESS: '/process/my/processes',
    ALL_GROUP: '/process/group',
    ALL_MAP: '/process/all',
    MAPPING: '/process/mapping/',
    PRIVACY: '/Privacy_Policy',
    FAQ: '/faq',
    TERMS: "/terms_and_conditions",


    //dASHBOAD
    USERS_LIST: "/dashboard/users",
    COLLABORATORS_LIST: "/dashboard/collaborators",
    USERS_DETAILS: "",
    DASHBOARDS: '/dashboard/home',
    OWNER_LIST: "/dashboard/owner",
    ADMIN_LIST: "/dashboard/admin",
    PROCESS_LIST: "/dashboard/process"
}

export default Routes