import * as d3 from 'd3';

export class LayerContainerRenderer {
  constructor(private container: d3.Selection<HTMLDivElement, unknown, null, undefined>) { }

  public render(
    lineId: string,
    position: { x: number; y: number },
    showLayers: boolean
  ) {
    const layersContainer = this.container
      .append('div')
      .attr('id', 'lineManager')
      .attr('class', `line-layers-${lineId}`)
      .style('position', 'absolute')
      .style('left', `${position.x}px`)
      .style('top', `${position.y}px`)
      .style('width', '125px')
      .style('z-index', '1000')
      .style('cursor', 'move')
      .style('display', showLayers ? 'block' : 'none')
      .style('user-select', 'none');

    const layersDiv = layersContainer
      .append('div')
      .style('background', '#ffffff')
      .style('border', '1px solid rgba(0, 0, 0, 0.1)')
      .style('border-radius', '8px')
      .style('box-shadow', '0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 1px 2px -1px rgba(0, 0, 0, 0.03)')
      .style('width', '100%')
      .style('z-index', '5')
      // .style('display', showLayers ? 'block' : 'none')
      .style('overflow', 'hidden');

    // Add drag handle
    const dragHandle = layersDiv
      .append('div')
      .style('height', '4px')
      .style('background', '#f1f5f9')
      .style('border-top-left-radius', '8px')
      .style('border-top-right-radius', '8px')
      .style('cursor', 'move')
      .style('display', 'flex')
      .style('justify-content', 'center')
      .style('align-items', 'center')
      .style('padding', '4px 0');

    dragHandle
      .append('div')
      .style('width', '32px')
      .style('height', '2px')
      .style('background', '#e2e8f0')
      .style('border-radius', '1px');

    // Add drag behavior
    this.setupDragging(layersContainer.node());

    return { layersContainer, layersDiv };
  }

  private setupDragging(element: HTMLDivElement | null) {
    if (!element) return;

    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let originalX = 0;
    let originalY = 0;

    const onMouseDown = (e: MouseEvent) => {
      // Only start drag if clicking the drag handle or container itself
      const target = e.target as HTMLElement;
      if (!target.closest('[style*="cursor: move"]')) return;

      isDragging = true;
      startX = e.clientX;
      startY = e.clientY;
      originalX = element.offsetLeft;
      originalY = element.offsetTop;

      // Add temporary event listeners
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);

      // Prevent text selection while dragging
      e.preventDefault();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;

      const dx = e.clientX - startX;
      const dy = e.clientY - startY;

      // Calculate new position
      const newX = originalX + dx;
      const newY = originalY + dy;

      // Get container boundaries
      const containerRect = this.container.node()?.getBoundingClientRect();
      if (!containerRect) return;

      // Keep within container bounds
      const maxX = containerRect.width - element.offsetWidth;
      const maxY = containerRect.height - element.offsetHeight;

      const boundedX = Math.max(0, Math.min(newX, maxX));
      const boundedY = Math.max(0, Math.min(newY, maxY));

      // Update position
      element.style.left = `${boundedX}px`;
      element.style.top = `${boundedY}px`;

      // Add dragging visual feedback
      element.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
    };

    const onMouseUp = () => {
      isDragging = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      // Reset shadow
      element.style.boxShadow = '';

      // Add a subtle animation when dropping
      element.style.transition = 'box-shadow 0.2s ease';
      setTimeout(() => {
        element.style.transition = '';
      }, 200);
    };

    // Add initial mousedown listener
    element.addEventListener('mousedown', onMouseDown);
  }
}