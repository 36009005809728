import React from "react";
import "../assets/css/dropdownhelper.css";

interface MenuItem {
    icon?: React.ReactNode;
    icons?: JSX.Element;
    label: string;
    shortcut?: string;
    onClick?: () => void; // Optionnel : Exécute une action au clic
    href?: string; // Optionnel : Définit un lien
    children?: MenuItem[]; // Optionnel : Gère des sous-menus
    className?: string;
    dataBsTarget?: string;
    dataBsToggle?: string;
    disabled?: boolean;
    classParentChildren?: string;
    heightCh?: number

}

interface DropdownMultiMenuProps {
    dropName: string;
    buttonClassName: string;
    menuItems: MenuItem[]; // Liste dynamique des éléments du menu
    id?: string;

}

const DropdownMultiMenu: React.FC<DropdownMultiMenuProps> = ({ dropName, buttonClassName, menuItems, id }) => {
    // Fonction récursive pour générer les menus imbriqués
    const renderMenuItems = (items: MenuItem[] | undefined) => {
        return items?.map((item, index) => {
            const hasChildren = item.children && item.children.length > 0;

            return (item.label ?
                <li key={index} className={`dropdown-item  small ${hasChildren ? "dropend dropend-hover" : ""}`}>
                    {item.href ? (
                        <a className="w-100" href={item.href}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex">
                                    {item.icon && <span className="">{item.icon}</span>}
                                    <span className="menu-label ms-1">{item.label}</span>
                                </div>
                                {item.icons}
                                {item.shortcut && <span className="menu-shortcut small">{item.shortcut}</span>}
                            </div>
                        </a>
                    ) : ((item.label) &&
                        <button
                            {...(item.dataBsTarget && { "data-bs-target": item.dataBsTarget })}
                            {...(item.dataBsToggle && { "data-bs-toggle": item.dataBsToggle })}
                            className="dropdown-toggle w-100 small"
                            type="button"
                            onClick={item.onClick}
                            disabled={item.disabled}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex">
                                    {item.icon && <span className="">{item.icon}</span>}
                                    <span className="menu-label ms-1 ">{item.label}</span>
                                </div>
                                {item.icons}
                                {item.shortcut && <span className="menu-shortcut small">{item.shortcut}</span>}

                            </div>
                        </button>
                    )}
                    {hasChildren && <ul className={`dropdown-menu dropdown-menu-hover ${item.classParentChildren}`} style={item.heightCh ? { height: item.heightCh } : {}}>{renderMenuItems(item.children)}</ul>}
                </li> : null
            );
        });
    };


    return (
        <div className="dropdown dropdown-hover" id={id}>
            <button className={buttonClassName} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {dropName}
            </button>
            <ul className="dropdown-menu dropdown-menu-hover">
                {renderMenuItems(menuItems)}
            </ul>
        </div>
    );
};

export default DropdownMultiMenu;
