// React Component
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// App Component
import { Buttons, InputForm, SelectForm } from '../../../components'
import { Routes } from '../../../routes'
import { FamlaServices } from '../../../services'
import { useAppSelector } from '../../../stores'
import { Languages } from '../../../constants'

//interface
import { iAlert } from '../../../interfaces'

interface industry {
    _id: string
    name: string
    description: string
};


const ProcessCreate = () => {
    const { t } = useTranslation();
    const users = useAppSelector((state) => state.auth);
    const navigate = useNavigate()
    const language = localStorage.getItem('currentLanguage') === "fr" ? "french" : "english"
    const [newProcess, setNewProcess] = React.useState({
        name: '',
        description: '',
        industry: '',
        owner: '',
        isCollaborator: true,
        isSubmit: false,
        myRole: '',
        lang: language
    });

    const [industry, setIndustry] = React.useState<industry[]>([])

    const sortedIndustry = industry.sort((a, b) => a.name.localeCompare(b.name));
    const selectIndustryOptions = sortedIndustry.map((industry) => ({
        label: industry.name,
        value: industry.name,
    }));


    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })

    React.useEffect(() => {

        const getAllIndustry = async () => {
            let data = await FamlaServices("api_host", `/industry/`, "GET", "", users.access_token);
            if (data.status === 200) {
                setIndustry(data.body.data)
            }
        }

        const getMyRole = async () => {
            let data = await FamlaServices("api_host", `/users/my/role`, "GET", "", users.access_token);
            if (data.status === 200) {
                setNewProcess((prev) => ({ ...prev, myRole: data.body.data }))
            }
        }

        getAllIndustry()
        getMyRole()

    }, [users.access_token])

    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'name') {
            setNewProcess({ ...newProcess, name: fieldValue })
        } else if (name === 'description') {
            setNewProcess({ ...newProcess, description: fieldValue })
        } else if (name === 'selectName') {
            setNewProcess({ ...newProcess, industry: fieldValue })
        } else if (name === 'lang') {
            setNewProcess({ ...newProcess, lang: fieldValue })
        } else {
            setNewProcess({ ...newProcess, owner: fieldValue })
        }
    };

    function checkName(name: string, description: string): boolean {
        if (name === "Test" || name === "test" || description === "Test" || description === "test") {
            setAlert({ isActive: true, type: 'danger', message: t('form.createProcess.nameIsNotValid') });
            return false;
        }

        if (description && description.trim().length < 5) {
            setAlert({ isActive: true, type: 'danger', message: t('form.createProcess.descriptionTooShort') });
            return false;
        }

        return true;
    }


    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (newProcess.name.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('form.nameRequired') });
            return;
        }
        if (newProcess.industry.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('form.createProcess.industryRequired') });
            return;
        }
        const selectedIndustry = industry.find(item => item.name === newProcess.industry);
        if (!selectedIndustry) {
            setAlert({ isActive: true, type: 'danger', message: t('form.createProcess.invalidSelection') });
            return;
        }

        const isValid = checkName(newProcess.name, newProcess.description);
        if (!isValid) {
            return;
        }

        let formData = {
            project_id: "",
            title: newProcess.name,
            description: newProcess.description,
            is_sub_process: false,
            process_id: "",
            created_at: Date.now(),
        }

        setNewProcess({ ...newProcess, isSubmit: true });

        let data = await FamlaServices("api_host", `/process/${selectedIndustry._id}?language=${newProcess.lang}`, "POST", formData, users.access_token);

        if (data.status === 201) {
            let datas = await FamlaServices("api_host", `/interview/process/${data.body.data._id}`, "GET", "", users.access_token);
            if (datas.status === 200) {
                setNewProcess({ ...newProcess, name: '', description: '', isSubmit: false });
                navigate(Routes.PROCESS_INTERVIEW + datas.body.data._id);
            }

        }
        else if (data.body.status_code === 400 && data.body.description === "Process name already exist") {
            setAlert({ isActive: true, type: 'danger', message: t('form.createProcess.processNameAlreadyExist') });

        } else if (data.body.status_code === 401) {
            setAlert({ isActive: true, type: 'danger', message: t('form.createProcess.processCannotCreate') });
        } else if (data.body.status_code === 426) {
            setAlert({ isActive: true, type: 'warning', message: t('form.createProcess.processCreateLimite') });
        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.anErrorOcured') });
        }
        setNewProcess({ ...newProcess, isSubmit: false });
    };

    return (
        <div className="w-50" style={{ margin: "auto" }}>
            <div className="mb-5" style={{ marginTop: "20%" }}>
                <Link to={Routes.HOMEVIEW} className="text-light fw-bold fs-3 text-decoration-none">
                    <i className="fa-solid fa-arrow-left "></i> {t('form.createProcess.processOverview')}
                </Link>
            </div>

            <div className="mt-4">
                <InputForm
                    inputType="text"
                    inputLabel={t('views.processByGroupView.processName') + '*'}
                    inputName="name"
                    inputValue={newProcess.name}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />
                <InputForm
                    inputType="text"
                    inputLabel={t('words.description') + "*"}
                    inputName="description"
                    inputValue={newProcess.description}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e) => handleChange(e)}
                    inputContainerClass={'mb-3'}
                    inputClass="bg-dark text-light text-bg-dark"
                />

                <SelectForm
                    selectClass="mb-3 bg-dark"
                    placeholder={t('form.createProcess.selectIndustry')}
                    selectName='selectName'
                    selectOptions={selectIndustryOptions}
                    onChange={(e) => handleChange(e)}
                />

                <SelectForm
                    selectClass="mb-3 bg-dark"
                    placeholder={t('form.createProcess.selectLanguage')}
                    selectName='lang'
                    selectOptions={Languages}
                    onChange={(e) => handleChange(e)}
                    defaultValue={newProcess.lang}
                />

                {alert.isActive &&
                    <div className={`alert alert-${alert.type}`} role="alert">
                        {alert.message}
                    </div>
                }
                <Buttons
                    buttonType="submit"
                    name={t('words.create')}
                    onClick={(e) => { handleSubmit(e) }}
                    isLoading={newProcess.isSubmit}
                    btnClass="btn-theme mt-3 w-100"
                    labelClass="text-white fw-bold fs-5"
                    btnStyle={{ height: 68 }}
                    arrowVisible={true}
                    arrowColor='text-white'
                />
            </div>
        </div>
    )
}

export default ProcessCreate