import { useCallback, useState, RefObject } from 'react';
import { exportToJson, exportToPng, exportToPdf } from '../utils/exportUtils';
import { convertToOriginalFormat } from '../utils/saveUtils';

export const useExportHandlers = (containerRef: RefObject<HTMLDivElement>, data: any) => {
  const [isExporting, setIsExporting] = useState(false);

  const handleExportJson = useCallback(() => {
    // const exportData = convertToOriginalFormat(data);
    exportToJson(data);
  }, [data]);

  const handleExportPng = useCallback(async () => {
    setIsExporting(true);
    try {
      await exportToPng(containerRef);
    } finally {
      setIsExporting(false);
    }
  }, [containerRef]);

  const handleExportPdf = useCallback(async () => {
    setIsExporting(true);
    try {
      await exportToPdf(containerRef);
    } finally {
      setIsExporting(false);
    }
  }, [containerRef]);

  return {
    isExporting,
    handleExportJson,
    handleExportPng,
    handleExportPdf
  };
};