import React from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../../components';

interface SaveModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    onDiscard: () => void;
    diagramName: string;
    onDiagramNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    submit: boolean;
    alert: { msg: string; type: string };
}

export const SaveModal: React.FC<SaveModalProps> = ({
    isOpen,
    onClose,
    onSave,
    onDiscard,
    diagramName,
    onDiagramNameChange,
    submit,
    alert
}) => {
    const { t } = useTranslation()
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[2050]">
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4">
                <div className="flex justify-between items-center p-4">
                    <h3 className="text-xl font-normal">{t('features.swimlane.saveFileAs')}</h3>
                    <button
                        onClick={onDiscard}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X size={20} />
                    </button>
                </div>
                <div className="p-4 pt-0">
                    <p className="text-gray-600 mb-4">
                        {t('features.swimlane.doYouwantSaveave')}
                    </p>
                    <div className="bg-gray-900 text-white rounded-lg p-4">
                        <input
                            type="text"
                            name='swimlane'
                            value={diagramName}
                            onChange={onDiagramNameChange}
                            placeholder="Diagram name"
                            className="w-full bg-transparent border-0 outline-none text-white placeholder-gray-400"
                        />
                    </div>
                    {alert?.msg?.length !== 0 && <Alert msg={alert?.msg} type={alert?.type} />}
                </div>
                <div className="flex justify-end gap-2 p-4">
                    <button
                        onClick={onDiscard}
                        className="px-6 py-2 text-white bg-gray-900 rounded hover:bg-gray-800 font-medium"
                    >
                        {t('words.close')}
                    </button>
                    <button
                        onClick={onSave}
                        className="px-6 py-2 text-white bg-gray-500 rounded hover:bg-gray-600 font-medium"
                    >
                        {t('words.save')}
                    </button>
                </div>
            </div>
        </div>
    );
};