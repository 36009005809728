// React Component
import React from 'react';
import { NavLink } from "react-router-dom";

// App Component
import Routes from "../Routes"
import { Images } from "../../constants"
import { useTranslation } from 'react-i18next';
interface ChildProps {
    className: string
}

const DashNavigation = (props: ChildProps) => {
    const { className } = props
    const { t } = useTranslation()
    return (
        <nav className={`${className} second_bg `} >
            <div className="position-sticky vh-100 pt-3">
                <NavLink className="navbar-vertical_navlink m-none" to={Routes.HOMEVIEW} >
                    <img src={Images.logov1} alt="" style={{ width: "100px" }} />
                </NavLink>
                <ul className="nav flex-column mt-3 position-relative">
                    <li className="nav-item">
                        <NavLink to={Routes.DASHBOARDS} className={({ isActive }) => isActive ? "nav-link fw-bold active text-light" : "nav-link text-light opacity-50 "}>
                            <i className="fa-solid fa-tachometer-alt me-1"></i>  {t('dashboard.dashboard')}
                        </NavLink>
                    </li>
                    <li className='mx-3 border-bottom border-secondary opacity-50 my-2'></li>
                    <li className="nav-item">
                        <NavLink to={Routes.USERS_LIST} className={({ isActive }) => isActive ? "nav-link fw-bold active text-light" : "nav-link  text-light opacity-50"}>
                            <i className="fa fa-user-friends me-1"></i> {t('dashboard.users')}
                        </NavLink>
                    </li>
                    {/* <li className='mx-3 border-bottom border-secondary opacity-50 my-2'></li>
                    <li className="nav-item">
                        <NavLink to={Routes.COLLABORATORS_LIST} className={({ isActive }) => isActive ? "nav-link fw-bold active text-light" : "nav-link  text-light opacity-50"}>
                            <i className="fa fa-people-group me-1"></i> {t('dashboard.collaborators')}
                        </NavLink>
                    </li>
                    <li className='mx-3 border-bottom border-secondary opacity-50 my-2'></li>
                    <li className="nav-item">
                        <NavLink to={Routes.OWNER_LIST} className={({ isActive }) => isActive ? "nav-link fw-bold active text-light" : "nav-link  text-light opacity-50"}>
                            <i className="fa fa-user-tie me-1"></i> {t('dashboard.processOwners')}
                        </NavLink>
                    </li> */}

                    <li className='mx-3 border-bottom border-secondary opacity-50 my-2'></li>
                    <li className="nav-item">
                        <NavLink to={Routes.ADMIN_LIST} className={({ isActive }) => isActive ? "nav-link fw-bold active text-light" : "nav-link  text-light opacity-50"}>
                            <i className="fa fa-user-cog me-1"></i> {t('dashboard.admin')}
                        </NavLink>
                    </li>
                    <li className='mx-3 border-bottom border-secondary opacity-50 my-2'></li>
                    <li className="nav-item">
                        <NavLink to={Routes.PROCESS_LIST} className={({ isActive }) => isActive ? "nav-link fw-bold active text-light" : "nav-link  text-light opacity-50"}>
                            <i className="bi bi-collection me-1"></i> {t('views.processView.library')}
                        </NavLink>
                    </li>
                    <li className='mx-3 border-bottom border-secondary opacity-50 my-2'></li>
                </ul>
            </div>
        </nav>
    )
}

export default DashNavigation; 