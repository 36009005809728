// React Component
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// App Component
import { CardProcess, NewProcessOrGroup, SearchInput } from '../../components'
import { AppLayout, HeaderLayout, ProcessLayout } from '../../layouts'
import { FamlaServices } from '../../services'
import { role, useAppDispatch, useAppSelector, company } from '../../stores'
import { Spiner } from '../../components'
import { Routes } from '../../routes'

// Features

//interface
import { iCompany, iProcess } from '../../components/type'
import iProject from '../../interfaces/project'
import { useSearch } from '../../utils'

interface iState {
    isLoadingProcess: boolean,
    isLoadingProject: boolean,
    isLoadingPopular: boolean,
    isLoadingApproval: boolean
    isLoading: boolean
    process: any
    myRole: string
    error: string
    popularUseCase: any
    requestApproval: Array<any>
    companyById: iCompany
}


const ProcessView = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const language = localStorage.getItem('currentLanguage') === "fr" ? "french" : "english"
    const users = useAppSelector((state) => state.auth);
    const companyId = users.session.company_id;
    const [visibleProcesses, setVisibleProcesses] = useState(3);
    const [processNumbers, setProcessNumbers] = useState<{ [key: string]: number }>({});
    const [notifProcess, setNotifProcess] = useState<{ [key: string]: boolean }>({});
    const [notifProject, setNotifProject] = useState<{ [key: string]: boolean }>({});
    const [project, setProject] = React.useState<iProject[]>([]);
    const [process, setProcess] = React.useState<iProcess[]>([]);
    const [state, setState] = React.useState<iState>({
        isLoadingProcess: true,
        isLoadingProject: true,
        isLoadingPopular: true,
        isLoadingApproval: true,
        isLoading: true,
        process: [],
        myRole: "WORKER",
        error: '',
        popularUseCase: [],
        requestApproval: [],
        companyById: {}
    })
    const visibleProcessInpopular = (visibleProcesses + 1)

    const { searchQuery, setSearchQuery, searchResults, searchLoad, setFilter, filter } = useSearch("/process/search/my/process");


    const options = [
        { label: t('words.role'), value: "ROLE" },
        { label: `${t('words.name')}/${t('words.description')}`, value: "TITLE" }
    ]

    const getProcessNumber = async (projectId: string) => {
        try {
            let data = await FamlaServices("api_host", `/project/number/${projectId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                return data.body.data;
            } else {
                return 0;
            }
        } catch (e) {
            return 0;
        }
    };

    const loadNotificationByProcess = async (processId: string) => {
        try {
            let data = await FamlaServices("api_host", `/notification/by/id/${processId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                return data.body.data;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };
    const loadNotificationByProject = async (projectId: string) => {
        try {
            let data = await FamlaServices("api_host", `/notification/project/by/id/${projectId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                const datas = data.body.data
                if (datas || datas !== null) {
                    return true
                } else {
                    return false
                }
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    React.useEffect(() => {


        const loadCompanyById = async () => {

            try {
                let data = await FamlaServices("api_host", `/company/by/id/${companyId}`, "GET", "", users.access_token);

                if (data.status === 200) {
                    const companie = data.body.data
                    setState((prev) => ({ ...prev, companyById: companie }));
                    let newData = await FamlaServices("api_host", `/request/total_tokens/company/?company_id=${companyId}`, "GET", "", users.access_token);
                    if (newData.status === 200) {
                        const tokenData = newData.body.data
                        const companyData = {
                            ...companie,
                            tokensRemaining: tokenData.left_token,
                            tokensUsed: tokenData.total_tokens,
                            totalTokens: tokenData.total_tokens

                        }
                        dispatch(company({ company: companyData }));
                    }

                } else {

                }

            } catch (error) {

            }

        }

        loadCompanyById()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, users.access_token])




    React.useEffect(() => {
        const updateLang = async () => {
            try {
                const updateData = {
                    fullname: users.user.fullname,
                    phone_number: users.user.phone_number,
                    lang: language,
                    updated_at: users.user.created_at
                };

                let data = await FamlaServices("api_host", `/users/`, "PUT", updateData, users.access_token);
                if (data.status === 200) {
                }
            } catch (error) {

            }

        }
        updateLang()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users.access_token, language])


    React.useEffect(() => {

        const getMyRole = async () => {
            try {
                let data = await FamlaServices("api_host", `/users/my/role`, "GET", "", users.access_token);
                if (data.status === 200) {
                    setState((prev) => ({ ...prev, myRole: data.body.data }))
                    dispatch(role({ role: data.body.data }),
                    )
                }
            } catch (error) {

            }

        }
        getMyRole()

        const getProcessUser = async () => {
            try {
                const data = await FamlaServices("api_host", `/process/my`, "GET", "", users.access_token);

                if (data.status === 200) {
                    const processes = data.body.data;

                    const notif: { [key: string]: boolean } = {};
                    for (const process of processes.slice(0, 3)) {
                        notif[process._id] = await loadNotificationByProcess(process._id);
                    }

                    setState((prev) => ({ ...prev, isLoadingProcess: false }));

                    setProcess((prevProcesses) => {
                        const processMap = new Map(prevProcesses.map((p) => [p._id, p]));
                        processes.forEach((newProcess: iProcess) => {
                            if (!processMap.has(newProcess._id)) {
                                processMap.set(newProcess._id, newProcess);
                            }
                        });

                        return Array.from(processMap.values());
                    });

                    setNotifProcess(notif);
                }
            } catch (error) {

            }
        };

        getProcessUser();

        const getFolderByUser = async () => {
            try {
                let data = await FamlaServices("api_host", `/project/my`, "GET", "", users.access_token);

                if (data.status === 200) {
                    let projects = data.body.data
                    const numbers: { [key: string]: number } = {};
                    const notif: { [key: string]: boolean } = {};
                    for (const project of projects.slice(0, 3)) {
                        numbers[project._id] = await getProcessNumber(project._id);
                        notif[project._id] = await loadNotificationByProject(project._id)
                    }
                    setProcessNumbers(numbers);
                    setNotifProject(notif)
                    setProject(projects)
                    setState((prev) => ({ ...prev, isLoadingProject: false }))
                }
            } catch (error) {

            }
        }

        getFolderByUser()

        const loadPopularUseCase = async () => {
            try {
                let data = await FamlaServices("api_host", `/popular/${language}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    setState((prev) => ({ ...prev, popularUseCase: data.body.data, isLoadingPopular: false }));
                } else {
                    setState((prev) => ({ ...prev, isLoadingPopular: false, error: 'Failed to fetch data' }));
                }
            } catch (error: any) {
                setState((prev) => ({ ...prev, isLoadingPopular: false, error: error.message }));
            }
        }

        loadPopularUseCase();

        const loadApprovalList = async () => {
            try {
                let data = await FamlaServices("api_host", `/approval/`, "GET", "", users.access_token);
                if (data.status === 200) {
                    setState((prev) => ({ ...prev, requestApproval: data.body.data, isLoadingApproval: false }));
                } else {
                    setState((prev) => ({ ...prev, isLoadingApproval: false, error: 'Failed to fetch data' }));
                }
            } catch (error: any) {
                setState((prev) => ({ ...prev, isLoadingApproval: false }));
            }
        }

        loadApprovalList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users.access_token])

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 576) {
                setVisibleProcesses(1);
            } else if (window.innerWidth < 768) {
                setVisibleProcesses(2)
            } else if (window.innerWidth < 1540) {
                setVisibleProcesses(2);
            } else if (window.innerWidth < 1837) {
                setVisibleProcesses(3);
            } else if (window.innerWidth < 2130) {
                setVisibleProcesses(4);
            } else if (window.innerWidth < 2424) {
                setVisibleProcesses(5);
            } else if (window.innerWidth < 3016) {
                setVisibleProcesses(6);
            } else {
                setVisibleProcesses(8);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [state.myRole]);

    const deleProcess = async (process_id: string, name: string) => {
        setProcess(prevState =>
            prevState.filter((process: { _id: string }) => process._id !== process_id)
        );
        await FamlaServices("api_host", `/process/${process_id}`, "DELETE", "", users.access_token);
    }

    const deleFolder = async (folder_id: string, name: string) => {
        if (name !== "Default") {
            setProject(project.filter((project: { _id: string }) => project._id !== folder_id));
            await FamlaServices("api_host", `/project/${folder_id}`, "DELETE", "", users.access_token);
        }
    }

    return (
        <AppLayout>
            <>
                <HeaderLayout
                    loadcompany={true}
                    listApproval={state.requestApproval}
                    loading={state.isLoadingApproval}
                    companyById={state.companyById}
                    key={'processView'}
                    inputValue={searchQuery}
                    setSearchQuery={setSearchQuery}
                    setFilter={setFilter}
                    search={true}
                    filterValue={filter}
                    searchfilter={true}

                />
                <div className="container-fluid px-3 w-100 m-none newbg-black mt-3 ">
                    {/* <div className="mt-4 m-auto d-flex align-items-center justify-content-center w-75">
                        <SearchInput
                            inputType="text"
                            inputValue={searchQuery}
                            placeholderValue={`${t('words.search')}...`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                            inputContainerClass="w-75"
                            inputStyle={{ borderRadius: 20 }}
                            inputClass="newbg-black text-light border-0"
                            iconClass="fa-solid fa-magnifying-glass text-secondary"
                            iconName=""
                            formClass='rounded-5 bg-transparent text-light text-bg-dark'
                            iconContainer='newbg-black text-light border-0'
                            setFilter={setFilter}
                            filter={true}
                            fiterOption={options}
                        />
                    </div> */}

                    <div className='overflow-auto'>

                        <>
                            {(searchQuery !== "") ?
                                <ProcessLayout
                                    title={`${t('words.searching')}...${searchQuery}`}
                                    arrowBack={false} style={{}}
                                    addClassName='containerCardProcess'

                                >
                                    <>
                                        {searchResults?.slice(0, visibleProcesses).map((process: iProcess, index) => (
                                            <CardProcess
                                                processName={process.title}
                                                thisProcessHasABadge={notifProcess[process._id] || false}
                                                theBadgeHasAValue={false}
                                                badgeValue={0}
                                                thisProcessHasAOwner={true}
                                                owner={users.user.fullname === process.owner.fullname ? t('words.me') : process.owner.fullname}
                                                key={process._id}
                                                route={Routes.PROCESS_INTERVIEW}
                                                deleteProcess={() => deleProcess(process._id, process.title)}
                                                description={process.description}
                                                processId={process._id}
                                                folder={false}
                                                setProject={setProject}
                                                setProcess={setProcess}
                                                elementType='process'
                                            />
                                        ))}
                                        {(searchResults.length === 0 && searchLoad) &&
                                            <NewProcessOrGroup
                                                newElement={t('views.processView.processNotFound')}
                                                create='process'
                                                noResult={true}
                                            />
                                        }
                                    </>
                                </ProcessLayout>
                                :
                                <ProcessLayout
                                    title={t('views.processView.myProcess')}
                                    arrowBack={false}
                                    style={{}}
                                    addClassName='containerCardProcess'
                                    route={Routes.MY_PROCESS}
                                    viewall={process.length > visibleProcesses}
                                >
                                    <NewProcessOrGroup newElement={t('views.processView.newProcess')} create='process' noResult={false} />
                                    <>
                                        {state.isLoadingProcess ?
                                            <Spiner className='ms-5 mt-5 spinner-border-sm  ' />
                                            :
                                            <>
                                                {(Array.isArray(process) &&
                                                    process.slice(0, visibleProcesses)?.map((process: iProcess, index: number) => (
                                                        <CardProcess
                                                            index={index}
                                                            processName={process.title}
                                                            thisProcessHasABadge={notifProcess[process._id] || false}
                                                            theBadgeHasAValue={false}
                                                            badgeValue={0}
                                                            thisProcessHasAOwner={true}
                                                            owner={users.user.fullname === process.owner.fullname ? t('words.me') : process.owner.fullname}
                                                            key={process._id}
                                                            route={Routes.PROCESS_INTERVIEW}
                                                            deleteProcess={() => deleProcess(process._id, process.title)}
                                                            description={process.description}
                                                            processId={process._id}
                                                            folder={false}
                                                            setProject={setProject}
                                                            setProcess={setProcess}
                                                            elementType='process'
                                                        />

                                                    )))}

                                            </>
                                        }

                                    </>
                                    {/* {process.length > visibleProcesses && <ViewAllLink urlLink={Routes.MY_PROCESS} />} */}
                                </ProcessLayout>
                            }
                        </>

                        <ProcessLayout
                            title={t('views.processView.library')}
                            arrowBack={false}
                            style={{}}
                            addClassName='containerCardProcess'
                            route={Routes.ALL_GROUP}
                            viewall={project.length > visibleProcesses}
                        >

                            <NewProcessOrGroup newElement={t('views.processView.newfolder')} create='groupe' noResult={false} />

                            <>
                                {state.isLoadingProject ? (
                                    <Spiner className='ms-5 mt-5 spinner-border-sm' />
                                ) : (
                                    <>
                                        {project?.slice(0, visibleProcesses)?.map((project: iProject, index: number) => (
                                            <CardProcess
                                                processName={project.title}
                                                thisProcessHasABadge={processNumbers[project._id] > 0}
                                                theBadgeHasAValue={true}
                                                badgeValue={processNumbers[project._id] || 0}
                                                thisProcessHasAOwner={true}
                                                owner=''
                                                key={index}
                                                route=''
                                                deleteProcess={() => deleFolder(project._id, project.title)}
                                                description={project.description}
                                                processId={project._id}
                                                folder={true}
                                                setProject={setProject}
                                                setProcess={setProcess}
                                                elementType='folder'
                                                notifFolder={notifProject[project._id] || false}

                                            />
                                        ))}
                                    </>
                                )}

                            </>
                            {/* {project.length > visibleProcesses && < ViewAllLink urlLink={Routes.ALL_GROUP} />} */}
                        </ProcessLayout>


                        <ProcessLayout
                            title={t('views.processView.needInspiration') + '...'}
                            arrowBack={false}
                            style={{}}
                            addClassName='containerCardProcess'
                            route={Routes.ALL_MAP}
                            viewall={state.popularUseCase.length}
                        >

                            {state.isLoadingPopular ?
                                <Spiner className='ms-5 mt-5 spinner-border-sm' /> :
                                <>
                                    {state.popularUseCase?.slice(0, visibleProcessInpopular).map((popularUseCase: iProcess) => {
                                        return (
                                            <CardProcess
                                                processName={popularUseCase.title}
                                                thisProcessHasABadge={false}
                                                theBadgeHasAValue={false}
                                                badgeValue={0}
                                                thisProcessHasAOwner={false}
                                                owner=''
                                                key={popularUseCase._id}
                                                route=''
                                                deleteProcess={() => { }}
                                                description={popularUseCase.description}
                                                processId=''
                                                folder={false}
                                                setProject={() => { }}
                                                usage={'populare'}
                                                setProcess={() => { }}
                                                elementType='popular'
                                            />
                                        )
                                    })}
                                </>
                            }
                            {/* {state.popularUseCase.length > visibleProcessInpopular && < ViewAllLink urlLink={Routes.ALL_MAP} />} */}
                        </ProcessLayout>
                    </div>
                </div>

            </>
        </AppLayout>
    )
}

export default ProcessView