

const en = {

    dashboard: {
        addNewAdmin: "Add new admin",
        addNewUser: "Add new user",
        addNewAdminTitle: "Add new administrator",
        adminAddedSuccessfully: "Administrator added with success.",
        admin: "Administrators",
        answerDelete: "Are you sure you want to permanently delete this user from the system? This action cannot be undone.",
        dashboard: "Dashboard",
        daysRemaining: "Days remaining",
        deleteUser: "Delete User",
        demoteFromAdmin: "Demote from admin",
        confirmUserDemotion: "Do you really want to promote this user to admin?",
        confirmAdminPromotion: "You are about to promote this user to the Administrator role",
        collaborators: "Collaborators",
        companyNotFound: "Company not found.",
        exitDashboard: "Exit Dashboard",
        exportXls: "Export to Xls",
        lastConnectionDate: "Last connection date",
        list: "list",
        mappingCreationDate: "Mapping Creation Date",
        monthlyTokenConsumption: "Monthly Token Consumption",
        monthlyTokenConsumed: "Monthly tokens consumed",
        promoteToAdmin: "Promote to admin",
        processOwners: "Process owners",
        remainingTokens: "Remaining Tokens",
        registrationDate: "Registration date",
        shareToAll: "Share to All",
        shareToSelectedUser: "Share to selected users",
        shareWithOtherUsers: "Share with other users",
        successDemotion: "The admin was successfully demoted to user.",
        successPromotion: "The user has been successfully promoted to the Administrator role.",
        users: "Users",
        userAlreadyAdmin: "The user is already an admin.",
        rootUserCannotBeRemoved: "The main administrator cannot be downgraded to a single user.",
        theSelectedProcessWill: "The selected process(es) will be available to all users within your company",
        theProcessSuccessfullyShared: "The process has been shared with the selected user(s).",
        totalUsers: "Users",
        totalTokens: "Token",
        tokensConsumed: "Token Consumed",
    }
    ,

    form: {
        nameRequired: "Name is required",
        createCompany: {
            companyName: "Company name",
            createACompany: "Create a company",
            phoneNumber: "Phone number",
            postalCode: "Postal code",
            website: "Website"
        },
        createGroup: {
            createGroup: "Create Folder",
            folderNameExist: "Folder name already exist",
            groupCreateSuccessfully: "Folder create successfully",
            selectOwner: "Select Owner",
            youNotAuthorized: "You are not authorized to create a folder"
        },
        createProcess: {
            descriptionTooShort: "The description must be at least 5 characters long.",
            industryRequired: "Industry is required",
            invalidSelection: "Invalid industry selection",
            nameIsNotValid: "The name or description is not valid. 'Test' or 'test' are not accepted.",
            processCannotCreate: "process cannot be create by its user",
            processCreateLimite: "Creation limit reached – please upgrade to Premium to continue.",
            processNameAlreadyExist: "Process name already exist",
            processOverview: "Create a new process",
            selectIndustry: "Select Industry",
            selectLanguage: "Select interview language"
        }
    },

    features: {
        swimlane: {
            accessGranted: "Access granted",
            addRowUp: "Add row up",
            addRowDown: "Add row down",
            approveQuestion: "Are you sure you want to approve this diagram?",
            arrowsAttributes: "Arrows attributes",
            analysis: "Analysis",
            analyzeAllAttributesData: "Explores attribute data and provides a summary.",
            analyzeLead: "Analyze lead times and recommend optimization strategies.",
            bottleneckIdentification: "Bottlenecks",
            collaboratorAccessManagement: "Collaborator access management",
            ctrlMouseWheel: "Ctrl + MouseWheel",
            deleteRow: "Delete row",
            disableEditMode: "Disable Edit Mode",
            disaableMapEdit: "Disable Map Edit",
            disaableDataEdit: "Disable Data Edit",
            disApproveQuestion: "Êtes-vous sûr de vouloir désapprouver ce diagramme ?",
            doYouwantSaveave: "Do you want to save the changes made to the diagram before leaving?",
            dontLikeReport: "I don't like this report",
            editableAttrinbutesConfiguration: "Editable Attributes Configuration",
            enableEditMode: "Edit Map",
            enableMapEdit: "Enable Map Edit",
            enableDataEdit: "Enable Data Edit",
            enterYourPrompt: "Enter your prompt",
            exportPDF: "Export to PDF",
            exportPNG: "Export to PNG",
            exportCSV: "Export to CSV",
            exportXES: "Export to XES",
            examineDefect: "Examine defect rates and propose quality improvements.",
            famlaMake: "Famla may make mistakes. Check for important information.",
            feedbackPlaceholder: "Please tell us what we can improve...",
            fontSize: 'Font size',
            giveAccess: "Give access",
            helpful: "Rate this map",
            howCanHelpYouToday: "No reports available. Enter a prompt to generate a detailed analysis in just a few moments.",
            hideElements: "Hide Elements",
            hideLayers: "Hide Attributes",
            improvSentence: " We are partnering with industry leaders to support your unique improvement journey - Coming soon . ",
            identifyStakeholders: "Identify the constraint and suggest improvements.",
            likeReport: "I like this report",
            leadTimeAnalysis: "Lead Time Analysis",
            mapSaveWithSucess: "Map save with sucess",
            moveToBin: "Move to Bin",
            noAccess: "No access",
            noInformation: "No information found for this block. The reference may not have been found",
            oops: " Oops! Something Went Wrong",
            openInterview: " Open Interview",
            onboardingTutorial: "Onboarding Tutorial",
            pleaseWait: "The diagram is loading. It should be ready in just a few seconds",
            properties: "Toolbar",
            prompt1: "Please analyze all attributes data and provide a summary of key insights for each attribute, highlighting significant patterns and trends.",
            prompt2: "Analyze stakeholder data to identify those with the highest backlog and longest action times. Use irritants and improvement data to suggest targeted performance enhancements.",
            prompt3: "Review lead time attribute data, provide detailed analysis, and suggest improvement actions based on identified irritants and potential enhancements.",
            prompt4: "Analyze defect rate attribute data, provide insights on quality trends, and recommend improvement actions based on identified issues and enhancement opportunities.",
            qualityAnalysis: "Quality Analysis",
            removeAccess: "Remove access",
            redo: "Redo",
            reportAbug: "Report a bug",
            reportGenerate: " What analysis do you want to perform today?",
            shareYourFeedback: "Share Your Feedback",
            showLayers: "Show Attributes",
            saveFileAs: "Save file as",
            selectAll: "Select All",
            shapes: "Shapes (drag & drop)",
            // eslint-disable-next-line no-template-curly-in-string
            s1: "As a process owner, you've saved a whopping {{time}} minutes on interviews!",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "{{time}} interview minutes reclaimed! Famla empowers process owners like you.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "{{time}} minutes saved on interviews. Process owners, Famla streamlines your work.",
            stakeholder: 'Contributeur',
            sucessApprov: "Success! The diagram has been successfully approved.",
            sucessDisapprov: "Success! The diagram has been successfully rejected.",
            suggestions: "Suggestions",
            summaryStatistics: "Global overview",
            swimlaneNameIsNandatory: "swimlane name is mandatory",
            tasksAttributes: 'Tasks attributes',
            undo: "Undo ",
            versionHistory: "Version History",
            verifInfo: "Verify Map Info",
            WeSorry: "We were unable to display your swimlane diagram due to a technical issue, such as network connectivity problems, server errors, or incorrect data formatting. To return to the chatbot and allow the diagram to regenerate automatically, please click 'Refresh' and wait while the process completes.",
            yourChangesWereNotSaved: "Your changes were not saved. We kindly invite you to refresh the page and try again."
        }
    },

    navigation: {
        addAnotherEmail: "Add another email",
        allRightsReserved: "All rights reserved.",
        allFieldsRequired: "All fields marked with (*) are required.",
        anErrorOcured: "An error has occurred please try again later",
        anErrorOcuredProcessNotFund: "An error occurred. Process not found.",
        anErrorOccurredMappNotFound: "An error occurred. mappnot found",
        assigningProcessFolder: "Assigning a process to a folder",
        approvalCannotCreateByUser: "You cannot request approval as a collaborator.",
        backHome: "Back home",
        backToFamla: "Back To Famla",
        contactUs: "Contact Us",
        deleteConfirmation: "Delete Confirmation",
        diagramName: "Diagram name",
        diagramsPendingApproval: "Diagrams Pending Approval",
        enterFolderName: "Enter a folder name",
        enterProcessName: "Enter a process name",
        enterValideEmail: "Enter a valide e-mail",
        folderSuccessfully: "Folder successfully updated.",
        folderNotFound: "Error: Folder was not found",
        interviewNotOver: "Interview not over",
        inviteOwner: "Invite a process owner",
        inviteAApprover: "Invite a approver",
        inviteCollaborator: "Invite a collaborator",
        invitationSentSuccessfully: "Invitation sent successfully",
        logOut: 'Log out',
        markAsFinal: "Mark As Final",
        mapWithNotFound: "An error occurred: Map with not found.",
        noData: "No Data",
        noRequest: "No Requests to Approve",
        privacyHub: 'Privacy Hub',
        shareLink: "Share link",
        selectFolder: "Select folder",
        successfullyUpdated: "Successfully updated",
        transcript: "Download transcript",
        termsOfUse: "Terms Of Use",
        theProcessHasBeenHalted: "The process has been successfully halted.",
        time_remaining: "Time remaining: {{count}} day{{count, plural, one {} other {s}}}",
        timeSaved: "Time saved",
        userGuide: "User guide",
        oops: "Oops! The page not found",
        privacyPolicy: "Privacy Policy",
        process: "Process",
        processName: "Process name",
        processchange: "The process can only be modified once it has been completed.",
        processClassifiedWithSuccess: "Process classified with success",
        processCannot: "You are not authorized to update this process.",
        processNotFound: "The process could not be found.",
        proJectNotFound: "The project could not be found.",
        processSuccessfully: "Processus mis à jour avec succès.",
        publishQuestion: "Mark as final? This will make the Process read-only and prevent further editing.",
        roleAlreadyExist: "Role already exist",
        roleNotFound: "An error occurred. role not found",
        simplyLeverage: "Or simply leverage the expertise of our consultation team.",
        sendReminder: "Send reminder ",
        termsAndConditions: "Terms and conditions",
        youAreNotLoggedIn: "You are not logged in",
        youAreNotAuthorized: "You are not authorized to upgrade this Folder.",
        youAreNotAuthorizedToPerformThisAction: "You are not authorized to perform this action."
    }
    ,

    layout: {
        header: {
            approvalRequests: "Approval Requests",
            upgradeToPremium: " Upgrade to premium - {{daysRemaing}} day left",
            upgradeToPremiums: " Upgrade to premium - {{daysRemaing}} days left",
            upgradeToPrem: " Upgrade to premium",
            updateCompany: 'View company',
            company: 'Companies',
            changeCompany: "Change company",
            selectCompany: "Selecting a company will update your session and reload the application."
        }
    },

    views: {
        auth: {
            accountAlreadyExist: "Account already exist",
            alreadyHaveAccount: "Already have an account ?",
            anErrorOcured: "An error has occurred please try again later",
            amazingFeatures: "You're one click away from unlocking amazing features!",
            compagnyNameIsRequired: "Compagny name is required",
            continueWithApple: "Continue with Apple",
            continueWithGoogle: "Continue with Google",
            continueWithMicrosoft: "Continue with Microsoft",
            clicking: "Please verify your email by clicking the link in your inbox.",
            dontHaveAccount: "Don't have an account ?",
            enterValidMail: "Enter a valid work e-mail address",
            eliminate: "Eliminate Waste, Slash Costs and Maximise Profits with proven Lean Six Sigma methodologies.",
            emailAddres: "E-mail Address",
            emailAddresPro: "Work e-mail address",
            emailIsRequired: "E-mail is required",
            emailAddressIs: "E-mail address is incorrect or does not exist",
            establish: "Establish clear roles & responsibilities, remove ambiguity, optimize collaboration and drive team's success.",
            famlaTeam: "Famla Team ",
            forgotPassword: "Forgot your password?",
            getStarted: "Get Started",
            hi: "Hi",
            incorrectEmailOrPassword: "Invalid e-mail or password",
            logIn: "Log In",
            msgReset: "We will send you instructions for resetting your password upon entering your e-mail address",
            mailVerification: "Check your mailbox We've sent you a reset link.",
            newPassword: "New password",
            newPassWordIsRequired: "newPassword is required",
            password: "Password",
            passwordsNotMatch: "Passwords do not match",
            passwordIsRequired: "Password is required",
            passwordSuccessfully: "Password successfully reset",
            professionalEmail: "Please use a professional e-mail address.",
            pleaseFollowTheInstructions: "Please follow the instructions",
            resetYourPassword: 'Reset your password',
            reset: 'Reset',
            signUp: 'Sign Up',
            seamlessly: "Seamlessly document your processes and achieve ISO 9001:2015 compliance with confidence.",
            thisEmailHasBanned: "This e-mail address has been banned from Famla",
            userDoesNotExist: "User does not exist",
            verifyYourEmail: "Please verify your e-mail address",
            yourSessionHasExp: "Your session has expired or you are logged in on another device."

        },
        processView: {
            areYouSure: "Are you sure you want to delete this item?",
            defaultFolder: "Default folder",
            folder: "Folder",
            folderNotFound: "Folder not found",
            myProcess: 'My processes',
            newProcess: 'New process',
            newfolder: "New folder",
            needInspiration: "Need inspiration ? Explore these popular use cases",
            library: "Library",
            popularUseCase: "Popular use cases",
            processNotFound: "Process not found",
            viewAll: 'View all'
        },
        processInterview: {
            addCollaborators: "Add Collaborators",
            browse: 'Browse',
            cannotReplaceOwner: "You cannot replace the interview owner.",
            cannotDeleteMappedInterview: "You cannot delete an interview with existing mappings.",
            collaborators: "Collaborators",
            contributor: "Contributor",
            confirmEditPermissionGrant: "Are you sure you want to allow this employee to edit the process map?",
            confirmEditPermissionRevoke: "Are you sure you want to revoke this user's edit permissions?",
            comment: 'Comment',
            dropYourFileHere: "Drag and drop your file here, or",
            diagramsList: "Diagrams list",
            disableEditMode: "Disable Edit Mode",
            deleteDiagramme: "Are you sure you want to permanently delete this diagram? This action cannot be undone",
            deleteInterviewQuestion: "Are you sure you want to delete this interview? This action is irreversible.",
            enableEditMode: "Enable Edit Mode",
            grantEditPermission: "Granting publishing rights",
            interviewNotFound: "An error occurred. Interview not found.",
            mapReady: "Map ready in 10 minutes",
            memberAdded: "Member added successfully.",
            newProcess: "New process",
            requestLimit: "Request limit reached. Upgrade to Premium to continue without interruption.",
            pleaseEnsure: "Please ensure consistent spelling of stakeholders and clients in all communications.",
            processNotFound: "An error occurred. Process not found.",
            processUpdateRestricted: "You are not authorized to modify this process.",
            reminderMessage: "A reminder email will be sent to the invited collaborator.",
            reminderSent: "Reminder email sent successfully.",
            reply1: "End of the interview thanks for your contribution.",
            reply2: "End of the interview thanks for your contribution. We will reach out to the team and come back to you later",
            reply3: "I have completed the interviews, click on generate map to view the result",
            reply4: "Please can you list missing collaborator?",
            reply5: "Is there anything else you would like to add or any additional information you think would be valuable for us to consider?",
            recentProcesses: "Recent processes",
            sucessDelete: 'The interview has been successfully deleted.',
            // eslint-disable-next-line no-template-curly-in-string
            s1: "Your process map will be ready in 10 minutes! As a process owner, you've saved a whopping {{time}} minutes on interviews and drawing!",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "Your process map will be ready in 10 minutes! - {{time}} interview and drawing minutes reclaimed! Famla empowers process owners like you.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "Your process map will be ready in 10 minutes! - {{time}} minutes saved on interviews and drawing. Process owners, Famla streamlines your work.",
            tip: "Pro Tip: To uncover true improvement potential, document what actually happens, not the ideal or desired one.",
            validFille: "Invalid file: PDF required, max 5 MB.",
            validSize: "PDF,JPG,JPEG,PNG,GIF,WEBP (max.5MB)",
            yourResponse: "Response",
            youCannotDelete: "You cannot delete this interview because it has already been completed.",

        },

        processByGroupView: {
            approvalName: 'Approver name',
            approvalDate: 'Approval date',
            dateFormat: "Date format must be YYYY-MM-DD HH:MM:SS",
            diagramLink: "Diagram link",
            formatDate: "YYYY-MM-dd HH:mm:ss",
            interviewTime: "Interview Time saved",
            lastModifiedDate: "Last modified date",
            noProcess: "No process",
            nexRevisionDate: "Next revision date",
            ownerName: "Owner's name",
            processName: "Process name",
            processMetrics: "Process Metrics",
            revisionTriggers: "Revision Triggers",
            requestChange: "Request change"
        },

        legalDocs: {
            contact: {
                eitherFill: "You can fill in the form or use the contact details below.",
                gettingInTouch: "Getting in touch is easy!",
                sendMeCopy: "Send me a copy",
            },

            faq: {
                contactUsPage: 'contact Us page',
                p1: "Think of your processes as your organization's DNA – they're the blueprint for how things get done! Famla was created to help organizations of all sizes, industries, and locations understand, document, and streamline those essential workflows. So, what makes Famla the perfect fit for you? It's all about simplicity, speed, and a powerful AI improvement engine that helps you work smarter, not harder",
                p2: "This initial version focuses on cross-functional swimlane diagrams. These are perfect for visualizing how work flows across different teams or departments. We'll be adding more diagram types soon!",
                p3: "Yes! We support common notations like BPMN (Business Process Model and Notation) to ensure consistency and easy understanding.",
                p4: "Of course. Export your process maps as images (PNG, JPEG) or PDFs – perfect for reports, presentations, or getting feedback. You can also invite people to view the finished maps directly inside the tool for quick collaboration.",
                p5: "Absolutely! We built this tool with teamwork in mind. Easily invite team members to collaborate on maps in real-time, making process improvement a shared effort.",
                p6: " Not yet, but it's coming soon! We know how important it is to keep everything organized, so we're working on the ability to attach files, links, notes, and more directly within your maps.",
                p7: "Get ready for some process magic! Our AI engine analyzes your maps using Lean Six Sigma principles to pinpoint areas where you can streamline and improve.",
                p8: "Definitely. We take data security seriously. Our platform is hosted on Google Cloud, giving you top-notch security with encryption and all the latest safeguards.",
                p9: "After signing up, one of our team members will reach out to chat about your needs and the best subscription fit. In the meantime, dive right in, explore the tool and experience Famla's power firsthand!",
                p10: "Our friendly support team is here for you! Reach out anytime through our ",
                p11: "We're constantly working to improve Famla!  Let us know what features you'd love to see next.",
                t1: "Is Famla the perfect match for my business? ",
                t2: "What kind of process maps can I build with this tool?",
                t3: "Does this tool work with the process mapping standards I already know?",
                t4: "Can I easily share my finished maps with others?",
                t5: "Can my team and I work on a process map together?   ",
                t6: "Can I connect other information to my process maps?",
                t7: "How can this tool help me improve my processes? ",
                t8: "Is my process map data secure?",
                t9: "Do you offer a free subscription or a trial period?",
                t10: "How can I get help if I need it? ",
            },

            privacy: {
                famlaMay: "Famla may use your data to provide and improve the service, including to:",
                famlaWillTake: "Famla will take reasonable measures to protect Your Data from unauthorized access, use, or disclosure. These measures include:",
                p1: "At Famla AI, we are deeply committed to the privacy and security of your business's data. We understand the critical role data integrity plays in the successful deployment of AI solutions, and we have implemented robust measures to ensure its protection. This policy outlines the principles guiding our data collection, use, and security practices.",
                p2: "We may integrate with third-party services to enhance certain features of our AI solution. These third-parties will have access only to the data necessary to perform their specific functions on our behalf. We require strict privacy and security safeguards from all our partners.",
                p3: "As our AI solutions and privacy regulations evolve, we will revise this policy. You will be notified of any significant changes, and the updated policy will be published on our website.",
                p4: "If you have questions about our Privacy and Security Policy, please contact us at",
                privacyAndSecurityPolicy: "Privacy and Security Policy",
                t1: "Our Commitment",
                t2: "Information We Collect",
                t3: "How We Use Your Data",
                t4: "Security Measures",
                t5: "Protection of your rights",
                t6: "Third-Party Services",
                t7: "Policy Updates",
                ulLi1: "Data Required for operations: To provide our AI services, we will collect the minimum necessary data to train, improve, and deliver insights through our models. This data may include organogram, customer name, activities performance data.",
                ulLi2: "Usage Data: We may collect anonymous usage data to understand how our AI solution is used, allowing us to optimize its performance and tailor it to your business needs.",
                ulLi3: "Essential Account Information To manage your account and provide customer support, we may collect basic contact information such as your company name, address, and authorized user e-mail addresses.",
                ulLi4: "Service Improvement: We may use aggregated and anonymized usage data to understand and improve our AI solution for all our clients.",
                ulLi5: "Customer Support: We may use your account information to provide technical support, communicate with your authorized users, and respond to your inquiries.",
                ulLi6: "Tailored Marketing and Insights: We may analyze anonymized and aggregated data derived from your usage to provide more relevant industry insights, trends, and potentially advertisements related to our services or partner offerings.",
                ulLi7: "Encryption: We employ industry-standard encryption methods to protect your data both in transit and at rest.",
                ulLi8: "Secure Data Storage: Our AI solution is hosted on secure, professionally managed cloud infrastructure that undergoes regular security audits.",
                ulLi9: "Access Controls: Access to your data is strictly controlled, granted only to authorized personnel who are contractually bound by confidentiality agreements.",
                ulLi10: "Incident Response: We have a detailed incident response plan in place to address potential security breaches swiftly. We will notify you in accordance with any applicable regulations should a breach occur",
                ulLi11: "Data Transparency: We provide clear information about how your data is used within this policy and in any contractual agreements.",
                ulLi12: "Data Access: You have the right to access the data we collect and process on your business's behalf.",
                ulLi13: "Data Rectification: You have the right to request the correction of any inaccurate personal data held by us.",
                ulLi14: "Data Deletion: Upon termination of your service agreement, you can request the deletion or return of your data in accordance with applicable regulations.",
            },
            terms: {
                effectiveDate: "Effective Date",
                famlaMay: "Famla may use Your Data to provide and improve the Service, including to:",
                terms_and_condition: "Terms and Conditions for Famla AI",
                introduction: 'introduction',
                p1: "Welcome to Famla AI ('Famla,''we,' 'us,' or 'our'), an AI-powered enterprise solution designed to assist companies with process discovery and process documentation. These Terms and Conditions ('Terms') govern your organization's access to and use of Famla, including our website, software, and any related services (collectively, the 'Service'). Please read these Terms carefully before using the Service.",
                t2: "Acceptance of Terms",
                p2: "By accessing or using the Service, you, on behalf of your organization ('you' or 'your'), agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Service.  ",
                p3: "To access and use the Service, you must register for an account and provide Famla with accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to immediately notify us of any unauthorized use of your account or any other breach of security.  ",
                p4: "Subject to your compliance with these Terms, Famla grants you a non-exclusive, non-transferable, limited license to access and use the Service for your internal business purposes.",
                p5: "The Service and its original content, features, and functionality are and will remain the exclusive property of Famla and its licensors. The Service is protected by copyright, trademark, and other laws of both The Republic of Ireland and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Famla.  ",
                p6: "You retain ownership of all data, information, and materials that you input, upload, or submit to the Service ('Your Data'). This includes, but is not limited to, organogram details, customer names, and activities performance data.  ",
                p7: "We may integrate with third-party services to enhance certain features of our AI solution. These third parties will have access only to the data necessary to perform their specific functions on our behalf and are required to maintain strict privacy and security safeguards.",
                p8: "The Service is provided ' as is' and 'as available' without any warranty of any kind, express or implied. Famla disclaims all warranties, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Famla does not warrant that the Service will be uninterrupted or error-free, that defects will be corrected, or that the Service or the server that makes it available are free of viruses or other harmful components. [cite: 1, 2, 3] ",
                p9: "In no event shall Famla, its affiliates, directors, officers, employees, agents, suppliers, or licensors be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or relating to your use of or inability to use the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, even if Famla has been advised of the possibility of such damages.",
                p10: "You agree to defend, indemnify, and hold harmless Famla, its affiliates, directors, officers, employees, agents, suppliers, and licensors from and against any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your breach of these Terms or your use of the Service, including, but not limited to, your use of the Service's content, services, and products other than as expressly authorized in these Terms, or your use of any information obtained from the Service. ",
                p11: "This Agreement shall remain in effect until terminated as set forth herein. Either party may terminate this Agreement for any reason upon [number] days’ written notice to the other party. Famla may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. ",
                p12: "These Terms shall be governed and construed in accordance with the laws of The Republic of Ireland, without regard to its conflict of law provisions",
                p13: "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 5 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. ",
                p14: "These Terms constitute the entire agreement between you and Famla relating to the Service and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and Famla. ",
                p15: "If you have any questions about these Terms, please contact us at contact@famla.ai. ",
                t3: "Use of the Service",
                t4: "Account Registration",
                t5: "License Grant",
                t6: "Restrictions",
                t7: "Intellectual Property",
                t8: "Data",
                t9: 'Data Ownership',
                t10: "Data Use",
                t11: "Data Security",
                t12: " Data Transparency and Access",
                t13: "Third-Party Services",
                t14: "Disclaimer",
                t15: "Limitation of Liability",
                t16: "Indemnification",
                t17: "Termination",
                t18: "Governing Law",
                t19: "Changes to Terms",
                t20: "Entire Agreement",
                ulLi1: "License, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share, or otherwise commercially exploit or make the Service available to any third party, other than authorized users within your organization. ",
                ulLi2: "Modify, adapt, or hack the Service or otherwise attempt to gain unauthorized access to the Service or its related systems or networks.  ",
                ulLi3: "Use the Service in any way that violates any applicable federal, state, local, or international law or regulation.",
                ulLi4: "Use the Service to process any sensitive personal information without obtaining explicit consent and implementing appropriate safeguards.  ",
                ulLi5: "Use the Service to transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation.",
                ulLi6: "Use the Service to impersonate or attempt to impersonate Famla, a Famla employee, another user, or any other person or entity.",
                ulLi7: "Interfere with or disrupt the integrity or performance of the Service or its related systems or networks.",
                ulLi8: "Attempt to decipher, decompile, reverse engineer, or otherwise discover any source code or underlying ideas or algorithms of the Service.  ",
                ulLi9: "Process and analyze Your Data to generate process maps and documentation.",
                ulLi10: "Develop and improve AI models and algorithms.",
                ulLi11: "Aggregate and anonymize Your Data for research and development purposes.",
                ulLi12: "Provide technical support, communicate with your authorized users, and respond to your inquiries.",
                ulLi13: "Analyze anonymized and aggregated data derived from your usage to provide more relevant industry insights, trends, and potentially advertisements related to our services or partner offerings.  ",
                ulLi14: "Encryption of Your Data both in transit and at rest.",
                ulLi15: "Secure storage of Your Data on professionally managed cloud infrastructure that undergoes regular security audits.",
                ulLi16: "Strict access controls to Your Data, granted only to authorized personnel who are contractually bound by confidentiality agreements.",
                ulLi17: "Un plan d'intervention détaillé en cas d'incident est en place afin de remédier rapidement à d'éventuelles violations de la sécurité.  ",
                ulLi18: "Famla provides clear information about how Your Data is used within this policy and in any contractual agreements.",
                ulLi19: "You have the right to access the data we collect and process on your business's behalf.",
                ulLi20: "You have the right to request the correction of any inaccurate personal data held by us.",
                ulLi21: "Upon termination of your service agreement, you can request the deletion or return of your data following applicable regulations. ",
                youAgreeNotTo: "You agree not to:"
            },

        }
    },

    words: {
        add: "Add",
        administrator: "Administrator",
        address: "Address",
        alignment: "Alignment",
        answer: "Answer",
        approve: "Approve",
        attributes: "Attributes",
        bold: "Bold",
        bottom: "Bottom",
        cancel: "Cancel",
        capitalize: "Capitalize",
        city: "City",
        close: "Close",
        comments: "Comment",
        center: "Center",
        country: "Country",
        create: "Create",
        copy: "Copy",
        revoke: "Revoke",
        decision: "Decision",
        delete: "Delete",
        demote: "Demote",
        description: "Description",
        disApprove: "Reject",
        edit: "Edit",
        edition: "Edit",
        email: "E-mail",
        english: "English",
        end: "End",
        export: "Export",
        error: "Error",
        faq: "FAQ",
        file: "File",
        filter: "Filter",
        finish: "Finish",
        french: "French",
        from: "De",
        grant: "Grant",
        help: "Help",
        hello: "Hello",
        hide: "hide",
        information: "Information",
        improve: "Improve",
        invite: "Invite",
        interview: "Interview",
        italic: "Italic",
        languages: "Languages",
        left: "Left",
        link: "Link",
        light: "Light",
        line: "Line",
        lowercase: "Lowercase",
        loading: "Loading",
        map: "Map",
        me: "Me",
        message: "Describe your request here",
        name: "Name",
        new: "New",
        no: "No",
        normal: "Normal",
        or: "OR",
        organize: "Move Folder",
        owner: "Owner",
        past: "Paste",
        promote: "Promote",
        process: "Processes",
        proces: "Task",
        publish: "Publish",
        question: "Question",
        refresh: "Refresh",
        rename: "Rename",
        reminder: 'Reminder',
        requestApproval: "Request approval",
        replace: "Replace",
        right: "Right",
        role: "Role",
        status: "Status",
        start: "Start",
        save: "Save",
        search: "Search",
        searching: "Searching",
        send: "Send",
        share: "Share",
        subject: "Subject",
        text: "Text",
        to: "To",
        top: "Top",
        token: "Token",
        uppercase: "Uppercase",
        view: "View",
        thank: "Thank",
        yes: "Yes"
    },


    layer: {
        connectYourProcessMaps: "Connect your process maps",
        hiddenProperties: "Hidden Properties",
        noHiddenpropertiesAvailable: "No hidden properties available",
        layerSettings: "Attribute settings",
        selectProcess: "Select a process",
        selectMap: "Select a map",
        searchProcesses: "Search processes",
        block: {
            leadtime: "Lead time",
            defectrate: "Defect rate",
            itsystem: "IT System",
            cost: "Cost",
            carbonfootprint: "Carbon footprint",
            headcount: "Headcount",
            irritantsandimprovements: "Irritants and improvements",
            risksandcontrols: "Risks and controls",
            relateddocuments: "Related documents",
            failuremode: "Failure mode",
            link: "Link",
        },
        line: {
            inventory: "Inventory",
            transfertime: "Transfer time",
            input: "Input",
            output: "Output",
        }
    }

}

export default en