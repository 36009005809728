import { SwimlaneData } from '../types';

export function editHeader(
  element: HTMLElement,
  index: number | 'main',
  data: SwimlaneData,
  onSave: (data: SwimlaneData) => void
) {
  const currentText = element.textContent || '';
  const input = document.createElement('input');
  input.value = currentText;
  input.style.width = '100%';
  input.style.height = '170px';
  input.maxLength = 15;
  input.style.border = 'transparent';
  input.style.padding = '0 5px';
  input.style.background = 'white';
  input.style.textAlign = 'center';
  input.style.writingMode = 'vertical-rl';

  // Appliquer la rotation uniquement pour les titres des lanes (pas pour le titre principal)
  if (typeof index !== 'string') {
    // input.style.writingMode = 'vertical-rl';
    input.style.transform = 'rotate(270deg)';
    input.style.transformOrigin = 'center center';
  }

  element.textContent = '';
  element.appendChild(input);
  input.focus();

  const saveEdit = () => {
    const newText = input.value.trim();
    if (index === 'main') {
      data.header.text = newText;
    } else {
      data.subHeaderRows.headers[index] = newText;
    }
    element.textContent = newText;
    onSave(data);
  };

  input.addEventListener('blur', saveEdit);
  input.addEventListener('keypress', (e) => {
    if (e.key === 'Enter') {
      saveEdit();
    }
  });
}