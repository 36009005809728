import * as d3 from 'd3';
import { Line, Layer } from '../../../../types';
import { slice } from '../../../../../../utils';

const LAYER_TYPE_STYLES = {
  text: {
    bg: '#f8fafc',
    border: '#e2e8f0',
    color: '#374151'
  },
  link: {
    bg: '#f5f3ff',
    border: '#ddd6fe',
    color: '#5b21b6'
  },
  number: {
    bg: '#f0fdf4',
    border: '#dcfce7',
    color: '#166534'
  },
  date: {
    bg: '#fff7ed',
    border: '#ffedd5',
    color: '#c2410c'
  },
  default: {
    bg: '#f8fafc',
    border: '#e2e8f0',
    color: '#374151'
  }
};

export class LayerContentRenderer {
  private editMode: boolean;
  private t: (key: string) => string;
  private attributesDataEditable: any
  private onUpdateLayer?: (line: Line, layer: Layer, value: string) => void;

  constructor(
    editMode: boolean,
    t: (key: string) => string,
    attributesDataEditable: any,
    onUpdateLayer?: (line: Line, layer: Layer, value: string) => void
  ) {
    this.editMode = editMode;
    this.t = t;
    this.attributesDataEditable = attributesDataEditable
    this.onUpdateLayer = onUpdateLayer;
  }

  public renderLayers(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    line: Line,
    onHideLayer: (layer: Layer) => void
  ) {
    const layersContainer = container
      .append('div')
      .style('max-height', '150px')
      .style('overflow-y', 'auto')
      .style('overflow-x', 'hidden')
      .style('padding', '1px');

    line.layers?.forEach((layer: Layer, index: number) => {
      const layerDiv = this.createLayerDiv(layersContainer, index, layer.type);
      this.renderLayerContent(layerDiv, layer, line, onHideLayer);
    });
  }

  private createLayerDiv(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    index: number,
    type: string = 'default'
  ) {
    const style = LAYER_TYPE_STYLES[type as keyof typeof LAYER_TYPE_STYLES] || LAYER_TYPE_STYLES.default;

    return container
      .append('div')
      .style('padding', '1px 3px')
      .style('margin', '1px')
      .style('font-size', '11px')
      .style('border-radius', '3px')
      .style('background-color', style.bg)
      .style('border', `1px solid ${style.border}`)
      .style('display', 'flex')
      .style('justify-content', 'space-between')
      .style('align-items', 'center')
      .style('transition', 'all 0.15s ease')
      .style('cursor', 'default')
      .style('width', '120px')
      .on('mouseenter', function () {
        d3.select(this)
          .style('border-color', style.color)
          .style('background-color', `${style.bg}ee`);
      })
      .on('mouseleave', function () {
        d3.select(this)
          .style('border-color', style.border)
          .style('background-color', style.bg);
      });
  }

  private renderLayerContent(
    layerDiv: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    layer: Layer,
    line: Line,
    onHideLayer: (layer: Layer) => void
  ) {
    const contentContainer = layerDiv
      .append('div')
      .style('flex', '1')
      .style('min-width', '0')
      .style('margin-right', '1px')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('gap', '1px');

    // Layer text container with class for easy selection
    const textContainer = contentContainer
      .append('div')
      .attr('class', 'layer-text')
      .style('display', layer.value ? 'none' : 'block')
      .style('font-weight', '500')
      .style('color', '#1e293b')
      .style('white-space', 'nowrap')
      .style('overflow', 'hidden')
      .style('text-overflow', 'ellipsis')
      .style('max-width', '120px')
      .attr('title', this.t(`layer.line.${layer.text.toLowerCase().replace(/\s+/g, '')}`))
      .text(slice(this.t(`layer.line.${layer.text.toLowerCase().replace(/\s+/g, '')}`), 24)); // Increased max length for text when no value

    // Layer value container
    const valueContainer = contentContainer
      .append('div')
      .attr('class', 'layer-value')
      .style('flex', '1')
      .style('overflow', 'hidden');

    this.renderLayerValue(valueContainer, layer);

    if (this.editMode) {
      const actionsContainer = layerDiv
        .append('div')
        .style('display', 'flex')
        .style('gap', '0px')
        .style('margin-left', '1px');
      // Fixed condition with proper null checks
      const isEditable = this.attributesDataEditable?.line_layers?.find((item: any) =>
        item?.id === layer.id
      )?.edit ?? true;

      // Simplified condition that's more null-safe
      const canEdit = isEditable || !!this.attributesDataEditable?.owner;

      if (canEdit) {
        const editButton = this.createEditButton(actionsContainer);
        editButton.on('click', (event) => {
          event.stopPropagation();
          this.startLayerEditing(valueContainer, layer, line, textContainer);
        });
      }


      // Hide button
      const hideButton = this.createHideButton(actionsContainer);
      hideButton.on('click', (event) => {
        event.stopPropagation();
        onHideLayer(layer);
      });

      // Show actions on hover
      layerDiv
        .on('mouseenter', () => actionsContainer.selectAll('button').style('opacity', '1'))
        .on('mouseleave', () => actionsContainer.selectAll('button').style('opacity', '0'));
    }
  }

  private renderLayerValue(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    layer: Layer
  ) {
    container.selectAll('span').remove();

    const valueSpan = container
      .append('span')
      .style('color', '#64748b')
      .style('font-size', '11px')
      .style('white-space', 'nowrap')
      .style('overflow', 'hidden')
      .style('text-overflow', 'ellipsis')
      .style('max-width', layer.value ? '120px' : '60px'); // Full width when value is alone

    if (layer.type === 'link' && layer.value) {
      valueSpan
        .append('a')
        .attr('href', layer.value as string)
        .attr('target', '_blank')
        .attr('rel', 'noopener noreferrer')
        .style('color', '#4f46e5')
        .style('text-decoration', 'none')
        .text('Link')
        .attr('title', 'Open link');
    } else if (layer.value) {
      const value = layer.value.toString();
      valueSpan
        .attr('title', value)
        .text(value); // No truncation when value is alone
    }

    return valueSpan;
  }

  private createEditButton(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>
  ) {
    const button = container
      .append('button')
      .style('padding', '1px')
      .style('background', 'none')
      .style('border', 'none')
      .style('cursor', 'pointer')
      .style('color', '#6366f1')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('opacity', '0')
      .style('transition', 'all 0.15s ease')
      .style('border-radius', '2px')
      .attr('title', this.t('words.edit'));

    button
      .on('mouseenter', function () {
        d3.select(this).style('background', '#eef2ff');
      })
      .on('mouseleave', function () {
        d3.select(this).style('background', 'none');
      });

    button
      .append('svg')
      .attr('width', '8')
      .attr('height', '8')
      .attr('viewBox', '0 0 24 24')
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .attr('stroke-width', '2')
      .html('<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>');

    return button;
  }

  private createHideButton(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>
  ) {
    const button = container
      .append('button')
      .style('padding', '1px')
      .style('background', 'none')
      .style('border', 'none')
      .style('cursor', 'pointer')
      .style('color', '#94a3b8')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('opacity', '0')
      .style('transition', 'all 0.15s ease')
      .style('border-radius', '2px')
      .attr('title', this.t('words.hide'));

    button
      .on('mouseenter', function () {
        d3.select(this)
          .style('background', '#fee2e2')
          .style('color', '#ef4444');
      })
      .on('mouseleave', function () {
        d3.select(this)
          .style('background', 'none')
          .style('color', '#94a3b8');
      });

    button
      .append('svg')
      .attr('width', '8')
      .attr('height', '8')
      .attr('viewBox', '0 0 24 24')
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .attr('stroke-width', '2')
      .html('<path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"/><line x1="1" y1="1" x2="23" y2="23"/>');

    return button;
  }

  private startLayerEditing(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    layer: Layer,
    line: Line,
    textContainer: d3.Selection<HTMLDivElement, unknown, null, undefined>
  ) {
    const editingClass = `editing-${Date.now()}`;
    container.select('span').style('display', 'none');

    const input = container
      .append('input')
      .attr('class', editingClass)
      .attr('type', 'text')
      .attr('value', layer.value || '')
      .style('width', '120px')
      .style('padding', '0px 2px')
      .style('border', '1px solid #e2e8f0')
      .style('border-radius', '2px')
      .style('outline', 'none')
      .style('font-size', '11px')
      .style('background', '#ffffff')
      .style('color', '#1e293b')
      .style('transition', 'all 0.15s ease');

    input
      .on('focus', function () {
        d3.select(this).style('border-color', '#6366f1');
      })
      .on('blur', function () {
        d3.select(this).style('border-color', '#e2e8f0');
      });

    const cleanup = () => {
      const newValue = input.property('value');
      const inputElement = container.select(`.${editingClass}`);
      if (!inputElement.empty()) {
        inputElement.remove();
      }

      layer.value = newValue;

      // Update text container visibility based on new value
      textContainer.style('display', newValue ? 'none' : 'block');

      this.renderLayerValue(container, layer);

      if (this.onUpdateLayer) {
        this.onUpdateLayer(line, layer, newValue);
      }
    };

    let blurTimeout: number;
    input.on('blur', () => {
      if (blurTimeout) {
        window.clearTimeout(blurTimeout);
      }
      blurTimeout = window.setTimeout(cleanup, 50);
    });

    input.on('keypress', (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (blurTimeout) {
          window.clearTimeout(blurTimeout);
        }
        cleanup();
      }
    });

    requestAnimationFrame(() => {
      const inputNode = input.node();
      if (inputNode) {
        inputNode.focus();
        inputNode.select();
      }
    });
  }

  private truncateText(text: string, maxLength: number): string {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }
}