import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../stores";
import { FamlaServices } from '../../services';
import { iAlert } from '../../interfaces';
import { InputForm, Spiner } from '../../components';

interface ChildProps {
    processId: string;
    closeRef: any;
    interviewwId: string | undefined;
    invited: string | undefined;
}

const ReplaceModal = ({ processId, closeRef, interviewwId, invited }: ChildProps) => {
    const { t } = useTranslation();
    const users = useAppSelector(state => state.auth);
    const [email, setEmail] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [alert, setAlert] = useState<iAlert>({ isActive: false, type: '', message: '' });
    const [role, setRole] = useState(invited || "");

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(event.target.value);
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsSubmit(true);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim() || !emailRegex.test(email)) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.enterValideEmail') });
            setIsSubmit(false);
            return;
        }

        let newFormData = {
            mail: email,
            role: role || invited
        };

        let data = await FamlaServices("api_host", `/interview/add_member/${interviewwId}`, "PUT", newFormData, users.access_token);

        if (data.status === 200) {
            setAlert({ isActive: true, type: 'success', message: t('views.processInterview.memberAdded') });
            setEmail('');
            setRole("");
            window.location.reload()
        } else if (data.body.status_cose === 400) {
            setAlert({ isActive: true, type: 'danger', message: t("views.processInterview.cannotReplaceOwner") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        } else if (data.body.status_cose === 401) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.youAreNotLoggedIn") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        } else if (data.body.status_cose === 404 && data.body.response_type === "interview not found") {
            setAlert({ isActive: true, type: 'danger', message: t("views.processInterview.interviewNotFound") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        } else if (data.body.status_cose === 404 && data.body.response_type === "interview not found") {
            setAlert({ isActive: true, type: 'danger', message: t("views.processInterview.interviewNotFound") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        } else if (data.body.status_cose === 404 && data.body.response_type === "processus not found") {
            setAlert({ isActive: true, type: 'danger', message: t("views.processInterview.processNotFound") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        } else if (data.body.status_cose === 400 && data.body.description === "process cannot be update by its user") {
            setAlert({ isActive: true, type: 'danger', message: t("views.processInterview.processUpdateRestricted") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        } else if (data.body.status_cose === 400 && data.body.description === "you cannot delete interview with existed mapp") {
            setAlert({ isActive: true, type: 'danger', message: t("views.processInterview.cannotDeleteMappedInterview") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOccurred") });
            setTimeout(() => {
                setAlert({ isActive: false, type: '', message: "" });
            }, 5000);
        }
        setIsSubmit(false);
    };

    return (
        <div className="modal" data-bs-backdrop="static" id='replaceModal' tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header border-0">
                        <h4 className="modal-title text-white fs-5">{t('words.replace')}</h4>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {alert.isActive && <div className={`alert alert-${alert.type}`} role="alert">{alert.message}</div>}

                        <InputForm
                            inputType="email"
                            inputLabel={t('words.email')}
                            inputName="email"
                            inputValue={email}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={handleEmailChange}
                            inputClass="bg-dark text-light mb-2"
                        />

                        <InputForm
                            inputType="text"
                            inputLabel={t('words.role')}
                            inputName="role"
                            inputValue={role || invited}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={handleRoleChange}
                            inputClass="bg-dark text-light"
                        />
                    </div>
                    <div className="modal-footer">
                        <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">
                            {isSubmit ? <Spiner className='spinner-border-sm' /> : <>{t('words.replace')}</>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReplaceModal;
