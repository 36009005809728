import React, { RefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../features/swimLane";
import { InputForm, Alert, Spiner } from "../../components";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes";

interface ConfirmationModalProps {
    id: string;
    title: string;
    message?: string;
    buttonLabel: string;
    inputLabel?: string;
    inputName: string;
    inputValue?: string;
    inputRef?: RefObject<HTMLInputElement>;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAction: () => void;
    submit: boolean;
    alert: { msg: string; type: string };
    closeModalRef: RefObject<HTMLSpanElement>;
    showInput?: boolean;
    isDisabled?: boolean;
    route?: string;
    processId?: string;
    mapId?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    id,
    title,
    message,
    buttonLabel,
    inputLabel,
    inputName,
    inputValue,
    inputRef,
    handleChange,
    handleAction,
    submit,
    alert,
    closeModalRef,
    showInput = true,
    isDisabled,
    route,
    processId,
    mapId,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1);

    };
    const handleGoToHome = () => {
        navigate(Routes.HOMEVIEW);

    };
    return (
        <div className="modal" tabIndex={-1} id={id}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mt-0 pt-0 fs-5">{title}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={route ? route === 'chatBot' ? handleGoBack : route === 'home' ? handleGoToHome : () => { } : () => { }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {message && <p>{message}</p>}
                        {showInput && (
                            <InputForm
                                inputType="text"
                                inputLabel={inputLabel}
                                inputName={inputName}
                                inputValue={inputValue}
                                isFloatingLabel={true}
                                labelClass="text-theme"
                                onChange={handleChange}
                                inputContainerClass={'mb-3 mt-1'}
                                inputClass="bg-dark text-light text-bg-dark"
                                activeFocusRef={inputRef}
                            />
                        )}
                        {alert?.msg?.length !== 0 && <Alert msg={alert?.msg} type={alert?.type} />}
                    </div>
                    <div className="modal-footer">
                        <span className="btn btn-scondary" ref={closeModalRef} data-bs-dismiss="modal">
                            <Button
                                className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark"
                                name={t('words.close')}
                                onClick={route ? route === 'chatBot' ? handleGoBack : route === 'home' ? handleGoToHome : () => { } : () => { }}
                            />
                        </span>

                        {/* <Button
                            key={6}
                            className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark "
                            name={buttonLabel}
                            onClick={handleAction}
                            submit={submit}
                            isDisabled={isDisabled && isDisabled}
                        /> */}

                        <div className="me-2">
                            <button
                                disabled={isDisabled}
                                className={`${'dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark'} ${isDisabled ? "opacity-50 disabled " : ""}`}
                                onClick={handleAction}
                                style={isDisabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                            >
                                {submit ?
                                    <Spiner className="spinner-border-sm text-white" />
                                    :
                                    <>{buttonLabel}</>
                                }
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
