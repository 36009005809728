import * as d3 from 'd3';
import { Line } from '../../types';
import { STYLES } from '../../styles/constants';

export class LineTextManager {
  private container: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private editMode: boolean;
  private onUpdate: (line: Line) => void;

  constructor(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    editMode: { enabled: boolean },
    onUpdate: (line: Line) => void
  ) {
    this.container = container;
    this.editMode = editMode.enabled;
    this.onUpdate = onUpdate;
  }

  public renderLineText(
    line: Line,
    points: { x: number; y: number }[],
    visiblePath: d3.Selection<SVGPathElement, unknown, null, undefined>
  ) {
    // Remove existing text container if it exists
    this.container.selectAll(`.line-text-${line.id}`).remove();

    const midIndex = Math.floor((points.length - 1) / 2);
    const segmentStart = points[midIndex];
    const segmentEnd = points[midIndex + 1];
    const midPoint = {
      x: (segmentStart.x + segmentEnd.x) / 2,
      y: (segmentStart.y + segmentEnd.y) / 2
    };
    const prevPoint = points[midIndex - 1];
    const nextPoint = points[midIndex + 1];



    const textContainer = this.container
      .append('div')
      .attr('class', `line-text line-text-${line.id}`)
      .style('position', 'absolute')
      .style('left', `${midPoint.x}px`)
      .style('top', `${midPoint.y}px`)
      .style('transform', 'translate(-50%, -50%)')
      .style('z-index', '2')
      .style('min-width', '20px')
      .style('min-height', '20px')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'center')
      .style('pointer-events', this.editMode ? 'auto' : 'none');

    // Calculate rotation based on line direction
    const dx = Math.abs((nextPoint?.x || 0) - (prevPoint?.x || 0));
    const dy = Math.abs((nextPoint?.y || 0) - (prevPoint?.y || 0));
    const isHorizontal = dx > dy;

    const transform = isHorizontal
      ? 'translate(-50%, -50%)'
      : 'translate(-50%, -50%)';

    textContainer.style('transform', transform);

    if (line.text) {
      textContainer
        .append('span')
        .style('background-color', 'white')
        .style('padding', '2px 4px')
        .style('border-radius', '2px')
        .style('font-size', '12px')
        .style('color', STYLES.colors.text)
        .style('white-space', 'nowrap')
        .style('text-align', 'center')
        .text(line.text);
    }

    // Only attach edit event if in edit mode
    if (this.editMode) {
      textContainer.on('click', (event: MouseEvent) => {
        event.stopPropagation();
        this.startEditing(textContainer, line, points, isHorizontal);
      });
    }
  }

  private startEditing(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    line: Line,
    points: { x: number; y: number }[],
    isHorizontal: boolean
  ) {
    const currentTransform = container.style('transform');
    container.selectAll('*').remove();

    const input = container
      .append('input')
      .attr('type', 'text')
      .attr('value', line.text || '')
      .style('width', '100px')
      .style('padding', '2px 4px')
      .style('border', '1px solid #e5e7eb')
      .style('border-radius', '2px')
      .style('outline', 'none')
      .style('font-size', '12px')
      .style('background', 'white')
      .style('text-align', 'center');

    const cleanup = () => {
      const newText = input.property('value');
      input.remove();
      line.text = newText;

      if (newText) {
        container
          .append('span')
          .style('background-color', 'white')
          .style('padding', '2px 4px')
          .style('border-radius', '2px')
          .style('font-size', '12px')
          .style('color', STYLES.colors.text)
          .style('white-space', 'nowrap')
          .style('text-align', 'center')
          .text(newText);
      }

      container.style('transform', currentTransform);
      this.onUpdate(line);

      // Suppression du gestionnaire d'événements après le nettoyage
      document.removeEventListener('mousedown', handleOutsideClick);
    };

    const handleCleanup = (event?: KeyboardEvent) => {
      if (!event || event.key === 'Enter') {
        if (event) event.preventDefault();
        input.on('blur', null).on('keypress', null);
        cleanup();
      }
    };

    // Fonction pour détecter les clics en dehors de l'input
    const handleOutsideClick = (event: MouseEvent) => {
      if (input.node() && !input.node()?.contains(event.target as Node)) {
        cleanup();
      }
    };

    // Écouteur de clic global
    document.addEventListener('mousedown', handleOutsideClick);

    input.on('blur', handleCleanup).on('keypress', handleCleanup);

    requestAnimationFrame(() => {
      const inputNode = input.node();
      if (inputNode) {
        inputNode.focus();
        inputNode.select();
      }
    });
  }

}