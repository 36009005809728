import * as d3 from 'd3';
import { Block, Position, EditMode, Layer } from '../../types';
import { STYLES } from '../../styles/constants';
import { implementDrag } from '../utils/dragUtils';
import { setupTextEditing } from '../../utils/textEditor';
import { getBlockDimensions, getBlockFill } from '../../utils/blockUtils';
import { createLayerTypeMenu, syncLayersAcrossBlocks, handleLinkLayerEdit } from '../../utils/layerUtils';
import { slice } from '../../../../utils';

export function createStartEndBlock(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  block: Block,
  editMode: EditMode,
  onDragEnd: (id: string, position: Position) => void,
  onTextUpdate: (id: string, newText: string) => void,
  allBlocks: Block[] = [],
  t: (key: string) => string,
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>,
  attributesDataEditable: any
) {
  const dimensions = getBlockDimensions(block);
  const fill = getBlockFill(block);
  const borderRadius = STYLES.startEnd.borderRadius;

  const blockContainer = container
    .append('div')
    .attr('class', `${block.type}-block-container-${block.id}`)
    .style('position', 'absolute')
    .style('left', `${block.x || 0}px`)
    .style('top', `${block.y || 0}px`)
    .style('z-index', '2');

  const blockk = blockContainer
    .append('div')
    .attr('class', `${block.type}-block-${block.id}`)
    .style('width', `${dimensions.width}px`)
    .style('height', `${dimensions.height}px`)
    .style('background-color', fill)
    .style('border', `1px solid ${STYLES.colors.border}`)
    .style('border-radius', '30px')
    .style('cursor', editMode.enabled ? 'move' : 'default')
    .style('user-select', 'none')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('justify-content', 'center');

  const textElement = blockk
    .append('div')
    .style('width', '100%')
    .style('height', '100%')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('justify-content', 'center')
    .style('padding', block.textStyle?.padding ? `${block.textStyle.padding}px` : '10px')
    .style('text-align', block.textStyle?.textAlign || 'center')
    .style('font-size', block.textStyle?.fontSize ? `${block.textStyle.fontSize}px` : '14px')
    .style('font-weight', block.textStyle?.fontWeight || 'normal')
    .style('font-family', block.textStyle?.fontFamily || 'inherit')
    .style('font-style', block.textStyle?.fontStyle || 'normal')
    .style('color', block.textStyle?.textColor || STYLES.colors.getBlockColors('start').text)
    .style('line-height', block.textStyle?.lineHeight || 'normal')
    .style('text-transform', block.textStyle?.textTransform || 'none')
    .style('text-decoration', block.textStyle?.textDecoration || 'none')
    .text(slice(block.text, 40))
    .node();

  if (block.layers && block.layers.length > 0) {
    const layersContainer = blockContainer
      .append('div')
      .attr('class', `${block.type}-layers-${block.id}`);

    const layersDiv = layersContainer
      .append('div')
      .attr('class', 'absolute bg-white border border-gray-200 rounded shadow-sm')
      .style('left', '0px')
      .style('top', `${dimensions.height + 10}px`)
      .style('width', `${dimensions.width}px`)
      .style('z-index', '5')
      .style('display', 'none');

    const layersWithValues = new Set<string>();
    allBlocks.forEach(block => {
      block.layers?.forEach(layer => {
        layersWithValues.add(layer.text);
      });
    });

    if (editMode.enabled) {
      const addButton = layersDiv
        .append('div')
        .style('padding', '8px')
        .style('display', 'flex')
        .style('justify-content', 'center')
        .style('border-bottom', '1px solid #f0f0f0');

      const button = addButton
        .append('button')
        .style('background-color', '#f0f9ff')
        .style('color', '#0284c7')
        .style('padding', '4px 12px')
        .style('border-radius', '4px')
        .style('border', 'none')
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('gap', '4px')
        .style('cursor', 'pointer')
        .style('font-size', '12px')
        .style('transition', 'all 0.2s')
        .on('mouseenter', function () {
          d3.select(this).style('background-color', '#e0f2fe');
        })
        .on('mouseleave', function () {
          d3.select(this).style('background-color', '#f0f9ff');
        })
        .on('click', (event) => {
          event.stopPropagation();
          createLayerTypeMenu(layersDiv, block, (newLayer: Layer) => {
            const updatedBlocks = syncLayersAcrossBlocks(allBlocks, block.id, newLayer);
            container.selectAll('.start-block-container, .end-block-container, .process-block-container, .decision-block-container').remove();
            updatedBlocks.forEach(block => {
              if (block.type === 'start' || block.type === 'end') {
                createStartEndBlock(container, block, editMode, onDragEnd, onTextUpdate, updatedBlocks, t, setBlocks, attributesDataEditable);
              }
            });
          }, t);
        });

      button
        .append('span')
        .html('&#43;');

      button
        .append('span');

      block.layers.forEach((layer, index) => {
        if (layersWithValues.has(layer.text)) {
          const layerDiv = layersDiv
            .append('div')
            .attr('class', `layer-item layer-${layer.text.toLowerCase().replace(/\s+/g, '-')}`)
            .attr('data-layer-name', layer.text)
            .style('padding', '4px 8px')
            .style('font-size', '12px')
            .style('border-bottom', '1px solid #f0f0f0')
            .style('position', 'relative')
            .style('transition', 'all 0.2s')
            .style('background-color', index % 2 === 0 ? '#f9fafb' : '#ffffff');

          const layerContent = layerDiv
            .append('div')
            .style('display', 'flex')
            .style('justify-content', 'space-between')
            .style('align-items', 'center')
            .style('white-space', 'nowrap')
            .style('overflow', 'hidden')
            .style('text-overflow', 'ellipsis')
          if (layer.type !== "link") {
            layerContent.attr('title', `${t(`layer.block.${layer.text.toLowerCase().replace(/\s+/g, '')}`)}: ${layer.value}`);
          }
          if (!layer.value) {
            layerContent
              .append('span')
              .style('font-weight', '500')
              .style('color', 'rgb(75, 85, 99)')
              .style('margin-right', '8px')
              .text(t(`layer.block.${layer.text.toLowerCase().replace(/\s+/g, '')}`));
          }

          const valueSpan = layerContent
            .append('span')
            .style('color', 'rgb(31, 41, 55)');

          if (layer.type === 'link' && layer.value) {
            valueSpan
              .append('a')
              .attr('href', layer.value as string)
              .attr('target', '_blank')
              .attr('rel', 'noopener noreferrer')
              .style('color', 'rgb(37, 99, 235)')
              .style('text-decoration', 'none')
              .style('hover:text-decoration', 'underline')
              .text('Link');
          } else {
            let displayValue = layer.value || '';
            if (layer.type === "minute") {
              displayValue = `${layer.value} min`;
            }
            valueSpan.text(displayValue);
          }

          const actionsDiv = layerDiv
            .append('div')
            .attr('class', 'layer-actions')
            .style('position', 'absolute')
            .style('right', '8px')
            .style('top', '50%')
            .style('transform', 'translateY(-50%)')
            .style('display', 'flex')
            .style('gap', '4px')
            .style('opacity', '0')
            .style('transition', 'opacity 0.2s');

          layerDiv
            .on('mouseenter', function () {
              d3.select(this).style('background-color', '#f8fafc');
              actionsDiv.style('opacity', '1');
            })
            .on('mouseleave', function () {
              d3.select(this).style('background-color', index % 2 === 0 ? '#f9fafb' : '#ffffff');
              actionsDiv.style('opacity', '0');
            });

          const isEditable = attributesDataEditable?.start_end_layers?.find((item: any) =>
            item.id === layer.id
          )?.edit ?? true;

          if (isEditable) {
            const editButton = actionsDiv
              .append('button')
              .style('padding', '2px')
              .style('background', '#f0f9ff')
              .style('border', 'none')
              .style('border-radius', '4px')
              .style('cursor', 'pointer')
              .style('display', 'flex')
              .style('align-items', 'center')
              .style('justify-content', 'center')
              .style('color', '#0284c7')
              .on('mouseenter', function () {
                d3.select(this).style('background', '#e0f2fe');
              })
              .on('mouseleave', function () {
                d3.select(this).style('background', '#f0f9ff');
              })
              .on('click', async (event) => {
                event.stopPropagation();
                actionsDiv.style('opacity', '0');

                if (layer.type === 'link') {
                  await handleLinkLayerEdit(layer, (newValue) => {
                    layer.value = newValue;
                    updateLayerDisplay(layer, layerContent, container, block, allBlocks, t);
                  }, t, attributesDataEditable);
                } else {
                  const editFormContainer = layerDiv
                    .append('div')
                    .style('position', 'absolute')
                    .style('left', '0')
                    .style('right', '0')
                    .style('top', '0')
                    .style('background', 'white')
                    .style('padding', '4px 8px')
                    .style('border-radius', '4px')
                    .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)')
                    .style('z-index', '20');

                  const input = editFormContainer
                    .append('input')
                    .attr('type', layer.type === 'integer' || layer.type === 'percent' ? 'number' : 'text')
                    .attr('value', layer.value || '')
                    .style('width', '100%')
                    .style('padding', '4px')
                    .style('border', '1px solid #e5e7eb')
                    .style('border-radius', '4px')
                    .style('margin-bottom', '4px');

                  const buttonsContainer = editFormContainer
                    .append('div')
                    .style('display', 'flex')
                    .style('justify-content', 'flex-end')
                    .style('gap', '4px')
                    .style('position', 'relative')
                    .style('z-index', '21');

                  const cleanup = () => {
                    editFormContainer.remove();
                    layerDiv
                      .style('background-color', index % 2 === 0 ? '#f9fafb' : '#ffffff')
                      .on('mouseenter', null)
                      .on('mouseleave', null);

                    setTimeout(() => {
                      layerDiv
                        .on('mouseenter', function () {
                          d3.select(this).style('background-color', '#f8fafc');
                          actionsDiv.style('opacity', '1');
                        })
                        .on('mouseleave', function () {
                          d3.select(this).style('background-color', index % 2 === 0 ? '#f9fafb' : '#ffffff');
                          actionsDiv.style('opacity', '0');
                        });
                    }, 100);
                  };

                  buttonsContainer
                    .append('button')
                    .html('<i class="fas fa-save"></i>')
                    .attr('title', t('words.save'))
                    .style('padding', '2px 8px')
                    .style('background', '#f0f9ff')
                    .style('border', 'none')
                    .style('border-radius', '4px')
                    .style('color', '#0284c7')
                    .style('cursor', 'pointer')
                    .on('click', () => {
                      const newValue = input.property('value');
                      layer.value = newValue;
                      updateLayerDisplay(layer, layerContent, container, block, allBlocks, t);
                      cleanup();
                    });

                  buttonsContainer
                    .append('button')
                    .html('<i class="fas fa-times"></i>')
                    .attr('title', t('words.cancel'))
                    .style('padding', '2px 8px')
                    .style('background', '#fee2e2')
                    .style('border', 'none')
                    .style('border-radius', '4px')
                    .style('color', '#dc2626')
                    .style('cursor', 'pointer')
                    .on('click', cleanup);
                }
              });

            editButton
              .append('svg')
              .attr('width', '14')
              .attr('height', '14')
              .attr('viewBox', '0 0 24 24')
              .attr('fill', 'none')
              .attr('stroke', 'currentColor')
              .attr('stroke-width', '2')
              .attr('stroke-linecap', 'round')
              .attr('stroke-linejoin', 'round')
              .html('<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>');
          }

          const hideButton = actionsDiv
            .append('button')
            .style('padding', '2px')
            .style('background', '#fef2f2')
            .style('border', 'none')
            .style('border-radius', '4px')
            .style('cursor', 'pointer')
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('justify-content', 'center')
            .style('color', '#dc2626')
            .on('mouseenter', function () {
              d3.select(this).style('background', '#fee2e2');
            })
            .on('mouseleave', function () {
              d3.select(this).style('background', '#fef2f2');
            })
            .on('click', (event) => {
              event.stopPropagation();
              const layerName = layerDiv.attr('data-layer-name');
              d3.selectAll(`.layer-${layerName.toLowerCase().replace(/\s+/g, '-')}`)
                .style('display', 'none');
            });

          hideButton
            .append('svg')
            .attr('width', '14')
            .attr('height', '14')
            .attr('viewBox', '0 0 24 24')
            .attr('fill', 'none')
            .attr('stroke', 'currentColor')
            .attr('stroke-width', '2')
            .attr('stroke-linecap', 'round')
            .attr('stroke-linejoin', 'round')
            .html('<path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"/><line x1="1" y1="1" x2="23" y2="23"/>');
        }
      });
    }
  }

  if (textElement) {
    setupTextEditing(textElement, block, editMode.enabled, onTextUpdate);
  }

  if (editMode.enabled) {
    implementDrag(blockContainer, block.id, allBlocks, onDragEnd, setBlocks);
  }

  return blockContainer;
}

// Helper function to update layer display
function updateLayerDisplay(
  layer: Layer,
  layerContent: d3.Selection<any, unknown, null, undefined>,
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  block: Block,
  allBlocks: Block[],
  t: (key: string) => string,
) {
  layerContent.selectAll('*').remove();

  if (!layer.value) {
    layerContent
      .append('span')
      .style('font-weight', '500')
      .style('color', 'rgb(75, 85, 99)')
      .style('margin-right', '8px')
      .text(t(`layer.block.${layer.text.toLowerCase().replace(/\s+/g, '')}`));
  }

  const valueSpan = layerContent
    .append('span')
    .style('color', 'rgb(31, 41, 55)');

  if (layer.type === 'link' && layer.value) {
    valueSpan
      .append('a')
      .attr('href', layer.value as string)
      .attr('target', '_blank')
      .attr('rel', 'noopener noreferrer')
      .style('color', 'rgb(37, 99, 235)')
      .style('text-decoration', 'none')
      .on('mouseover', function () {
        d3.select(this).style('text-decoration', 'underline');
      })
      .on('mouseout', function () {
        d3.select(this).style('text-decoration', 'none');
      })
      .text('Link');
  } else {
    let displayValue = layer.value || '';
    if (layer.type === "minute") {
      displayValue = `${layer.value} min`;
    }
    valueSpan.text(displayValue);

    layerContent.attr('title', `${t(`layer.block.${layer.text.toLowerCase().replace(/\s+/g, '')}`)}: ${layer.value || ''}`);

  }


  allBlocks.forEach(currentBlock => {
    if (currentBlock.id === block.id && currentBlock.layers) {
      const matchingLayer = currentBlock.layers.find(l => l.text === layer.text);
      if (matchingLayer) {
        matchingLayer.value = layer.value;
        const otherLayerDivs = container
          .selectAll(`.${block.type}-block-container`)
          .selectAll(`.layer-${layer.text.toLowerCase().replace(/\s+/g, '-')}`);

        otherLayerDivs.each(function () {
          const otherLayerContent = d3.select(this).select('div');
          otherLayerContent.selectAll('*').remove();

          if (!layer.value) {
            otherLayerContent
              .append('span')
              .style('font-weight', '500')
              .style('color', 'rgb(75, 85, 99)')
              .style('margin-right', '8px')
              .text(t(`layer.block.${layer.text.toLowerCase().replace(/\s+/g, '')}`));
          }

          const otherValueSpan = otherLayerContent
            .append('span')
            .style('color', 'rgb(31, 41, 55)');

          if (layer.type === 'link' && layer.value) {
            otherValueSpan
              .append('a')
              .attr('href', layer.value as string)
              .attr('target', '_blank')
              .attr('rel', 'noopener noreferrer')
              .style('color', 'rgb(37, 99, 235)')
              .style('text-decoration', 'none')
              .on('mouseover', function () {
                d3.select(this).style('text-decoration', 'underline');
              })
              .on('mouseout', function () {
                d3.select(this).style('text-decoration', 'none');
              })
              .text('Link');
          } else {
            let displayValue = layer.value || '';
            if (layer.type === "minute") {
              displayValue = `${layer.value} min`;
            }
            otherValueSpan.text(displayValue);

            otherLayerContent.attr('title', `${t(`layer.block.${layer.text.toLowerCase().replace(/\s+/g, '')}`)}: ${layer.value || ''}`);

          }

        });
      }
    }
  });
}