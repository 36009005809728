// React Component
import { useRouteError, NavLink } from "react-router-dom"

// App Component
import { Colors, Images } from "../../constants"
// import { BodyLayout } from "../../layouts"
import { Routes } from "../../routes"
import { useTranslation } from "react-i18next"

const Error404View = () => {
    const errors = useRouteError() as Error
    const { t } = useTranslation();
    console.error('error', errors)

    return (
        <div className="vh-100" style={{ backgroundImage: `url(${Images.bg_1})`, backgroundSize: 'cover' }}>
            <div className="vh-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: Colors.bg_dark_blur_2 }}>
                <div className="d-flex flex-column align-items-center">
                    <div className="me-3">
                        <img src={Images.famla} alt="Imagedds" className="img-fluid" width={300} />
                    </div>

                    <div>
                        <h1 className="text-light mb-3" style={{ fontSize: "5rem" }}>{t('navigation.oops')}</h1>
                        <p className="text-light fs-2 mb-3">{t('navigation.simplyLeverage')}</p>
                        <p>
                            {/* <i>{errors.message}</i>  */}
                        </p>
                    </div>
                    <NavLink to={Routes.HOMEVIEW} className="btn btn-theme fw-bold px-5 mt-3 d-flex align-items-center justify-content-center  ">{t('navigation.backToFamla')}</NavLink>

                </div>
            </div>
        </div>
    )
}

export default Error404View