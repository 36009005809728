import * as d3 from 'd3';
import { Line, Layer } from '../../../types';
import { LayerContainerRenderer } from './renderers/LayerContainerRenderer';
import { LayerContentRenderer } from './renderers/LayerContentRenderer';
import { LayerButtonRenderer } from './renderers/LayerButtonRenderer';
import { LayerHiddenRenderer } from './renderers/LayerHiddenRenderer';

interface RenderOptions {
  line: Line;
  position: { x: number; y: number };
  showLayers: boolean;
  hiddenLayers: Layer[];
  showHiddenMenu: boolean;
  onHideLayer: (layer: Layer) => void;
  onShowLayer: (layer: Layer) => void;
  onToggleMenu: () => void;
}

export class LayerUIManager {
  private containerRenderer: LayerContainerRenderer;
  private contentRenderer: LayerContentRenderer;
  private buttonRenderer: LayerButtonRenderer;
  private hiddenRenderer: LayerHiddenRenderer;

  constructor(
    private container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    private editMode: boolean,
    private eventManager: any,
    private t: (key: string) => string,
    private attributesDataEditable: any
  ) {
    this.containerRenderer = new LayerContainerRenderer(container);
    this.contentRenderer = new LayerContentRenderer(editMode, t, attributesDataEditable);
    this.buttonRenderer = new LayerButtonRenderer();
    this.hiddenRenderer = new LayerHiddenRenderer();
  }

  public render(options: RenderOptions) {
    // Clean up existing elements
    this.container.selectAll(`.line-layers-${options.line.id}`).remove();

    // Create container
    const { layersContainer, layersDiv } = this.containerRenderer.render(
      options.line.id,
      options.position,
      true // Always show layers initially when rendering
    );

    // Render hidden layers section if menu is open
    if (options.showHiddenMenu) {
      if (options.hiddenLayers.length > 0) {
        this.hiddenRenderer.render(layersDiv, options.hiddenLayers, options.onShowLayer);
      } else {
        this.hiddenRenderer.renderNoHiddenMessage(layersDiv);
      }
    }

    // Render add layer button if needed
    if (this.editMode) {
      this.buttonRenderer.renderAddButton(layersDiv, options.onToggleMenu);
    }

    // Render existing layers
    if (options.line.layers?.length) {
      this.contentRenderer.renderLayers(layersDiv, options.line, options.onHideLayer);
    }

    // Ensure visibility matches the showLayers option
    if (!options.showLayers) {
      layersDiv.style('display', 'none');
    }
  }

  public toggleVisibility(lineId: string, visible: boolean) {
    const layersContainer = this.container.select(`.line-layers-${lineId}`);
    if (!layersContainer.empty()) {
      const layersDiv = layersContainer.select('div');
      layersDiv.style('display', visible ? 'block' : 'none');
    }
  }

  public cleanup(lineId: string) {
    this.container.selectAll(`.line-layers-${lineId}`).remove();
  }
}