import React from 'react';
import { useTranslation } from 'react-i18next';

//App
import { Routes } from '../../routes';

interface ChildProps {
    swimlaneId: string;
    processId: string;
    theme?: 'dark' | 'light'; // Ajout du prop theme
}

const ShareModal = ({ swimlaneId, processId, theme = 'dark' }: ChildProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [copySuccess, setCopySuccess] = React.useState(false);
    const { t } = useTranslation();
    const baseUrl = window.location.origin;
    const shareLink = swimlaneId && processId ? `${baseUrl}${Routes.MAPPING}${processId}/${swimlaneId}` : '';

    const copyToClipboard = () => {
        if (inputRef.current) {
            inputRef.current.select();
            document.execCommand('copy');
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 15000);
        }
    };

    return (
        <div className="modal fade" id={`shareModal${swimlaneId}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                {/* Changement de classe selon le thème */}
                <div className={`modal-content py-4 px-2 border-0 ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`}>
                    <div className="modal-header d-flex align-items-center justify-content-between  p-0 pb-3 mb-3">
                        <h5 className="mb-0 fs-5">{t('navigation.shareLink')}</h5>
                        <button className="text-muted border-0 bg-transparent p-2 small" data-bs-dismiss="modal">
                            <i className={`fa-solid fa-xmark ${theme === 'dark' ? 'text-white' : 'text-dark'}`}></i>
                        </button>
                    </div>

                    <div>
                        {/* <h6 className="fw-bold mb-3">{t('navigation.orCopyLink')}</h6> */}
                        <div className="d-flex align-items-center border rounded-3 px-2 py-1 w-100">
                            <span className="material-icons me-2">{t('words.link')}</span>
                            <input
                                type="text"
                                ref={inputRef}
                                className={`form-control form-control-sm p-0 rounded-3 fw-light border-0 ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`}
                                defaultValue={shareLink}
                                readOnly
                            />
                            <button className={`p-2 border-0 rounded-2 px-3 ms-1 ${theme === 'dark' ? 'bg-secondary text-white' : 'bg-primary text-white'}`} onClick={copyToClipboard}>
                                {copySuccess ? 'Copied' : 'Copy'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShareModal;
