import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../stores";
import { Routes } from "../routes";
import AuthView from "../views/auth/AuthView";

const AuthGuard = ({ component: Component }) => {
    const users = useAppSelector((state) => state.auth);

    if ((users.logged_in && users.access_token !== "")) {
        if (Component.name === "AuthView" || Component.displayName === "AuthView") {
            return <Navigate to={Routes.HOMEVIEW} replace={false} />;
        } else {
            return <Component />;
        }
    } else {
        return <AuthView />;
    }
};

export default AuthGuard;
