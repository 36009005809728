//React
import React, { useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//APP
import { DefaultEditorSwimlane } from "../../swimLane";
import { DiagramList } from "../../../views/modal";
import { FamlaServices } from "../../../services";
import { simlane_export } from "../../swimLane/static/data";
import { SwimlaneError } from "../../../components";
import { mapId, useAppDispatch, useAppSelector } from "../../../stores";
import '../../../../src/assets/css/diagram.css'
import { LayerSelections } from "../../../swimlane/lib/types";

const ProcessDiagram = () => {
  const { id, sId } = useParams();
  const { t } = useTranslation();
  const [swimLane, setSwimlane] = React.useState(simlane_export);
  const swimLaneRef = useRef<any[]>([]);
  const [allSwimLane, setAllSwimLane] = React.useState<any[]>([]);
  const [approvallist, setApprovallist] = React.useState([]);
  const users = useAppSelector((state) => state.auth);
  const [spinner, setSpinner] = React.useState(true);
  const [time, setTime] = React.useState(0);
  const [layer, setLayer] = React.useState<LayerSelections>({
    block: [],
    line: [],
    startEnd: []
  });
  const [processesWithDiagrams, setProcessesWithDiagrams] = React.useState<any[]>([]);
  const [canEditMap, setCanEditMap] = React.useState(false);
  const [collaborators, setcollaborators] = React.useState([]);
  const closeRef = useRef<HTMLButtonElement>(null);
  const [isMapGenerationFailed, setIsMapGenerationFailed] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Nouvel état pour forcer la mise à jour visuelle
  const [updateTrigger, setUpdateTrigger] = React.useState(0);

  const loadAllSwimlane = useCallback(async () => {
    try {
      const data = await FamlaServices("api_host", `/mapping/process/${id}`, "GET", "", users.access_token);
      if (data.status === 200) {
        const newData = data.body.data;

        // If swimLaneRef is empty, initialize it with the first data
        if (swimLaneRef.current.length === 0) {
          swimLaneRef.current = [...newData];
          setAllSwimLane([...newData]);
          return;
        }

        // Create a map of existing items for faster lookup
        const existingItemsMap = new Map(
          swimLaneRef.current.map((item, index) => [item._id, { item, index }])
        );

        // Create a new array maintaining the original order and updating existing items
        const updatedItems = swimLaneRef.current.map(item => {
          const updatedItem = newData.find((newItem: any) => newItem._id === item._id);
          return updatedItem || item;
        });

        // Add any new items that don't exist in the current array
        const newItems = newData.filter(
          (newItem: any) => !existingItemsMap.has(newItem._id)
        );

        // Update the ref and state while maintaining order
        swimLaneRef.current = [...updatedItems, ...newItems];
        setAllSwimLane([...swimLaneRef.current]);
      }
    } catch (error) {
      console.error("Error loading swimlanes:", error);
    }
  }, [id, users.access_token]);

  const loadProcessesWithDiagrams = async () => {
    try {
      // Récupérer tous les processus
      const processResponse = await FamlaServices("api_host", "/process/my", "GET", "", users.access_token);

      if (processResponse.status === 200) {
        const processes = processResponse.body.data;

        // Récupérer les diagrammes pour chaque processus
        const processesWithDiagrams = await Promise.all(
          processes.map(async (process: any) => {
            try {
              const diagramResponse = await FamlaServices("api_host", `/mapping/process/${process._id}`, "GET", "", users.access_token);
              return {
                ...process,
                diagrams: diagramResponse.status === 200 ? diagramResponse.body.data : [],
              };
            } catch (error) {
              console.error(`Erreur lors du chargement des diagrammes pour le processus ${process._id}:`, error);
              return { ...process, diagrams: [] };
            }
          })
        );

        // Retourner ou stocker le résultat
        return processesWithDiagrams;
      } else {
        console.error("Erreur lors du chargement des processus.");
        return [];
      }
    } catch (error) {
      console.error("Erreur générale dans loadProcessesWithDiagrams:", error);
      return [];
    }
  };

  const loadProcessById = useCallback(async () => {
    try {
      const data = await FamlaServices("api_host", `/process/by/id/${id}`, "GET", "", users.access_token);
      if (data.status === 200) {
        const DataLayer = data.body.data;
        setLayer({
          block: DataLayer.block_layers,
          line: DataLayer.line_layers,
          startEnd: DataLayer.start_end_layers
        });
      }
    } catch (error) {
      console.error("Error loading process:", error);
    }
  }, [id, users.access_token]);

  React.useEffect(() => {
    checkMapEditPermission(sId ?? "");
    dispatch(mapId({ mapId: sId ?? "" }));
    loadCollaborators(sId ?? "");
  }, [sId, dispatch]);

  React.useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        loadProcessById(),
        loadAllSwimlane(),
        loadNewSwimlane(),
        loadApprovalList(),
        loadTime()
      ]);
    };

    loadData();
  }, [id, sId, users.access_token, loadProcessById, loadAllSwimlane]);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await loadProcessesWithDiagrams();
      setProcessesWithDiagrams(data);
    };

    fetchData();
  }, [id]);


  const loadNewSwimlane = async () => {
    try {
      const data = await FamlaServices("api_host", `/mapping/calculate/new/${sId}`, "GET", "", users.access_token);
      if (data.status === 200) {
        setSwimlane(data.body.data);
        // Déclencher la mise à jour visuelle
        // setUpdateTrigger(prev => prev + 1);
        setTimeout(() => setSpinner(false), 5000);
      } else if (data.body.status_code === 400) {
        setIsMapGenerationFailed(true);
        setSpinner(false);
      }
    } catch (error) {
      console.error("Error loading new swimlane:", error);
      setSpinner(false);
    }
  };

  const loadApprovalList = async () => {
    try {
      const data = await FamlaServices("api_host", `/approval/`, "GET", "", users.access_token);
      if (data.status === 200) {
        setApprovallist(data.body.data);
      }
    } catch (error) {
      console.error("Error loading approval list:", error);
    }
  };

  const loadTime = async () => {
    try {
      const data = await FamlaServices("api_host", `/stats/process/${id}`, "GET", "", users.access_token);
      if (data.status === 200) {
        setTime(data.body.data);
      }
    } catch (error) {
      console.error("Error loading time:", error);
    }
  };

  const checkMapEditPermission = async (mapId: string) => {
    try {
      const response = await FamlaServices("api_host", `/approval/edit/${mapId}`, "GET", "", users.access_token);
      setCanEditMap(response.body.status_code === 200);
    } catch (error) {
      setCanEditMap(false);
    }
  };

  const loadCollaborators = async (mapId: string) => {
    try {
      const data = await FamlaServices("api_host", `/approval/${mapId}`, "GET", "", users.access_token);
      if (data.status === 200) {
        setcollaborators(data.body.data);
      }
    } catch (error) {
      console.error("Error loading collaborators:", error);
    }
  };

  const setMap = async (_id: string) => {
    try {
      const diagramData = swimLaneRef.current.find((d: any) => d._id === _id);
      if (!diagramData) return;

      setSwimlane(diagramData);
      // Déclencher la mise à jour visuelle
      setUpdateTrigger(prev => prev + 1);

      await Promise.all([
        loadCollaborators(_id),
        checkMapEditPermission(_id)
      ]);

      dispatch(mapId({ mapId: _id }));
      closeRef.current?.click();
    } catch (error) {
      console.error("Error in setMap:", error);
    }
  };

  const handleSelectionsChange = async (newSelections: LayerSelections) => {
    try {
      setLayer(newSelections);
      const selection = {
        line: newSelections.line,
        start_end: newSelections.startEnd,
        process: newSelections.block
      };

      const data = await FamlaServices(
        "api_host",
        `/process/update/layers/${id}`,
        "PUT",
        selection,
        users.access_token
      );

      if (data.status === 200) {
        // Déclencher la mise à jour visuelle sans reload
        setUpdateTrigger(prev => prev + 1);
        await loadProcessById();
      }
    } catch (error) {
      console.error("Error updating selections:", error);
    }
  };

  return (
    <>
      <div className="bg-white" key={updateTrigger}>
        {spinner ? (
          <SwimlaneError refresh={() => { }} error={false} t={t} time={time} />
        ) : swimLane.length === 0 || isMapGenerationFailed ? (
          <SwimlaneError refresh={() => navigate(-1)} error={true} t={t} />
        ) : (
          <DefaultEditorSwimlane
            swimlaneExport={swimLane}
            id={id}
            allSwimLane={swimLaneRef.current}
            sId={sId}
            t={t}
            approvallist={approvallist}
            loadAllSwimlane={loadAllSwimlane}
            canEditMap={canEditMap}
            setMap={setMap}
            layer={layer}
            handleSelectionsChange={handleSelectionsChange}
            collaborators={collaborators}
            setCollaborators={setcollaborators}
            processesWithDiagrams={processesWithDiagrams}
          />
        )}
      </div>
      <DiagramList
        closeRef={closeRef}
        allSwimLane={swimLaneRef.current}
        setMap={setMap}
        t={t}
      />
    </>
  );
};

export default ProcessDiagram;