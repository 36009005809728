// React Compoment
import React from 'react'

// Features
import { Routes } from '../../routes'
import { DashProcessPage } from '../../features/dashboard'

// Layout
import DashLayout from '../../layouts/dashboard/dashLayout'

const AdminView = () => {

    return (
        <DashLayout>
            < DashProcessPage />
        </DashLayout>
    )
}

export default AdminView;