import * as d3 from 'd3';
import { DecisionBlock, Position, EditMode, Block, Layer, ProcessBlock } from '../../types';
import { STYLES } from '../../styles/constants';
import { implementDrag } from '../utils/dragUtils';
import { setupTextEditing } from '../../utils/textEditor';
import { getBlockDimensions, getBlockFill } from '../../utils/blockUtils';
import { createLayerTypeMenu, syncLayersAcrossBlocks } from '../../utils/layerUtils';
import { createProcessBlock } from './ProcessBlock';
import { slice } from '../../../../utils';

export function createDecisionBlock(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  decision: DecisionBlock,
  editMode: EditMode,
  onDragEnd: (id: string, position: Position) => void,
  onTextUpdate: (id: string, newText: string) => void,
  allBlocks: Block[] = [],
  t: (key: string) => string,
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>
) {
  const dimensions = getBlockDimensions(decision);
  const fill = getBlockFill(decision);
  const halfWidth = dimensions.width / 2;
  const halfHeight = dimensions.height / 2;
  

  const blockContainer = container
    .append('div')
    .attr('class', `decision-block-container-${decision.id}`)
    .style('position', 'absolute')
    .style('left', `${decision.x || 0}px`)
    .style('top', `${decision.y || 0}px`)
    .style('z-index', '2');
  ;

  const block = blockContainer
    .append('div')
    .attr('class', `decision-block-${decision.id}`)
    .style('width', `${dimensions.width}px`)
    .style('height', `${dimensions.height}px`)
    .style('cursor', editMode.enabled ? 'move' : 'default')
    .style('user-select', 'none')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('justify-content', 'center');

  const svg = block
    .append('svg')
    .attr('width', dimensions.width)
    .attr('height', dimensions.height)
    .style('overflow', 'visible');

  svg
    .append('path')
    .attr('d', `M ${halfWidth} 0 L ${dimensions.width} ${halfHeight} L ${halfWidth} ${dimensions.height} L 0 ${halfHeight} Z`)
    .style('fill', fill)
    .style('stroke', STYLES.colors.border)
    .style('stroke-width', '1px');

  // Ajout du texte directement en SVG
  svg.append('text')
    .attr('x', halfWidth) // Centre horizontalement
    .attr('y', halfHeight) // Centre verticalement
    .attr('text-anchor', 'middle') // Centre le texte horizontalement
    .attr('dominant-baseline', 'middle') // Centre le texte verticalement
    .style('fill', decision.textStyle?.textColor || STYLES.colors.getBlockColors('process').text)
    .style('font-size', decision.textStyle?.fontSize || '12px')
    .style('font-weight', decision.textStyle?.fontWeight || 'normal')
    .style('font-family', decision.textStyle?.fontFamily || 'inherit')
    .text(slice(decision.text, 25));

 

  const textElement = svg.select('text').node();
  if (textElement instanceof SVGTextElement) {
    setupTextEditing(textElement, decision, editMode.enabled, onTextUpdate);
  }

  if (editMode.enabled) {
    implementDrag(blockContainer, decision.id, allBlocks, onDragEnd, setBlocks);
  }

  return blockContainer;
}