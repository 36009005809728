import * as d3 from 'd3';
import { Block, ProcessGroup, Position, EditMode, Line } from '../types';
import { createBlock } from './blocks/BlockFactory';
import { BlockSelectionManager } from './blocks/BlockSelectionManager';
import { STYLES } from '../styles/constants';
import { snapToGrid } from '../utils/gridUtils';
import { ensureBlockDimensions } from '../utils/blockUtils';

export class ProcessManager {
  private container: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private blocks: Block[];
  private groups: ProcessGroup[];
  private editMode: EditMode;
  private onUpdate: (blocks: Block[], deletedBlockId?: string) => void;
  private onLineCreated?: (line: Line) => void;
  private onBlockSelect?: (block: Block | null) => void;
  private blockPositions: Map<string, Position>;
  public t: (key: string) => string;
  private selectionManager: BlockSelectionManager;
  private mapId: string;
  private setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  private lineLayer: string[];
  private attributesDataEditable: any

  constructor(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    blocks: Block[],
    groups: ProcessGroup[],
    editMode: EditMode,
    onUpdate: (blocks: Block[], deletedBlockId?: string) => void,
    t: (key: string) => string,
    setBlocks: React.Dispatch<React.SetStateAction<Block[]>>,
    lineLayer: string[],
    attributesDataEditable: any,
    onLineCreated?: (line: Line) => void,
    onBlockSelect?: (block: Block | null) => void,
    mapId?: string,
  ) {
    this.container = container;
    this.blocks = blocks.map(ensureBlockDimensions);
    this.groups = groups;
    this.editMode = editMode;
    this.mapId = mapId ?? "";
    this.onUpdate = onUpdate;
    this.onLineCreated = onLineCreated;
    this.onBlockSelect = onBlockSelect;
    this.blockPositions = new Map();
    this.t = t;
    this.setBlocks = setBlocks;
    this.lineLayer = lineLayer;
    this.attributesDataEditable = attributesDataEditable;

    this.selectionManager = new BlockSelectionManager(container, {
      onDelete: this.handleDelete.bind(this),
      onAddLine: this.handleAddLine.bind(this),
      onLineCreated: this.handleLineCreated.bind(this),
    },
      mapId ?? "",
      t,
      lineLayer,

    );

    this.initializeBlockPositions();
    this.setupEventListeners();
    this.render();
  }

  private initializeBlockPositions(): void {
    this.blockPositions.clear();
    this.blocks.forEach(block => {
      this.blockPositions.set(block.id, {
        x: block.x || 0,
        y: block.y || 0
      });
    });
    this.selectionManager.updateBlocks(this.blocks);
  }

  private setupEventListeners(): void {
    if (this.editMode.enabled) {
      this.container.on('click', (event) => {
        const target = event.target as HTMLElement;
        const isBlock = target.closest('.process-block, .decision-block, .start-block, .end-block');
        const isMenu = target.closest('[class^="block-selection-menu"]');
        const isConnectionPoint = target.closest('.connection-point');
        const isModal = target.closest('.info-modal');

        if (!isBlock && !isMenu && !isConnectionPoint && !isModal) {
          this.selectionManager.clearSelection();
          if (this.onBlockSelect) {
            this.onBlockSelect(null);
          }
        }
      });
    }
  }

  private handleBlockMove = (id: string, position: Position): void => {
    const block = this.blocks.find(b => b.id === id);
    if (block) {
      const snappedPosition = snapToGrid(position);
      const updatedBlock = {
        ...block,
        x: snappedPosition.x,
        y: snappedPosition.y
      };

      this.blocks = this.blocks.map(b =>
        b.id === id ? updatedBlock : b
      );
      this.blockPositions.set(id, snappedPosition);

      const headerHeight = STYLES.header.height + STYLES.header.gap;
      const laneHeight = STYLES.header.laneHeight;
      const relativeY = position.y - headerHeight;
      const laneIndex = Math.floor(relativeY / laneHeight);

      if (laneIndex >= 0 && laneIndex < this.groups.length) {
        this.updateBlockLaneAssignment(updatedBlock, laneIndex);
      }

      this.onUpdate([...this.blocks]);
    }
  };

  private handleTextUpdate = (id: string, newText: string): void => {
    const block = this.blocks.find(b => b.id === id);
    if (block) {
      const updatedBlock = { ...block, text: newText };
      this.blocks = this.blocks.map(b =>
        b.id === id ? updatedBlock : b
      );
      this.onUpdate([...this.blocks]);
    }
  };

  private handleDelete = (block: Block): void => {
    // Remove the block from the blocks array
    this.blocks = this.blocks.filter(b => b.id !== block.id);

    // Remove the block's position from blockPositions
    this.blockPositions.delete(block.id);

    // Remove the block from all groups
    this.groups.forEach(group => {
      group.groupChildren = group.groupChildren.filter(childId => childId !== block.id);
    });

    // Clear any selection-related elements
    this.container.selectAll(`[class^="block-selection-menu-${block.id}"]`).remove();
    this.container.selectAll(`.connection-point[data-block-id="${block.id}"]`).remove();

    // Remove the block's DOM element
    this.container.select(`[data-block-id="${block.id}"]`).remove();

    // Update the selection manager with the new blocks array
    this.selectionManager.updateBlocks(this.blocks);

    // Notify parent components about the deletion
    this.onUpdate([...this.blocks], block.id);
  };

  private handleAddLine = (block: Block): void => {
    // Start the link mode in the selection manager
    this.selectionManager.startLinkMode(block);
  };

  private handleLineCreated = (line: Line): void => {
    if (this.onLineCreated) {
      this.onLineCreated(line);
    }
  };

  private updateBlockLaneAssignment(block: Block, newLaneIndex: number): void {
    // Remove block from all groups first
    this.groups.forEach(group => {
      group.groupChildren = group.groupChildren.filter(id => id !== block.id);
    });

    // Add block to new group if valid
    if (newLaneIndex >= 0 && newLaneIndex < this.groups.length) {
      this.groups[newLaneIndex].groupChildren.push(block.id);
    }
  }

  public updateData(blocks: Block[]): void {
    // Clear existing blocks from DOM
    this.container.selectAll('.process-block, .decision-block, .start-block, .end-block').remove();

    // Update blocks array with new data
    this.blocks = blocks.map(ensureBlockDimensions);

    // Reinitialize positions and render
    this.initializeBlockPositions();
    this.render();
  }

  private render(): void {
    // Get all existing block elements
    const existingBlocks = this.container
      .selectAll<HTMLDivElement, Block>('.process-block, .decision-block, .start-block, .end-block')
      .data(this.blocks, (d: Block) => d.id);

    // Remove blocks that no longer exist in the data
    existingBlocks.exit().remove();

    // Update existing blocks
    existingBlocks
      .style('left', d => `${d.x}px`)
      .style('top', d => `${d.y}px`);

    // Add new blocks
    this.blocks.forEach(block => {
      // Check if block already exists in DOM
      const existingBlock = this.container.select(`[data-block-id="${block.id}"]`);
      if (!existingBlock.empty()) {
        return; // Skip if block already exists
      }

      const storedPosition = this.blockPositions.get(block.id);
      if (storedPosition) {
        block.x = storedPosition.x;
        block.y = storedPosition.y;
      }

      const blockElement = createBlock(
        this.container,
        block,
        this.editMode,
        this.handleBlockMove,
        this.handleTextUpdate,
        this.blocks,
        this.t,
        this.setBlocks,
        this.attributesDataEditable
      );

      if (blockElement && this.editMode.enabled) {
        blockElement
          .attr('data-block-id', block.id)
          .on('click', (event) => {
            event.stopPropagation();

            // Automatically activate the node when clicked
            this.selectionManager.selectBlock(block);

            // Directly call onAddLine when a block is clicked
            this.handleAddLine(block);

            if (this.onBlockSelect) {
              this.onBlockSelect(block);
            }
          });
      }
    });
  }
}