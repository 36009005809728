import * as d3 from 'd3';
import { Line } from '../../types';
import { STYLES } from '../../styles/constants';
import { createLineContextMenu } from '../menus/LineContextMenu';

export class EventManager {
  private container: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private editMode: boolean;
  private onSelect: (line: Line | null) => void;
  private onUpdate: (line: Line) => void;
  private onDelete: (lineId: string) => void;
  private onToggleLayers: (line: Line) => void;
  private t: (key: string) => string;

  constructor(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    editMode: { enabled: boolean },
    onSelect: (line: Line | null) => void,
    onUpdate: (line: Line) => void,
    onDelete: (lineId: string) => void,
    onToggleLayers: (line: Line) => void,
    t: (key: string) => string
  ) {
    this.container = container;
    this.editMode = editMode.enabled;
    this.onSelect = onSelect;
    this.onUpdate = onUpdate;
    this.onDelete = onDelete;
    this.onToggleLayers = onToggleLayers;
    this.t = t
  }

  public attachLineEvents(
    line: Line,
    visiblePath: d3.Selection<SVGPathElement, unknown, null, undefined>
  ) {
    // Get the parent node (g element) and then find the hit area path
    const parentNode = visiblePath.node()?.parentNode as Element | null; // Cast en Element

    let hitArea: any;
    if (parentNode instanceof Element) {  // Vérifier si c'est bien un Element
      hitArea = d3.select(parentNode).select('path:first-child');
    }

    if (!hitArea.empty()) {
      hitArea
        .style('pointer-events', this.editMode ? 'auto' : 'none')
        .style('cursor', this.editMode ? 'pointer' : 'default')
        .on('click', (event: MouseEvent) => this.handleLineClick(event, line, visiblePath))
        .on('mouseenter', () => this.handleLineHover(visiblePath, true))
        .on('mouseleave', () => this.handleLineHover(visiblePath, false));
    }
  }

  public attachContainerEvents() {
    this.container.on('click', (event: MouseEvent) => {
      // Only deselect if clicking directly on the container
      if (event.target === this.container.node()) {
        this.onSelect(null);
      }
    });
  }

  private handleLineClick(event: MouseEvent, line: Line, visiblePath: d3.Selection<SVGPathElement, unknown, null, undefined>) {
    event.stopPropagation();
    this.onSelect(line);

    const containerNode = this.container.node();
    const containerRect = containerNode?.getBoundingClientRect();
    if (!containerRect) return;

    const x = event.clientX - containerRect.left;
    const y = event.clientY - containerRect.top;

    createLineContextMenu(
      this.container,
      { x, y },
      line,
      this.onUpdate,
      () => this.onDelete(line.id),
      this.t,
      () => this.onToggleLayers(line)
    );
  }

  private handleLineHover(
    visiblePath: d3.Selection<SVGPathElement, unknown, null, undefined>,
    isHovered: boolean
  ) {
    const isSelected = visiblePath.attr('stroke') === STYLES.colors.selected;

    visiblePath
      .attr('stroke-width', isHovered ? (isSelected ? 2.5 : 2) : (isSelected ? 2 : 1.5))
      .attr('stroke', isHovered ? (isSelected ? STYLES.colors.selected : '#999') : (isSelected ? STYLES.colors.selected : STYLES.colors.arrow));
  }
}