import React from 'react';
import { Check, X } from 'lucide-react';
import { Layer } from "../lib/types";


export interface AttributeItem {
    id: string;
    edit: boolean;
  }

interface AttributeSectionProps {
    title: string;
    attributes: Layer[];
    selectedAttributes: AttributeItem[];
    onToggle: (attributeId: string) => void;
}

export function AttributeSection({
    title,
    attributes,
    selectedAttributes,
    onToggle,
}: AttributeSectionProps) {
    return (
        <div className="card shadow-sm mb-4">
            <div className="card-header bg-light text-dark fw-semibold">
                {title}
            </div>
            <ul className="list-group list-group-flush">
                {attributes.map((attr) => {
                    const isSelected = selectedAttributes.find(item => item.id === attr.id)?.edit ?? false;

                    return (
                        <li
                            key={attr.id}
                            className="list-group-item d-flex justify-content-between align-items-center"
                        >
                            <div className="d-flex align-items-center">
                                <span className="me-2">{attr.text}</span>
                            </div>
                            <button
                                onClick={() => onToggle(attr.id)}
                                className={`btn btn-sm ${isSelected
                                    ? 'btn-success'
                                    : 'btn-danger'
                                    }`}
                                aria-label={isSelected ? 'Disable editing' : 'Enable editing'}
                            >
                                {isSelected ? <Check size={18} /> : <X size={18} />}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
