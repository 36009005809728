import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { useAppSelector } from "../../stores";
import { FamlaServices } from '../../services';
import { iAlert } from '../../interfaces';
import { InputForm, Spiner } from '../../components';

interface ChildProps {
    swimlaneId: string;
    isDarkMode?: boolean;
}

const ApprovalModal = ({ swimlaneId, isDarkMode = true }: ChildProps) => {
    const { t } = useTranslation();
    const closeRef = useRef<HTMLButtonElement>(null);
    const users = useAppSelector(state => state.auth);
    const [state, setState] = React.useState({
        email: "",
        isSubmit: false,
    });

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.email.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.enterValideEmail") });
            return;
        }

        setState({ ...state, isSubmit: true });
        let newFormData = { map_id: swimlaneId, mail: state.email };
        let data = await FamlaServices("api_host", `/approval/`, "POST", newFormData, users.access_token);

        if (data.status === 201) {
            setAlert({ isActive: true, type: 'success', message: t("navigation.invitationSentSuccessfully") });
            setTimeout(() => {
                closeRef.current?.click();
                setState({ email: "", isSubmit: false });
                setAlert({ isActive: false, type: '', message: "" });
            }, 1000);
        } else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        }
        setState({ ...state, isSubmit: false });
    };

    return (
        <div className="modal" data-bs-backdrop="static" id={`approval${swimlaneId}`} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content ${isDarkMode ? 'bg-dark text-white' : 'bg-white text-dark'}`}>
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-5">{t('navigation.inviteAApprover')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {alert.isActive && <div className={`alert alert-${alert.type}`} role="alert">{alert.message}</div>}
                        <InputForm
                            inputType="email"
                            inputLabel={t('words.email')}
                            inputName="email"
                            inputValue={state.email}
                            isFloatingLabel={true}
                            labelClass={isDarkMode ? "text-dark" : "text-dark"}
                            onChange={handleChange}
                            inputContainerClass="mb-3 p-0"
                            inputClass={isDarkMode ? "bg-dark text-light" : "bg-white text-dark"}
                        />
                    </div>
                    <div className="modal-footer">
                        <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">
                            {state.isSubmit ? <Spiner className='spinner-border-sm' /> : t('words.invite')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApprovalModal;
