// React
import React from 'react';
import { useTranslation } from 'react-i18next';

//App
import Spiner from '../Spiner';
import Dropdown from '../Dropdown';

//Interface
import { iUser } from '../type';
import { iInterview } from '../type';

//utils
import { slice } from '../../utils';

interface InterviewListProps {
    role: string;
    selected: string | null;
    processName: string;
    interviwsId: string | undefined;
    isLoadingProcess: boolean;
    interviews: iInterview[];
    users: iUser
    handleClick: (id: string, user: iUser) => void;
    prepareAndShowModal: (user: iUser | undefined) => void;
    handleClickNonMappedElement: () => void;
    updateselection: (id: string) => void;
    deleteInterviews: (id: string, name: string) => void;
    handleReplaceMenber: (replaceRole: string, replaceId: string, action: string) => void
}

const InterviewList: React.FC<InterviewListProps> = ({
    role,
    selected,
    processName,
    interviwsId,
    isLoadingProcess,
    interviews,
    users,
    handleClick,
    handleClickNonMappedElement,
    updateselection,
    deleteInterviews,
    prepareAndShowModal,
    handleReplaceMenber

}) => {
    const { t } = useTranslation();

    const checkADuplicate = React.useCallback(
        (name: string, role: string, email?: string, interviews?: iInterview[]): string => {
            const normalizedRole = role || 'owner';

            if (!name) {
                return email ? `${slice(email, 14)} (invited)` : '(invited)';
            }

            if (interviews) {
                const duplicateName = interviews.filter(
                    (interview) => interview.collaboration.user.fullname === name
                );

                if (duplicateName.length > 1) {
                    return `${slice(name, 16)} (${slice(normalizedRole, 7)})`;
                }
            }

            return slice(name, 23);
        },
        []
    );

    const checkDuplicateUserRole = React.useCallback((currentInterview: iInterview, interviews: iInterview[]): boolean => {
        const { collaboration, role: currentRole } = currentInterview;
        const { user } = collaboration;
        // Count how many times this user appears with the same role
        const duplicateCount = interviews.filter(interview =>
            interview.collaboration.user._id === user._id &&
            interview.role === currentRole
        ).length;

        return duplicateCount > 1;
    }, []);


    return (
        <div className="mt-3 w-100">
            {/* {role !== 'WORKER' ? (
                <button
                    data-bs-target="#inviteUser"
                    data-bs-toggle="modal"
                    className="text-decoration-none text-light fs-6 bg-dark border-0 rounded-4 mb-2 "
                >
                    <div className="rounded-4 py-1 ps-1 btn btn-secondary bg-dark border-0 text-gradient">
                        <span>
                            <i className="fa-solid fa-plus pe-0"></i>
                        </span>
                        <span className="ms-1">{t('views.processInterview.addCollaborators')}</span>
                    </div>
                </button>
            ) : (
                <div className="text-decoration-none text-light fs-6 bg-dark border-0 rounded-4 mb-2">
                    <div className="rounded-4 py-1 ps-1 bg-dark border-0 text-gradient">
                        <span className="ms-1">{t('views.processInterview.collaborators')}</span>
                    </div>
                </div>
            )} */}

            <div className="text-decoration-none text-light fs-6 mb-2">
                <div className="rounded-4 py-1 ps-1 bg-dark border-0 text-gradient">
                    <span className="ms-1">{t('views.processInterview.collaborators')}</span>
                </div>
            </div>

            <div className="overflow-auto customHeightInterview w-100">
                {/* {processName && (
                    <div onClick={() => handleClick(interviwsId ? interviwsId : "", users)}>
                        <h5
                            onClick={handleClickNonMappedElement}
                            className={`${role === "WORKER" && "disabled"} text-light text-decoration-none w-100 btn ${selected === null ? 'btn-secondary btn-md' : 'btn-outline-secondary btn-md'} border-0 rounded-4 fs-6`}
                        >
                            <div className="d-flex">
                                <>
                                    <span>
                                        <i className="fa-solid fa-user me-2"></i>
                                    </span>
                                    {processName.length > 23 ? `${processName.slice(0, 21)}...` : processName} (Owner)
                                </>

                            </div>
                        </h5>
                    </div>
                )} */}

                {isLoadingProcess ? (
                    <Spiner className="spinner-border-sm" />
                ) : (
                    <>
                        {interviews.map((interview: iInterview) => {
                            const { _id, collaboration, role } = interview;
                            const { user } = collaboration;
                            const { fullname, email } = user;
                            const isDeletable = checkDuplicateUserRole(interview, interviews);
                            return (
                                <div
                                    onClick={() => {
                                        handleClick(interview._id, interview.collaboration.user);
                                        updateselection(interview._id);
                                    }}
                                    key={interview._id}
                                    title={interview.role !== null ? `Role: ${interview.role}` : ""}
                                    className={`interview-item fs-6 mb-0 text-light w-100 text-decoration-none btn ${selected === interview._id ? 'custom-fontWeight btn-md' : 'btn-outline-secondary btn-md'} border-0 rounded-4`}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex text-nowrap">
                                            <span><i className="fa-solid fa-user me-2"></i></span>
                                            <span>{checkADuplicate(fullname, interview?.role || "", email, interviews)}</span>
                                        </div>
                                        {(role !== 'WORKER' && interview.role !== null) && (
                                            <div className="" onClick={(e) => e.stopPropagation()}>
                                                <Dropdown
                                                    dropName=""
                                                    dropdownType="btn-group dropend"
                                                    iconClassName="fa fa-ellipsis-vertical small dropdown_icon"
                                                    buttonDropClassName="bg-transparent border-0 ms-2"
                                                    styleButton={{}}
                                                    deleteProcess={() => deleteInterviews(_id, "deleInterview")}
                                                    action1={(isDeletable) ? t('words.delete') : ""}
                                                    action4={t('words.replace')}
                                                    onclickAction4={() => handleReplaceMenber(role, _id, 'replace')}
                                                    action5={t('navigation.sendReminder')}
                                                    onclickAction5={() => handleReplaceMenber(role, _id, 'reminder')}
                                                    theRouteOfAction4=""
                                                    theRouteOfAction5=""
                                                    routeFaq=""
                                                    prepareAndShowModal={prepareAndShowModal}
                                                    col={interview.collaboration.user}
                                                // dataBsTarget={`#grantEditPermission${interview.collaboration?.user._id}`}
                                                // enableEdit={interview.collaboration?.user.editMode ? t('views.processInterview.disableEditMode') : t('views.processInterview.enableEditMode')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};

export default InterviewList;
