//React
import React from "react";

import App from "../../../swimlane/App.tsx";
import "../../../swimlane/index.css"




const DefaultEditorSwimlane = (
  { swimlaneExport,
    id,
    allSwimLane,
    sId,
    t,
    approvallist,
    loadAllSwimlane,
    canEditMap,
    setMap,
    layer,
    handleSelectionsChange,
    collaborators,
    setCollaborators,
    processesWithDiagrams,
  }
) => {

  return (

    <App
      swimlaneData={swimlaneExport}
      processId={id}
      allSwimLane={allSwimLane}
      loadAllSwimlane={loadAllSwimlane}
      sId={sId}
      canEditMap={canEditMap}
      approvallist={approvallist}
      setMap={setMap}
      layer={layer}
      handleSelectionsChange={handleSelectionsChange}
      collaborators={collaborators}
      setCollaborators={setCollaborators}
      processesWithDiagrams={processesWithDiagrams}

    />
  );
};

export default DefaultEditorSwimlane;
