import * as d3 from 'd3';
import { Block, Layer } from '../types';
import { iProcess } from '../../../components/type';

// Format layer values based on type
export function formatLayerValue(value: string | number | undefined, type: string): string {
    if (!value) return '';
    const val = value.toString();
    switch (type) {
        case 'minute':
            return `${val} min`;
        case 'percent':
            return `${val}%`;
        case '$':
            return `$${val}`;
        case 'tCO2e':
            return `${val} tCO2e`;
        case 'integer':
            return val;
        default:
            return val;
    }
}

// Available layer properties with their default configuration
export const AVAILABLE_PROPERTIES = [
    { text: "Lead Time", type: "minute" },
    { text: "Defect rate", type: "percent" },
    { text: "IT System", type: "text" },
    { text: "Cost", type: "$" },
    { text: "Carbon footprint", type: "tCO2e" },
    { text: "Headcount", type: "integer" },
    { text: "Irritants and improvements", type: "text" },
    { text: "Risks and controls", type: "text" },
    { text: "Related documents", type: "link" }
] as const;

// Get icon for layer type
export function getLayerTypeIcon(type: string): string {
    switch (type) {
        case 'minute':
            return '⏱';
        case 'percent':
            return '%';
        case 'text':
            return '📝';
        case '$':
            return '💰';
        case 'tCO2e':
            return '🌱';
        case 'integer':
            return '#';
        case 'link':
            return '🔗';
        default:
            return '📝';
    }
}

// Synchronize layers across blocks
export function syncLayersAcrossBlocks(blocks: Block[], currentBlockId: string, layer: Layer): Block[] {
    return blocks.map(block => {
        if (block.type === 'process' || block.type === 'decision') {
            const existingLayerIndex = block.layers?.findIndex(l => l.text === layer.text) ?? -1;

            if (existingLayerIndex !== -1 && block.layers) {
                block.layers[existingLayerIndex] = {
                    ...block.layers[existingLayerIndex],
                    value: block.id === currentBlockId ? layer.value : block.layers[existingLayerIndex].value
                };
            } else {
                if (!block.layers) block.layers = [];
                block.layers.push({
                    ...layer,
                    id: `l-${Date.now()}-${block.id}`,
                    value: block.id === currentBlockId ? layer.value : ''
                });
            }
        }
        return block;
    });
}

// Create layer type menu
export function createLayerTypeMenu(
    container: d3.Selection<any, any, any, any>,
    block: Block,
    onLayerAdded: (newLayer: Layer) => void,
    t: (key: string) => string
) {
    // Remove any existing menus
    d3.selectAll('.layer-type-menu').remove();

    // Get all hidden layers
    const hiddenLayers = block.layers?.filter(layer => {
        const layerElement = container.select(`.layer-${layer.text.toLowerCase().replace(/\s+/g, '-')}`);
        return !layerElement.empty() && layerElement.style('display') === 'none';
    }) || [];

    if (hiddenLayers.length === 0) {
        const messageContainer = container
            .append('div')
            .attr('class', 'layer-type-menu')
            .style('position', 'absolute')
            .style('bottom', '100%')
            .style('left', '50%')
            .style('transform', 'translateX(-50%)')
            .style('background-color', 'white')
            .style('border', '1px solid #e5e7eb')
            .style('border-radius', '4px')
            .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)')
            .style('padding', '8px 12px')
            .style('margin-bottom', '8px')
            .style('z-index', '1001')
            .style('font-size', "14px")
            .style('white-space', 'nowrap')
            .style('color', '#6b7280')
            .text(t(`layer.noHiddenpropertiesAvailable`));

        setTimeout(() => {
            messageContainer.remove();
        }, 2000);

        return;
    }

    // Create menu container with reduced width
    const menuContainer = container
        .append('div')
        .attr('class', 'layer-type-menu')
        .style('position', 'absolute')
        .style('bottom', '100%')
        .style('left', '50%')
        .style('transform', 'translateX(-50%)')
        .style('background-color', 'white')
        .style('border', '1px solid #e5e7eb')
        .style('border-radius', '4px')
        .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)')
        .style('width', '160px') // Reduced width
        .style('margin-bottom', '8px')
        .style('z-index', '5001')
        .style('display', 'flex')
        .style('flex-direction', 'column');

    // Add title
    menuContainer
        .append('div')
        .style('padding', '8px 12px')
        .style('border-bottom', '1px solid #e5e7eb')
        .style('font-weight', '500')
        .style('color', '#374151')
        .style('background-color', 'white')
        .style('position', 'sticky')
        .style('top', '0')
        .style('z-index', '2')
        .style('font-size', '14px')
        .text(t(`layer.hiddenProperties`));

    // Create scrollable container limited to 3 items height
    const optionsContainer = menuContainer
        .append('div')
        .style('overflow-y', 'auto')
        .style('max-height', '88px') // Height for 3 items (44px each)
        .style('scrollbar-width', 'thin')
        .style('scrollbar-color', '#cbd5e1 transparent')
        .style('position', 'relative');

    // Add custom scrollbar styles
    optionsContainer
        .style('::-webkit-scrollbar', '4px')
        .style('::-webkit-scrollbar-track', 'transparent')
        .style('::-webkit-scrollbar-thumb', '#cbd5e1')
        .style('::-webkit-scrollbar-thumb:hover', '#94a3b8');

    // Add options for hidden layers
    hiddenLayers.forEach(layer => {
        const option = optionsContainer
            .append('div')
            .style('padding', '8px 12px')
            .style('cursor', 'pointer')
            .style('transition', 'background-color 0.2s')
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('gap', '8px')
            .style('height', '22px')
            .style('box-sizing', 'border-box')
            .on('mouseenter', function () {
                d3.select(this).style('background-color', '#f3f4f6');
            })
            .on('mouseleave', function () {
                d3.select(this).style('background-color', 'white');
            })
            .on('click', () => {
                const layerClass = `.layer-${layer.text.toLowerCase().replace(/\s+/g, '-')}`;
                d3.selectAll(layerClass).style('display', 'block');
                menuContainer.remove();
            });

        option
            .append('span')
            .style('color', '#6b7280')
            .style('font-size', '12px')
            .style('min-width', '16px')
            .style('text-align', 'center')
            .html(getLayerTypeIcon(layer.type || 'text'));

        option
            .append('span')
            .style('font-size', '12px')
            .style('white-space', 'nowrap')
            .style('overflow', 'hidden')
            .style('text-overflow', 'ellipsis')
            .text(t(`layer.block.${layer.text.toLowerCase().replace(/\s+/g, '')}`));
    });

    // Add scroll indicators if needed
    if (hiddenLayers.length > 3) { // Changed from 4 to 3
        // Add scroll shadows
        const shadowTop = menuContainer
            .append('div')
            .style('position', 'absolute')
            .style('top', '36px') // Below header
            .style('left', '0')
            .style('right', '0')
            .style('height', '8px')
            .style('background', 'linear-gradient(to bottom, rgba(0,0,0,0.05), transparent)')
            .style('pointer-events', 'none')
            .style('opacity', '0')
            .style('transition', 'opacity 0.2s');

        const shadowBottom = menuContainer
            .append('div')
            .style('position', 'absolute')
            .style('bottom', '0')
            .style('left', '0')
            .style('right', '0')
            .style('height', '8px')
            .style('background', 'linear-gradient(to top, rgba(0,0,0,0.05), transparent)')
            .style('pointer-events', 'none')
            .style('opacity', '1')
            .style('transition', 'opacity 0.2s');

        // Update shadows on scroll
        optionsContainer.on('scroll', function () {
            const element = this as HTMLElement;
            const scrollTop = element.scrollTop;
            const scrollHeight = element.scrollHeight;
            const clientHeight = element.clientHeight;

            shadowTop.style('opacity', scrollTop > 0 ? '1' : '0');
            shadowBottom.style('opacity',
                scrollHeight - scrollTop - clientHeight > 1 ? '1' : '0');
        });
    }

    // Close menu on outside click
    d3.select('body').on('click.layer-menu', function (event) {
        if (!menuContainer.node()?.contains(event.target as Node)) {
            menuContainer.remove();
            d3.select('body').on('click.layer-menu', null);
        }
    });

    return menuContainer;
}
export async function handleLinkLayerEdit(
    layer: Layer,
    onSave: (value: string) => void,
    t: (key: string) => string,
    attributesDataEditable: any
) {
    // Create modal container with a semi-transparent backdrop
    const modal = document.createElement('div');
    modal.className = 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 sm:p-6 md:p-8';
    document.body.appendChild(modal);

    // Create modal content with improved styling
    const content = document.createElement('div');
    content.className = 'bg-white rounded-lg shadow-xl w-full max-w-lg mx-auto transform transition-all';
    content.style.maxHeight = '90vh';
    content.style.overflowY = 'auto';
    modal.appendChild(content);

    // Create header section
    const header = document.createElement('div');
    header.className = 'px-6 py-4 border-b border-gray-200';
    content.appendChild(header);

    const title = document.createElement('h2');
    title.className = 'text-xl font-semibold text-gray-900';
    title.textContent = t('layer.connectYourProcessMaps');
    header.appendChild(title);

    // Create main content section
    const mainContent = document.createElement('div');
    mainContent.className = 'px-6 py-4 space-y-4';
    content.appendChild(mainContent);

    // Process selection section
    const processSection = document.createElement('div');
    processSection.className = 'space-y-2';
    mainContent.appendChild(processSection);

    const processLabel = document.createElement('label');
    processLabel.className = 'block text-sm font-medium text-gray-700';
    processLabel.textContent = t('layer.selectProcess');
    processSection.appendChild(processLabel);

    // Create search input
    const searchInput = document.createElement('input');
    searchInput.type = 'text';
    searchInput.placeholder = t('layer.searchProcesses') + '...';
    searchInput.className = 'w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mb-2';
    processSection.appendChild(searchInput);

    // Create custom dropdown container
    const dropdownContainer = document.createElement('div');
    dropdownContainer.className = 'relative';
    processSection.appendChild(dropdownContainer);

    const dropdownList = document.createElement('div');
    dropdownList.className = 'absolute w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto z-10';
    dropdownList.style.display = 'none';
    dropdownContainer.appendChild(dropdownList);

    // Selected process display
    const selectedDisplay = document.createElement('div');
    selectedDisplay.className = 'w-full px-3 py-2 border border-gray-300 rounded-md cursor-pointer bg-white flex justify-between items-center';
    selectedDisplay.innerHTML = '<span class="text-gray-500">Select a process</span><span class="text-gray-400">▼</span>';
    dropdownContainer.appendChild(selectedDisplay);

    // Map selection section
    const mapSection = document.createElement('div');
    mapSection.className = 'space-y-2 hidden mt-4';
    mainContent.appendChild(mapSection);

    const mapLabel = document.createElement('label');
    mapLabel.className = 'block text-sm font-medium text-gray-700';
    mapLabel.textContent = t('layer.selectMap');
    mapSection.appendChild(mapLabel);

    // Create custom map dropdown
    const mapDropdownContainer = document.createElement('div');
    mapDropdownContainer.className = 'relative';
    mapSection.appendChild(mapDropdownContainer);

    const mapDropdownList = document.createElement('div');
    mapDropdownList.className = 'absolute w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto z-10';
    mapDropdownList.style.display = 'none';
    mapDropdownContainer.appendChild(mapDropdownList);

    const mapSelectedDisplay = document.createElement('div');
    mapSelectedDisplay.className = 'w-full px-3 py-2 border border-gray-300 rounded-md cursor-pointer bg-white flex justify-between items-center';
    mapSelectedDisplay.innerHTML = '<span class="text-gray-500">Select a map</span><span class="text-gray-400">▼</span>';
    mapDropdownContainer.appendChild(mapSelectedDisplay);

    // Footer section with buttons
    const footer = document.createElement('div');
    footer.className = 'px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end space-x-3 rounded-b-lg';
    content.appendChild(footer);

    const cancelButton = document.createElement('button');
    cancelButton.className = 'px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';
    cancelButton.textContent = t('words.cancel');
    footer.appendChild(cancelButton);

    const saveButton = document.createElement('button');
    saveButton.className = 'px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed';
    saveButton.textContent = t('words.save');
    saveButton.disabled = true;
    footer.appendChild(saveButton);

    // Load processes from attributesDataEditable and filter those with diagrams
    const processes = (attributesDataEditable.processesWithDiagrams || [])
        .filter((p: any) => p.diagrams && p.diagrams.length > 0);

    let selectedProcess: any | null = null;
    let selectedMapId: string | null = null;

    // Function to render process list
    function renderProcessList(searchTerm = '') {
        dropdownList.innerHTML = '';
        const filteredProcesses = processes.filter((p: any) =>
            p.title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (filteredProcesses.length === 0) {
            const noResults = document.createElement('div');
            noResults.className = 'px-3 py-2 text-gray-500 text-center';
            noResults.textContent = searchTerm ? 'No matching processes found' : 'No processes with diagrams available';
            dropdownList.appendChild(noResults);
        } else {
            filteredProcesses.forEach((process: any) => {
                const item = document.createElement('div');
                item.className = 'px-3 py-2 hover:bg-blue-50 cursor-pointer flex justify-between items-center';

                const titleSpan = document.createElement('span');
                titleSpan.textContent = process.title;
                item.appendChild(titleSpan);

                const diagramCount = document.createElement('span');
                diagramCount.className = 'text-sm text-gray-500';
                diagramCount.textContent = `${process.diagrams?.length || 0} diagram${process.diagrams?.length !== 1 ? 's' : ''}`;
                item.appendChild(diagramCount);

                item.addEventListener('click', () => {
                    selectedProcess = process;
                    selectedDisplay.innerHTML = `<span class="text-gray-900">${process.title}</span><span class="text-gray-400">▼</span>`;
                    dropdownList.style.display = 'none';
                    updateMapDropdown(process);
                });

                dropdownList.appendChild(item);
            });
        }
    }

    // Function to render map list
    function renderMapList(process: any) {
        mapDropdownList.innerHTML = '';
        const diagrams = process.diagrams || [];

        diagrams.forEach((diagram: any) => {
            const item = document.createElement('div');
            item.className = 'px-3 py-2 hover:bg-blue-50 cursor-pointer';
            item.textContent = diagram.name;

            item.addEventListener('click', () => {
                selectedMapId = diagram._id;
                mapSelectedDisplay.innerHTML = `<span class="text-gray-900">${diagram.name}</span><span class="text-gray-400">▼</span>`;
                mapDropdownList.style.display = 'none';
                saveButton.disabled = false;
            });

            mapDropdownList.appendChild(item);
        });
    }

    // Function to update map dropdown
    function updateMapDropdown(process: any) {
        mapSection.classList.remove('hidden');
        selectedMapId = null;
        mapSelectedDisplay.innerHTML = '<span class="text-gray-500">Select a map</span><span class="text-gray-400">▼</span>';
        saveButton.disabled = true;
        renderMapList(process);
    }

    // Event listeners for search
    searchInput.addEventListener('input', (e) => {
        const target = e.target as HTMLInputElement;
        renderProcessList(target.value);
        dropdownList.style.display = 'block';
    });

    // Event listeners for dropdowns
    selectedDisplay.addEventListener('click', () => {
        dropdownList.style.display = dropdownList.style.display === 'none' ? 'block' : 'none';
        mapDropdownList.style.display = 'none';
    });

    mapSelectedDisplay.addEventListener('click', () => {
        mapDropdownList.style.display = mapDropdownList.style.display === 'none' ? 'block' : 'none';
        dropdownList.style.display = 'none';
    });

    // Initial render
    renderProcessList();

    // Handle save
    saveButton.addEventListener('click', () => {
        if (selectedProcess?._id && selectedMapId) {
            const url = `/process/mapping/${selectedProcess._id}/${selectedMapId}`;
            onSave(url);
            modal.remove();
        }
    });

    // Handle cancel
    cancelButton.addEventListener('click', () => {
        modal.remove();
    });

    // Handle click outside dropdowns to close them
    document.addEventListener('click', (e) => {
        if (!dropdownContainer.contains(e.target as Node)) {
            dropdownList.style.display = 'none';
        }
        if (!mapDropdownContainer.contains(e.target as Node)) {
            mapDropdownList.style.display = 'none';
        }
    });

    // Handle click outside modal
    modal.addEventListener('click', (e) => {
        if (e.target === modal) {
            modal.remove();
        }
    });

    // Add keyboard support for closing modal
    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            modal.remove();
        }
    });

    // Prevent scrolling of background content
    document.body.style.overflow = 'hidden';
    modal.addEventListener('beforeremove', () => {
        document.body.style.overflow = '';
    });
}