import React from 'react';

interface RightSidebarProps {
    className?: string;
    children?: React.ReactNode;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ className = '', children }) => {
    return (
        <div
            className={`w-64 bg-light border-r border-gray-200 flex flex-col p-4 pt-0 h-screen fixed right-0 ms-2 w-[200px] md:w-[200px] lg:w-[250px] xl:w-[280px] 2xl:w-[500px] ${className}`}
            style={{ background: '#00909' }}
        >
            {children}
        </div>
    );
};

export { RightSidebar };
