export const STYLES = {
  header: {
    height: 40,
    gap: 10,
    width: 800,
    laneHeight: 200,
    sideHeaderWidth: 50
  },
  process: {
    width: 120,
    height: 60
  },
  decision: {
    width: 100,
    height: 60
  },
  startEnd: {
    width: 120,
    height: 60,
    borderRadius: 30  // Arrondi pour start/end
  },
  grid: {
    size: 10,
    color: 'rgba(0, 0, 0, 0.1)'
  },
  colors: {
    background: '#EFEFEF ',
    border: '#ccc',
    text: '#333',
    processBackground: '#fff',
    connectionPoint: '#4299e1',
    arrow: '#666',
    selected: '#3b82f6',

    // Fonction pour récupérer la couleur de fond et du texte en fonction du type
    getBlockColors: (type: string) => {
      const bgColorMap: Record<string, string> = {
        start: '#008080',
        end: '#ff5757',
        process: '#c7755b',
        decision: '#c7755b'
      };

      return {
        bgColor: bgColorMap[type] || STYLES.colors.processBackground,
        text: type === "process" || type === "decision" || type === "start" || type === "end"
          ? '#fff'
          : STYLES.colors.text
      };
    }
  }
} as const;
