// Reac Component
import React from 'react'
import { NavLink, Link, useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


// App Component
import { FamlaServices } from '../../services'
import { Metrics } from '../../constants'
import { Buttons, InputForm } from '../../components'
import { Routes } from '../../routes'

// Features
import { iAlert } from '../../interfaces'
// import ButtonSocialeNetwork from './ButtonSocialeNetwork'
import { useAppDispatch } from '../../stores'
import iSessions from './Sessions'
import { login } from '../../stores'


const UsersLogin = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [user, setUser] = React.useState({
        email: '',
        password: '',
        isSubmit: false,
        isValidMail: true
    });
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })
    let [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        if (searchParams.get("status") != null) {
            if (searchParams.get("status") === "10") {
                setAlert({ isActive: true, type: 'warning', message: t('views.auth.yourSessionHasExp') });
            }

            setSearchParams({})
        }
    }, [searchParams, setSearchParams, t])


    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (user.email.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.emailIsRequired') });
            return;
        }
        else if (user.password.length === 0) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.passwordIsRequired') });
            return;
        }

        let formData = {
            email: user.email,
            password: user.password,
        }
        setUser({ ...user, isSubmit: true });
        let data = await FamlaServices("api_host", "/auth/login", "POST", formData, "");

        if (data.status === 200) {
            let sessions: iSessions = data.body.data.session;
            dispatch(login({ token: data.body.data.access_token, session: sessions, user: data.body.data.session.user }));
            setUser({ email: '', password: '', isSubmit: false, isValidMail: true });

            if (sessions.company_id === null) {
                navigate(Routes.CREATE_MY_COMPANY)
            } else {
                navigate(Routes.HOMEVIEW);
            }

        }
        else if (data.status === 400) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.incorrectEmailOrPassword') });
            setUser({ ...user, email: user.email, password: '', isSubmit: false });
        }
        else if (data.status === 401) {
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.verifyYourEmail') });
            setUser({ ...user, email: user.email, password: '', isSubmit: false });
        }
        else {
            setUser({ ...user, email: user.email, password: '', isSubmit: false });
            setAlert({ isActive: true, type: 'danger', message: t('views.auth.anErrorOcured') });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { type, name, value, checked } = event.target;
        let fieldValue: string | number | boolean;
        if (type === 'checkbox') {
            fieldValue = checked;
        } else if (type === 'number') {
            fieldValue = value !== '' ? Number(value) : '';
        } else {
            fieldValue = value !== undefined ? value : '';
        }
        fieldValue = typeof fieldValue !== 'string' ? String(fieldValue) : fieldValue;
        const trimmedValue: string = typeof fieldValue === 'string' ? fieldValue.trim() : '';
        let isEmailValid = name === 'email' ? Metrics.mailFormat.test(trimmedValue) : true;

        setUser({ ...user, [name]: fieldValue, isValidMail: isEmailValid });
    };


    return (
        <div className="w-75">
            <div className="mb-4">
                <h1 className="mb-1 fw-bold text-light fs-3">{t('views.auth.logIn')}</h1>
            </div>
            <form onSubmit={handleSubmit}>
                {alert.isActive &&
                    <div className={`alert alert-${alert.type}`} role="alert">
                        {alert.message}
                    </div>
                }

                <InputForm
                    inputType="email"
                    inputLabel={t('views.auth.emailAddresPro')}
                    inputName="email"
                    inputValue={user.email}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    inputContainerClass={user.isValidMail ? 'mb-3' : 'mb-0'}
                />
                <span className={`text-danger fs-6 d-bloc ${!user.isValidMail ? 'mb-3' : 'mb-0'}`}>{user.isValidMail ? '' : t('views.auth.enterValidMail')}</span>

                <InputForm
                    inputType="password"
                    inputLabel={t('views.auth.password')}
                    inputName="password"
                    inputValue={user.password}
                    isFloatingLabel={true}
                    labelClass="text-theme"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    inputContainerClass="mb-3"
                    withIcon={true}
                    iconClass="text-theme"
                />
                <div className="d-lg-flex justify-content-between align-items-center mb-4" style={{ marginTop: "-1rem" }}>
                    <div>
                        <NavLink to={Routes.AUTH_RESET_PASSWORD} className="text-theme fs-6 text-decoration-none">{t('views.auth.forgotPassword')}</NavLink>
                    </div>
                </div>

                <Buttons
                    buttonType="submit"
                    name={t('views.auth.logIn')}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => { handleSubmit(event) }}
                    isLoading={user.isSubmit}
                    btnClass="btn-theme w-100"
                    labelClass="text-white fw-bold"
                    styleProps={{ height: 45 }}
                    arrowVisible={false}
                    spinnerColor={"text-warning"}
                    arrowColor=''
                />

                <p className="mt-2 text-light">
                    {t('views.auth.dontHaveAccount')} <Link to={Routes.AUTH_SIGNIN} className="text-decoration-none text-warning">{t('views.auth.signUp')}</Link>
                </p>
            </form>

            {/* <ButtonSocialeNetwork t={t} /> */}
        </div>
    )
}

export default UsersLogin;