import * as d3 from 'd3';
import { SwimlaneData, Block, ProcessGroup, EditMode, Line } from './types';
import { STYLES } from './styles/constants';
import { renderMainHeader } from './components/MainHeader';
import { renderSubHeaders } from './components/SubHeaders';
import { renderControls } from './components/Controls';
import { ProcessManager } from './components/ProcessManager';
import { LineManager } from './components/LineManager/LineManager';
import { removeBlockAndConnections } from './utils/blockUtils';
import { HistoryManager } from './utils/historyManager';
import { addRow, deleteRow } from './utils/rowOperations';

export class SwimlaneHeaders {
  private container: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private data: SwimlaneData;
  private blocks: Block[];
  private groups: ProcessGroup[];
  private processManager?: ProcessManager;
  private lineManager?: LineManager;
  private onSave?: (data: SwimlaneData, blocks: Block[], groups: ProcessGroup[]) => void;
  private onEditModeChangeCallback?: (editMode: boolean) => void;
  private onHistoryChangeCallback?: (canUndo: boolean, canRedo: boolean) => void;
  private onBlockSelectCallback?: (block: Block | null) => void;
  private editMode: EditMode = { enabled: false };
  private historyManager: HistoryManager;
  private t: (key: string) => string;
  private mapId: string;
  private setBlocks: React.Dispatch<React.SetStateAction<Block[]>>;
  private lineLayer: string[];
  private attributesDataEditable: any


  constructor(
    containerId: string,
    data: SwimlaneData,
    blocks: Block[] = [],
    groups: ProcessGroup[] = [],
    t: (key: string) => string,
    mapId: string,
    setBlocks: React.Dispatch<React.SetStateAction<Block[]>>,
    lineLayer: string[],
    attributesDataEditable: any
  ) {
    this.data = data;
    this.blocks = blocks;
    this.groups = groups;
    this.container = d3.select<HTMLDivElement, unknown>(`#${containerId}`) as unknown as d3.Selection<HTMLDivElement, unknown, null, undefined>;
    this.historyManager = new HistoryManager();
    this.setupContainer();
    this.setupKeyboardShortcuts();
    this.historyManager.push({ data, blocks, groups });
    this.render();
    this.t = t;
    this.mapId = mapId;
    this.setBlocks = setBlocks;
    this.lineLayer = lineLayer;
    this.attributesDataEditable = attributesDataEditable
  }

  // In SwimlaneHeaders class
  public toggleLineLayers(): void {
    if (this.lineManager) {
      this.lineManager.toggleLayers();
    }
  }


  private setupContainer(): void {
    this.container
      .style('width', `${this.data.width || STYLES.header.width}px`)
      .style('position', 'relative')
      .style('font-family', 'sans-serif');
  }

  private setupKeyboardShortcuts(): void {
    d3.select('body').on('keydown.swimlane', (event) => {
      if (this.editMode.enabled && event.ctrlKey) {
        if (event.key === 'z') {
          event.preventDefault();
          this.undo();
        } else if (event.key === 'y') {
          event.preventDefault();
          this.redo();
        }
      }
    });
  }

  public render(): void {
    this.container.selectAll('*').remove();

    // Update container width
    this.container.style('width', `${this.data.width || STYLES.header.width}px`);

    renderControls(
      this.container,
      this.data,
      this.blocks,
      this.groups,
      this.editMode,
      this.historyManager,
      this.updateData.bind(this),
      this.toggleEditMode.bind(this)
    );

    renderMainHeader(
      this.container,
      this.data,
      this.handleSave.bind(this)
    );

    renderSubHeaders(
      this.container,
      this.data,
      this.handleSave.bind(this),
      this.editMode,
      this.handleRowOperation.bind(this),
      this.t
    );

    this.processManager = new ProcessManager(
      this.container,
      this.blocks,
      this.groups,
      this.editMode,
      this.handleBlockUpdate.bind(this),
      this.t,
      this.setBlocks,
      this.lineLayer,
      this.attributesDataEditable,
      this.handleLineCreated.bind(this),
      this.handleBlockSelect.bind(this),
      this.mapId,


    );

    if (this.data.lines) {
      this.lineManager = new LineManager(
        this.container,
        this.data.lines,
        this.blocks,
        this.handleLineUpdate.bind(this),
        this.editMode,
        this.t,
        this.attributesDataEditable
      );
    }

    // Update history state
    if (this.onHistoryChangeCallback) {
      this.onHistoryChangeCallback(
        this.historyManager.canUndo(),
        this.historyManager.canRedo()
      );
    }
  }

  public updateData(data?: SwimlaneData, blocks: Block[] = this.blocks, groups: ProcessGroup[] = this.groups): void {
    if (data) {
      this.data = data;
    }
    this.blocks = blocks;
    this.groups = groups;
    this.saveToHistory();
    this.render();
    this.handleSave(this.data);
  }

  public onSaveCallback(callback: (data: SwimlaneData, blocks: Block[], groups: ProcessGroup[]) => void): this {
    this.onSave = callback;
    return this;
  }

  public onEditModeChange(callback: (editMode: boolean) => void): this {
    this.onEditModeChangeCallback = callback;
    return this;
  }

  public onHistoryChange(callback: (canUndo: boolean, canRedo: boolean) => void): this {
    this.onHistoryChangeCallback = callback;
    if (this.historyManager) {
      callback(this.historyManager.canUndo(), this.historyManager.canRedo());
    }
    return this;
  }

  public onBlockSelect(callback: (block: Block | null) => void): this {
    this.onBlockSelectCallback = callback;
    return this;
  }

  private handleBlockSelect(block: Block | null): void {
    if (this.onBlockSelectCallback) {
      this.onBlockSelectCallback(block);
    }
  }

  private handleSave(data: SwimlaneData): void {
    if (this.onSave) {
      this.onSave(data, this.blocks, this.groups);
    }
  }

  private handleLineUpdate(lines: Line[]): void {
    if (this.data.lines) {
      this.data.lines = lines;
      this.saveToHistory();
      this.handleSave(this.data);
    }
  }

  private handleLineCreated(line: Line): void {
    if (!this.data.lines) {
      this.data.lines = [];
    }
    this.data.lines.push(line);
    this.saveToHistory();
    this.render();
    this.handleSave(this.data);
  }

  private handleBlockUpdate(updatedBlocks: Block[], deletedBlockId?: string): void {
    if (deletedBlockId && this.data.lines) {
      const { updatedBlocks: newBlocks, updatedLines } = removeBlockAndConnections(
        this.blocks,
        deletedBlockId,
        this.data.lines
      );
      this.blocks = newBlocks;
      if (updatedLines) {
        this.data.lines = updatedLines;
        if (this.lineManager) {
          this.lineManager.updateLines(updatedLines, newBlocks);
        }
      }
      this.saveToHistory();
      this.render();
    } else {
      this.blocks = updatedBlocks;
      if (this.lineManager && this.data.lines) {
        this.lineManager.updateLines(this.data.lines, updatedBlocks);
      }
      this.saveToHistory();
    }

    this.handleSave(this.data);
  }

  private handleRowOperation(index: number, operation: 'up' | 'down' | 'delete'): void {
    const result = operation === 'delete'
      ? deleteRow(this.data, this.blocks, this.groups, index)
      : addRow(this.data, this.blocks, this.groups, index, operation);

    this.data = result.updatedData;
    this.blocks = result.updatedBlocks;
    this.groups = result.updatedGroups;

    this.saveToHistory();
    this.render();
    this.handleSave(this.data);
  }

  public toggleEditMode(): void {
    this.editMode.enabled = !this.editMode.enabled;
    if (this.onEditModeChangeCallback) {
      this.onEditModeChangeCallback(this.editMode.enabled);
    }
    if (!this.editMode.enabled) {
      this.handleBlockSelect(null);
    }
    this.render();
  }

  public undo(): void {
    const previousState = this.historyManager.undo();
    if (previousState) {
      this.data = previousState.data;
      this.blocks = previousState.blocks;
      this.groups = previousState.groups;
      this.render();
      this.handleSave(this.data);
    }
  }

  public redo(): void {
    const nextState = this.historyManager.redo();
    if (nextState) {
      this.data = nextState.data;
      this.blocks = nextState.blocks;
      this.groups = nextState.groups;
      this.render();
      this.handleSave(this.data);
    }
  }

  private saveToHistory(): void {
    this.historyManager.push({
      data: this.data,
      blocks: this.blocks,
      groups: this.groups
    });

    if (this.onHistoryChangeCallback) {
      this.onHistoryChangeCallback(
        this.historyManager.canUndo(),
        this.historyManager.canRedo()
      );
    }
  }
}