import { Block } from '../types';
export function setupTextEditing(
  element: HTMLElement | SVGTextElement,
  block: Block,
  enabled: boolean,
  onUpdate: (id: string, newText: string) => void
) {
  if (!enabled) return;

  element.addEventListener('dblclick', (event) => {
    const e = event as MouseEvent;
    e.stopPropagation();

    // Create a container that will maintain position relative to viewport
    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.zIndex = '1000';
    container.style.pointerEvents = 'none';

    // Get element position relative to viewport
    const { top, left, width, height } = element.getBoundingClientRect();

    container.style.top = `${top}px`;
    container.style.left = `${left}px`;
    container.style.width = `${width}px`;
    container.style.height = `${height}px`;

    // Hide original text
    (element as SVGTextElement).style.visibility = 'hidden';

    // Create and setup input
    const input = document.createElement('input');
    input.value = block.text;
    input.style.width = '100%';
    input.style.height = '100%';
    input.style.position = 'absolute';
    input.style.textAlign = block.textStyle?.textAlign || 'center';
    input.style.fontSize = block.textStyle?.fontSize ? `${block.textStyle.fontSize}px` : '14px';
    input.style.fontWeight = 'normal';
    input.style.color = 'white';
    input.style.background = 'transparent';
    input.style.border = 'none';
    input.style.outline = 'none';
    input.style.padding = '0';
    input.style.margin = '0';
    input.style.pointerEvents = 'auto';

    // Add input to container
    container.appendChild(input);
    document.body.appendChild(container);

    // Focus and select text
    input.focus();
    input.select();

    let isSaved = false;

    const cleanup = () => {
      if (document.body.contains(container)) {
        document.body.removeChild(container);
      }
      (element as SVGTextElement).style.visibility = 'visible';
    };

    const saveChanges = () => {
      if (isSaved) return;
      isSaved = true;

      const newText = input.value.trim();
      if (newText && newText !== block.text) {
        block.text = newText;
        onUpdate(block.id, newText);
      }

      element.textContent = block.text;
      cleanup();
    };

    // Handle input events
    input.addEventListener('blur', saveChanges, { once: true });
    input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        saveChanges();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        cleanup();
      }
    });

    // Update container position on scroll
    const updatePosition = () => {
      if (!isSaved) {
        const rect = element.getBoundingClientRect();
        container.style.top = `${rect.top}px`;
        container.style.left = `${rect.left}px`;
      }
    };

    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    // Cleanup event listeners when done
    const cleanupEvents = () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };

    input.addEventListener('blur', cleanupEvents, { once: true });
  });
}




