import * as d3 from 'd3';
import { Line, Block } from '../../types';
import { STYLES } from '../../styles/constants';
import { calculateConnectionPoint, calculateElbowPoints, createArrowMarker } from '../../utils/connectionUtils';

export class LineRenderer {
  private svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private editMode: boolean;

  constructor(
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    editMode: { enabled: boolean }
  ) {
    this.svg = svg;
    svg.style('z-index', '1');
    this.editMode = editMode.enabled;
    createArrowMarker(this.svg);
  }

  public renderLine(line: Line, fromBlock: Block, toBlock: Block, isSelected: boolean) {
    const startPoint = calculateConnectionPoint(fromBlock, line?.fromSide as any);
    const endPoint = calculateConnectionPoint(toBlock, line.toSide as any);
    const points = calculateElbowPoints(startPoint, endPoint);

    const linePath = d3.line<{ x: number; y: number }>()
      .x(d => d.x)
      .y(d => d.y)
      .curve(d3.curveMonotoneX);

    // Create a group for the line elements
    const lineGroup = this.svg.append('g');

    // Create invisible wider path for better hit detection
    const hitArea = lineGroup
      .append('path')
      .attr('d', linePath(points))
      .attr('fill', 'none')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '15');

    // Visible path
    const visiblePath = lineGroup
      .append('path')
      .attr('d', linePath(points))
      .attr('fill', 'none')
      .attr('stroke', isSelected ? STYLES.colors.selected : STYLES.colors.arrow)
      .attr('stroke-width', isSelected ? 2 : 1.5)
      .attr('marker-end', 'url(#arrowhead)')
      .style('pointer-events', 'none');

    return { hitArea, visiblePath, points };
  }
}