import React, { useRef, useState } from 'react';
import { Search } from 'lucide-react';
import { iUser } from '../../components/type';
import { useTranslation } from 'react-i18next';
import { Spiner } from '../../components';
import { iAlert } from '../../interfaces';

interface ShareModalProps {
    onShare: (userIds: string[]) => void;
    users: iUser[];
    isSubmit: boolean
    alert: iAlert
}

export function ShareProcessModal({ onShare, users, isSubmit, alert }: ShareModalProps) {
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { t } = useTranslation();
    const closeRef = useRef(null)

    const filteredUsers = users.filter(user =>
        user.fullname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const toggleUser = (userId: string) => {
        setSelectedUsers(prev =>
            prev.includes(userId)
                ? prev.filter(id => id !== userId)
                : [...prev, userId]
        );
    };

    const handleShare = () => {
        onShare(selectedUsers);
        setTimeout(() => {
            setSelectedUsers([])
        }, 3000);
    };

    return (
        <div className="modal fade" id="shareProcessModal" data-bs-backdrop="static" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark text-light">
                    <div className="modal-header border-0">
                        <h5 className="modal-title">{t('dashboard.shareToSelectedUser')}</h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        {/* <div className="input-group mb-3">
                            <span className="input-group-text bg-secondary text-light"><Search className="w-5 h-5" /></span>
                            <input
                                type="text"
                                className="form-control bg-dark text-light"
                                placeholder="Rechercher des utilisateurs..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div> */}
                        {alert.isActive &&
                            <div className={`alert alert-${alert.type} mb-0  mb-2 py-1`} role="alert">
                                {alert.message}
                            </div>
                        }

                        <div className="list-group overflow-auto h-auto" >
                            {filteredUsers.map(user => (
                                <label key={user._id} className="list-group-item d-flex align-items-center bg-dark text-light">
                                    {/* <img src={user} alt={user.name} className="rounded-circle me-3" width="40" height="40" /> */}
                                    <div className="flex-fill">
                                        {/* <p className="mb-0">{user.fullname}</p> */}
                                        <small className="text-white">{user.email}</small>
                                    </div>
                                    <input
                                        type="checkbox"
                                        className="form-check-input ms-2"
                                        checked={selectedUsers.includes(user._id ?? "")}
                                        onChange={() => toggleUser(user._id ?? "")}
                                    />
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button
                            type="button"
                            onClick={handleShare}
                            disabled={selectedUsers.length === 0}
                            className="btn btn-primary">
                            {isSubmit ?
                                <Spiner className='spinner-border-sm' />
                                :
                                <>{t('words.share')}</>}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}
