import * as d3 from 'd3';
import { Line, Block, EditMode } from '../../types';
import { LineRenderer } from './LineRenderer';
import { LineTextManager } from './LineTextManager';
import { LayerManager } from './LayerManager';
import { EventManager } from './EventManager';

export class LineManager {
  private container: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private svg!: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private lines: Line[];
  private blocks: Block[];
  private selectedLine: Line | null = null;
  private onUpdate: (lines: Line[]) => void;
  private editMode: EditMode;
  private showLayers: boolean = false;
  private t: (key: string) => string;
  private attributesDataEditable: any

  private lineRenderer!: LineRenderer;
  private lineTextManager!: LineTextManager;
  private layerManager!: LayerManager;
  private eventManager!: EventManager;

  constructor(
    container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
    lines: Line[],
    blocks: Block[],
    onUpdate: (lines: Line[]) => void,
    editMode: EditMode,
    t: (key: string) => string,
    attributesDataEditable: any
  ) {
    this.container = container;
    this.lines = lines;
    this.blocks = blocks;
    this.onUpdate = onUpdate;
    this.editMode = editMode;
    this.t = t;
    this.attributesDataEditable = attributesDataEditable

    this.setupSvg();
    this.initializeManagers();
    this.setupContainerClickHandler();
    this.render();
  }

  private setupSvg() {
    this.svg = this.container
      .append('svg')
      .style('position', 'absolute')
      .style('top', '0')
      .style('left', '0')
      .style('width', '100%')
      .style('height', '100%')
      .style('pointer-events', 'none')
      .style('z-index', '1');
  }

  private setupContainerClickHandler() {
    this.container.on('click.deselectLine', (event: MouseEvent) => {
      if (event.defaultPrevented) return;

      const target = event.target as HTMLElement;

      if (target === this.container.node() ||
        (target.closest('.diagram-container') &&
          !target.closest('.line-path') &&
          !target.closest('.line-text') &&
          !target.closest('.line-layers') &&
          !target.closest('[class^="line-selection-menu-"]'))) {

        if (this.selectedLine) {
          this.selectLine(null);
        }
      }
    });
  }

  private initializeManagers() {
    this.lineRenderer = new LineRenderer(this.svg, this.editMode);
    this.lineTextManager = new LineTextManager(this.container, this.editMode, this.updateLine.bind(this));
    this.layerManager = new LayerManager(this.container, this.editMode, this.updateLine.bind(this), this.t, this.attributesDataEditable);
    this.eventManager = new EventManager(
      this.container,
      this.editMode,
      this.selectLine.bind(this),
      this.updateLine.bind(this),
      this.deleteLine.bind(this),
      this.toggleLayers.bind(this),
      this.t
    );
  }

  public updateLines(lines: Line[], blocks: Block[]) {
    this.lines = lines;
    this.blocks = blocks;
    this.render();
  }

  public updateLine(updatedLine: Line) {
    const index = this.lines.findIndex(line => line.id === updatedLine.id);
    if (index !== -1) {
      this.lines[index] = updatedLine;
      this.onUpdate(this.lines);
      this.render();
    }
  }

  public deleteLine(lineId: string) {
    this.lines = this.lines.filter(line => line.id !== lineId);
    this.selectedLine = null;
    this.onUpdate(this.lines);
    this.render();
  }

  private selectLine(line: Line | null) {
    if (line && (!this.selectedLine || this.selectedLine.id !== line.id)) {
      this.selectedLine = line;
      this.onUpdate([...this.lines]);
    } else {
      this.selectedLine = line;
    }
    this.render();
  }

  // In LineManager class



  public toggleLayers(line?: Line) {
    this.showLayers = !this.showLayers;
    // Clean up existing layer UI before toggling
    if (!this.showLayers) {
      this.lines.forEach(currentLine => {
        if (this.layerManager) {
          this.layerManager.cleanup(currentLine.id);
        }
      });
    }

    // Immediately re-render to show/hide layers
    this.render();

    // After render, ensure layer visibility is properly set
    if (this.layerManager && this.showLayers) {
      this.lines.forEach(currentLine => {
        if (currentLine.layers && currentLine.layers.length > 0) {
          // Force immediate visibility of layers
          this.layerManager.toggleLayersVisibility(currentLine.id, true);
        }
      });
    }
  }

  private cleanupElements() {
    const paths = this.svg.selectAll('path');
    if (!paths.empty()) {
      paths.remove();
    }

    const texts = this.container.selectAll('.line-text');
    if (!texts.empty()) {
      texts.remove();
    }

    // Only clean up layers if they're not being shown
    if (!this.showLayers) {
      const layers = this.container.selectAll('.line-layers');
      if (!layers.empty()) {
        layers.remove();
      }
    }
  }

  private render() {
    this.cleanupElements();

    this.lines.forEach(line => {
      const fromBlock = this.blocks.find(b => b.id === line.from);
      const toBlock = this.blocks.find(b => b.id === line.to);

      if (!fromBlock || !toBlock) return;

      try {
        const { visiblePath, points } = this.lineRenderer.renderLine(
          line,
          fromBlock,
          toBlock,
          line === this.selectedLine
        );

        if (visiblePath && points) {
          visiblePath.classed('line-path', true);

          if (this.lineTextManager) {
            this.lineTextManager.renderLineText(line, points, visiblePath);
          }

          // Render layers if they should be shown
          if (this.showLayers && line.layers && line.layers.length > 0 && this.layerManager) {
            this.layerManager.renderLayers(line, points, true); // Force layers to be visible
          }

          if (this.editMode.enabled && this.eventManager) {
            this.eventManager.attachLineEvents(line, visiblePath);
          }
        }
      } catch (error) {
        console.error(`Error rendering line ${line.id}:`, error);
      }
    });

    if (this.editMode.enabled && this.eventManager) {
      try {
        this.eventManager.attachContainerEvents();
      } catch (error) {
        console.error('Error attaching container events:', error);
      }
    }
  }
}