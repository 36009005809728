import * as d3 from 'd3';
import { Block, Position } from '../types';
import { STYLES } from '../styles/constants';

const ALIGNMENT_THRESHOLD = 30; // Increased threshold for showing guides
const SNAP_THRESHOLD = 5; // Very small snap threshold
const GUIDE_COLOR = 'rgba(33, 150, 243, 0.8)'; // More visible guides

interface AlignmentGuide {
  type: 'vertical' | 'horizontal';
  position: number;
}

export function createAlignmentGuides(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>
) {
  const guidesContainer = container
    .append('div')
    .attr('class', 'alignment-guides')
    .style('position', 'absolute')
    .style('top', '0')
    .style('left', '0')
    .style('width', '100%')
    .style('height', '100%')
    .style('pointer-events', 'none')
    .style('z-index', '1000');

  const verticalGuide = guidesContainer
    .append('div')
    .attr('class', 'vertical-guide')
    .style('position', 'absolute')
    .style('top', '0')
    .style('width', '1px')
    .style('height', '100%')
    .style('background-color', GUIDE_COLOR)
    .style('display', 'none')
    .style('transition', 'opacity 0.15s');

  const horizontalGuide = guidesContainer
    .append('div')
    .attr('class', 'horizontal-guide')
    .style('position', 'absolute')
    .style('left', '0')
    .style('height', '1px')
    .style('width', '100%')
    .style('background-color', GUIDE_COLOR)
    .style('display', 'none')
    .style('transition', 'opacity 0.15s');

  return { verticalGuide, horizontalGuide };
}

function getBlockDimensions(block: Block): { width: number; height: number } {
  if (block.type === 'start' || block.type === 'end') {
    return {
      width: STYLES.startEnd.width,
      height: STYLES.startEnd.height
    };
  } else if (block.type === 'process') {
    return {
      width: STYLES.process.width,
      height: STYLES.process.height
    };
  } else {
    return {
      width: STYLES.decision.width,
      height: STYLES.decision.height
    };
  }
}

export function findAlignments(
  currentBlock: Block,
  allBlocks: Block[],
  dragPosition: Position,
  dragSpeed?: { x: number; y: number }
): { guides: AlignmentGuide[], snappedPosition: Position } {
  const guides: AlignmentGuide[] = [];
  let snappedX = dragPosition.x;
  let snappedY = dragPosition.y;

  const otherBlocks = allBlocks.filter(block => block.id !== currentBlock.id);
  const currentDimensions = getBlockDimensions(currentBlock);
  const currentCenterX = dragPosition.x + currentDimensions.width / 2;
  const currentCenterY = dragPosition.y + currentDimensions.height / 2;

  // Only show guides, don't snap if moving fast
  const isMovingFast = dragSpeed && (Math.abs(dragSpeed.x) > 5 || Math.abs(dragSpeed.y) > 5);

  otherBlocks.forEach(block => {
    const blockDimensions = getBlockDimensions(block);
    const blockX = block.x || 0;
    const blockY = block.y || 0;
    const blockCenterX = blockX + blockDimensions.width / 2;
    const blockCenterY = blockY + blockDimensions.height / 2;

    // Check center alignment
    const xDiff = Math.abs(currentCenterX - blockCenterX);
    const yDiff = Math.abs(currentCenterY - blockCenterY);

    // Show guides within threshold
    if (xDiff < ALIGNMENT_THRESHOLD) {
      guides.push({ type: 'vertical', position: blockCenterX });
      
      // Only snap if very close and moving slowly
      if (xDiff < SNAP_THRESHOLD && !isMovingFast) {
        snappedX = blockCenterX - currentDimensions.width / 2;
      }
    }

    if (yDiff < ALIGNMENT_THRESHOLD) {
      guides.push({ type: 'horizontal', position: blockCenterY });
      
      // Only snap if very close and moving slowly
      if (yDiff < SNAP_THRESHOLD && !isMovingFast) {
        snappedY = blockCenterY - currentDimensions.height / 2;
      }
    }

    // Check edge alignments
    const edges = [
      { current: dragPosition.x, other: blockX }, // Left edges
      { current: dragPosition.x + currentDimensions.width, other: blockX + blockDimensions.width }, // Right edges
      { current: dragPosition.y, other: blockY }, // Top edges
      { current: dragPosition.y + currentDimensions.height, other: blockY + blockDimensions.height } // Bottom edges
    ];

    edges.forEach((edge, index) => {
      const diff = Math.abs(edge.current - edge.other);
      if (diff < ALIGNMENT_THRESHOLD) {
        if (index < 2) { // Vertical edges
          guides.push({ type: 'vertical', position: edge.other });
          if (diff < SNAP_THRESHOLD && !isMovingFast) {
            snappedX = index === 0 ? edge.other : edge.other - currentDimensions.width;
          }
        } else { // Horizontal edges
          guides.push({ type: 'horizontal', position: edge.other });
          if (diff < SNAP_THRESHOLD && !isMovingFast) {
            snappedY = index === 2 ? edge.other : edge.other - currentDimensions.height;
          }
        }
      }
    });
  });

  return { 
    guides, 
    snappedPosition: { 
      x: snappedX, 
      y: snappedY 
    } 
  };
}

export function updateGuides(
  verticalGuide: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  horizontalGuide: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  guides: AlignmentGuide[]
) {
  const verticalGuides = guides.filter(g => g.type === 'vertical');
  const horizontalGuides = guides.filter(g => g.type === 'horizontal');

  // Show all guides that are within threshold
  verticalGuides.forEach((guide, index) => {
    if (index === 0) { // Only show the first guide for now
      verticalGuide
        .style('display', 'block')
        .style('left', guide.position + 'px')
        .style('opacity', '1');
    }
  });

  horizontalGuides.forEach((guide, index) => {
    if (index === 0) { // Only show the first guide for now
      horizontalGuide
        .style('display', 'block')
        .style('top', guide.position + 'px')
        .style('opacity', '1');
    }
  });

  if (verticalGuides.length === 0) {
    verticalGuide.style('opacity', '0');
  }
  if (horizontalGuides.length === 0) {
    horizontalGuide.style('opacity', '0');
  }
}

export function hideGuides(
  verticalGuide: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  horizontalGuide: d3.Selection<HTMLDivElement, unknown, null, undefined>
) {
  verticalGuide.style('opacity', '0');
  horizontalGuide.style('opacity', '0');
  
  setTimeout(() => {
    verticalGuide.style('display', 'none');
    horizontalGuide.style('display', 'none');
  }, 150);
}