import * as d3 from 'd3';
import { Layer } from '../../../../types';

export class LayerHiddenRenderer {
    public render(
        container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
        hiddenLayers: Layer[],
        onShowLayer: (layer: Layer) => void
    ) {
        const hiddenSection = container
            .append('div')
            .style('padding', '12px')
            .style('background', '#f8fafc')
            .style('border-bottom', '1px solid rgba(0, 0, 0, 0.06)');

        hiddenSection
            .append('div')
            .style('font-size', '13px')
            .style('font-weight', '500')
            .style('color', '#64748b')
            .style('margin-bottom', '8px')
            .text('Hidden Layers');

        const hiddenList = hiddenSection
            .append('div')
            .style('display', 'flex')
            .style('flex-wrap', 'wrap')
            .style('gap', '6px');

        hiddenLayers.forEach(layer => {
            this.createHiddenLayerChip(hiddenList, layer, onShowLayer);
        });
    }

    public renderNoHiddenMessage(
        container: d3.Selection<HTMLDivElement, unknown, null, undefined>
    ) {
        container
            .append('div')
            .style('padding', '8px 12px')
            .style('color', '#94a3b8')
            .style('font-size', '13px')
            .style('text-align', 'center')
            .style('border-bottom', '1px solid rgba(0, 0, 0, 0.06)')
            .text('No hidden properties');
    }

    private createHiddenLayerChip(
        container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
        layer: Layer,
        onShowLayer: (layer: Layer) => void
    ) {
        const chip = container
            .append('button')
            .style('background', '#eef2ff')
            .style('color', '#6366f1')
            .style('border', 'none')
            .style('border-radius', '16px')
            .style('padding', '4px 10px')
            .style('font-size', '12px')
            .style('font-weight', '500')
            .style('cursor', 'pointer')
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('gap', '6px')
            .style('transition', 'all 0.2s ease')
            .attr('title', layer.text)
            .on('mouseenter', function () {
                d3.select(this)
                    .style('background', '#e0e7ff')
                    .style('transform', 'translateY(-1px)');
            })
            .on('mouseleave', function () {
                d3.select(this)
                    .style('background', '#eef2ff')
                    .style('transform', 'translateY(0)');
            })
            .on('click', () => onShowLayer(layer));

        chip
            .append('span')
            .text(this.truncateText(layer.text, 15));

        chip
            .append('span')
            .html('×')
            .style('font-size', '14px')
            .style('line-height', '1');

        return chip;
    }

    private truncateText(text: string, maxLength: number): string {
        if (!text) return '';
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    }
}