import * as d3 from 'd3';
import { Position, Block } from '../../types';
import { snapToGrid } from '../../utils/gridUtils';
import { createAlignmentGuides, findAlignments, updateGuides, hideGuides } from '../../utils/alignmentGuides';

export function implementDrag(
  element: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  elementId: string,
  blocks: Block[],
  onDragEnd: (id: string, position: Position) => void,
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>
) {
  const container = d3.select(element.node()?.parentNode as HTMLElement);
  const { verticalGuide, horizontalGuide } = createAlignmentGuides(container as unknown as d3.Selection<HTMLDivElement, unknown, null, undefined>);

  // Remove any existing container click handlers
  container.on('click.deselect', null);

  // Add container-level click handler for deselection
  container.on('click.deselect', function (event: MouseEvent) {
    // Stop if we're in a drag operation
    if (event.defaultPrevented) return;

    const target = event.target as HTMLElement;

    // Check if the click is directly on the container (not on a block or its children)
    if (target === container.node() ||
      (target.closest('.diagram-container') &&
        !target.closest('.block') &&
        !target.closest('[class^="block-selection-menu-"]') &&
        !target.closest('.connection-point'))) {

      // Deselect all blocks
      container.selectAll('.block-selected')
        .classed('block-selected', false)
        .style('box-shadow', 'none');

      // Update blocks state to deselect all
      setBlocks && setBlocks(prevBlocks =>
        prevBlocks.map(block => ({
          ...block,
          selected: false
        }))
      );

      // Remove all menus and connection points
      container.selectAll('[class^="block-selection-menu-"]').remove();
      container.selectAll('.connection-point').remove();
    }
  });

  const drag = d3.drag<HTMLDivElement, unknown>()
    .on('start', (event) => {
      event.sourceEvent.stopPropagation();

      element
        .style('transform', 'scale(1.02)')
        .style('transition', 'transform 0.1s ease');

      // Remove existing menus and connection points
      container.select(`[class^="block-selection-menu-${elementId}"]`).remove();
      container.selectAll('.connection-point').remove();
    })
    .on('drag', (event) => {
      const x = parseInt(element.style('left')) + event.dx;
      const y = parseInt(element.style('top')) + event.dy;

      const currentBlock = blocks.find(b => b.id === elementId);
      if (!currentBlock) return;

      const { guides, snappedPosition } = findAlignments(
        currentBlock,
        blocks,
        { x, y }
      );

      updateGuides(verticalGuide, horizontalGuide, guides);

      element
        .style('left', `${snappedPosition.x}px`)
        .style('top', `${snappedPosition.y}px`);

      if (currentBlock) {
        currentBlock.x = snappedPosition.x;
        currentBlock.y = snappedPosition.y;
      }
    })
    .on('end', (event) => {
      event.sourceEvent.stopPropagation();

      const currentX = parseInt(element.style('left'));
      const currentY = parseInt(element.style('top'));

      const snappedPosition = snapToGrid({ x: currentX, y: currentY });

      element
        .style('left', `${snappedPosition.x}px`)
        .style('top', `${snappedPosition.y}px`)
        .style('transform', 'scale(1)')
        .style('transition', 'transform 0.1s ease');

      hideGuides(verticalGuide, horizontalGuide);
      onDragEnd(elementId, snappedPosition);

      // Handle selection after drag
      requestAnimationFrame(() => {
        setBlocks && setBlocks(prevBlocks =>
          prevBlocks.map(block => ({
            ...block,
            x: block.id === elementId ? snappedPosition.x : block.x,
            y: block.id === elementId ? snappedPosition.y : block.y,
            selected: block.id === elementId
          }))
        );

        // Update visual selection
        container.selectAll('.block-selected')
          .classed('block-selected', false)
          .style('box-shadow', 'none');

        element
          .classed('block-selected', true)
          .style('box-shadow', '0 0 0 2px rgba(59, 130, 246, 0.5)');
      });
    });

  element.call(drag);

  // Handle block clicks with Ctrl/Cmd key for multiple selection
  element.on('click', (event: Event) => {
    event.stopPropagation();
    const e = event as MouseEvent;
    const multiSelect = e.ctrlKey || e.metaKey;

    if (!multiSelect) {
      // Single selection - deselect all other blocks
      container.selectAll('.block-selected')
        .classed('block-selected', false)
        .style('box-shadow', 'none');

      setBlocks(prevBlocks =>
        prevBlocks.map(block => ({
          ...block,
          selected: block.id === elementId
        }))
      );
    } else {
      // Multiple selection - toggle current block
      const isSelected = element.classed('block-selected');

      setBlocks(prevBlocks =>
        prevBlocks.map(block => ({
          ...block,
          selected: block.id === elementId ? !isSelected : block.selected
        }))
      );
    }

    // Update visual selection for the clicked block
    element
      .classed('block-selected', !element.classed('block-selected') || !multiSelect)
      .style('box-shadow', element.classed('block-selected') ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : 'none');
  });

  // Ensure block class is present
  element.classed('block', true);

  // Ensure container has the diagram-container class
  container.classed('diagram-container', true);
}