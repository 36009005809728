import { useState, useCallback } from 'react';
import { Block } from '../lib/types';

export const useClipboardActions = (
  blocks: Block[],
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>,
  swimlaneRef: React.RefObject<any>
) => {
  const [clipboard, setClipboard] = useState<Block[]>([]);

  // Helper function to generate a unique ID
  const generateUniqueId = (prefix: string) => `${prefix}-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

  // Copy selected blocks
  const handleCopy = useCallback(() => {
    // First check for blocks with selected property
    let selectedBlocks = blocks.filter(block => block.selected);
    
    // If no blocks have the selected property, check for the currently selected block
    if (selectedBlocks.length === 0 && swimlaneRef.current?.getSelectedBlock) {
      const currentSelectedBlock = swimlaneRef.current.getSelectedBlock();
      if (currentSelectedBlock) {
        selectedBlocks = [currentSelectedBlock];
      }
    }
    
    // If still no blocks, check for DOM elements with the block-selected class
    if (selectedBlocks.length === 0) {
      const selectedElements = document.querySelectorAll('.block-selected');
      if (selectedElements.length > 0) {
        const selectedIds = Array.from(selectedElements).map(el => el.id || '');
        selectedBlocks = blocks.filter(block => selectedIds.includes(block.id));
      }
    }
    
    // If we have a single selected block from the swimlaneRef but it's not in the selectedBlocks array
    if (selectedBlocks.length === 0 && swimlaneRef.current?.selectedBlock) {
      const currentBlock = swimlaneRef.current.selectedBlock;
      if (currentBlock) {
        selectedBlocks = [currentBlock];
      }
    }
    
    if (selectedBlocks.length === 0) {
      console.log('No blocks selected for copying');
      return;
    }
    
    setClipboard(selectedBlocks);
    console.log(`Copied ${selectedBlocks.length} blocks to clipboard`);
    return selectedBlocks;
  }, [blocks, swimlaneRef]);

  // Cut selected blocks
  const handleCut = useCallback(() => {
    // First check for blocks with selected property
    let selectedBlocks = blocks.filter(block => block.selected);
    
    // If no blocks have the selected property, check for the currently selected block
    if (selectedBlocks.length === 0 && swimlaneRef.current?.getSelectedBlock) {
      const currentSelectedBlock = swimlaneRef.current.getSelectedBlock();
      if (currentSelectedBlock) {
        selectedBlocks = [currentSelectedBlock];
      }
    }
    
    // If still no blocks, check for DOM elements with the block-selected class
    if (selectedBlocks.length === 0) {
      const selectedElements = document.querySelectorAll('.block-selected');
      if (selectedElements.length > 0) {
        const selectedIds = Array.from(selectedElements).map(el => el.id || '');
        selectedBlocks = blocks.filter(block => selectedIds.includes(block.id));
      }
    }
    
    // If we have a single selected block from the swimlaneRef but it's not in the selectedBlocks array
    if (selectedBlocks.length === 0 && swimlaneRef.current?.selectedBlock) {
      const currentBlock = swimlaneRef.current.selectedBlock;
      if (currentBlock) {
        selectedBlocks = [currentBlock];
      }
    }
    
    if (selectedBlocks.length === 0) {
      console.log('No blocks selected for cutting');
      return;
    }
    
    setClipboard(selectedBlocks);
    
    // Remove the selected blocks from the diagram
    const selectedIds = selectedBlocks.map(block => block.id);
    const updatedBlocks = blocks.filter(block => !selectedIds.includes(block.id));
    setBlocks(updatedBlocks);
    swimlaneRef.current?.updateData(undefined, updatedBlocks);
    
    console.log(`Cut ${selectedBlocks.length} blocks`);
    return selectedBlocks;
  }, [blocks, setBlocks, swimlaneRef]);

  // Paste blocks from clipboard
  const handlePaste = useCallback(() => {
    if (clipboard.length === 0) {
      console.log('Clipboard is empty');
      return;
    }
    
    // Calculate offset to avoid direct overlap
    const OFFSET_X = 20;
    const OFFSET_Y = 20;
    
    // Create new blocks with new IDs and slightly offset positions
    const newBlocks = clipboard.map(block => {
      const newBlock: Block = {
        ...JSON.parse(JSON.stringify(block)), // Deep clone to avoid reference issues
        id: generateUniqueId(block.type),
        x: (block.x || 0) + OFFSET_X,
        y: (block.y || 0) + OFFSET_Y,
        selected: true // Select the newly pasted blocks
      };
      
      return newBlock;
    });
    
    // Deselect all existing blocks
    const updatedBlocks = blocks.map(block => ({
      ...block,
      selected: false
    }));
    
    // Add the new blocks
    const finalBlocks = [...updatedBlocks, ...newBlocks];
    setBlocks(finalBlocks);
    swimlaneRef.current?.updateData(undefined, finalBlocks);
    
    // Update the DOM to reflect selection
    setTimeout(() => {
      newBlocks.forEach(block => {
        const element = document.getElementById(block.id);
        if (element) {
          element.classList.add('block-selected');
          element.style.boxShadow = '0 0 0 2px rgba(59, 130, 246, 0.5)';
        }
      });
    }, 50);
    
    console.log(`Pasted ${newBlocks.length} blocks`);
    return newBlocks;
  }, [clipboard, blocks, setBlocks, swimlaneRef]);

  return {
    clipboard,
    handleCopy,
    handleCut,
    handlePaste
  };
};