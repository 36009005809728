// Recat Component
import { PURGE } from 'redux-persist'
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Interface

interface AuthState {
    mapId: string;
}

const initialState: AuthState = {
    mapId: "",
}

const mapIdSlice = createSlice({
    name: "mapId",
    initialState,
    reducers: {
        mapId: (state, action: PayloadAction<{ mapId: string }>) => {
            state.mapId = action.payload.mapId;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.mapId = '';
        })
    }
})

export default mapIdSlice.reducer;
export const { mapId } = mapIdSlice.actions; 
