import { useEffect } from 'react';

export const useAutoResizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // Reset height
      textAreaRef.style.height = '0px';

      // Get viewport height
      const viewportHeight = window.innerHeight;

      // Calculate dynamic min and max heights
      const minHeight = Math.floor(viewportHeight * 0.06); // 6% of viewport height
      const maxHeight = Math.floor(viewportHeight * 0.8); // 80% of viewport height

      // Set new height
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = `${Math.min(Math.max(minHeight, scrollHeight), maxHeight)}px`;
    }
  }, [textAreaRef, value]);

  // Add resize listener to update heights when viewport changes
  useEffect(() => {
    const handleResize = () => {
      if (textAreaRef) {
        const viewportHeight = window.innerHeight;
        const minHeight = Math.floor(viewportHeight * 0.06);
        const maxHeight = Math.floor(viewportHeight * 0.8);
        const scrollHeight = textAreaRef.scrollHeight;
        textAreaRef.style.height = `${Math.min(Math.max(minHeight, scrollHeight), maxHeight)}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [textAreaRef]);
};