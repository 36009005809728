import React from "react";
import { InputForm, Spiner } from "../../components"
import { iAlert } from "../../interfaces";



interface InviteOwnerModalProps {
    alert: iAlert;
    email: string;
    isSubmitting: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.SyntheticEvent) => void;
    t: (key: string) => string;
}

const InviteOwnerModal: React.FC<InviteOwnerModalProps> = ({ alert, email, isSubmitting, handleChange, handleSubmit, t }) => {
    return (
        <div className="modal fade" id="inviteOwner" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white">{t("navigation.inviteOwner")}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        {alert.isActive && (
                            <div className={`alert alert-${alert.type}`} role="alert">
                                {alert.message}
                            </div>
                        )}

                        <InputForm
                            inputType="email"
                            inputLabel="Email"
                            inputName="email"
                            inputValue={email}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={handleChange}
                            inputContainerClass="mb-3 p-0"
                            inputClass="bg-dark text-light text-bg-dark"
                            inputStyle={{ height: 0, padding: 0 }}
                        />
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            {t("words.close")}
                        </button>
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">
                            {isSubmitting ? <Spiner className="spinner-border-sm" /> : t("words.invite")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteOwnerModal;
