import * as d3 from 'd3';
import { Block } from '../../types';
import { STYLES } from '../../styles/constants';

interface BlockSelectionMenuCallbacks {
  onDelete?: (block: Block) => void;
  onAddLine?: (block: Block) => void;
  onToggleLayers?: () => void;
  onFetchInfo?: (block: Block) => void;
}

export function createBlockSelectionMenu(
  container: d3.Selection<HTMLDivElement, unknown, null, undefined>,
  block: Block,
  callbacks: BlockSelectionMenuCallbacks = {},
  t: (key: string) => string

): d3.Selection<HTMLDivElement, unknown, null, undefined> {
  // Remove any existing menus
  container.selectAll(`[class^="block-selection-menu"]`).remove();

  // Get block dimensions based on type
  let width, height;
  if (block.type === 'start' || block.type === 'end') {
    width = STYLES.startEnd.width;
    height = STYLES.startEnd.height;
  } else {
    width = block.type === 'process' ? STYLES.process.width : STYLES.decision.width;
    height = block.type === 'process' ? STYLES.process.height : STYLES.decision.height;
  }

  // Calculate menu position
  const menuX = (block.x || 0) + width / 2;
  const menuY = (block.y || 0) - 40;

  const menu = container
    .append('div')
    .attr('class', `block-selection-menu-${block.id}`)
    .style('position', 'absolute')
    .style('left', `${menuX}px`)
    .style('top', `${menuY}px`)
    .style('transform', 'translateX(-50%)')
    .style('background-color', 'white')
    .style('border', '1px solid #e2e8f0')
    .style('border-radius', '6px')
    .style('padding', '4px')
    .style('display', 'flex')
    .style('gap', '4px')
    .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)')
    .style('z-index', '5'); // Réduit pour être sous le menu des layers

  // Info button (previously line button)
  if (callbacks.onFetchInfo) {
    const infoButton = menu
      .append('button')
      .style('background-color', '#e0f2fe')
      .style('color', '#0284c7')
      .style('padding', '6px')
      .style('border-radius', '4px')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'center')
      .style('cursor', 'pointer')
      .style('border', 'none')
      .attr('title', t('features.swimlane.verifInfo'))
      .style('transition', 'all 0.2s')
      .on('mouseenter', function () {
        d3.select(this).style('background-color', '#bae6fd');
      })
      .on('mouseleave', function () {
        d3.select(this).style('background-color', '#e0f2fe');
      })
      .on('click', (event) => {
        event.stopPropagation();
        callbacks.onFetchInfo?.(block);
      });

    // Info icon (i)
    infoButton
      .append('svg')
      .attr('width', '16')
      .attr('height', '16')
      .attr('viewBox', '0 0 24 24')
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .html('<circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/>');
  }

  // Layers button
  if (callbacks.onToggleLayers) {
    const layersButton = menu
      .append('button')
      .style('background-color', '#f0fdf4')
      .style('color', '#16a34a')
      .style('padding', '6px')
      .style('border-radius', '4px')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'center')
      .style('cursor', 'pointer')
      .attr('title', t('features.swimlane.showLayers'))
      .style('border', 'none')
      .style('transition', 'all 0.2s')
      .on('mouseenter', function () {
        d3.select(this).style('background-color', '#dcfce7');
      })
      .on('mouseleave', function () {
        d3.select(this).style('background-color', '#f0fdf4');
      })
      .on('click', (event) => {
        event.stopPropagation();
        callbacks.onToggleLayers?.();
      });

    // Layers icon
    layersButton
      .append('svg')
      .attr('width', '16')
      .attr('height', '16')
      .attr('viewBox', '0 0 24 24')
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .html('<path d="M12 2 2 7l10 5 10-5-10-5z"/><path d="m2 17 10 5 10-5"/><path d="m2 12 10 5 10-5"/>');
  }

  // Delete button
  if (callbacks.onDelete) {
    const deleteButton = menu
      .append('button')
      .style('background-color', '#fee2e2')
      .style('color', '#dc2626')
      .style('padding', '6px')
      .style('border-radius', '4px')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'center')
      .style('cursor', 'pointer')
      .attr('title', t('words.delete'))
      .style('border', 'none')
      .style('transition', 'all 0.2s')
      .on('mouseenter', function () {
        d3.select(this).style('background-color', '#fecaca');
      })
      .on('mouseleave', function () {
        d3.select(this).style('background-color', '#fee2e2');
      })
      .on('click', (event) => {
        event.stopPropagation();
        callbacks.onDelete?.(block);
      });

    // Trash icon
    deleteButton
      .append('svg')
      .attr('width', '16')
      .attr('height', '16')
      .attr('viewBox', '0 0 24 24')
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .html('<path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>');
  }

  // Prevent menu from closing when clicking inside
  menu.on('click', (event) => {
    event.stopPropagation();
  });

  return menu;
}