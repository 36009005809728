// React
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../routes';
import { useTranslation } from 'react-i18next';

// Interface
// import { iAproval } from "../../type";
import { Spiner } from '../../components';

interface ChildProps {
    approvalList?: any;
    loading?: boolean
}

const ApprovalRequestModal = ({ approvalList, loading }: ChildProps) => {
    const closeModal = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    function navigateToMap(processId: string, mapId: string): void {
        if (closeModal.current) {
            closeModal.current.click();
        }
        navigate(`${Routes.MAPPING}${processId}/${mapId}`);
    }

    // function handleApproval(action: 'approve' | 'disapprove', approvalId: string) {
    //     // Logique d'approbation/désapprobation à implémenter
    //     console.log(`${action} request for ID: ${approvalId}`);
    // }
    return (
        <div className="modal" data-bs-backdrop="static" id="requestApproval" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header">
                        <h5 className="modal-title text-white">{t('navigation.diagramsPendingApproval')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body overflow-auto">
                        <>{loading ?
                            <Spiner className='spinner-border spinner-border-sm' />
                            :
                            <>
                                {approvalList?.length === 0 ?
                                    <div className="text-center text-white">
                                        <i className="fa-solid fa-triangle-exclamation "></i>
                                        <p>{t('navigation.noRequest')}</p>
                                    </div>
                                    :
                                    <>
                                        {approvalList?.map((approval: any) => (
                                            <div key={approval._id} className="d-flex justify-content-between align-items-center mb-2">
                                                <div>
                                                    <button
                                                        onClick={() => navigateToMap(approval.process._id, approval._id)}
                                                        className="btn btn-link text-decoration-none text-light">
                                                        {approval.name}
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={() => navigateToMap(approval.process._id, approval._id)}
                                                        className="btn btn-theme btn-sm me-2">
                                                        {t('words.approve')}
                                                    </button>
                                                    {/* <button
                                            onClick={() => handleApproval('disapprove', approval._id)}
                                            className="btn btn-danger btn-sm">
                                            Disapprove
                                        </button> */}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }

                            </>
                        }
                        </>

                    </div>
                    <div className="modal-footer">
                        <button ref={closeModal} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApprovalRequestModal;
