import React, { useState, useRef, useEffect } from 'react';
import { Send, Loader2, Sparkles, ThumbsUp, ThumbsDown, X, BarChart2, AlertCircle, Clock, LineChart } from 'lucide-react';
import { useAppSelector } from '../../../stores';
import { FamlaServices } from '../../../services';
import { Report } from '../../lib/types';
import { useTranslation } from 'react-i18next';
import { useAutoResizeTextArea } from '../../hooks/useAutoResizeTextArea';
import { slice } from '../../../utils';

interface AnalysisPanelProps {
    className?: string;
    processId: string;
    mapId: string;
    setShoRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FeedbackState {
    [key: string]: {
        showCommentInput: boolean;
        comment: string;
        isPositive: boolean | null;
        submitted: boolean;
    };
}

export function AnalysisPanel({ className = '', processId, mapId, setShoRightPanel }: AnalysisPanelProps) {
    const users = useAppSelector((state) => state.auth);
    const [prompt, setPrompt] = useState('');
    const [report, setReport] = useState<Report[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingReports, setIsLoadingReports] = useState(true);
    const [feedback, setFeedback] = useState<FeedbackState>({});
    const reportEndRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useAutoResizeTextArea(textAreaRef.current, prompt);

    useEffect(() => {
        const loadAllAnalysies = async () => {
            try {
                let data = await FamlaServices("api_host", `/report/map/${mapId}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    const DataReport = data.body.data;
                    setReport(DataReport);
                    setIsLoadingReports(false);
                }
            } catch (error) {
                console.error("Erreur lors du chargement des analyses", error);
            }
        };
        loadAllAnalysies();
    }, []);

    useEffect(() => {
        reportEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [report, feedback]);

    const handleSubmit = async (prompt: string) => {
        if (!prompt.trim()) return;
        const generateTempId = () => `temp-${Date.now()}-${Math.floor(Math.random() * 100000)}`;
        const tempId = generateTempId();
        setReport((prevReports) => [
            ...prevReports,
            {
                _id: tempId,
                process_id: processId,
                user_id: "",
                map_id: mapId,
                prompt: prompt,
                text: "",
                comment: null,
                created_at: new Date().toISOString(),
                updated_at: null,
            }
        ]);
        setIsLoading(true);

        try {
            const endpoint = `/report/process/${processId}/${mapId}?text=${encodeURIComponent(prompt)}`;
            const response = await FamlaServices("api_host", endpoint, "POST", "", users.access_token);
            const newReport = response.body.data;

            if (response.status === 200) {
                setReport((prevReports) =>
                    prevReports.map((report) =>
                        report._id === tempId ? newReport : report
                    )
                );
                setPrompt('');
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi du rapport", error);
            setReport((prevReports) => prevReports.filter(report => report._id !== tempId));
        } finally {
            setIsLoading(false);
            setPrompt('');
        }
    };

    const handleDownloadPDF = async (reportId: string) => {
        try {
            const response = await FamlaServices("api_host", `/report/generate-pdf/${reportId}`, "POST", "", users.access_token);

            if (response.status !== 200) {
                throw new Error(`Erreur: ${response.status}`);
            }

            if (response.contentType && response.contentType.includes("application/pdf")) {
                const blob = response.body;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `analyse_cartographique_${reportId}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Le fichier retourné n'est pas un PDF.");
            }
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier PDF :", error);
        }
    };

    const handleFeedbackClick = async (reportId: string, isPositive: boolean) => {
        // Si le feedback a déjà été soumis, ne rien faire
        if (feedback[reportId]?.submitted) return;

        if (isPositive) {
            setFeedback(prev => ({
                ...prev,
                [reportId]: {
                    showCommentInput: false,
                    comment: '',
                    isPositive: true,
                    submitted: false
                }
            }));

            try {
                const response = await FamlaServices(
                    "api_host",
                    `/report/thumbs/${reportId}`,
                    "PUT",
                    { up: true, comment: '' },
                    users.access_token
                );

                if (response.status === 200) {
                    setFeedback(prev => ({
                        ...prev,
                        [reportId]: {
                            ...prev[reportId],
                            submitted: true
                        }
                    }));
                }
            } catch (error) {
                // En cas d'erreur, réinitialiser le state
                setFeedback(prev => {
                    const newFeedback = { ...prev };
                    delete newFeedback[reportId];
                    return newFeedback;
                });
            }
        } else {
            setFeedback(prev => ({
                ...prev,
                [reportId]: {
                    showCommentInput: true,
                    comment: '',
                    isPositive: false,
                    submitted: false
                }
            }));
        }
    };

    const handleCommentSubmit = async (reportId: string) => {
        const feedbackData = feedback[reportId];
        if (feedbackData && feedbackData.comment.trim()) {
            try {
                const response = await FamlaServices(
                    "api_host",
                    `/report/thumbs/${reportId}`,
                    "PUT",
                    { up: false, comment: feedbackData.comment },
                    users.access_token
                );

                if (response.status === 200) {
                    setFeedback(prev => ({
                        ...prev,
                        [reportId]: {
                            ...prev[reportId],
                            submitted: true
                        }
                    }));
                }
            } catch (error) {
                console.error("Erreur lors de l'envoi du feedback négatif", error);
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
            e.stopPropagation();
            return;
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            e.stopPropagation();
            return;
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
            e.stopPropagation();
            return;
        }
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(prompt);

        }
    };

    const promptSuggestions = [
        {
            icon: <BarChart2 size={16} />,
            title: t('features.swimlane.summaryStatistics'),
            description: t('features.swimlane.analyzeAllAttributesData'),
            prompt: t('features.swimlane.prompt1')
        },
        {
            icon: <AlertCircle size={16} />,
            title: t('features.swimlane.bottleneckIdentification'),
            description: t('features.swimlane.identifyStakeholders'),
            prompt: t('features.swimlane.prompt2'),
        },
        {
            icon: <Clock size={16} />,
            title: t('features.swimlane.leadTimeAnalysis'),
            description: t('features.swimlane.analyzeLead'),
            prompt: t('features.swimlane.prompt3'),
        },
        {
            icon: <LineChart size={16} />,
            title: t('features.swimlane.qualityAnalysis'),
            description: t('features.swimlane.examineDefect'),
            prompt: t('features.swimlane.prompt4')
        }
    ];

    return (
        <>
            <div className="border-b border-gray-100 flex items-center justify-between">
                <div className="flex items-center gap-1 p-1">
                    <div className="">
                        <Sparkles className="p-0 pt-1" size={22} />
                    </div>
                    <h2 className="text-sm font-semibold">
                        <span className="text-xl">Famla</span>
                    </h2>
                </div>
                <div className="flex items-center gap-2">
                    <button
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                        onClick={() => setShoRightPanel(false)}
                    >
                        <X size={18} className="text-gray-600" />
                    </button>
                </div>
            </div>

            <div className="p-1 pb-2 border-b border-gray-100">
                <h2 className="text-xl font-semibold text-gray-900 text-gradient">
                    {t("words.hello")}, {users.user.fullname}
                </h2>
                <p className="text-md text-gray-500">
                    {t('features.swimlane.reportGenerate')}
                </p>
            </div>

            <div className="flex-1 p-1 pb-4 mb-14 space-y-6 overflow-auto relative">
                {isLoadingReports ? (
                    <div className="flex flex-col items-center justify-center py-12 space-y-4">
                        <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
                    </div>
                )
                    :
                    report && report.length > 0 ? (
                        <div className="space-y-4">
                            {report.map((item: Report, index) => (
                                <div key={item._id || index}>
                                    <div className="relative bg-blue-100 p-3 mb-2 rounded-tl-xl rounded-br-xl">
                                        <div className="whitespace-pre-wrap text-sm text-gray-600">
                                            {item.prompt}
                                        </div>
                                    </div>
                                    {item.text && (
                                        <div className="relative bg-gray-200 p-3 rounded-tr-xl rounded-bl-xl">
                                            <div className="whitespace-pre-wrap text-sm text-gray-600">
                                                {item.text}
                                            </div>
                                            <div className="flex items-center gap-2 mt-2">
                                                {(index === report.length - 1 && !feedback[item._id]?.submitted) && (
                                                    <>
                                                        <button
                                                            onClick={() => handleFeedbackClick(item._id, true)}
                                                            className={`p-1 rounded-full transition-colors ${feedback[item._id]?.isPositive === true
                                                                ? 'bg-blue-200 text-blue-600'
                                                                : 'hover:bg-blue-100 text-gray-600'
                                                                }`}
                                                            title={t('features.swimlane.likeReport')}
                                                        >
                                                            <ThumbsUp
                                                                size={18}
                                                                fill={feedback[item._id]?.isPositive === true ? "currentColor" : "none"}
                                                            />
                                                        </button>
                                                        <button
                                                            onClick={() => handleFeedbackClick(item._id, false)}
                                                            className={`p-1 rounded-full transition-colors ${feedback[item._id]?.isPositive === false
                                                                ? 'bg-red-200 text-red-400'
                                                                : 'hover:bg-red-100 text-gray-600'
                                                                }`}
                                                            title={t('features.swimlane.dontLikeReport')}
                                                        >
                                                            <ThumbsDown
                                                                size={18}
                                                                fill={feedback[item._id]?.isPositive === false ? "currentColor" : "none"}
                                                            />
                                                        </button>
                                                    </>
                                                )}
                                                {/* <button
                                                onClick={() => handleDownloadPDF(item._id)}
                                                title="Télécharger le rapport d'analyse"
                                                className="p-1 hover:bg-gray-300 rounded-full transition-colors ml-auto"
                                            >
                                                <Download size={18} className="text-gray-600" />
                                            </button> */}
                                            </div>
                                        </div>
                                    )}

                                    {feedback[item._id]?.showCommentInput && !feedback[item._id]?.submitted && (
                                        <div className="mt-3">
                                            <div className="relative bg-gray-100 rounded-lg p-2">
                                                <div className="mb-3">
                                                    <textarea
                                                        className="p-1 w-full bg-gray-100 text-gray-500 resize-none focus:outline-none placeholder-gray-500 min-h-[70px]"
                                                        placeholder={t('words.comments') + '...'}
                                                        value={feedback[item._id]?.comment || ''}
                                                        onChange={(e) => setFeedback(prev => ({
                                                            ...prev,
                                                            [item._id]: { ...prev[item._id], comment: e.target.value }
                                                        }))}
                                                    />
                                                </div>
                                                <div className="absolute bottom-0 right-4 flex items-center gap-2 bg-transparent p-1">
                                                    <button
                                                        onClick={() => setFeedback(prev => {
                                                            const newFeedback = { ...prev };
                                                            delete newFeedback[item._id];
                                                            return newFeedback;
                                                        })}
                                                        className="p-1 hover:bg-gray-700 rounded-full transition-colors"
                                                    >
                                                        <X size={18} className="text-gray-400" />
                                                    </button>
                                                    <button
                                                        onClick={() => handleCommentSubmit(item._id)}
                                                        className="p-1 hover:bg-gray-700 rounded-full transition-colors"
                                                        disabled={!feedback[item._id]?.comment.trim()}
                                                    >
                                                        <Send size={18} className={`${feedback[item._id]?.comment.trim() ? 'text-blue-400' : 'text-gray-400'}`} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div ref={reportEndRef} />
                        </div>
                    ) : (
                        // <div className="text-lg bg-gray-200 text-gray-500 rounded-lg p-3">
                        //     <p>
                        //         <span className="text-gray-700 whitespace-pre-wrap">
                        //             {t('features.swimlane.howCanHelpYouToday')}
                        //         </span>
                        //     </p>
                        // </div>
                        <div className="grid gap-2 mb-4">
                            <h6>{t('features.swimlane.suggestions')}</h6>
                            {promptSuggestions.map((suggestion, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleSubmit(suggestion.prompt)}
                                    className="flex items-center gap-3 p-2 bg-blue-50 text-left hover:bg-gray-50 rounded-lg border border-gray-200 transition-colors group"
                                // title={suggestion.description}
                                >
                                    <div className="p-2 bg-blue-50 rounded-lg text-blue-600 group-hover:bg-blue-100">
                                        {suggestion.icon}
                                    </div>
                                    <div>
                                        <h3 className="font-medium text-gray-900 text-xs ">{slice(suggestion.title, 50)}</h3>
                                        <p className={`text-xs text-gray-500`}>{slice(suggestion.description, 120)}</p>
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}
            </div>

            <div className="border-t border-gray-200 rounded-t-xl pt-1 absolute fixed bottom-0 left-0 right-0 mr-2 ml-2 bg-white ">
                <div className="relative px-2">
                    <div className={`relative bg-gray-100 flex ${prompt ? "rounded-2xl" : "rounded-full"}`}>
                        <textarea
                            ref={textAreaRef}
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onClick={(e) => e.stopPropagation()}
                            onFocus={(e) => e.stopPropagation()}
                            placeholder={t('features.swimlane.enterYourPrompt') + '...'}
                            className={`w-full pr-4 pl-4 py-3 border-none bg-transparent text-gray-700 placeholder-gray-500 ${prompt ? "rounded-2xl" : "rounded-full"} focus:ring-1 focus:ring-gray-300 focus:outline-none resize-none`}
                            disabled={isLoading}
                            style={{
                                height: "6vh",
                                minHeight: '48px',
                                maxHeight: '700px',
                                overflow: 'hidden'
                            }}
                        />
                        {/* <div className="absolute right-2 top-1/2 -translate-y-1/2">
                            <button
                                onClick={handleSubmit}
                                disabled={isLoading || !prompt.trim()}
                                className="p-2 text-gray-400 hover:text-gray-600 disabled:text-gray-300 disabled:cursor-not-allowed transition-colors"
                                title={t('words.send')}
                            >
                                {isLoading ? (
                                    <Loader2 size={20} className="animate-spin" />
                                ) : (
                                    <Send size={20} />
                                )}
                            </button>
                        </div> */}
                        {isLoading && (
                            <div className='absolute right-2 top-1/2 -translate-y-1/2'>
                                <Loader2 size={20} className="animate-spin" />
                            </div>
                        )
                        }
                    </div>
                </div>
                <p className="mt-2 pb-1 text-xs text-gray-500 text-center">
                    {t('features.swimlane.famlaMake')}
                </p>
            </div>
        </>
    );
}

export default AnalysisPanel;